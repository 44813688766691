
import { AbaseLoading, AbaseTextField, AbaseCheckbox, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import { RouteComponentProps } from 'react-router';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup'
import {
    EmpresaAllComponent,

    ObservacaoTipoQueryByIdComponent, useObservacaoTipoCreate, useObservacaoTipoUpdate
} from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import ObservacaoTipoLabel from './ObservacaoTipoNameLabel'

const ObservacaoTipoSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    empId: Yup.string().notRequired(),
    gestorChaveintegracao: Yup.string().required(),
    descricao: Yup.string().required(),
}).noUnknown()

function ObservacaoTipoForm(props) {
    const { match, history, location } = props
    const { params } = match
    const { showError, showSnack } = useAbaseDialog()
    const update = useObservacaoTipoUpdate()
    const insert = useObservacaoTipoCreate()
    const handleSubmit = async (values) => {
        const mutationData = await cast(ObservacaoTipoSchema, values)
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                })
                if (props.onSave) {
                    props.onSave(_.get(result, "data.observacaoTipo.update.id"))
                }
            } else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                })
                if (props.onSave) {
                    props.onSave(_.get(result, "data.observacaoTipo.create.id"))
                }
                clearDraft()
            }

            redirectBackForm(history, location)
            showSnack(defaultSnack("save"))
        } catch (err) {
            console.log({ err })
            showError({ message: defaultErrorMessage("query_one", err) })

        }
    }
    const notEditting = !params || !params.id
    const isUsingDraft = notEditting
    return (

        <ObservacaoTipoQueryByIdComponent
            variables={params}
            skip={notEditting}
            onError={(err) => {
                showError({ message: defaultErrorMessage("query_one", err) })
                redirectBackForm(history, location)
            }}
        >
            {({ data, loading, refetch, error }) => (

                <FormDraft
                    useDraft={notEditting}
                    onSubmit={handleSubmit}
                    validate={validate(ObservacaoTipoSchema)}
                    initialValues={_.get(data, "observacaoTipo.list[0]") || {}}
                    decorators={defaultDecorators}
                    render={({ handleSubmit, form, submitting }) => (
                        <DialogForm title="ObservacaoTipo" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                            <AbaseLoading open={loading || submitting} />


                            <Field name="gestorChaveintegracao">{({ input, meta }) => (
                                <AbaseTextField meta={meta} fullWidth={true} {...input} label={ObservacaoTipoLabel.gestorChaveintegracao} />
                            )}</Field>
                            <Field name="descricao">{({ input, meta }) => (
                                <AbaseTextField meta={meta} fullWidth={true} {...input} label={ObservacaoTipoLabel.descricao} />
                            )}</Field>
                            <Field name="ativo">{({ input, meta }) => (
                                <AbaseCheckbox meta={meta} {...input} label={ObservacaoTipoLabel.ativo} />
                            )}</Field>
                            <EmpresaAllComponent>{({ data, loading }) => (
                                <Field name="empId" >{({ input, meta }) => (
                                    <AbaseAutoCompleteFieldFinalForm isLoading={loading} label={ObservacaoTipoLabel.emp} valueField={"id"} textField={"razaosocial"} data={_.get(data, "empresa.list", [])} {...input} />)}</Field>
                            )}
                            </EmpresaAllComponent>
                        </DialogForm>
                    )}
                />


            )}
        </ObservacaoTipoQueryByIdComponent>
    )
}

export default ObservacaoTipoForm