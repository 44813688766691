import { AbaseLoading, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { EmpresaAllComponent, RequisicaoTipoAllComponent, UsuarioAllComponent, RequisicaoResponsavelQueryByIdComponent, useRequisicaoResponsavelCreate, useRequisicaoResponsavelUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import RequisicaoResponsavelLabel from './RequisicaoResponsavelNameLabel';
import { Grid } from '@material-ui/core';
const RequisicaoResponsavelSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    reqId: Yup.string().notRequired(),
    usuId: Yup.string().notRequired(),
    empId: Yup.string().notRequired(),
}).noUnknown();
function RequisicaoResponsavelForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useRequisicaoResponsavelUpdate();
    const insert = useRequisicaoResponsavelCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(RequisicaoResponsavelSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.requisicaoResponsavel.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.requisicaoResponsavel.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<RequisicaoResponsavelQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
            {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(RequisicaoResponsavelSchema)} initialValues={_.get(data, "requisicaoResponsavel.list[0]") || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Responsável de requisição" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                            <AbaseLoading open={loading || submitting}/>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <EmpresaAllComponent>{({ data, loading }) => (<Field name="empId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={RequisicaoResponsavelLabel.emp} valueField={"id"} textField={"razaosocial"} data={_.get(data, "empresa.list", [])} {...input}/>)}</Field>)}
                                    </EmpresaAllComponent>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <RequisicaoTipoAllComponent>{({ data, loading }) => (<Field name="reqId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={RequisicaoResponsavelLabel.req} valueField={"id"} textField={"descricao"} data={_.get(data, "requisicaoTipo.list", [])} {...input}/>)}</Field>)}
                                    </RequisicaoTipoAllComponent>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <UsuarioAllComponent variables={{ cliente: false }}>{({ data, loading }) => (<Field name="usuId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={RequisicaoResponsavelLabel.usu} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input}/>)}</Field>)}
                                    </UsuarioAllComponent>
                                </Grid>
                            </Grid>
                        </DialogForm>)}/>)}
        </RequisicaoResponsavelQueryByIdComponent>);
}
export default RequisicaoResponsavelForm;
