const names = {
    form: 'Restaurante',
    id: 'Id',
    nome: 'Nome',
    posicaoGeografica: 'Localização',
    telefone: 'Telefone',
    email: 'E-mail',
    site: 'Site',
    cidade: 'Cidade',
    endereco: 'Endereço',
    preferencia: 'Preferência',
    celular: 'Celular',
    precoBuffetLivre: 'Preço Buffet Livre',
    precoKg: 'Preço Por Kilo',
    tipo: 'Tipo',
    referencia: 'Referência',
    almoco: 'Almoço',
    janta: 'Jantar',
    cafeManha: 'Cafe da Manhã'
};
export default names;
