/* eslint-disable no-useless-computed-key */
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import { withRouter } from 'react-router-dom';
import { UsuarioAllComponent } from '../../generated/graphql';
import { AbaseAutocompleteMultipleField } from 'abase-components';
import UserAvatarSac from '../../components/UserAvatarSac';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
const SingleValue = props => {
    console.log("SINGLE VALUE", props);
    return <span {...props}>uÉ</span>;
};
function Option(props) {
    return (<MenuItem buttonRef={props.innerRef} selected={props.isFocused} component="div" style={{
        fontWeight: props.isSelected ? 500 : 400,
        padding: "3px 10px"
    }} {...props.innerProps}>
            {props.children}
        </MenuItem>);
}
function MultiValue(props) {
    return (<Chip tabIndex={-1} label={props.children} className={classNames(props.selectProps.classes !== null ? props.selectProps.classes.chip : '', {
        ['chipFocused']: props.isFocused,
    })} onDelete={props.removeProps.onClick} deleteIcon={<CancelIcon {...props.removeProps}/>}/>);
}
const StepParticipantes = ({ match, navigatePrevKeyDown, navigateNextKeyDown, focusRef }) => {
    return (<React.Fragment>
            <UsuarioAllComponent variables={{
        cliente: false
    }}>{({ data, loading, refetch }) => (<Field name="agendaUsuario">{({ input, meta }) => (<React.Fragment>
                        {<AbaseAutocompleteMultipleField isLoading={loading} label={"Participantes"} data={_.get(data, "usuario.list", []).map((x) => {
        if (input.value) {
            const usuAlreadyExists = _.first(input.value.filter((y) => y.usu.id === x.id));
            if (usuAlreadyExists) {
                return usuAlreadyExists;
            }
        }
        return { usu: x, usuId: x.id };
    })} value={input.value} onChange={input.onChange} getOptionValue={x => x} valueComponent={props => (<React.Fragment>
                                    <UserAvatarSac name={props.data.usu.nome} color={props.data.usu.corAvatar} url={props.data.usu.avatarUrl} size={15}/>&nbsp;&nbsp;{props.data.usu.nome}
                                </React.Fragment>)} getOptionLabel={x => x.usu.nome} onKeyDown={(e) => {
        navigatePrevKeyDown(e);
        navigateNextKeyDown(e);
    }} forwardRef={focusRef} id={"tab-1-focus"}/>}

                    </React.Fragment>)}</Field>)}
            </UsuarioAllComponent>
        </React.Fragment>);
};
// getOptionLabel={x => <div><UserAvatarSac name={x.usu.nome} color={x.usu.corAvatar} url={x.usu.avatarUrl} size={22} /> {x.usu.nome}</div>}
export default withRouter(StepParticipantes);
