import React from 'react';
import { Grid, Card, CardContent, Typography, withStyles } from '@material-ui/core';
import { niveisEnum, getNivel } from '../../services/login';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
const styles = {
    bullet: {
        margin: '0 2px',
        transform: 'scale(0.8)',
        display: 'inline-block'
    },
    title: {
        fontSize: 15,
        color: '#404040'
    }
};
function HomePage(props) {
    const { classes } = props;
    const nivel = getNivel();
    return (<React.Fragment>
        <style>
            {`.dentroConteudo > div {
                   top: 0; 
                   margin: 0;
                   padding: 0;
                   height: 100%;
                }`}
            {`.dentroConteudo .material-icons {
                    width: 100%;
                    font-size: 52px;
                    margin-bottom: 5px;
                }`}
        </style>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <b>ACESSO RÁPIDO</b>
                        </Typography>
                        <div className="colHome">
                            <Link to="agenda/agenda" className="blocoHome">
                                <Icon>today</Icon>
                                AGENDAS
                                </Link>
                            <Link to="agenda/calendar" className="blocoHome">
                                <Icon>event_note</Icon>
                                CALENDÁRIO
                                </Link>
                            {nivel === niveisEnum.administrativo || nivel === niveisEnum.master ? (<Link to="agenda/ordemdeservico" className="blocoHome">
                                <Icon>description</Icon>
                                ORDEM DE SERVIÇO
                            </Link>) : null}
                            <Link to="agenda/recursolocacao" className="blocoHome">
                                <Icon>local_activity</Icon>
                                RESERVA DE RECURSOS
                                </Link>
                            <Link to="perfil" className="blocoHome">
                                <Icon>account_circle</Icon>
                                PERFIL
                                </Link>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    </React.Fragment>);
}
export default withStyles(styles)(HomePage);
