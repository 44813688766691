import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseAutocompleteMultipleField } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import * as Yup from 'yup';
import { ModeloProjetoQueryByIdComponent, useModeloProjetoCreate, useModeloProjetoUpdate, UsuarioAllComponent } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import ModeloProjetoLabel from './ModeloProjetoNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { Grid, AppBar, Button, Step, Stepper, StepButton, StepLabel, Dialog, Toolbar, IconButton, Typography, DialogContent, withStyles } from '@material-ui/core';
import ModeloEtapaList from '../ModeloEtapa/ModeloEtapaList';
import ModeloTarefaList from '../ModeloTarefa/ModeloTarefaList';
import SaveIcon from '@material-ui/icons/Save';
import withIsMobile from '../../components/withIsMobile';
const ModeloProjetoSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    descricao: Yup.string().required(),
    participantes: Yup.array(Yup.object().shape({
        id: Yup.string().required(),
        nome: Yup.string().required(),
        permissao: Yup.number().required(),
        cliente: Yup.boolean().required(),
    }).noUnknown()).notRequired()
}).noUnknown();
function ModeloProjetoForm(props) {
    const { match, history, location, isMobile } = props;
    const { classes } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useModeloProjetoUpdate();
    const insert = useModeloProjetoCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(ModeloProjetoSchema, values);
        try {
            if (params && params.modeloProjetoId) {
                const result = await update({
                    variables: {
                        id: params.modeloProjetoId,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.modeloProjeto.update.id"));
                    history.push("/projeto/modelo");
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.modeloProjeto.create.id"));
                }
                clearDraft();
                history.push(`/projeto/modeloprojetoform/edit/${_.get(result, 'data.modeloProjeto.create.id')}/aba/etapas`);
            }
            // redirectBackForm(history, location)
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.modeloProjetoId;
    var modeloProjetoId = props.match.params.modeloProjetoId;
    const [indicator, setindicator] = React.useState(0);
    let value = modeloProjetoId && props.match.params.aba ? props.match.params.aba : 'projeto';
    function handleChange(event, newValue) {
        props.match.params.aba = newValue;
        var url = props.match.path;
        var aba = "";
        switch (props.match.params.aba) {
            case 0:
                aba = 'etapas';
                setindicator(0);
                break;
            case 1:
                aba = 'tarefas';
                setindicator(1);
                break;
            case 2:
                aba = 'participante';
                setindicator(2);
                break;
            default:
                aba = 'projeto';
                setindicator(0);
                break;
        }
        url = url.replace(`:modeloProjetoId`, props.match.params.modeloProjetoId);
        url = url.replace(`:aba`, aba);
        props.history.push(url);
    }
    return (<ModeloProjetoQueryByIdComponent variables={{ id: params.modeloProjetoId }} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => {
            let initial = _.get(data, "modeloProjeto.list[0]");
            _.set(initial, 'participantes', _.get(initial, 'modeloProjetoParticipante', []).map(x => x.usu));
            console.log(initial);
            return (<Dialog fullScreen open={true}>
                {isMobile ? (<AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={() => {
                            props.history.push("/projeto/modelo");
                        }}>
                            <i className="material-icons" title="Fechar">
                                close
                                        </i>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Modelo do Projeto
                                    </Typography>
                        <Button type="Button" variant="contained" className="btnSuccess ml5">
                            <SaveIcon>send</SaveIcon> Salvar
                                    </Button>
                    </Toolbar>
                </AppBar>) : (<AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Manutenção Modelo do Projeto
                                    </Typography>
                        <Button variant="contained" onClick={() => {
                            props.history.push("/projeto/modelo");
                        }}>
                            <i className="material-icons">close</i> Cancelar
                                        </Button>
                        <Button type="Button" className="btnSuccess ml5" variant="contained">
                            <SaveIcon>send</SaveIcon> Salvar
                                        </Button>
                    </Toolbar>
                </AppBar>)}
                <FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(ModeloProjetoSchema)} initialValues={initial || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogContent className="mt15">
                    <form onSubmit={handleSubmit}>
                        <AbaseLoading open={loading || submitting} />
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Field name="descricao" parse={defaultFieldParser}>
                                    {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ModeloProjetoLabel.descricao} />)}
                                </Field>
                            </Grid>
                            {modeloProjetoId ?
                                <Grid item xs={12} className="mt15">
                                    <Stepper nonLinear alternativeLabel activeStep={indicator}>
                                        {[
                                            { label: "Etapas" },
                                            { label: "Tarefas" },
                                            { label: "Participantes" },
                                        ].map((x, i) => (<Step key={x.label} {...props}>
                                            <StepButton onClick={(e) => handleChange(e, i)} style={{ padding: '5px', margin: '0px -8px 0px -5px' }} disabled={!modeloProjetoId}>
                                                <StepLabel>{x.label.toUpperCase()}</StepLabel>
                                            </StepButton>
                                                            )}
                                        </Step>))}
                                    </Stepper>
                                </Grid>
                                : null}
                            <div className={classes.grids}>
                                {value === 'etapas' && (<div><ModeloEtapaList {...props} /></div>)}
                                {value === 'tarefas' && (<div><ModeloTarefaList {...props} /></div>)}
                                {value === 'participante' && (<div>
                                    <div className={"colDivBloco"}>
                                        <UsuarioAllComponent>{({ data, loading }) => (<Field name="participantes">{({ input, meta }) => (<AbaseAutocompleteMultipleField label="Participantes" data={_.get(data, 'usuario.list', [])} isLoading={loading} textField="nome" {...input}>
                                        </AbaseAutocompleteMultipleField>)}
                                        </Field>)}
                                        </UsuarioAllComponent>
                                    </div>
                                </div>)}
                            </div>
                            <Grid container spacing={1}>
                                <Grid item xs={12} className="mt15">
                                    <div className="center mt15">
                                        <Button key="cancelar" variant="contained" onClick={() => {
                                            props.history.push("/projeto/modelo");
                                        }}>
                                            <i className="material-icons">close</i> Cancelar
                                                        </Button>
                                        <Button type="Button" variant="contained" className="btnSuccess ml5" key="submit" onClick={(event) => form.submit()}>
                                            <SaveIcon>send</SaveIcon> Salvar
                                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    <button type="submit" hidden />
                </DialogContent>)} />
            </Dialog>);
        }}
    </ModeloProjetoQueryByIdComponent>);
}
const styles = {
    appBar: {
        position: 'relative'
    },
    appBarBottom: {
        bottom: 0,
        top: 'auto'
    },
    flex: {
        flex: 1
    },
    toolbar: {
        color: '#404040',
        backgroundColor: '#e1e8ed'
    },
    grids: {
        width: '100%',
        padding: '15px',
        marginTop: '15px',
        display: 'inline-block',
        backgroundColor: '#f8f8f8',
        border: '1px solid #e0e0e0',
        '& label': {
            backgroundColor: '#f8f8f8',
        },
        '& input': {
            backgroundColor: '#f8f8f8',
        },
        '& textarea': {
            backgroundColor: '#f8f8f8',
        },
    },
    stepper: {
        padding: 0,
        marginTop: '20px',
        '& span': {
            fontWeight: 'bold',
        },
    },
    appBarIndicator: {
        backgroundColor: "#ffffff"
    },
    appBarSelected: {
        color: "#ffffff"
    }
};
export default withIsMobile(withStyles(styles)(ModeloProjetoForm));
