/* eslint-disable array-callback-return */
import { AbaseAutoCompleteFieldFinalForm, AbaseDatePickerField, AbaseLoading, AbaseTextField, clearDraft, FormDraft, useAbaseDialog, AbaseAutocompleteMultipleField, AbaseCheckbox } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import * as Yup from "yup";
import { ProjetoQueryByIdComponent, useProjetoCreate, useProjetoUpdate, UsuarioAllComponent, UsuarioContatoClienteAllComponent, useModeloProjetoAll, useProjetoAndamento, ParceiroAllComponent } from "../../generated/graphql";
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from "../../helpers";
import { cast, validate } from "../../helpers/validations";
import ProjetoLabel from "./ProjetoNameLabel";
import { Grid, AppBar, Button, DialogTitle, Stepper, Step, StepButton, StepLabel, withStyles, Dialog, DialogContent, Typography, Toolbar, IconButton } from "@material-ui/core";
import ProjetoEtapaList from "../ProjetoEtapa/ProjetoEtapaList";
import ProjetoApontamentoList from "../ProjetoApontamento/ProjetoApontamentoList";
import TarefaList from "../Tarefa/TarefaList";
import ProjetoAnexoList from "../ProjetoAnexo/ProjetoAnexoList";
import AgendaTarefaList from "../AgendaTarefa/AgendaTarefaList";
import { defaultFieldParser } from "../../helpers/finalFormDecorators";
import AutoCompleteCliente from "../../components/AutoCompleteCliente";
import EmpresaEquipeArea from "../Agenda/EmpresaEquipeArea";
import CardContato from "../../components/CardContato";
import withIsMobile from "../../components/withIsMobile";
import SaveIcon from '@material-ui/icons/Save';
const ProjetoSchema = Yup.object()
    .shape({
        id: Yup.string().notRequired(),
        liderUsuId: Yup.string().notRequired(),
        responsavelUsuId: Yup.string().notRequired(),
        empId: Yup.string().notRequired(),
        areId: Yup.string().notRequired(),
        equId: Yup.string().notRequired(),
        modId: Yup.string().notRequired(),
        descricao: Yup.string().required(),
        prazo: Yup.date().notRequired(),
        dataLimite: Yup.date().notRequired(),
        observacao: Yup.string().required(),
        cliId: Yup.string().notRequired(),
        confidencial: Yup.bool().notRequired(),
        participantes: Yup.array(Yup.object().shape({
            id: Yup.string().required(),
            nome: Yup.string().notRequired(),
            permissao: Yup.number().notRequired(),
            cliente: Yup.boolean().notRequired(),
        }).noUnknown()).notRequired(),
        parId: Yup.string().notRequired()
    })
    .noUnknown();
function ProjetoForm(props) {
    const { match, history, location, isMobile } = props;
    const { classes } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useProjetoUpdate();
    const insert = useProjetoCreate();
    const [editandoTitulo, setEditandoTitulo] = React.useState(!params.projetoId);
    const { data: dataModeloProjeto } = useModeloProjetoAll();
    const { data: dataProjetoAndamento } = useProjetoAndamento({ skip: !params.projetoId, variables: { projetoId: params.projetoId } });
    const handleSubmit = async (values) => {
        values.cliId = _.get(values, "cli.id");
        const mutationData = await cast(ProjetoSchema, values);
        if (mutationData.participantes)
            mutationData.participantes.map(x => {
                delete x['__typename'];
            });
        try {
            if (params && params.projetoId) {
                const result = await update({
                    variables: {
                        id: params.projetoId,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.projeto.update.id"));
                }
                props.history.push('/projeto');
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                const projetoId = _.get(result, "data.projeto.create.id");
                let url = props.match.url;
                url = url.replace('/projeto/projetoform', '/projeto/projetoform/edit/:projetoId/aba/:aba');
                url = url.replace(`:projetoId`, projetoId);
                url = url.replace(`:aba`, 'projeto');
                clearDraft();
                props.history.push(url);
            }
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params.projetoId;
    const [participantes, setParticipantes] = React.useState(false);
    var projetoId = props.match.params.projetoId;
    const [indicator, setindicator] = React.useState(0);
    let value = projetoId && props.match.params.aba ? props.match.params.aba : 'projeto';
    function handleChange(event, newValue) {
        props.match.params.aba = newValue;
        var url = props.match.path;
        var aba = "";
        switch (props.match.params.aba) {
            case 0:
                aba = 'projeto';
                setindicator(0);
                break;
            case 1:
                aba = 'etapas';
                setindicator(1);
                break;
            case 2:
                aba = 'apontamentos';
                setindicator(2);
                break;
            case 3:
                aba = 'tarefas';
                setindicator(3);
                break;
            case 4:
                aba = 'anexos';
                setindicator(4);
                break;
            case 5:
                aba = 'agenda';
                setindicator(5);
                break;
            default:
                aba = 'projeto';
                setindicator(0);
                break;
        }
        url = url.replace(`:projetoId`, props.match.params.projetoId);
        url = url.replace(`:aba`, aba);
        props.history.push(url);
    }
    return (<ProjetoQueryByIdComponent variables={{ id: params.projetoId }} skip={notEditting} onError={err => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} subscription={{
            submitting: true
        }} validate={validate(ProjetoSchema)} initialValues={_.get(data, "projeto.list[0]") || { confidencial: true }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<React.Fragment>
            <Dialog fullScreen open={true}>
                {isMobile ? (<AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" aria-label="Close" onClick={() => { props.history.push("/projeto"); }}>
                            <i className="material-icons" title="Fechar">
                                close
                                                </i>
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Manutenção Projetos
                                             </Typography>
                        <Button type="Button" variant="contained" className="btnSuccess ml5" key="submit" onClick={(event) => {
                            form.submit();
                        }}>
                            <SaveIcon>send</SaveIcon> Salvar
                                            </Button>
                    </Toolbar>
                </AppBar>) : (<AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Manutenção Projetos
                                            </Typography>
                        <Button key="cancelar" variant="contained" onClick={() => { props.history.push("/projeto"); }}>
                            <i className="material-icons">close</i> Cancelar
                                            </Button>
                        <Button variant="contained" className="btnSuccess ml5" onClick={() => {
                            form.change("action", "none");
                            handleSubmit();
                        }}>
                            <SaveIcon>send</SaveIcon> Salvar
                                            </Button>
                    </Toolbar>
                </AppBar>)}
                <AbaseLoading open={loading || submitting} />
                <DialogContent className="mt15">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Field name="descricao" parse={defaultFieldParser}>
                                {({ input, meta }) => {
                                    return editandoTitulo ?
                                        <AbaseTextField meta={meta} fullWidth={true} {...input} label={"Nome do projeto"} onBlur={() => {
                                            if (editandoTitulo && input.value)
                                                setEditandoTitulo(false);
                                        }} />
                                        : <DialogTitle style={{ cursor: "pointer", "padding": "5px 5px 0 5px" }} id="form-dialog-title" onClick={() => {
                                            if (!editandoTitulo)
                                                setEditandoTitulo(true);
                                        }}>
                                            {input.value}
                                        </DialogTitle>;
                                }}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Stepper nonLinear alternativeLabel activeStep={indicator}>
                                {[
                                    { label: "Projeto", complete: _.get(dataProjetoAndamento, "projeto.pagedList.total") },
                                    { label: "Etapas", complete: _.get(dataProjetoAndamento, "projetoEtapa.pagedList.total") },
                                    { label: "Apontamentos", complete: _.get(dataProjetoAndamento, "projetoApontamento.pagedList.total") },
                                    { label: "Tarefas", complete: _.get(dataProjetoAndamento, "tarefa.pagedList.total") },
                                    { label: "Anexos", complete: _.get(dataProjetoAndamento, "projetoAnexo.pagedList.total") },
                                    { label: "Agenda", complete: _.get(dataProjetoAndamento, "agenda.pagedList.total") }
                                ].map((x, i) => (<Step key={x.label} {...props}>
                                    <StepButton onClick={(e) => handleChange(e, i)} style={{ padding: '5px', margin: '0px -8px 0px -5px' }} disabled={i !== 0 && !projetoId} completed={x.complete}>
                                        <StepLabel>{x.label.toUpperCase()}</StepLabel>
                                    </StepButton>
                                                        )}
                                </Step>))}
                            </Stepper>
                        </Grid>
                        <Grid container spacing={0} className="colForm">
                            <Grid item xs={12}>
                                {value === 'projeto' &&
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={1}>
                                            <EmpresaEquipeArea disableEmpresa={params.projetoId} />
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <Field name="prazo">
                                                    {({ input, meta }) => (<AbaseDatePickerField meta={meta} {...input} label={ProjetoLabel.prazo} />)}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <Field name="dataLimite">
                                                    {({ input, meta }) => (<AbaseDatePickerField meta={meta} {...input} label={ProjetoLabel.dataLimite} />)}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <Field name="cli">{({ input, meta }) => (<AutoCompleteCliente {...input} />)}</Field>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <Field name={'cli'}>{({ input: { value: cli } }) => (<UsuarioContatoClienteAllComponent skip={!_.get(cli, "id")} variables={{
                                                    cliId: _.get(cli, "id")
                                                }}>
                                                    {({ data, loading }) => (<Field name={'responsavelUsuId'}>
                                                        {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} itemHeight={73} components={{ Option: CardContato }} meta={meta} label={'Contato'} valueField={'id'} textField={'nome'} data={_.get(data, 'usuario.list')} {...input} />)}
                                                    </Field>)}
                                                </UsuarioContatoClienteAllComponent>)}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <UsuarioAllComponent variables={{ cliente: false }}>
                                                    {({ data, loading }) => (<Field name="liderUsuId">
                                                        {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={"Líder"} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input} />)}
                                                    </Field>)}
                                                </UsuarioAllComponent>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                                <Field name="parId">
                                                    {({ input, meta }) => {
                                                        return (<ParceiroAllComponent>{({ data, loading }) => {
                                                            return !loading ? <AbaseAutoCompleteFieldFinalForm label={"Parceiro"} meta={meta} valueField={'id'} textField={'nome'} data={_.get(data, 'parceiro.list', [])} {...input} /> : null;
                                                        }}</ParceiroAllComponent>);
                                                    }}
                                                </Field>
                                            </Grid>
                                            {!params.projetoId ?
                                                <Grid item xs={12}>
                                                    <Field name="modId">
                                                        {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={"Modelo de Projeto"} valueField={"id"} textField={"descricao"} meta={meta} {...input} data={_.get(dataModeloProjeto, "modeloProjeto.list", [])} onChange={(val) => {
                                                            // input.onChange(val)
                                                            const modelo = _.get(dataModeloProjeto, "modeloProjeto.list", []).filter(x => x.id === val);
                                                            let participantes = modelo.map(x => _.get(x, 'modeloProjetoParticipante'))[0];
                                                            form.change("participantes", participantes.map(x => _.get(x, 'usu')));
                                                            form.change("modId", val);
                                                        }} />)}
                                                    </Field>
                                                </Grid>
                                                : null}
                                            <Grid item xs={12}>
                                                <UsuarioAllComponent variables={{ cliente: false }}>{({ data, loading }) => {
                                                    return <Field name={"projetoParticipante"}>
                                                        {({ input: inputProjetoParticipantes }) => {
                                                            if (inputProjetoParticipantes.value !== "") {
                                                                var participantesAtuais = inputProjetoParticipantes.value.map(x => x ? x.usu : null);
                                                                return (<Field name={"participantes"}>{({ input }) => {
                                                                    if (!participantes && !loading) {
                                                                        input.onChange(participantesAtuais);
                                                                        setParticipantes(true);
                                                                    }
                                                                    return (<AbaseAutocompleteMultipleField label="Participantes" data={_.get(data, 'usuario.list', [])} isLoading={loading} textField="nome" {...input}>
                                                                    </AbaseAutocompleteMultipleField>);
                                                                }}
                                                                </Field>);
                                                            }
                                                            else
                                                                return <Field name={"participantes"}>{({ input }) => (<AbaseAutocompleteMultipleField label="Participantes" data={_.get(data, 'usuario.list', [])} isLoading={loading} textField="nome" {...input}>
                                                                </AbaseAutocompleteMultipleField>)}
                                                                </Field>;
                                                        }}
                                                    </Field>;
                                                }}</UsuarioAllComponent>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field name="observacao" parse={defaultFieldParser}>
                                                    {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} multiline rows="4" label={ProjetoLabel.observacao} />)}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Field name="confidencial">
                                                    {({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"Confidencial"} />)}
                                                </Field>
                                            </Grid>
                                            <button type="submit" hidden />
                                        </Grid>
                                    </form>}
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className={classes.grids}>
                                    {value === 'etapas' && (<div><ProjetoEtapaList projetoId={projetoId} {...props} /></div>)}
                                    {value === 'apontamentos' && <ProjetoApontamentoList projetoId={projetoId} {...props} />}
                                    {value === 'tarefas' && (<div><TarefaList projetoId={projetoId} {...props} /></div>)}
                                    {value === 'anexos' && <ProjetoAnexoList projetoId={projetoId} {...props} />}
                                    {value === 'agenda' && (<div className={"colDivBloco"}><AgendaTarefaList {...props} /></div>)}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} className="mt15">
                                <div className="center mt15">
                                    <Button key="cancelar" variant="contained" onClick={() => {
                                        props.history.push("/projeto");
                                    }}>
                                        <i className="material-icons">close</i> Cancelar
                                                    </Button>
                                    <Button type="Button" variant="contained" className="btnSuccess ml5" key="submit" onClick={(event) => {
                                        form.submit();
                                    }}>
                                        <SaveIcon>send</SaveIcon> Salvar
                                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>)} />)}
    </ProjetoQueryByIdComponent>);
}
const styles = {
    appBar: {
        position: 'relative'
    },
    appBarBottom: {
        bottom: 0,
        top: 'auto'
    },
    flex: {
        flex: 1
    },
    toolbar: {
        color: '#404040',
        backgroundColor: '#e1e8ed'
    },
    grids: {
        width: '100%',
        padding: '15px',
        marginTop: '15px',
        display: 'inline-block',
        backgroundColor: '#f8f8f8',
        border: '1px solid #e0e0e0',
        '& label': {
            backgroundColor: '#f8f8f8',
        },
        '& input': {
            backgroundColor: '#f8f8f8',
        },
        '& textarea': {
            backgroundColor: '#f8f8f8',
        },
    },
    stepper: {
        padding: 0,
        marginTop: '20px',
        '& span': {
            fontWeight: 'bold',
        },
    },
    appBarIndicator: {
        backgroundColor: "#ffffff"
    },
    appBarSelected: {
        color: "#ffffff"
    }
};
export default withIsMobile(withStyles(styles)(ProjetoForm));
