import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ProjetoAnexoListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import ProjetoAnexoDelete from "./ProjetoAnexoDelete";
import ProjetoAnexoForm from "./ProjetoAnexoForm";
import ProjetoAnexoLabel from "./ProjetoAnexoNameLabel";
import { IconButton } from "@material-ui/core";
import { RequestDownload } from "../../services/request";
export default function ProjetoAnexoList(props) {
    const { match } = props;
    const columns = [
        {
            name: ProjetoAnexoLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: ProjetoAnexoLabel.descricao,
            field: "descricao"
        },
        {
            name: ProjetoAnexoLabel.tipo,
            field: "tipo",
            width: 100
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<IconButton aria-label={`Baixar Anexo`} onClick={(evt) => downloadAnexo(selected)}>
                <i className={"material-icons"}>cloud_download</i>
            </IconButton>) : null,
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/delete/${_.map(selected, "id").join(",")}`} />
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.url}/add`} />
    ];
    const filters = () => [
        <Field name={"id"}>
            {({ input, meta }) => (<AbaseTextField label={"ID"} {...input} meta={meta} />)}
        </Field>,
        <Field name={"descricao"}>
            {({ input, meta }) => (<AbaseTextField label={"Descricao"} {...input} meta={meta} />)}
        </Field>,
        <Field name={"tipo"}>
            {({ input, meta }) => (<AbaseTextField label={"Tipo"} {...input} meta={meta} />)}
        </Field>
    ];
    return (<React.Fragment>
        <div className={"colDivBloco"}>
            <GraphqlPaperTableListener>
                {({ variables, setVariables }) => {
                    if (props.projetoId) {
                        if (!variables || variables.projetoId !== props.projetoId) {
                            var variaveis = variables || {};
                            _.set(variaveis, "projetoId", props.projetoId);
                            setVariables(variaveis);
                        }
                    }
                    return (<ProjetoAnexoListComponent variables={variables} skip={variables === null}>
                        {({ data, loading, refetch, error }) => (<div>
                            <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "projetoAnexo.pagedList.data") || []} total={_.get(data, "projetoAnexo.pagedList.total")} columns={columns} customFilters={filters} title={`Anexos do Projeto`} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
                                downloadAnexo([row]);
                            }} />
                            <ReenterRouteListener callback={refetch} />
                            <Route path={`${match.path}/add`} render={RenderWithModal(ProjetoAnexoForm)} />
                            <Route path={`${match.path}/edit/:idProjetoAnexo`} render={RenderWithModal(ProjetoAnexoForm)} />
                            <Route path={`${match.path}/delete/:idProjetoAnexo`} render={RenderWithModal(ProjetoAnexoDelete)} />
                        </div>)}
                    </ProjetoAnexoListComponent>);
                }}
            </GraphqlPaperTableListener>
        </div>
    </React.Fragment>);
    function downloadAnexo(selected) {
        RequestDownload("api/projetoanexo/download", { id: selected[0].id }, 'GET');
    }
}
