import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, AppBar, Toolbar, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router';
import { redirectBackForm } from './helpers';
import SaveIcon from '@material-ui/icons/Save';
import useMediaQuery from '@material-ui/core/useMediaQuery';
const styles = {
	appBar: {
		position: 'relative',
	},
	flex: {
		flex: 1,
	},
};
function DialogFormComponent1(props) {
	const { children, onSubmit, reset, onClose, noScroll, dialogProps, classes } = props;
	const isMobile = useMediaQuery('@media (max-width:796px)');
	const actions = props.actions
		? props.actions
		: [
			<Grid container spacing={0}>
				<Grid item xs={12} className="center mb15">
					<Button onClick={onClose} key="cancelar" variant="contained">
						<i className="material-icons">close</i>Cancelar
					</Button>
					<Button type="button" variant="contained" className="btnSuccess ml5" key="submit" onClick={onSubmit}>
						<SaveIcon>send</SaveIcon>Salvar
					</Button>
				</Grid>
			</Grid>
		];
	const dialogExtra = isMobile && !dialogProps ? {
		maxWidth: false,
		fullWidth: true,
		fullScreen: true
	} : dialogProps;
	return (<Dialog disableRestoreFocus open={true} disableBackdropClick onClose={onClose} aria-labelledby="scroll-dialog-title" scroll={'paper'} fullWidth={true} maxWidth={false} {...dialogExtra} className="dialogs">
		{reset && false ? (<IconButton onClick={reset} style={{ position: 'absolute', right: '60px', top: '10px' }}>
			<i className="material-icons" title="Limpar Rascunho">
				bookmarks
					</i>
		</IconButton>) : null}
		{isMobile ? <React.Fragment>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton color="inherit" onClick={onClose} aria-label="Close">
						<i className="material-icons" title="Fechar">
							close
							</i>
					</IconButton>
					<Typography variant="h6" color="inherit" className={classes.flex}>
						{props.title}
					</Typography>
					<Button type="button" variant="contained" className="btnSuccess ml5" key="submit" onClick={onSubmit}>
						<SaveIcon>send</SaveIcon> Salvar
						</Button>
				</Toolbar>
			</AppBar>
			<form onSubmit={onSubmit} style={{ overflowY: "scroll", height: "100%" }}>
				<div style={{ padding: 5, overflowX: "hidden", height: "100%" }}>
					{children}
					{props.actions}
				</div>
				<button type="submit" hidden />
			</form>
		</React.Fragment> :
			<React.Fragment>
				<IconButton onClick={onClose} style={{ position: 'absolute', right: '10px', top: '10px' }}>
					<i className="material-icons" title="Fechar">
						close
				</i>
				</IconButton>

				<DialogTitle id="form-dialog-title"> {props.title} </DialogTitle>
				<DialogContent key="formulario" style={{ minWidth: isMobile ? 300 : 600, minHeight: 193, marginTop: '-15px', overflowY: noScroll ? 'initial' : 'scroll' }}>
					<form onSubmit={onSubmit}>
						{children}
						<button type="submit" hidden />
					</form>
				</DialogContent>
				<DialogActions key="acoes">{actions}</DialogActions>
			</React.Fragment>}

	</Dialog>);
}
export const DialogFormComponent = withStyles(styles)(DialogFormComponent1);
function DialogForm(props) {
	const onClose = () => {
		if (!props.dontUseRoute)
			redirectBackForm(props.history, props.location);
		if (props.onClose)
			props.onClose();
	};
	return <DialogFormComponent onClose={onClose} {...props} />;
}
export default withRouter(DialogForm);
