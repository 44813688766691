import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseDropZone } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { EmpresaQueryByIdComponent, useEmpresaCreate, useEmpresaUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import EmpresaLabel from './EmpresaNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { Grid } from '@material-ui/core';
const EmpresaSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    razaosocial: Yup.string().required(),
    nomefantasia: Yup.string().required(),
    logo: Yup.string().notRequired(),
}).noUnknown();
function EmpresaForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useEmpresaUpdate();
    const insert = useEmpresaCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(EmpresaSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.empresa.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.empresa.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<EmpresaQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
    }}>
        {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(EmpresaSchema)} initialValues={_.get(data, "empresa.list[0]") || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Empresa" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
            <AbaseLoading open={loading || submitting} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Field name="razaosocial" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={EmpresaLabel.razaosocial} />)}</Field>
                </Grid>
                <Grid item xs={12}>
                    <Field name="nomefantasia" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={EmpresaLabel.nomefantasia} />)}</Field>
                </Grid>
                <Grid item xs={12}>
                    <div className="mt15">
                        <Field name="logo">
                            {({ input, meta }) => {
                                return <React.Fragment>
                                    <AbaseDropZone accept="image/*" width="100%" onDrop={(accepted, rejected, event) => {
                                        const reader = new FileReader();
                                        reader.onabort = () => console.log('file reading was aborted');
                                        reader.onerror = () => console.log('file reading has failed');
                                        reader.onload = () => {
                                            const binaryStr = reader.result;
                                            var base64 = btoa(binaryStr);
                                            input.onChange(base64);
                                        };
                                        accepted.forEach(file => {
                                            reader.readAsBinaryString(file);
                                        });
                                    }} />
                                </React.Fragment>;
                            }}
                        </Field>
                    </div>
                </Grid>
            </Grid>
        </DialogForm>)} />)}
    </EmpresaQueryByIdComponent>);
}
export default EmpresaForm;
