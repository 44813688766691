import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { AreaListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import AreaDelete from "./AreaDelete";
import AreaForm from "./AreaForm";
import AreaLabel from './AreaNameLabel';
import EmpresaEquipeAreaFiltro from "../../components/EmpresaEquipeAreaFiltro";
export default function AreaList(props) {
    const { match } = props;
    const columns = [
        {
            name: AreaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: AreaLabel.descricao,
            field: "descricao"
        },
        {
            name: AreaLabel.emp,
            field: "emp.nomefantasia"
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" to={`${match.path}/delete/${_.map(selected, "id").join(",")}`} component={Link} />
        ];
    };
    const customActions = [
        <AddAction key="add" to={`${match.path}/add`} component={Link} />,
    ];
    const filters = () => ([
        <EmpresaEquipeAreaFiltro hideArea />,
        // <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descrição"} {...input} meta={meta}></AbaseTextField>}</Field>,

    ]);
    const formName = AreaLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<AreaListComponent variables={variables} skip={variables === null}>
        {({ data, loading, refetch, error }) => (<div>

            <AbasePaperTable
                customPlacehorderSearch={"Pesquisar por descrição"}
                sticky={true}
                selectMode={"multi"}
                loading={loading}
                data={_.get(data, "area.pagedList.data") || []}
                total={_.get(data, "area.pagedList.total")}
                columns={columns}
                customFilters={filters}
                title={formName}
                requestData={setVariables}
                error={errorMessagePaperTable(error)}
                customSelectedActions={selectedActions}
                customActions={customActions}
                selectListener={props.selectListener}
                onDoubleClick={row => {
                    props.history.push(`${match.path}/edit/${row.id}`);
                }}></AbasePaperTable>
            <ReenterRouteListener callback={refetch} />
            <Route path={`${match.path}/add`} render={RenderWithModal(AreaForm)} />
            <Route path={`${match.path}/edit/:id`} render={RenderWithModal(AreaForm)} />
            <Route path={`${match.path}/delete/:id`} render={RenderWithModal(AreaDelete)} />

        </div>)}
    </AreaListComponent>)}
    </GraphqlPaperTableListener>);
}
