import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import { withRouter } from 'react-router-dom';
import AutoCompleteWithEdit from '../../components/AutoCompleteWithEdit';
import MarkerPicker from '../../components/MarkerPicker';
import { LocalAllComponent } from '../../generated/graphql';
import LocalForm from '../Local/LocalForm';
import { Grid } from '@material-ui/core';
import { AbaseCheckbox } from 'abase-components';
const StepLocal = ({ match, navigatePrevKeyDown, navigateNextKeyDown, focusRef }) => {
    const [exibeMapa, setExibeMapa] = React.useState(false)
    return (<React.Fragment>
        <LocalAllComponent>{({ data, loading, refetch }) => (<Field name="locId">{({ input, meta }) => (<React.Fragment>
            <Grid container>
                <Grid item xs={11}>
                    <AutoCompleteWithEdit notEditable path={"local"} form={LocalForm} refetch={refetch} isLoading={loading} meta={meta} label={"Local"} valueField={"id"} textField={"descricao"} data={_.get(data, "local.list", [])} {...input} onKeyDown={(e) => {
                        navigatePrevKeyDown(e);
                        navigateNextKeyDown(e);
                    }} forwardRef={focusRef} />
                </Grid>
                <Grid item xs={1} className="text-center mt4">
                    <AbaseCheckbox label={"Mapa"} value={exibeMapa} onChange={event => setExibeMapa(event.target.checked)} />
                </Grid>
                <Grid item xs={12}>
                    {exibeMapa ? <div className="colRow b-1">
                        <MarkerPicker data={_.get(data, "local.list", [])} getDescription={x => x.descricao} getMarkerFromItem={x => x.posicaoMapa} value={input.value} onChange={input.onChange} />
                    </div> : null}
                </Grid>
            </Grid>
        </React.Fragment>)}</Field>)}
        </LocalAllComponent>
    </React.Fragment>);
};
export default withRouter(StepLocal);
