import React from 'react';
import LatLngPicker from '../../components/LatLngPicker';
import { Dialog } from '@material-ui/core';
import { withRouter } from 'react-router';
function HotelLocalizacao(props) {
    const { match } = props;
    const { params } = match;
    var a = { x: params.lat, y: params.long };
    return (<Dialog open={true} onClose={() => {
        props.history.goBack();
    }} aria-labelledby="scroll-dialog-title" scroll={"paper"} fullWidth>
            <div className="b-1 mt15"><LatLngPicker value={a}/></div>
        </Dialog>);
}
export default withRouter(HotelLocalizacao);
