const names = {
    "form": "Parceiro",
    "id": "Id",
    "nome": "Nome",
    "endereco": "Endereço",
    "site": "Site",
    "parceiroLogo": "Logo Parceiro",
    "usuarios": "Usuários",
};
export default names;
