const names = {
    form: 'Ordem de Serviço',
    id: 'Id',
    usuId: 'Usuário ID',
    cliId: 'Cliente ID',
    distancia: 'Distância',
    data: 'Data',
    tipo: 'Tipo',
    valor: 'Valor',
    status: 'Status',
    observacao: 'Observação',
    cli: 'Cliente',
    usu: 'Usuário',
    agenda: 'Agenda',
    ordemTarefas: 'Tarefas',
    numero: "Número"
};
export default names;
export const ordemServicoTipos = [
    {
        id: "INTERNO",
        text: "INTERNO"
    },
    {
        id: "EXTERNO",
        text: "NO CLIENTE"
    },
];
export const ordemServicoStatus = [
    {
        id: "ABERTA",
        text: "ABERTA"
    },
    {
        id: "CANCELADA",
        text: "CANCELADA"
    },
    {
        id: "FECHADA",
        text: "FECHADA"
    },
    {
        id: "AFATURAR",
        text: "A FATURAR"
    },
    {
        id: "FATURADA",
        text: "FATURADA"
    },
];
