/* eslint-disable no-loop-func */
import { ResponsivePaperTable, AbaseTextField, AddAction, EditAction, AbaseAutoCompleteFieldFinalForm, AbaseCheckbox } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { FiltroGeralConsumer } from "../../components/FiltroGeral";
import { AgendaListComponent, LocalAllComponent, AgendaTipoAllComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import { formatDateTime, formatDayOfWeek } from '../../helpers/format';
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal, { RenderListWithModal } from "../../RenderWithModal";
import AgendaDelete from "./AgendaDelete";
import AgendaForm from "./AgendaForm";
import AgendaLabel, { agendaStatusList } from './AgendaNameLabel';
import UserAvatarSac from '../../components/UserAvatarSac';
import { withStyles, Grid, Typography, IconButton } from "@material-ui/core";
import AutoCompleteCliente from "../../components/AutoCompleteCliente";
import { UsuarioColaboradorAllComponent } from './../../generated/graphql';
import moment from "moment";
import classNames from 'classnames';
import AgendaView from "./AgendaView";
import EmpresaEquipeAreaFiltro from "../../components/EmpresaEquipeAreaFiltro";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Info } from "@material-ui/icons";


const styles = (theme) => {
    return {
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        badgeFiltro: {
            "background": "#dcdcdc",
            "border": "1px solid #aaa",
            borderRadius: "10px",
            fontSize: "12px",
            display: "inline-block",
            padding: "0 5px",
            textTransform: "capitalize",
            marginRight: 2,
            color: "#444",
            cursor: "pointer"
        },
        badgeSelected: {
            background: "#3e4095",
            color: "#fff",
            borderColor: "#3e4095"
        },
        rowWithColor: {
            "& td": {
                borderBottom: "1px solid rgb(170,170,170)"
            },
        },
        rowColor1: {
            backgroundColor: "rgb(214, 214, 214)!important",
            "&:hover": {
                background: "rgb(180,180,180)!important"
            }
        },
        rowColor2: {
            backgroundColor: "white!important",
            "&:hover": {
                background: "rgb(238,238,238)!important"
            }

        }
    }
};
function FiltroButton({ classes, startDate, endDate, filtroGeral, title }) {
    if (!startDate || !filtroGeral.startDate || !filtroGeral.endDate || !endDate)
        return <div></div>
    const selected = filtroGeral.startDate.toISOString() === startDate.toISOString() && filtroGeral.endDate.toISOString() === endDate.toISOString();
    return (<div className={classNames(classes.badgeFiltro, {
        [classes.badgeSelected]: selected
    })} onClick={() => {
        filtroGeral.setPeriod({ startDate, endDate });
    }}>{title}</div>);
}
function AgendaList(props) {
    const { match } = props;
    const columns = [
        {
            name: AgendaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: AgendaLabel.cliId,
            field: "cliId",
            defaultVisible: false
        },
        {
            name: AgendaLabel.empId,
            field: "empId",
            defaultVisible: false
        },
        {
            name: AgendaLabel.usuId,
            field: "usuId",
            defaultVisible: false
        },
        {
            name: "Envolvidos",
            field: "usu.nome",
            sort: false,
            defaultVisible: false,
            width: 100,
            customBodyRender: (value, rowData) => {
                return <React.Fragment>
                    {(value && (!rowData.agendaUsuario || rowData.agendaUsuario.length === 0)) ? (<UserAvatarSac name={value} color={_.get(rowData, "usu.corAvatar")} url={_.get(rowData, "usu.avatarUrl")} />) : null}
                    {rowData.agendaUsuario.map(x => (<UserAvatarSac name={x.usu.nome} color={_.get(x, "usu.corAvatar")} url={_.get(x, "usu.avatarUrl")} />))}
                </React.Fragment>;
            }
        },
        {
            name: "Envolvidos Nome",
            field: "usu.nometext",
            sort: false,
            width: 150,
            customBodyRender: (value, rowData) => {
                return <React.Fragment>
                    {(rowData.usu.nome && (!rowData.agendaUsuario || rowData.agendaUsuario.length === 0)) ? (<React.Fragment>{rowData.usu.nome}<br /></React.Fragment>) : null}
                    {rowData.agendaUsuario.map(x => (<React.Fragment>{x.usu.nome}<br /></React.Fragment>))}
                </React.Fragment>;
            }
        },
        {
            name: "Área",
            field: "are",
            sort: false,
            defaultVisible: true,
            width: 100,
            customBodyRender: (value) => {
                if (value) {
                    return <b style={{ color: value.cor }}>{value.descricao}</b>;
                }
                else
                    return "N/A";
            }
        },
        {
            name: "OS",
            field: "ord.numero",
            defaultVisible: false,
            width: 50,
            customBodyRender: (value) => {
                return value || "N/A";
            }
        },
        {
            name: "Data",
            field: "inicio",
            width: 120,
            customBodyRender: (value) => {
                return formatDateTime(value).replace(" 00:00", "");
            }
        },
        {
            name: "Dia",
            field: "inicio",
            width: 50,
            customBodyRender: (value) => {
                return formatDayOfWeek(value);
            }
        },
        {
            name: AgendaLabel.fim,
            field: "fim",
            width: 120,
            defaultVisible: false,
            customBodyRender: (value) => {
                return formatDateTime(value);
            }
        },
        {
            name: "Tipo",
            field: "agendaTipo.descricao",
            width: 150
        },
        {
            name: AgendaLabel.cli,
            field: "contaCliente.nome",
            sort: false,
            width: 250
        },
        {
            name: "Recursos",
            field: "agendaRecurso",
            sort: false,
            width: 150,
            customBodyRender: (value) => {
                const recursoNames = (value || []).map(x => x.rec.nome);
                return recursoNames.join(", ") || "N/A";
            }
        },
        {
            name: AgendaLabel.titulo,
            field: "titulo",
            width: "15%"
        },
        {
            name: AgendaLabel.local,
            field: "loc.descricao",
            defaultVisible: true
        },
        {
            name: "P.",
            hint: "Particular",
            field: "particular",
            defaultVisible: false,
            sort: false,
            width: 30,
            customBodyRender: value => value ? "S" : "N"
        },
        {
            name: AgendaLabel.emp,
            field: "emp.nomefantasia",
            defaultVisible: false
        },
        {
            name: "Equipe",
            field: "equ.descricao",
            defaultVisible: false
        },
        {
            name: "Parceiro",
            field: "par.nome",
            defaultVisible: false
        },
        {
            name: AgendaLabel.status,
            field: "status",
            cellProps: {
                style: {
                    textAlign: "center",
                }
            },
            width: 100,
            customBodyRender: (value) => {
                let color = "#000";
                switch (value) {
                    case "CONFIRMADO":
                        color = "green";
                        break;
                    case "AGUARDANDO":
                        color = "#1e74b5";
                        break;
                    case "CANCELADO":
                        color = "red";
                        break;
                    default: color = "red";
                        break;
                }
                return <span style={{ color }}>{value}</span>;
            }
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<Link to={`${match.path}/view/${selected[0].id}`}>
                <IconButton aria-label="Detalhes">
                    <RemoveRedEyeIcon />
                </IconButton>
            </Link>) : null,
            selected.length === 1 && selected[0].editavel ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
        ];
    };
    const detalhesExpansive = val => {
        return <React.Fragment>
            <Typography className={props.classes.heading}>Titulo: {val.titulo}</Typography>
            <Typography className={props.classes.secondaryHeading}  >Início: {new Date(val.inicio).toLocaleString()} - Fim: {new Date(val.fim).toLocaleString()}</Typography>
        </React.Fragment>
    }
    const customActions = [
        <AddAction key="add" to={`${match.path}/add`} component={Link} />,
    ];
    const filters = () => ([
        // <Field key={"id"} name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,

        <EmpresaEquipeAreaFiltro />,

        <UsuarioColaboradorAllComponent>{({ data, loading }) => (
            <Field name="usuarioId">{({ input, meta }) => (
                <React.Fragment>
                    <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Colaborador"} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input} />
                </React.Fragment>)}
            </Field>)}
        </UsuarioColaboradorAllComponent>,

        <Field name={'cliId'}>
            {({ input }) => <AutoCompleteCliente {...input} />}
        </Field>,

        <AgendaTipoAllComponent>
            {({ data, loading }) => (<Field name="agendaTipoId">
                {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm label={"Tipo de Agenda"} meta={meta} valueField={'id'} textField={'descricao'} loading={loading} data={_.get(data, "agendaTipo.list", [])} {...input} />)}
            </Field>)}
        </AgendaTipoAllComponent>,

        <Field key={"titulo"} name={"titulo"}>{({ input, meta }) => <AbaseTextField label={"Título"} {...input} meta={meta}></AbaseTextField>}</Field>,

        <LocalAllComponent>{({ data, loading }) => (<Field name="locId">{({ input, meta }) => (<React.Fragment>
            <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Local"} valueField={"id"} textField={"descricao"} data={_.get(data, "local.list", [])} {...input} />
        </React.Fragment>)}</Field>)}
        </LocalAllComponent>,

        <Field name="status">
            {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm label={AgendaLabel.status} meta={meta} valueField={'id'} textField={'text'} data={agendaStatusList} {...input} />)}
        </Field>,

        <Field name={'somenteParticular'}>
            {({ input }) => <AbaseCheckbox label={"Somente particulares"} {...input} />}
        </Field>,
    ]);
    return (<React.Fragment>
        <FiltroGeralConsumer>{(filtroGeral) => {
            return (<GraphqlPaperTableListener extraVariables={{
                startDate: filtroGeral.startDate ? filtroGeral.startDate.toISOString() : null,
                endDate: filtroGeral.endDate ? filtroGeral.endDate.toISOString() : null,
            }}>{({ variables, setVariables }) => (<AgendaListComponent variables={{ ...variables, cliId: variables && variables.cliId ? variables.cliId.id : null }} skip={variables === null}>
                {({ data, loading, refetch, error }) => {
                    const allData = _.get(data, "agenda.pagedList.data") || [];
                    const start = filtroGeral.startDate ? filtroGeral.startDate.toISOString() : null;
                    const end = filtroGeral.endDate ? filtroGeral.endDate.toISOString() : null;
                    let dataWithDays = [];
                    let lastRowData = "";
                    let lastRowColor = 0;
                    if (start && end) {
                        for (const m = moment(start); m.isBefore(end); m.add(1, 'days')) {
                            let inThisDate = allData
                                .filter(x => moment(x.inicio).isSame(m, 'day') || (x.fim && moment(x.fim).isSameOrAfter(m, "day") && moment(x.inicio).isSameOrBefore(m, 'day')))
                                .map(x => ({ ...x, inicio: moment(x.inicio).isSame(m, 'day') ? x.inicio : m.startOf("day").toDate().toISOString() }));
                            const thisDate = m.format("DDMMYYYY");
                            if (inThisDate.length > 0 && thisDate !== lastRowData) {
                                lastRowData = thisDate;
                                lastRowColor++;
                                inThisDate = inThisDate.map(x => ({ ...x, color: lastRowColor }));
                            }
                            dataWithDays = [...dataWithDays, ...inThisDate];
                        }
                    }
                    return (<div>
                        <ResponsivePaperTable
                            customPlacehorderSearch={"Pesquise por descrição, título, cliente, usuario ou status."}
                            sticky={true}
                            selectMode={"single"}
                            loading={loading}
                            data={dataWithDays}
                            total={_.get(data, "agenda.pagedList.total")}
                            columns={columns}
                            customFilters={filters}
                            detalhesExpanse={detalhesExpansive}
                            title={(<div>
                                <Grid container spacing={0} className="fil">
                                    <Grid item xs={4} md={2} lg={2}>
                                        Agendas de: &nbsp;
                                    </Grid>
                                    <Grid item xs={8} md={4} lg={3}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="startDateFilter"
                                            label=""
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={moment(_.get(filtroGeral, "startDate")).toDate()}
                                            onChange={x => {
                                                if (x !== '') {
                                                    let periodo = {
                                                        startDate: moment(x),
                                                        endDate: _.get(filtroGeral, "endDate")
                                                    }
                                                    filtroGeral.setPeriod(periodo);
                                                }
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={1} lg={1}>
                                        &nbsp; até &nbsp;
                                    </Grid>
                                    <Grid item xs={8} md={4} lg={3}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="EndDateFilter"
                                            label=""
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={moment(_.get(filtroGeral, "endDate")).toDate()}
                                            onChange={x => {
                                                if (x !== '') {
                                                    let periodo = {
                                                        endDate: moment(x),
                                                        startDate: _.get(filtroGeral, "startDate")
                                                    }
                                                    filtroGeral.setPeriod(periodo);
                                                }
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'Alterar Data',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <div className="colFilters">
                                            <FiltroButton startDate={moment().subtract(1, 'weeks').startOf('isoWeek')} endDate={moment().subtract(1, 'weeks').endOf('isoWeek')} title={"Última semana"} filtroGeral={filtroGeral} classes={props.classes}></FiltroButton>
                                            <FiltroButton startDate={moment().startOf('isoWeek')} endDate={moment().endOf('isoWeek')} title={"Esta Semana"} filtroGeral={filtroGeral} classes={props.classes}></FiltroButton>
                                            <FiltroButton startDate={moment().add(1, 'weeks').startOf('isoWeek')} endDate={moment().add(1, 'weeks').endOf('isoWeek')} title={"Próxima semana"} filtroGeral={filtroGeral} classes={props.classes}></FiltroButton>
                                            <FiltroButton startDate={moment().startOf('month')} endDate={moment().endOf('month')} title={"Este Mês"} filtroGeral={filtroGeral} classes={props.classes}></FiltroButton>
                                            <FiltroButton startDate={moment().add(1, 'months').startOf('month')} endDate={moment().add(1, 'months').endOf('month')} title={"Próximo mês"} filtroGeral={filtroGeral} classes={props.classes}></FiltroButton>
                                            <div className={classNames(props.classes.badgeFiltro)} onClick={() => {
                                                props.history.push("/agenda/calendar");
                                            }}>Calendário</div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>)} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
                                if (row.editavel)
                                    props.history.push(`${match.path}/view/${row.id}`);
                            }} tableProps={{
                                paginated: false,
                                getRowProps: (row) => {
                                    return {
                                        classes: {
                                            root: classNames(props.classes.rowWithColor, row.color % 2 === 0 ? props.classes.rowColor1 : props.classes.rowColor2)
                                        }
                                    };
                                }
                            }}>
                        </ResponsivePaperTable>
                        <ReenterRouteListener callback={refetch} />
                        <Route path={`${match.path}/add`} render={RenderWithModal(AgendaForm)} />
                        <Route path={`${match.path}/edit/:id`} render={RenderWithModal(AgendaForm)} />
                        <Route path={`${match.path}/delete/:id`} render={RenderWithModal(AgendaDelete)} />
                        <Route path={`${match.url}/view/:id`} render={RenderListWithModal(AgendaView)} />
                    </div>);
                }}
            </AgendaListComponent>)
                }
            </GraphqlPaperTableListener>)
        }}</FiltroGeralConsumer>

    </React.Fragment>);
}
export default withStyles(styles)(AgendaList);
