const names = {
    "form":"ObservacaoTipo",
    "id":"Id",
    "empId":"EmpId",
    "gestorChaveintegracao":"GestorChaveintegracao",
    "descricao":"Descricao",
    "ativo":"Ativo",
    "emp":"Emp",
    "clienteObservacao":"ClienteObservacao",
}
export default names