/* eslint-disable array-callback-return */
import React from "react";
import Axios from "axios";
import { getToken } from "../../services/login";
import { AbaseDialogError } from "abase-components";
export function imprimirOrdemServico(ids, acao, nome) {
    var params = "";
    ids.length > 0 ? ids.map((id, index) => {
        index !== 0 ? params += '&' : null;
        params += "OrdemServicos=" + id;
    }) : null;
    // window.open(`/api/OrdemServico/gerar?${params}`)
    Axios({
        url: `api/OrdemServico/gerar?${params}`,
        method: 'get',
        responseType: 'blob',
        params: params,
        headers: { authorization: 'Bearer ' + getToken() }
    }).then(response => {
        console.log({ response });
        const name = nome ? nome + ".pdf" : "Ordem_servico.pdf", url = window.URL.createObjectURL(new Blob([response.data])), link = document.createElement('a');
        if (acao === "download" || !acao) {
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        }
        else if (acao === "abrir") {
            var file = new Blob([response.data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }).catch(err => {
        console.log({ err });
        var aberto = true;
        return <AbaseDialogError open={aberto} error={err} title="Erro" onClose={() => { aberto = false; }} />;
    });
}
export function imprimirOrdemServicoCliente(cliente) {
    var params = "cliente=" + cliente;
    Axios({
        url: `api/OrdemServico/relatoriocliente?${params}`,
        method: 'get',
        responseType: 'blob',
        params: params,
        headers: { authorization: 'Bearer ' + getToken() }
    }).then(response => {
        const name = "RelatorioCliente.pdf", url = window.URL.createObjectURL(new Blob([response.data])), link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        console.log({ err });
        var aberto = true;
        return <AbaseDialogError open={aberto} error={err} title="Erro" onClose={() => { aberto = false; }} />;
    });
}
