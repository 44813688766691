const names = {
    "form": "Área",
    "id": "ID",
    "empId": "Empresa ID",
    "descricao": "Descrição",
    "emp": "Empresa",
    "agenda": "Agenda",
    "areaEquipe": "Área Equipe",
};
export default names;
