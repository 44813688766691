const names = {
    "form": "Termo de Aceite",
    "id": "Id",
    "ordId": "Ordem ID",
    "cliId": "Cliente ID",
    "usuId": "Usuário ID",
    "modId": "Modelo ID",
    "empId": "Empresa ID",
    "jsonGerado": "Json Gerado",
    "nomeSistema": "Nome do Sistema",
    "dataGerado": "Data Gerado",
    "cli": "Cliente",
    "emp": "Empresa",
    "mod": "Modelo de Termo de Aceite",
    "ord": "Ordem de Serviço",
    "usu": "Usuário",
};
export default names;
