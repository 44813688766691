import { AbaseLoading, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import * as Yup from 'yup';
import { ProjetoAllComponent, UsuarioAllComponent, ProjetoParticipanteQueryByIdComponent, useProjetoParticipanteCreate, useProjetoParticipanteUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import ProjetoParticipanteLabel from './ProjetoParticipanteNameLabel';
import FormWithoutDialog from '../../FormWithoutDialog';
import { Grid } from '@material-ui/core';
const ProjetoParticipanteSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    proId: Yup.string().notRequired(),
    usuId: Yup.string().notRequired(),
}).noUnknown();
function ProjetoParticipanteForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useProjetoParticipanteUpdate();
    const insert = useProjetoParticipanteCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(ProjetoParticipanteSchema, values);
        try {
            if (params && params.participanteId) {
                const result = await update({
                    variables: {
                        id: params.participanteId,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.projetoParticipante.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.projetoParticipante.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<ProjetoParticipanteQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
            {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(ProjetoParticipanteSchema)} initialValues={_.get(data, "projetoParticipante.list[0]") || { proId: props.match.params.projetoId || null }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<FormWithoutDialog title="Projeto Participante" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                            <React.Fragment>
                                <AbaseLoading open={loading || submitting}/>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {!props.match.params.projetoId ?
        <ProjetoAllComponent>{({ data, loading }) => (<Field name="proId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={ProjetoParticipanteLabel.pro} valueField={"id"} textField={"descricao"} data={_.get(data, "projeto.list", [])} {...input}/>)}</Field>)}
                                            </ProjetoAllComponent>
        : null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <UsuarioAllComponent>{({ data, loading }) => (<Field name="usuId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={ProjetoParticipanteLabel.usu} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input}/>)}</Field>)}
                                        </UsuarioAllComponent>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        </FormWithoutDialog>)}/>)}
        </ProjetoParticipanteQueryByIdComponent>);
}
export default ProjetoParticipanteForm;
