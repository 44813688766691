const names = {
    "form": "Recurso",
    "id": "Id",
    "empId": "Empresa ID",
    "recId": "Recurso ID",
    "nome": "Nome",
    "dadosFormulario": "Dados Formulário",
    "emp": "Empresa",
    "rec": "Tipo",
    "agendaRecurso": "Agenda Recurso"
};
export default names;
