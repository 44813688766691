const names = {
    form: "Local dos usuários",
    id: "Id",
    usuId: "Usuário ID",
    locId: "Local ID",
    data: "Última atualização",
    loc: "Local",
    usu: "Usuário",
    usuarioNome: "Nome"
};
export default names;
