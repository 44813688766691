const names = {
    "form": "Tipo de Tarefa",
    "id": "ID",
    "descricao": "Descrição",
    "unidadeMedida": "Unidade de Medida",
    "tarefa": "Tarefa",
};
export default names;
export const unidadeMedida = {
    'HORAS': {
        text: "HORAS",
    },
    'DIAS': {
        text: "DIAS",
    },
    'NADA': {
        text: "NENHUM",
    },
};
export const unidadeMedidaList = Object.keys(unidadeMedida).map(x => ({ id: x, ...unidadeMedida[x] }));
