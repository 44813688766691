import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { AbaseButtonDefault, AbaseDialogConsumer, AbaseLoading, AbaseTextField } from 'abase-components';
import LogoAbase from 'abase-components/dist/styles/imgSacG.png';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { composeValidators, minLength, required } from '../../helpers/validations';
import { authenticateCliente } from '../../services/login';
import { LoginPageStyles } from './LoginStyle';
class LoginPage extends React.Component {
    constructor() {
        super(...arguments);
        this.handleSubmit = async (showError, showSnack, data) => {
            try {
                await authenticateCliente(data.email, data.senha);
                showSnack({
                    message: 'Usuário autenticado com sucesso'
                });
                this.props.history.push('/cliente/home');
            }
            catch (error) {
                console.log({ error });
                showError({
                    message: error.message
                });
            }
        };
        this.state = {
            icon: 'visibility',
            typePassword: 'password'
        };
    }
    render() {
        const { classes } = this.props;
        return (<main className={classes.main}>
            <CssBaseline />
            <AbaseDialogConsumer>
                {({ showError, showSnack }) => (<div>
                    <Paper className={classes.paper}>
                        <img src={LogoAbase} style={{ maxWidth: '85px' }} alt="Logo da empresa Abase Sistemas" />
                        <Form onSubmit={this.handleSubmit.bind(null, showError, showSnack)}>
                            {({ handleSubmit, submitting }) => (<form className={classes.form} onSubmit={handleSubmit}>
                                <AbaseLoading open={submitting} />
                                <Typography variant="title" align={"center"}>
                                    Login de Cliente
											</Typography>
                                <p style={{ textAlign: "justify" }}>Utilize seu usuário e senha do SAC ABASE.</p>
                                <Field name={'email'} validate={composeValidators(required, minLength(4))} parse={defaultFieldParser}>
                                    {({ input, meta }) => (<AbaseTextField fullWidth label="Usuário" {...input} meta={meta} />)}
                                </Field>
                                <Field name={'senha'} validate={composeValidators(required, minLength(3))}>
                                    {({ input, meta }) => (<AbaseTextField fullWidth type={this.state.typePassword} label="Senha" {...input} meta={meta} iconclick={() => {
                                        if (this.state.icon === 'visibility') {
                                            this.setState({
                                                icon: 'visibility_off',
                                                typePassword: 'text'
                                            });
                                        }
                                        else {
                                            this.setState({
                                                icon: 'visibility',
                                                typePassword: 'password'
                                            });
                                        }
                                    }} />)}
                                </Field>
                                <AbaseButtonDefault label="Entrar" type={'submit'} fullWidth variant="contained" color="primary" className={classes.submit} />
                                <AbaseButtonDefault label="Entrar como funcionário" type={'button'} onClick={() => this.props.history.push("/login")} fullWidth variant="contained" color="secondary" className={classes.cliente} />

                            </form>)}
                        </Form>
                    </Paper>
                </div>)}
            </AbaseDialogConsumer>
        </main>);
    }
}
export default withStyles(LoginPageStyles)(LoginPage);
