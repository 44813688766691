import { getConfig } from "../config";
import axios from "./axios";
import _ from "lodash";
import { getToken } from "./login";
const { apiUrl } = getConfig();
export async function AsyncRequest(opcoes) {
    const url = apiUrl + opcoes.path;
    return await axios({
        method: opcoes.method,
        url: url,
        data: opcoes.parameters,
        onUploadProgress: opcoes.onProgressUpload,
        headers: opcoes.headers
    });
}
export async function postJsonRequest(path, data) {
    try {
        const r = await axios.post(path, data);
        return r.data;
    }
    catch (e) {
        if (_.get(e, "response.data.error")) {
            throw new Error(_.get(e, "response.data.error"));
        }
        throw e;
    }
}
export async function getJsonRequest(path, data) {
    path = apiUrl + path + "=";
    if (data && typeof data === "object")
        // eslint-disable-next-line array-callback-return
        Object.keys(data).map((key) => {
            path += `&${key}=${data[key]}`;
        });
    const request = await fetch(path, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    const response = await request.json();
    if (response.error) {
        throw new Error(response.error);
    }
    return response;
}
export async function RequestDownload(path, params, method) {
    path = apiUrl + path;
    axios({
        url: path,
        method: method,
        responseType: "blob",
        params: params,
        headers: { authorization: "Bearer " + getToken() }
    })
        .then(response => {
            console.log(response);
            const name = response.headers["filename"] ||
                response.headers["content-disposition"].split('="')[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            console.log(err);
        });
}
