import { AbasePaperTable, AbaseTextField, AddAction, EditAction } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { ContasContatosListComponent, ContasQueryByIdComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal from '../../RenderWithModal';
import ContasContatosDelete from './ContasContatosDelete';
import ContasContatosForm from './ContasContatosForm';
import ContasContatosLabel from './ContasContatosNameLabel';
export default function ContasContatosList(props) {
    const { match } = props;
    const columns = [
        {
            name: ContasContatosLabel.id,
            field: 'id',
            defaultVisible: false
        },
        {
            name: ContasContatosLabel.contaId,
            field: 'contaId',
            defaultVisible: false
        },
        {
            name: ContasContatosLabel.idcontatoIntegracao,
            field: 'idcontatoIntegracao',
            defaultVisible: false
        },
        {
            name: ContasContatosLabel.nome,
            field: 'nome'
        },
        {
            name: ContasContatosLabel.email,
            field: 'email'
        },
        {
            name: ContasContatosLabel.telefone,
            field: 'telefone'
        },
        {
            name: ContasContatosLabel.setor,
            field: 'setor'
        },
        {
            name: ContasContatosLabel.cargo,
            field: 'cargo'
        },
        {
            name: ContasContatosLabel.conta,
            field: 'conta.id',
            defaultVisible: false
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link}/>) : null
            // Não faz sentido deletar uma conta
            // <DeleteAction
            // 	key="delete"
            // 	component={(props: any) => (
            // 		<Link to={`${match.url}/delete/${_.map(selected, 'id').join(',')}`} {...props} />
            // 	)}
            // />
        ];
    };
    const customActions = [
        <AddAction key="add" to={`${match.url}/add?conta=${match.params.id}`} params={match.params} component={Link}/>
    ];
    const filters = () => [
        <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta}/>}</Field>,
        <Field name={'nome'}>{({ input, meta }) => <AbaseTextField label={'Nome'} {...input} meta={meta}/>}</Field>,
        <Field name={'email'}>{({ input, meta }) => <AbaseTextField label={'E-mail'} {...input} meta={meta}/>}</Field>,
        <Field name={'telefone'}>
			{({ input, meta }) => <AbaseTextField label={'Telefone'} {...input} meta={meta}/>}
		</Field>,
        <Field name={'setor'}>{({ input, meta }) => <AbaseTextField label={'Setor'} {...input} meta={meta}/>}</Field>,
        <Field name={'cargo'}>{({ input, meta }) => <AbaseTextField label={'Cargo'} {...input} meta={meta}/>}</Field>
    ];
    return (<ContasQueryByIdComponent variables={{ id: match.params.id }}>
			{({ data, loading, refetch, error }) => {
        var nomeConta = _.get(data, 'contas.list[0].nomerazaosocial');
        return (<GraphqlPaperTableListener>
						{({ variables, setVariables }) => {
            _.set(variables, 'conta', match.params.id);
            return (<ContasContatosListComponent variables={variables} skip={variables === null}>
									{({ data, loading, refetch, error }) => (<div>
											<AbasePaperTable sticky={true} selectMode={'single'} loading={loading} data={_.get(data, 'contasContatos.pagedList.data') || []} total={_.get(data, 'contasContatos.pagedList.total')} columns={columns} customFilters={filters} title={`Contatos da Conta ${nomeConta}`} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener}/>
											<ReenterRouteListener callback={refetch}/>
											<Route path={`${match.url}/add`} render={RenderWithModal(ContasContatosForm)}/>
											<Route path={`${match.url}/edit/:id`} render={RenderWithModal(ContasContatosForm)}/>
											<Route path={`${match.url}/delete/:id`} render={RenderWithModal(ContasContatosDelete)}/>
										</div>)}
								</ContasContatosListComponent>);
        }}
					</GraphqlPaperTableListener>);
    }}
		</ContasQueryByIdComponent>);
}
