import { Grid } from '@material-ui/core';
import { AbaseAutoCompleteFieldFinalForm, AbaseDateTimePickerField, AbaseTextField, FormDraft, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field } from "react-final-form";
import * as Yup from 'yup';
import withIsMobile from '../../components/withIsMobile';
import DialogForm from '../../DialogForm';
import { useUsuarioAll } from '../../generated/graphql';
import { defaultDecorators, redirectBackForm } from '../../helpers';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { comparaValidadeIntervaloMaximoDeDatas } from '../../helpers/format';
import { cast, validate } from '../../helpers/validations';
import TarefaLabel, { tarefaStatusList } from './TarefaNameLabel';

const TarefaSchemaForm2 = Yup.object().shape({
    id: Yup.string().notRequired().nullable(),
    usuId: Yup.string().required().label("Usuário que executou"),
    state: Yup.string().notRequired().nullable(),
    data: Yup.date().notRequired().nullable(),
    inicio: Yup.date().required(),
    porcentagem: Yup.number().required().nullable(),
    status: Yup.string().required().nullable(),
    fim: Yup.date().when("inicio", inicio => {
        return inicio && Yup.date().min(inicio, "Fim deve ser maior que início").required();
    }),
    tempoIntervalo: Yup.string().notRequired().nullable(),
    tempoDeslocamento: Yup.string().notRequired().nullable(),
    tempoOcioso: Yup.string().notRequired().nullable(),
    descritivo: Yup.string().required(),
}).noUnknown();
function TarefaAtividadeForm(props) {
    const { match, history, location, isMobile } = props;
    const { params } = match;
    const { showError } = useAbaseDialog();
    const usuariosAll = useUsuarioAll({
        variables: { cliente: false }
    });
    const handleSubmit = async (values) => {
        if (values.tempoIntervalo && values.inicio && values.fim) {
            if (!comparaValidadeIntervaloMaximoDeDatas(values.inicio, values.fim, values.tempoIntervalo)) {
                showError({
                    title: "Erro de validação",
                    message: "Tempo de intervalo é maior que intervalo de início e fim"
                });
                return false;
            }
        }
        const mutationData = await cast(TarefaSchemaForm2, values);
        var usu = _.first(_.get(usuariosAll.data, "usuario.list", []).filter(x => x.id === mutationData.usuId));
        if (props.onSubmitItem) {
            props.onSubmitItem({ ...mutationData, usu });
            redirectBackForm(history, location);
            return;
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = false;
    const { porcentagem, status, usuId } = props;
    const [currentDate] = useState(() => new Date().toISOString());
    const dataInitialValues = notEditting || !props.data ? { porcentagem: porcentagem || 0, status: status || "EXECUTANDO", usuId: usuId || null, inicio: currentDate } : _.first(props.data.filter((x) => x.id === params.id));
    return (<FormDraft useDraft={isUsingDraft} onSubmit={handleSubmit} validate={validate(TarefaSchemaForm2)} initialValues={dataInitialValues} subscription={{
        submitting: true,
        initialValues: true
    }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting, values }) => (<DialogForm title="Atividade na tarefa" onSubmit={handleSubmit} dialogProps={isMobile ? null : {
        fullWidth: true,
        maxWidth: "sm"
    }}>
        <Grid container spacing={1} className="pb42">
            <Grid item xs={12}>
                <Field name="usuId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm meta={meta} isLoading={usuariosAll.loading} getOptionValue={x => x} label={"Pessoa que executou a tarefa"} valueField={"id"} textField={"nome"} data={_.get(usuariosAll.data, "usuario.list", [])} {...input} />)}
                </Field>
            </Grid>
            <Grid item xs={12}>
                <Field name="descritivo" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField multiline rows="10" meta={meta} fullWidth={true} {...input} label={"Descrição"} />)}</Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field name="fim">{({ input: inputFim }) => (<Field name="inicio">{({ input, meta }) => (<AbaseDateTimePickerField meta={meta} {...input} label={"Início"} onBlur={e => {
                    if (!inputFim.value) {
                        inputFim.onChange(input.value);
                    }
                    input.onBlur(e);
                }} />)}</Field>)}</Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field name="fim">{({ input, meta }) => (<AbaseDateTimePickerField meta={meta} {...input} label={"Fim"} />)}</Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field name="tempoIntervalo">{({ input, meta }) => (<AbaseTextField type={"time"} meta={meta} {...input} label={"Intervalo"} />)}</Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field name="tempoDeslocamento">{({ input, meta }) => (<AbaseTextField type={"time"} meta={meta} {...input} label={"Deslocamento"} />)}</Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field name="tempoOcioso">{({ input, meta }) => (<AbaseTextField type={"time"} meta={meta} {...input} label={"Ocioso"} />)}</Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field name="status">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm meta={meta} label={TarefaLabel.status} valueField={"id"} textField={"text"} data={tarefaStatusList} {...input} onChange={(value) => {
                    input.onChange(value);
                    if (value === "FINALIZADO") {
                        form.change("porcentagem", "100");
                    }
                    else if (value === "FAZER") {
                        form.change("porcentagem", "0");
                    }
                }} />)}</Field>
            </Grid>
            <Grid item xs={12}>
                <Field name="porcentagem">{({ input, meta }) => (<React.Fragment>
                    <AbaseTextField meta={meta} fullWidth={true} {...input} label={`Porcentagem concluida (${(input.value || 0) + "%"})`} type={"range"} value={input.value ? input.value : "0"} onChange={(e) => {
                        input.onChange(e);
                        if (e.target.value > 0 && e.target.value < 100) {
                            form.change("status", "EXECUTANDO");
                        }
                        else if (+e.target.value === 0) {
                            form.change("status", "FAZER");
                        }
                        else if (+e.target.value === 100) {
                            form.change("status", "FINALIZADO");
                        }
                    }} />
                </React.Fragment>)}</Field>
            </Grid>
        </Grid>
    </DialogForm>)} />);
}
export default withIsMobile(TarefaAtividadeForm);
