const names = {
    "form": "Modelo Etapa",
    "id": "Id",
    "modId": "Modelo ID",
    "descricao": "Descrição",
    "ordem": "Ordem",
    "mod": "Modelo",
    "modeloTarefa": "Modelo de Tarefa",
};
export default names;
