import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { EmpresaAllComponent, AreaQueryByIdComponent, useAreaCreate, useAreaUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import AreaLabel from './AreaNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { TwitterPicker } from 'react-color';
import { Grid } from '@material-ui/core';
const AreaSchema = Yup.object().shape({
    id: Yup.string().notRequired().label("Campo obrigatório"),
    empId: Yup.string().required(),
    descricao: Yup.string().required(),
    cor: Yup.string().notRequired()
}).noUnknown();
function AreaForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useAreaUpdate();
    const insert = useAreaCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(AreaSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.area.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.area.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<AreaQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
            {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(AreaSchema)} initialValues={_.get(data, "area.list[0]") || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Área" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                            <AbaseLoading open={loading || submitting}/>
                            <div className="colFiltro">
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Field name="descricao" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={AreaLabel.descricao}/>)}</Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EmpresaAllComponent>{({ data, loading }) => (<Field name="empId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} placeholder={"Selecione a empresa"} label={"Empresa"} valueField={"id"} textField={"razaosocial"} data={_.get(data, "empresa.list", [])} {...input}/>)}</Field>)}
                                        </EmpresaAllComponent>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="colRelative">
                                            <label className="lableFil">
                                                Cor da Área
                                            </label>
                                            <Field name="cor">
                                                {({ input, meta }) => {
        return <TwitterPicker width="100%" onChangeComplete={(cor) => {
            console.log(cor);
            input.onChange(cor.hex);
        }} color={input.value}/>;
    }}
                                            </Field>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogForm>)}/>)}
        </AreaQueryByIdComponent>);
}
export default AreaForm;
