import React from 'react';
import { getToken } from '../../services/login';
import { getConfig } from '../../config';
export default function SupersetFrame({ urlDestination }) {
    const jwt = getToken();
    const { supersetUrl } = getConfig();
    const url = `${supersetUrl}/login/?jwt=${encodeURIComponent(jwt)}&redirect=${encodeURIComponent(`${supersetUrl}/superset/welcome`)}`;
    return (<div style={{
        position: "relative",
        height: 0,
        overflow: "hidden",
        maxWidth: "100%",
        paddingBottom: "56.25%"
    }}>
        <style>
            {`.dentroConteudo > div {
                   top: 0; 
                   margin: 0;
                   padding: 0;
                   height: 100%;
                }`}
            {`.dentroConteudo .material-icons {
                    width: 100%;
                    font-size: 52px;
                    margin-bottom: 5px;
                }`}
            {`.dentroConteudo nav {
                    display:none;
                    visibility:hidden;
                }`}
        </style>

        <iframe title="SuperSet" src={url} style={{ position: "absolute", "top": 0, left: 0, width: "100%", height: "100%" }} frameborder="0" allowfullscreen> </iframe>
    </div>);
}
