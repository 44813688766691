import { AbaseLoading, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { UsuarioEquipeQueryByIdComponent, useUsuarioEquipeCreate, UsuarioNaoPertencemEquipeComponent } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import UsuarioEquipeLabel from './UsuarioEquipeNameLabel';
import { Grid } from '@material-ui/core';
const UsuarioEquipeSchema = Yup.object()
    .shape({
    id: Yup.string().notRequired(),
    equId: Yup.string().notRequired(),
    usuId: Yup.string().notRequired()
})
    .noUnknown();
function UsuarioEquipeForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const insert = useUsuarioEquipeCreate();
    const handleSubmit = async (values) => {
        _.set(values, 'equId', match.params.id);
        const mutationData = await cast(UsuarioEquipeSchema, values);
        try {
            const result = await insert({
                variables: {
                    data: mutationData
                }
            });
            if (props.onSave) {
                props.onSave(_.get(result, 'data.usuarioEquipe.create.id'));
            }
            clearDraft();
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<UsuarioEquipeQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
    }}>
			{({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(UsuarioEquipeSchema)} initialValues={_.get(data, 'usuarioEquipe.list[0]') || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Usuário Equipe" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
							<AbaseLoading open={loading || submitting}/>
							<Grid container spacing={1}>
                                <Grid item xs={12}>
									<UsuarioNaoPertencemEquipeComponent variables={{ equId: match.params.id }}>
										{({ data, loading }) => (<Field name="usuId">
												{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={UsuarioEquipeLabel.usu} valueField={'id'} textField={'nome'} data={_.get(data, 'usuario.naoPertencemEquipe', [])} {...input}/>)}
											</Field>)}
									</UsuarioNaoPertencemEquipeComponent>
								</Grid>
							</Grid>

							
						</DialogForm>)}/>)}
		</UsuarioEquipeQueryByIdComponent>);
}
export default UsuarioEquipeForm;
