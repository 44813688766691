import moment from "moment";
export function formatDateTime(isoDate) {
    if (!isoDate)
        return "";
    return moment(isoDate).format('DD/MM/YYYY HH:mm');
}
export function formatDayOfWeek(isoDate) {
    if (!isoDate)
        return "";
    const day = moment(isoDate).isoWeekday();
    switch (day) {
        case 1:
            return "Seg";
        case 2:
            return "Ter";
        case 3:
            return "Qua";
        case 4:
            return "Qui";
        case 5:
            return "Sex";
        case 6:
            return "Sáb";
        case 7:
            return "Dom";
        default: return "  -  "
    }
}
export function formatDate(isoDate) {
    if (!isoDate)
        return "";
    return moment(isoDate).format('DD/MM/YYYY');
}
export function diferencaEmHorasParaHoje(isoDate) {
    if (!isoDate)
        return 0;
    return moment.duration(moment(isoDate).diff(moment(new Date()))).asHours();
}
export function comparaValidadeIntervaloMaximoDeDatas(inicio, fim, tempo) {
    const segundos = moment.duration(moment(fim).diff(moment(inicio))).asSeconds();
    const segundosTempo = moment(tempo, 'HH:mm').diff(moment().startOf('day'), 'seconds');
    return segundos >= segundosTempo;
}
export function tempoTotalEmSegundos(inicio, fim, tempoIntervalo) {
    const segundos = moment.duration(moment(fim).diff(moment(inicio))).asSeconds();
    const segundosTempo = tempoIntervalo ? moment(tempoIntervalo, 'HH:mm').diff(moment().startOf('day'), 'seconds') : 0;
    return segundos - segundosTempo;
}
export function segundosParaTempoFormatado(seconds) {
    return moment("2015-01-01").startOf('day')
        .seconds(seconds)
        .format('HH:mm');
}
function padLeft(nr, n, str) {
    return Array(n - String(nr).length + 1).join(str || '0') + nr;
}
export function formataCpfCnpj(valor) {
    if (!valor) {
        return "";
    }
    valor = valor.replace(".", "").replace(".", "").replace("-", "").replace("/", "");
    if (valor.length <= 11) {
        if (valor.length < 11) {
            valor = padLeft(valor, 11, '0');
        }
        valor = valor.replace(/\D/g, "");
        valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
        valor = valor.replace(/(\d{3})(\d)/, "$1.$2");
        valor = valor.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        return valor;
    }
    else {
        if (valor.length < 14) {
            valor = padLeft(valor, 14, '0');
        }
        valor = valor.replace(/\D/g, "");
        valor = valor.replace(/^(\d{2})(\d)/, "$1.$2");
        valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        valor = valor.replace(/\.(\d{3})(\d)/, ".$1/$2");
        valor = valor.replace(/(\d{4})(\d)/, "$1-$2");
        return valor;
    }
}
