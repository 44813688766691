import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { HotelQueryByIdComponent, useHotelCreate, useHotelUpdate, MunicipiosAllComponent, EstadosAllComponent } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import HotelLabel from './HotelNameLabel';
import { MaskTelefone } from '../../helpers/mask';
import LatLngPicker from '../../components/LatLngPicker';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { Grid } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { Money } from 'walletjs';
const HotelSchema = Yup.object()
    .shape({
        id: Yup.string().notRequired(),
        nome: Yup.string().required(),
        telefone: Yup.string().required().min(14).max(15),
        posicaoGeografica: Yup.object()
            .shape({
                x: Yup.number().required(),
                y: Yup.number().required()
            })
            .noUnknown(),
        site: Yup.string(),
        celular: Yup.string().notRequired().min(14).max(15),
        endereco: Yup.string(),
        // cidade: Yup.string().required(),
        precoStandard: Yup.number(),
        precoDuplo: Yup.string(),
        precoTriplo: Yup.string(),
        email: Yup.string(),
        preferencia: Yup.number().notRequired(),
        tipo: Yup.string().required(),
        referencia: Yup.string().notRequired(),
        codigoIbge: Yup.number().notRequired(),
    })
    .noUnknown();
function HotelForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useHotelUpdate();
    const insert = useHotelCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(HotelSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.hotel.update.id'));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.hotel.create.id'));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    let [precoStandard, setPrecoStandard] = React.useState(0);
    let [precoDuplo, setPrecoDuplo] = React.useState(0);
    let [precoTriplo, setPrecoTriplo] = React.useState(0);
    let [estadoSelecionado, setEstadoSelecionado] = React.useState(43);
    return (<HotelQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => {
            return (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(HotelSchema)} initialValues={_.get(data, 'hotel.list[0]') || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Hotel" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                <Grid container spacing={1}>
                    <AbaseLoading open={loading || submitting} />
                    <Grid item xs={12}>
                        <Field name="nome" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={HotelLabel.nome} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="telefone">
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={HotelLabel.telefone} InputProps={{
                                inputComponent: MaskTelefone
                            }} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="celular">
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={HotelLabel.celular} InputProps={{
                                inputComponent: MaskTelefone
                            }} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="email" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={HotelLabel.email} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="tipo" parse={defaultFieldParser}>
                            {({ input, meta }) => {
                                var tipos = [{ nome: 'HOTEL' }, { nome: 'AIRBNB' }];
                                return (<AbaseAutoCompleteFieldFinalForm data={tipos} label={HotelLabel.tipo} valueField="nome" textField="nome" {...input} />);
                            }}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="site">
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={HotelLabel.site} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Field name="preferencia">
                            {({ input, meta }) => {
                                if (input.value > 10) {
                                    input.onChange(10);
                                }
                                else if (input.value < 0) {
                                    input.onChange(0);
                                }
                                return (<React.Fragment>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12} md={5} lg={5}>
                                            <AbaseTextField label="Preferência" {...input} type="number" />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={7} lg={7} style={{ marginTop: '29px', paddingLeft: '15px' }}>
                                            <Slider min={0} max={10} step={1} value={input.value} onChange={(event, value) => input.onChange(value)} />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>);
                            }}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="precoStandard" formatOnBlur={precoStandard > 0} format={(val) => {
                            if (val) {
                                val = Money.init(val).getValue();
                                setPrecoStandard(precoStandard + 1);
                            }
                            return val;
                        }}>
                            {({ input, meta }) => (<AbaseTextField type="number" meta={meta} fullWidth={true} {...input} label={HotelLabel.precoStandard} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="precoDuplo" formatOnBlur={precoDuplo > 0} format={(val) => {
                            if (val) {
                                val = Money.init(val).getValue();
                                setPrecoDuplo(precoDuplo + 1);
                            }
                            return val;
                        }}>
                            {({ input, meta }) => (<AbaseTextField type="number" meta={meta} fullWidth={true} {...input} label={HotelLabel.precoDuplo} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="precoTriplo" formatOnBlur={precoTriplo > 0} format={(val) => {
                            if (val) {
                                val = Money.init(val).getValue();
                                setPrecoTriplo(precoTriplo + 1);
                            }
                            return val;
                        }}>
                            {({ input, meta }) => (<AbaseTextField type="number" meta={meta} fullWidth={true} {...input} label={HotelLabel.precoTriplo} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12}>
                        <Field name="endereco" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={HotelLabel.endereco} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="referencia" parse={(val) => val.toUpperCase()}>
                            {({ input, meta }) => {
                                return (<AbaseTextField meta={meta} fullWidth={true} {...input} label={HotelLabel.referencia} />);
                            }}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <EstadosAllComponent>
                            {({ data, loading }) => (<Field name="codigoIbgeNavigation">
                                {({ input, meta }) => {
                                    if (_.get(input, 'value.codigoUfNavigation.codigoUf') && _.get(input, 'value.codigoUfNavigation.codigoUf') !== estadoSelecionado)
                                        setEstadoSelecionado(_.get(input, 'value.codigoUfNavigation.codigoUf'));
                                    return (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={"Estado"} valueField={'codigoUf'} textField={'nome'} data={_.get(data, 'estados.list', [])} value={estadoSelecionado} onChange={(val => {
                                        setEstadoSelecionado(val);
                                    })} />);
                                }}
                            </Field>)}
                        </EstadosAllComponent>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <MunicipiosAllComponent variables={{ codigoEstado: estadoSelecionado }}>
                            {({ data, loading }) => (<Field name="codigoIbge">
                                {({ input, meta }) => {
                                    return (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={"Municipio"} valueField={'codigoIbge'} textField={'nome'} data={_.get(data, 'municipios.list', [])} {...input} />);
                                }}
                            </Field>)}
                        </MunicipiosAllComponent>
                    </Grid>
                    <Grid container spacing={1}>
                        <Field name="posicaoGeografica">
                            {({ input, meta }) => (<React.Fragment>
                                <Grid item xs={12}>
                                    <LatLngPicker showTextfield value={input.value} onChange={input.onChange} />
                                </Grid>
                            </React.Fragment>)}
                        </Field>
                    </Grid>
                </Grid>
            </DialogForm>)} />);
        }}
    </HotelQueryByIdComponent>);
}
export default HotelForm;
