import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { withRouter, Route } from 'react-router';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
const breadcrumbNameMap = {
    '/cadastros': 'Cadastros',
    '/cadastros/cliente': 'Cliente',
    '/cadastros/empresa': 'Empresa',
    '/cadastros/local': 'Localização',
    '/cadastros/hotel': 'Hotel',
    '/cadastros/contas': 'Contas',
    '/cadastros/contas/contascontatos': 'Contas Contatos',
    '/cadastros/equipe': 'Equipe',
    '/cadastros/usuario': 'Usuário',
    '/cadastros/agendatipo': 'Tipo de Agenda',
    '/cadastros/equipe/membros/:uuid': 'Membros da Equipe',
    '/cadastros/equipe/areas/:uuid': 'Áreas da Equipe',
    '/cadastros/area': 'Área',
    '/cadastros/requisicaotipo': 'Tipo de Requisição',
    '/cadastros/recursotipo': 'Tipo de Recurso',
    '/cadastros/tarefatipo': 'Tipo de Tarefa',
    '/cadastros/recurso': 'Recurso',
    '/cadastros/restaurante': 'Restaurantes',
    '/agenda/usuariolocal': 'Localizar Pessoas',
    '/cadastros/modelotermoaceite': "Modelo de Termo de Aceite",
    '/cadastros/parceiro': 'Parceiro',
    '/cadastros/requisicaoresponsavel': 'Responsável por Requisições',
    '/agenda': 'Agenda',
    '/agenda/agenda': 'Agenda',
    '/agenda/calendar': 'Calendário',
    '/agenda/tarefa': 'Tarefas',
    '/agenda/ordemdeservico': "Ordem de Serviço",
    '/agenda/termoaceite': "Termo de Aceite",
    '/agenda/recursolocacao': "Reserva de recursos",
    '/perfil': 'Perfil',
    '/agenda/requisitos': 'Requisitos',
    '/cliente/home': 'Home',
    '/cliente': 'Externo', '/agenda/projeto': 'Projeto',
    '/log': 'Log',
    '/cadastros/categoria': "Categoria de Cliente",
    '/cadastros/atividade': "Atividade de Cliente",
    '/cadastros/observacaoTipo': "Tipo de Observação para Cliente",
    '/projeto': "Projeto",
    '/projeto/projeto': "Projeto",
    '/projeto/projetoform': "Form de Projeto",
    '/projeto/modeloprojetoform/edit/:uuid/aba/projeto': "Edição do Modelo de Projeto",
    '/projeto/modeloprojetoform/edit/:uuid/aba/etapas': "Etapas do Modelo de Projeto",
    '/projeto/modeloprojetoform/edit/:uuid/aba/tarefas': "Tarefas do Modelo de Projeto",
    '/projeto/modeloprojetoform/edit/:uuid/aba/participante': "Participantes do Modelo de Projeto",
    "/projeto/modeloprojetoform": "Cadastro de Modelo de Projeto",
    '/projeto/projetoform/edit/:uuid/aba/projeto': "Edição de Projeto",
    '/projeto/projetoform/edit/:uuid/aba/anexos': "Anexos de Projeto",
    '/projeto/projetoform/edit/:uuid/aba/etapas': "Etapas de Projeto",
    '/projeto/projetoform/edit/:uuid/aba/apontamentos': "Apontamentos de Projeto",
    '/projeto/projetoform/edit/:uuid/aba/tarefas': "Tarefas de Projeto",
    '/projeto/projetoform/edit/:uuid/aba/agenda': "Agendas do Projeto",
    '/projeto/projetoapontamento': "Apontamentos do Projeto",
    '/projeto/projetoetapa': "Etapas do Projeto",
    '/projeto/projetoparticipante': "Participantes do Projeto",
    'cadastros/modelotermoaceite': "Modelo de Termo de Aceite",
    '/projeto/modelo': "Modelo de Projeto"
};
class BreadcrumbsWithRouter extends React.Component {
    render() {
        return (<Route>
            {({ location }) => {
                let pathnames = location.pathname.replace(/\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/, ':uuid').split('/').filter((x) => x);
                return (<Breadcrumbs className="breadcrumb" arial-label="Breadcrumb">
                    {pathnames.map((value, index) => {
                        let last = index === pathnames.length - 1;
                        let to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        if (!breadcrumbNameMap[to]) {
                            var caminho = location.pathname.split('/');
                            to = '';
                            caminho.map((val, index) => {
                                if (val.length > 0)
                                    to += `/ ${val} `;
                                return null;
                            });
                        }
                        return last ? (<Typography color="textPrimary" key={to}>
                            {breadcrumbNameMap[to] || 'Desconhecido'}
                        </Typography>) : breadcrumbNameMap[to] ? (<Link key={to} component="button" onClick={() => {
                            this.props.history.push(to);
                        }}>&nbsp;{breadcrumbNameMap[to]}&nbsp;
                        </Link>) : null;
                    })}
                </Breadcrumbs>);
            }}
        </Route>);
    }
}
export default withRouter(BreadcrumbsWithRouter);
