import React from 'react';
import { withStyles, Grid, CardContent, Card, Typography, IconButton } from '@material-ui/core';
import _ from 'lodash';
import { Route } from 'react-router';
import { RenderListWithModal } from '../../RenderWithModal';
import ClienteAgendaDetalhe from './ClienteAgendaDetalhe';
import { getUserId, getNivel, niveisEnum } from '../../services/login';
import { UsuarioQueryByIdComponent, AgendaQueryByUsuarioClienteComponent, ClienteQueryByIdComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { AbasePaperTable } from 'abase-components';
import AgendaLabel from '../Agenda/AgendaNameLabel';
import { formatDateTime } from '../../helpers/format';
import { errorMessagePaperTable } from '../../helpers';
import { Link } from 'react-router-dom';
import { useFiltroGeral } from '../../components/FiltroGeral';
import UserAvatarSac from '../../components/UserAvatarSac';
const styles = {
    bullet: {
        margin: '0 2px',
        transform: 'scale(0.8)',
        display: 'inline-block'
    },
    title: {
        fontSize: 18,
        color: 'black'
    }
};
function HomeClientePage(props) {
    const columns = [
        {
            name: AgendaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: AgendaLabel.cliId,
            field: "cliId",
            defaultVisible: false
        },
        {
            name: AgendaLabel.empId,
            field: "empId",
            defaultVisible: false
        },
        {
            name: AgendaLabel.usuId,
            field: "usuId",
            defaultVisible: false
        },
        {
            name: "",
            field: "usu.nome",
            sort: false,
            width: 100,
            customBodyRender: (value, rowData) => {
                return <React.Fragment>
                    {(value && (!rowData.agendaUsuario || rowData.agendaUsuario.length === 0)) ? (<UserAvatarSac name={value} color={_.get(rowData, "usu.corAvatar")} url={_.get(rowData, "usu.avatarUrl")} />) : null}
                    {rowData.agendaUsuario.map(x => (<UserAvatarSac name={x.usu.nome} color={_.get(x, "usu.corAvatar")} url={_.get(x, "usu.avatarUrl")} />))}
                </React.Fragment>;
            }
        },
        {
            name: AgendaLabel.inicio,
            field: "inicio",
            width: 120,
            customBodyRender: (value) => {
                return formatDateTime(value);
            }
        },
        {
            name: AgendaLabel.fim,
            field: "fim",
            width: 120,
            defaultVisible: false,
            customBodyRender: (value) => {
                return formatDateTime(value);
            }
        },
        {
            name: "Tipo",
            field: "agendaTipo.descricao",
            sort: false,
            width: 150
        },
        {
            name: AgendaLabel.cli,
            field: "contaCliente.razaosocial",
            sort: false,
            width: "15%"
        },
        {
            name: "Recursos",
            field: "agendaRecurso",
            sort: false,
            width: 100,
            customBodyRender: (value, rowData) => {
                const recursoNames = (value || []).map(x => x.rec.nome);
                return recursoNames.join(", ") || "N/A";
            }
        },
        {
            name: AgendaLabel.titulo,
            field: "titulo",
            width: "15%"
        },
        {
            name: AgendaLabel.descricao,
            field: "descricao",
            defaultVisible: false
        },
        {
            name: AgendaLabel.local,
            field: "loc.descricao",
            sort: false,
            defaultVisible: true
        },
        {
            name: "P.",
            hint: "Particular",
            field: "particular",
            defaultVisible: true,
            sort: false,
            width: 30,
            customBodyRender: value => value ? "S" : "N"
        },
        {
            name: AgendaLabel.emp,
            field: "emp.nomefantasia",
            sort: false,
            defaultVisible: false
        },
        {
            name: "Área",
            field: "are.descricao",
            sort: false,
            defaultVisible: false
        },
        {
            name: "Equipe",
            field: "equ.descricao",
            sort: false,
            defaultVisible: false
        },
        {
            name: AgendaLabel.status,
            field: "status",
            cellProps: {
                style: {
                    textAlign: "center"
                }
            },
            width: 100
        },
    ];
    const selectedActions = (selected) => {
        if (selected.length === 1)
            console.log(`${match.path}/agenda/detalhe/${selected[0].id}`);
        return [
            selected.length === 1 ? (<Link to={`/cliente/home/agenda/detalhe/${selected[0].id}`} {...props} title={`Visualizar detalhes`}>
                <IconButton aria-label={`Areas da Equipe`}>
                    <i className={'material-icons'}>info</i>
                </IconButton>
            </Link>) : null
        ];
    };
    const usuarioClienteId = getUserId();
    const { match } = props;
    const nivel = getNivel();
    const { cliente } = useFiltroGeral();
    return (<Grid container>
        <Route path={`${match.path}/agenda/detalhe/:id`} render={RenderListWithModal(ClienteAgendaDetalhe)} />
        {nivel === niveisEnum.parceiro && cliente ?
            <Grid item xs={12}>
                <ClienteQueryByIdComponent variables={{ id: cliente }}>
                    {({ data, loading }) => {
                        const cliente = _.get(data, 'cliente.list[0]');
                        return <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography color="textSecondary" gutterBottom>
                                    <b>Cliente</b>
                                </Typography>
                                <div className="colRow">
                                    <ul className="listaHome">
                                        <li>
                                            Nome: <b>{_.get(cliente, 'nome', 'N/A')}</b> <br />
                                            Razão Social: <b>{_.get(cliente, 'razaosocial', 'N/A')}</b> <br />
                                            CPF/CNPJ: <b>{_.get(cliente, 'cpfcnpj', 'N/A')}</b> <br />
                                            Telefone: <b>{_.get(cliente, 'telefone', 'N/A')}</b> <br />
                                            E-mail: <b>{_.get(cliente, 'email', 'N/A')}</b> <br />
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography color="textSecondary" gutterBottom>
                                    <b>Endereço</b>
                                </Typography>
                                <div className="colRow">
                                    <ul className="listaHome">
                                        <li>
                                            Cidade: <b>{_.get(cliente, 'cidade', 'N/A')} - {_.get(cliente, 'uf', 'N/A')} </b> <br />
                                            Bairro: <b>{_.get(cliente, 'bairro', 'N/A')}</b> <br />
                                            CEP: <b>{_.get(cliente, 'cep', 'N/A')}</b> <br />
                                            Logradouro: <b>{_.get(cliente, 'logradouro', 'N/A')}</b> <br />
                                            Número: <b>{_.get(cliente, 'numero', 'N/A')}</b> <br />
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>;
                    }}
                </ClienteQueryByIdComponent>
            </Grid>
            : null}
        <Grid item xs={12}>
            <UsuarioQueryByIdComponent variables={{ id: usuarioClienteId }}>
                {({ data, loading }) => {
                    const usuario = _.get(data, 'usuario.list[0]', {});
                    return (<React.Fragment>
                        <Card>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <b>Minhas Informações</b>
                                        </Typography>
                                        <div className="colRow f-12">
                                            <ul className="listaHome">
                                                <li>
                                                    Nome: <b>{_.get(usuario, 'nome', 'N/A') || 'N/A'}</b> <br />
                                                    Nascimento: <b>{_.get(usuario, 'datanascimento', null) ? new Date(_.get(usuario, 'datanascimento', null)).toLocaleDateString() : "N/A"}</b>
                                                    <br />
                                                    Cargo: <b>{_.get(usuario, 'cargo', 'N/A') || 'N/A'}</b>
                                                    <br />
                                                    Setor: <b>{_.get(usuario, 'setor', 'N/A') || 'N/A'}</b>
                                                    <br />
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <b>Meus Contatos</b>
                                        </Typography>
                                        <div className="colRow f-12">
                                            <ul className="listaHome">
                                                <li>
                                                    E-mail: <b>{_.get(usuario, 'email', 'N/A') || 'N/A'} </b>{' '}
                                                    <br />
                                                    Telefone:{' '}
                                                    <b>{_.get(usuario, 'telefone', 'N/A') || 'N/A'}</b> - Ramal:
															<b>{_.get(usuario, 'ramal', 'N/A') || 'N/A'}</b> <br />
                                                    <br />
                                                    <br />
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <GraphqlPaperTableListener extraVariables={{
                                    usuId: usuarioClienteId,
                                    cliId: cliente
                                }}>
                                    {({ variables, setVariables }) => (<AgendaQueryByUsuarioClienteComponent variables={variables} skip={variables === null}>
                                        {({ data, loading, refetch, error }) => {
                                            const rows = _.get(data, 'agenda.agendaCliente', []) || [];
                                            return (<div>
                                                <AbasePaperTable sticky={true} selectMode={'single'} loading={loading} data={rows} total={rows.length} columns={columns} title="Agendas" requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions}
                                                    // customActions={customActions}
                                                    selectListener={props.selectListener} onDoubleClick={(row) => {
                                                        const url = `/cliente/home/agenda/detalhe/${row.id}`;
                                                        props.history.push(url);
                                                    }} />
                                            </div>);
                                        }}
                                    </AgendaQueryByUsuarioClienteComponent>)}
                                </GraphqlPaperTableListener>
                            </CardContent>
                        </Card>
                    </React.Fragment>);
                }}
            </UsuarioQueryByIdComponent>
        </Grid>
    </Grid>);
}
export default withStyles(styles)(HomeClientePage);
