import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { EmpresaListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import EmpresaDelete from "./EmpresaDelete";
import EmpresaForm from "./EmpresaForm";
import EmpresaLabel from './EmpresaNameLabel';
export default function EmpresaList(props) {
    const { match } = props;
    const columns = [
        {
            name: EmpresaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: EmpresaLabel.razaosocial,
            field: "razaosocial"
        },
        {
            name: EmpresaLabel.nomefantasia,
            field: "nomefantasia"
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" to={`${match.path}/delete/${_.map(selected, "id").join(",")}`} component={Link} />
        ];
    };
    const customActions = [
        <AddAction key="add" to={`${match.path}/add`} component={Link} />,
    ];
    const filters = () => ([
        <Field name={"razaosocial"}>{({ input, meta }) => <AbaseTextField label={"Razão Social"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"nomefantasia"}>{({ input, meta }) => <AbaseTextField label={"Nome Fantasia"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = EmpresaLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<EmpresaListComponent variables={variables} skip={variables === null}>
        {({ data, loading, refetch, error }) => (<div>

            <AbasePaperTable
                customPlacehorderSearch={'Pesquisar por nome fantasia ou razão social'}
                sticky={true}
                selectMode={"multi"}
                loading={loading}
                data={_.get(data, "empresa.pagedList.data") || []}
                total={_.get(data, "empresa.pagedList.total")}
                columns={columns}
                customFilters={filters}
                title={formName}
                requestData={setVariables}
                error={errorMessagePaperTable(error)}
                customSelectedActions={selectedActions}
                customActions={customActions}
                selectListener={props.selectListener}
                onDoubleClick={row => {
                    props.history.push(`${match.path}/edit/${row.id}`);
                }}></AbasePaperTable>
            <ReenterRouteListener callback={refetch} />
            <Route path={`${match.path}/add`} render={RenderWithModal(EmpresaForm)} />
            <Route path={`${match.path}/edit/:id`} render={RenderWithModal(EmpresaForm)} />
            <Route path={`${match.path}/delete/:id`} render={RenderWithModal(EmpresaDelete)} />

        </div>)}
    </EmpresaListComponent>)}
    </GraphqlPaperTableListener>);
}
