/* eslint-disable array-callback-return */
import React from "react";
import Axios from "axios";
import { getToken } from "../../services/login";
import { AbaseDialogError } from "abase-components";
export function imprimirProjeto(ids, acao) {
    var params = "";
    ids.length > 0 ? ids.map((id, index) => {
        index !== 0 ? params += '&' : null;
        params += "Projeto=" + id;
    }) : null;
    // window.open(`/api/Projeto/gerar?${params}`)
    Axios({
        url: `api/Projeto/gerar?${params}`,
        method: 'get',
        responseType: 'blob',
        params: params,
        headers: { authorization: 'Bearer ' + getToken() }
    }).then(response => {
        const name = response.headers['content-disposition'].split('filename=')[1], url = window.URL.createObjectURL(new Blob([response.data])), link = document.createElement('a');
        if (acao === "download" || !acao) {
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        }
        else if (acao === "abrir") {
            var file = new Blob([response.data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
    }).catch(err => {
        console.log({ err });
        var aberto = true;
        return <AbaseDialogError open={aberto} error={err} title="Erro" onClose={() => { aberto = false; }}/>;
    });
}
