import React from 'react';
import { Field } from "react-final-form";
import AgendaRecursoList from './AgendaRecursoList';
export default class StepRecurso extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            cliId: null
        };
        this.setCliId = (cliId) => {
            if (this.state.cliId !== cliId)
                this.setState({ cliId });
        };
    }
    render() {
        return (
            <React.Fragment>
                <Field name={"particular"}>{({ input, meta }) => {
                    return input.value ? "Agendas particulares não podem reservar recursos." :
                        <Field name={"agendaRecurso"}>{({ input, meta }) => {
                            return (<React.Fragment>
                                <AgendaRecursoList data={input.value} onChangeData={input.onChange} {...this.props} />
                            </React.Fragment>);
                        }}</Field>
                }}</Field>
            </React.Fragment>);
    }
}
