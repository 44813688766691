/* eslint-disable no-useless-escape */
import _ from 'lodash';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { ContasContatosListComponent, ClienteContasComponent, UsuarioContatoClienteAllComponent, ClienteUltimoLocalComponent } from '../../generated/graphql';
import AgendaLabel from './AgendaNameLabel';
import { Grid } from '@material-ui/core';
import ContasForm from '../Contas/ContasForm';
import AutoCompleteWithEdit from '../../components/AutoCompleteWithEdit';
import AutoCompleteWithEditCustom from '../../components/AutoCompleteWithEditCustom';
import AlertaCliente from '../../components/AlertaCliente';
import ContasContatosForm from '../ContasContatos/ContasContatosForm';
import { withRouter } from 'react-router';
import { AbaseAutoCompleteFieldFinalForm, AbaseAutocompleteField } from 'abase-components';
import CardContato from '../../components/CardContato';
const fixTypescript = AbaseAutocompleteField;
const CardCliente = ({ data, ...otherProps }) => {
    let style = { borderBottom: "1px dashed #cccccc", width: "100%", fontSize: '12px', lineHeight: '18px', display: 'block' };
    const cssInativo = { color: "#898989" };
    if (!data.ativo) {
        style = { ...style, ...cssInativo };
    }
    return (<fixTypescript.Components.Option {...otherProps}>
			<div style={style}>
				{data.contratoativo ? <strong className="tag"> Com Contrato </strong> : null}
				{!data.contratoativo ? <strong className="tag"> Sem Contrato </strong> : null}
				<br />
				<strong>{data.tipopessoa === "FISICA" ? "Nome" : "Razão Social"}</strong>: {data.razaosocial}<br />
				<strong>{data.tipopessoa === "FISICA" ? "Apelido" : "Nome Fantasia"}</strong>: {data.nome}<br />
				<strong>{data.cpfcnpj && data.cpfcnpj.length < 14 ? "CPF" : "CNPJ"}:</strong>{data.cpfcnpj}&nbsp;{!data.ativo ?
        <strong>(inativo)&nbsp;</strong> : null}{data.__typename === "ClienteType" ? <strong>(cliente)</strong> : <strong>(lead)</strong>}
			</div>
		</fixTypescript.Components.Option>);
};
class StepCliente extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            contaCliente: {},
            searchCliente: ""
        };
        this.setContaCliente = (contaCliente) => {
            if (this.state.contaCliente !== contaCliente && contaCliente !== null)
                this.setState({ contaCliente });
        };
        this.setSearchCliente = _.debounce(text => {
            this.setState({ searchCliente: text });
        }, 300);
    }
    render() {
        return (<React.Fragment>
				<Grid container spacing={1}>
					<FormSpy>{({ form }) => (<Field name={'empId'}>
							{({ input: { value: empId } }) => (<ClienteUltimoLocalComponent>{({ refetch: queryClienteUltimoLocal }) => (<ClienteContasComponent skip={!this.state.searchCliente} variables={{
            filter: this.state.searchCliente,
            empId
        }}>
										{({ data, error, loading, refetch, fetchMore }) => {
            return (<React.Fragment>


													<Field name={'contaCliente'}>
														{({ input, meta }) => {
                return (<React.Fragment>
																	<Grid item xs={12} sm={12} md={6} lg={6} className="colCli"> 
																		<Field name={'contaContatId'}>
																			{({ input: inputContaContatId }) => (<Field name={'clienteContatoUsuId'}>
																					{({ input: inputClienteContatoId }) => (<React.Fragment>

																							<AutoCompleteWithEditCustom noOptionsMessage={() => {
                    return (this.state.searchCliente ?
                        (loading ? "Carregando" : `Nenhuma opção encontrada para \"${this.state.searchCliente}\"`) : "Digite para buscar");
                }} onInputChange={searchString => {
                    this.setSearchCliente(searchString);
                    // refetch({
                    // 	filter: searchString
                    // })
                }} onMenuScrollToTop={() => {
                    console.log("onMenuScrollToTop");
                }} onMenuScrollToBottom={() => {
                    // const lastId = _.get(_.last(_.get(data, 'cliente.contas', [])), "id")
                    // console.log("onOptionsScrollToBottom", lastId)
                    // if (lastId) {
                    // 	fetchMore({
                    // 		variables: {
                    // 			afterId: lastId,
                    // 			filter: this.state.searchCliente
                    // 		},
                    // 		updateQuery: (previousResult, { fetchMoreResult }) => {
                    // 			const previousEntry = _.get(previousResult, 'cliente.contas', []);
                    // 			const newData = _.get(fetchMoreResult, 'cliente.contas', []);
                    // 			return {
                    // 				cliente: {
                    // 					// Put the new comments in the front of the list
                    // 					contas: [...previousEntry, ...newData]
                    // 				},
                    // 				__typename: previousEntry.__typename
                    // 			};
                    // 		}
                    // 	})
                    // }
                }} getOptionValue={(x) => x} notEditable={input.value &&
                    input.value.__typename === 'ClienteType'} path={'Conta'} form={ContasForm} isLoading={loading} label={AgendaLabel.cli} getOptionLabel={x => x.razaosocial + " " + (!x.ativo ? "(inativo)" : "")} textField={'nome'} data={_.get(data, 'cliente.contas', [])} itemHeight={73} components={{ Option: CardCliente }} {...input} filterOption={(candidate, value) => {
                    return true;
                }} onChange={(value) => {
                    if (value && value.__typename === 'ClienteType') {
                        queryClienteUltimoLocal({
                            id: value.id
                        })
                            .then(({ data: result }) => {
                            const ultimoLocal = result.cliente.list[0].ultimoLocal;
                            if (ultimoLocal) {
                                form.change("locId", ultimoLocal.id);
                            }
                        }).catch(e => {
                            console.log("Erro consultando local", e);
                        });
                    }
                    inputContaContatId.onChange(null);
                    inputClienteContatoId.onChange(null);
                    input.onChange(value);
                }} onKeyDown={this.props.navigatePrevKeyDown} forwardRef={this.props.focusRef}/>

																						</React.Fragment>)}
																				</Field>)}
																		</Field>
																	</Grid>

																	<Grid item xs={12} sm={12} md={6} lg={6}>
																		{input.value && input.value.__typename === 'ContasType' ? (<ContasContatosListComponent skip={!input.value} variables={{ conta: input.value.id || null }}>
																				{({ data, loading }) => (<Field name={'contaContatId'}>
																						{({ input: inputConta, meta }) => (<AutoCompleteWithEdit notEditable={true} path={'ContasContatos'} form={ContasContatosForm} isLoading={loading} itemHeight={73} components={{ Option: CardContato }} label={'Contato'} valueField={'id'} textField={'nome'} data={_.get(data, 'contasContatos.pagedList.data')} infoAdicionalRota={`/?conta=${input
                    .value.id}`} {...inputConta} onKeyDown={this.props.navigateNextKeyDown}/>)}
																					</Field>)}
																			</ContasContatosListComponent>) : (<UsuarioContatoClienteAllComponent skip={!input.value} variables={{
                    cliId: (input.value && input.value.id) || null
                }}>
																					{({ data, loading }) => (<Field name={'clienteContatoUsuId'}>
																							{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm form={ContasContatosForm} isLoading={loading} itemHeight={73} components={{ Option: CardContato }} label={'Contato'} valueField={'id'} textField={'nome'} data={_.get(data, 'usuario.list')} {...input} onKeyDown={this.props.navigateNextKeyDown}/>)}
																						</Field>)}
																				</UsuarioContatoClienteAllComponent>)}
																	</Grid>
																	<Grid item xs={12}>
																		<Field name={'contaCliente'}>{({ input: { value } }) => (<AlertaCliente clienteId={_.get(value, "id")} showAlert/>)}</Field>
																	</Grid>
																</React.Fragment>);
            }}
													</Field>

												</React.Fragment>);
        }}
									</ClienteContasComponent>)}</ClienteUltimoLocalComponent>)}</Field>)}</FormSpy>

				</Grid>
			</React.Fragment>);
    }
}
export default withRouter(StepCliente);
