import { AbaseLoading, AbaseTextField, AbaseCheckbox, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm, AbaseDatePickerField } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import * as Yup from "yup";
import { UsuarioAllComponent, ProjetoApontamentoQueryByIdComponent, useProjetoApontamentoCreate, useProjetoApontamentoUpdate } from "../../generated/graphql";
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from "../../helpers";
import { validate, cast } from "../../helpers/validations";
import ProjetoApontamentoLabel from "./ProjetoApontamentoNameLabel";
import { Grid } from "@material-ui/core";
import FormWithoutDialog from "../../FormWithoutDialog";
import { defaultFieldParser } from "../../helpers/finalFormDecorators";
import { getUserId } from "../../services/login";
const ProjetoApontamentoSchema = Yup.object()
    .shape({
    id: Yup.string().notRequired(),
    proId: Yup.string().required(),
    usuId: Yup.string().required(),
    descricao: Yup.string().required(),
    data: Yup.date().required(),
    confidencial: Yup.bool().notRequired()
})
    .noUnknown();
function ProjetoApontamentoForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useProjetoApontamentoUpdate({ refetchQueries: ["projetoAndamento"] });
    const insert = useProjetoApontamentoCreate({ refetchQueries: ["projetoAndamento"] });
    const handleSubmit = async (values) => {
        const mutationData = await cast(ProjetoApontamentoSchema, values);
        try {
            if (params && params.apontamentoId) {
                const result = await update({
                    variables: {
                        id: params.apontamentoId,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.projetoApontamento.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.projetoApontamento.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.apontamentoId;
    const isUsingDraft = notEditting;
    return (<ProjetoApontamentoQueryByIdComponent variables={{ ...params, id: params.apontamentoId }} skip={notEditting} onError={err => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
      {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} subscription={{
        submitting: true
    }} validate={validate(ProjetoApontamentoSchema)} initialValues={_.get(data, "projetoApontamento.list[0]") || { proId: props.match.params.projetoId, confidencial: true, usuId: getUserId(), data: new Date().toISOString() }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<FormWithoutDialog title="Projeto Apontamento" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
              <Grid container spacing={1}>
                <AbaseLoading open={loading || submitting}/>
                <Grid item xs={12}>
                  <UsuarioAllComponent variables={{ cliente: false }}>
                    {({ data, loading }) => (<Field name="usuId">
                        {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={ProjetoApontamentoLabel.usu} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input}/>)}
                      </Field>)}
                  </UsuarioAllComponent>
                </Grid>
                <Grid item xs={12}>
                  <Field name="descricao" parse={defaultFieldParser}>
                    {({ input, meta }) => (<AbaseTextField multiline meta={meta} fullWidth={true} rows={5} {...input} label={ProjetoApontamentoLabel.descricao}/>)}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Field name="data">
                    {({ input, meta }) => (<AbaseDatePickerField meta={meta} {...input} label={ProjetoApontamentoLabel.data}/>)}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Field name="confidencial">
                    {({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={ProjetoApontamentoLabel.confidencial}/>)}
                  </Field>
                </Grid>

              </Grid>
            </FormWithoutDialog>)}/>)}
    </ProjetoApontamentoQueryByIdComponent>);
}
export default ProjetoApontamentoForm;
