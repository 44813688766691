/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
import { Grid, Button } from '@material-ui/core';
import { AbaseAutocompleteField, AbaseDatePickerField } from 'abase-components';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useMinhasEmpresas } from '../generated/graphql';
import UserAvatarSac from './UserAvatarSac';
import { DialogFormComponent } from '../DialogForm';
import SaveIcon from '@material-ui/icons/Save';
import { isMobileMediaQuery } from './withIsMobile';
const filtroGeralContext = React.createContext({
    area: null,
    empresa: null,
    endDate: null,
    equipe: null,
    usuario: null,
    startDate: null,
    setEmpresa: () => null,
    setArea: () => null,
    setEquipe: () => null,
    setPeriod: () => null,
    setUsuario: () => null,
    filtroVisivel: false,
    setFiltroVisivel: () => null,
    filtroClienteVisivel: false,
    setFiltroClienteVisivel: () => null
});
function FiltroGeralSaveState() {
    return (<filtroGeralContext.Consumer>{({ empresa, area, equipe, usuario }) => {
        saveState({ empresa, area, equipe, usuario });
        return null;
    }}</filtroGeralContext.Consumer>);
}
export function FiltroGeralProvider(props) {
    const [empresa, setEmpresa] = useState(retrieveStateField.bind(null, "empresa"));
    const [area, setArea] = useState(retrieveStateField.bind(null, "area"));
    const [usuario, setUsuario] = useState(retrieveStateField.bind(null, "usuario"));
    const [equipe, setEquipe] = useState(retrieveStateField.bind(null, "equipe"));
    const [cliente, setCliente] = useState(retrieveStateField.bind(null, "cliente"));
    const [filtroClienteVisivel, setFiltroClienteVisivel] = useState(false);
    const [filtroVisivel, setFiltroVisivel] = useState(false);
    const [period, setPeriod] = useState({
        startDate: moment(new Date()),
        endDate: moment(new Date()).add(15, "days")
    });
    const setAreaWrapper = (newArea) => {
        if (!newArea) {
            setEquipe(null);
        }
        else if (equipe) {
            if ((newArea && newArea.areaEquipe || []).filter((x) => x.equ.id === equipe.id).length === 0) {
                setEquipe(null);
            }
        }
        setArea(newArea);
    };
    const setEmpresaWrapper = (empresa) => {
        if (!empresa) {
            setAreaWrapper(null);
        }
        else if (area) {
            if (empresa.area.filter(x => x.id === area.id).length === 0) {
                setAreaWrapper(null);
            }
        }
        setEmpresa(empresa);
    };
    const onClose = () => setFiltroVisivel(false);
    const onSubmit = () => {
        saveState({ empresa, area, equipe, usuario });
        setFiltroVisivel(false);
    };
    return (<filtroGeralContext.Provider value={{
        empresa,
        area,
        equipe,
        usuario,
        startDate: period.startDate,
        endDate: period.endDate,
        setEmpresa: setEmpresaWrapper,
        setEquipe,
        setPeriod,
        setArea: setAreaWrapper,
        setUsuario,
        filtroVisivel,
        setFiltroVisivel,
        cliente,
        setCliente,
        filtroClienteVisivel,
        setFiltroClienteVisivel
    }}>
        {props.children}
        {filtroVisivel ? (<DialogFormComponent noScroll title="Filtro Geral" onSubmit={onSubmit} dontUseRoute onClose={onClose} actions={<Grid container spacing={0}>
            <Grid item xs={12} className="center mb15">
                <Button onClick={onClose} key="cancelar" variant="contained">
                    <i className="material-icons">close</i>Fechar
                        </Button>

                <Button type="button" variant="contained" className="btnSuccess ml5" key="submit" onClick={onSubmit}>
                    <SaveIcon>send</SaveIcon> Salvar Filtro
                        </Button>
            </Grid>
        </Grid>}>
            <FiltroGeralHorizontal />

        </DialogFormComponent>) : null}
    </filtroGeralContext.Provider>);
}
export const useFiltroGeral = () => useContext(filtroGeralContext);
export const FiltroGeralConsumer = filtroGeralContext.Consumer;
export function FiltroGeral(props) {
    const { data, loading } = useMinhasEmpresas();
    const isMobile = isMobileMediaQuery();
    const [focusedInput, setFocusedInput] = useState(null);
    const { empresa, area, equipe, startDate, endDate, setEmpresa, setEquipe, setPeriod, setArea, usuario, setUsuario } = useContext(filtroGeralContext);
    if (!data || loading) {
        return null;
    }
    return <div className="colFiltro">
        <Grid container spacing={1}>
            {props.hideDates ? null : (isMobile ? <React.Fragment>
                <Grid item xs={12} lg={4}>
                    <AbaseDatePickerField label={"Início"} value={startDate} onChange={value => {
                        setPeriod({ endDate, startDate: value || null });
                    }} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <AbaseDatePickerField label={"Fim"} value={endDate} onChange={value => setPeriod({ startDate, endDate: value || null })} />
                </Grid>
            </React.Fragment> :
                <Grid item xs={12} lg={4}>
                    <label className="lableFil">
                        Datas
                </label>
                    <DateRangePicker startDatePlaceholderText="Data inicial" endDatePlaceholderText="Data final" startDate={startDate} // momentPropTypes.momentObj or null,
                        startDateId="filter_date_start" // PropTypes.string.isRequired,
                        endDate={endDate} // momentPropTypes.momentObj or null,
                        endDateId="filter_date_end" // PropTypes.string.isRequired,
                        isOutsideRange={() => false} onDatesChange={({ startDate, endDate }) => setPeriod({ startDate, endDate })} // PropTypes.func.isRequired,
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                    />
                </Grid>)}
            <Grid item xs={12} lg={props.hideDates ? 3 : 2}>
                <AbaseAutocompleteField label={"Empresa"} data={data.empresa.minhasEmpresas} getOptionValue={x => x} textField={"nomefantasia"} value={empresa} onChange={(x) => setEmpresa(x)} />
            </Grid>
            <Grid item xs={12} lg={props.hideDates ? 3 : 2}>
                <AbaseAutocompleteField label={"Área"} data={_.get(empresa, "area")} getOptionValue={x => x} textField={"descricao"} value={area} onChange={(x) => setArea(x)} />
            </Grid>
            <Grid item xs={12} lg={props.hideDates ? 3 : 2}>
                <AbaseAutocompleteField label={"Equipe"} data={_.map(_.get(area, "areaEquipe"), "equ")} getOptionValue={x => x} textField={"descricao"} value={equipe} onChange={(x) => setEquipe(x)} />
            </Grid>
            <Grid item xs={12} lg={props.hideDates ? 3 : 2}>
                <AbaseAutocompleteField label={"Usuário"} data={_.map(_.get(empresa, "usuarioEmpresa", []), "usu")} getOptionValue={x => x} textField={"nome"} value={usuario} onChange={(x) => setUsuario(x)} getOptionLabel={x => (<div><UserAvatarSac name={x.nome} color={x.corAvatar} url={x.avatarUrl} size={25} /> {x.nome}</div>)} />
            </Grid>
        </Grid>
    </div>;
}
export function FiltroGeralHorizontal(props) {
    const { data, loading } = useMinhasEmpresas();
    const [focusedInput, setFocusedInput] = useState(null);
    const isMobile = isMobileMediaQuery();
    const { empresa, area, equipe, startDate, endDate, setEmpresa, setEquipe, setPeriod, setArea, usuario, setUsuario } = useContext(filtroGeralContext);
    if (!data || loading) {
        return null;
    }
    return <div className="colFiltro">
        <Grid container spacing={1} style={{ marginBottom: 15 }}>
            {props.hideDates ? null : (<React.Fragment>
                <Grid item xs={12} lg={6}>
                    <AbaseDatePickerField label={"Início"} value={startDate} onChange={(value) => {
                        setPeriod({ endDate, startDate: value ? new Date(value) : null });
                    }} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <AbaseDatePickerField label={"Fim"} value={endDate} onChange={(value) => setPeriod({ startDate, endDate: value ? new Date(value) : null })} />
                </Grid>
            </React.Fragment>)}
            <Grid item xs={12} lg={6}>
                <AbaseAutocompleteField label={"Empresa"} data={data.empresa.minhasEmpresas} getOptionValue={x => x} textField={"nomefantasia"} value={empresa} onChange={(x) => setEmpresa(x)} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <AbaseAutocompleteField label={"Área"} data={_.get(empresa, "area")} getOptionValue={x => x} textField={"descricao"} value={area} onChange={(x) => setArea(x)} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <AbaseAutocompleteField label={"Equipe"} data={_.map(_.get(area, "areaEquipe"), "equ")} getOptionValue={x => x} textField={"descricao"} value={equipe} onChange={(x) => setEquipe(x)} />
            </Grid>
            <Grid item xs={12} lg={6}>
                <AbaseAutocompleteField label={"Usuário"} data={_.map(_.get(empresa, "usuarioEmpresa", []), "usu")} getOptionValue={x => x} textField={"nome"} valueComponent={props => (<React.Fragment>
                    <UserAvatarSac name={props.data.nome} color={props.data.corAvatar} url={props.data.avatarUrl} size={22} />&nbsp;&nbsp;{props.data.nome}
                </React.Fragment>)} getOptionLabel={x => x.nome} value={usuario} onChange={(x) => setUsuario(x)} />
            </Grid>

        </Grid>
    </div>;
}
function getUser() {
    const userText = localStorage.getItem("user");
    if (!userText) {
        return null;
    }
    const userJson = JSON.parse(userText);
    return userJson.identity.usuario;
}
function retrieveState() {
    const userId = getUser();
    if (!userId)
        return null;
    const key = "filtro-geral-v1-" + userId;
    const stateText = localStorage.getItem(key);
    if (!stateText) {
        return null;
    }
    return JSON.parse(stateText);
}
function saveState(state) {
    const userId = getUser();
    if (!userId)
        return null;
    const key = "filtro-geral-v1-" + userId;
    localStorage.setItem(key, JSON.stringify(state));
}
function retrieveStateField(field) {
    const state = retrieveState();
    if (state) {
        return state[field];
    }
    return null;
}
