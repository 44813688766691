import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseDropZone, AbaseDialogConsumer } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { ProjetoAnexoQueryByIdComponent, useProjetoAnexoCreate, useProjetoAnexoUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import ProjetoAnexoLabel from './ProjetoAnexoNameLabel';
import { Grid } from '@material-ui/core';
const ProjetoAnexoSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    proId: Yup.string().notRequired(),
    usuId: Yup.string().notRequired(),
    descricao: Yup.string().required().max(500, 'Tamanho máximo é de 500 caracteres.'),
    tipo: Yup.string().required(),
    arquivoBase64: Yup.string().required(),
}).noUnknown();
function ProjetoAnexoForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useProjetoAnexoUpdate({ refetchQueries: ["projetoAndamento"] });
    const insert = useProjetoAnexoCreate({ refetchQueries: ["projetoAndamento"] });
    const handleSubmit = async (values) => {
        const mutationData = await cast(ProjetoAnexoSchema, values);
        try {
            if (params && params.idProjetoAnexo) {
                const result = await update({
                    variables: {
                        id: params.idProjetoAnexo,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.projetoAnexo.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.projetoAnexo.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.idProjetoAnexo;
    const isUsingDraft = notEditting;
    return (<AbaseDialogConsumer>
            {({ showError, showSnack }) => (<ProjetoAnexoQueryByIdComponent variables={{ ...params, id: params.idProjetoAnexo }} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
                    {({ data, loading, refetch, error }) => (<FormDraft useDraft={false} onSubmit={handleSubmit} validate={validate(ProjetoAnexoSchema)} subscription={{
        submitting: true
    }} initialValues={_.get(data, "projetoAnexo.list[0]") || {
        proId: match.params.projetoId
    }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Projeto Anexo" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                                    <AbaseLoading open={loading || submitting}/>
                                    <Grid container spacing={1}>
                                        <Grid>
                                            <Field name="descricao">{({ input, meta }) => (<AbaseTextField style={{ marginBottom: 5 }} multiline meta={meta} rows={4} fullWidth={true} {...input} label={ProjetoAnexoLabel.descricao}/>)}</Field>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {!params.idProjetoAnexo ?
        <Field name="tipo">{({ input: inputTipo }) => (<Field name="arquivoBase64">{({ input }) => {
            const tamanhoMB = 15;
            const tamanhoBits = (tamanhoMB * 1024 * 1024 * 8);
            return (<AbaseDropZone maxSize={tamanhoBits} onDropAccepted={(files) => {
                let file = files[0];
                let extensao = file.name.split('.');
                inputTipo.onChange(extensao[extensao.length - 1]);
                const reader = new FileReader();
                reader.onload = (event) => {
                    input.onChange(event.target.result.split("base64,")[1]);
                };
                reader.onprogress = (ev) => {
                    console.log(ev.type);
                };
                reader.readAsDataURL(file);
            }} onDropRejected={(file, evento) => {
                if (file[0].size > tamanhoBits)
                    showError({ message: `Arquivo tem mais de ${tamanhoMB}MB."` });
                else {
                    return true;
                }
                return false;
            }} width="100%"/>);
        }}</Field>)}</Field>
        : null}
                                        </Grid>
                                    </Grid>
                                </DialogForm>)}/>)}
                </ProjetoAnexoQueryByIdComponent>)}
        </AbaseDialogConsumer>);
}
export default ProjetoAnexoForm;
