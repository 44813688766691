import { AbaseLoading, AbaseTextField, clearDraft, FormDraft, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import * as Yup from 'yup';
import DialogForm from '../../DialogForm';
import { LocalQueryByIdComponent, useLocalCreate, useLocalUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { cast, validate } from '../../helpers/validations';
import LocalLabel from './LocalNameLabel';
import { geocode } from '../../helpers/geography';
import LatLngPicker from '../../components/LatLngPicker';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
const LocalSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    descricao: Yup.string().required(),
    cep: Yup.string().notRequired().min(8).max(8),
    posicaoMapa: Yup.object().shape({
        x: Yup.number().required(),
        y: Yup.number().required(),
    }).noUnknown()
}).noUnknown();
function LocalForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useLocalUpdate({
        refetchQueries: ["localAll", "localList"]
    });
    const insert = useLocalCreate({
        refetchQueries: ["localAll", "localList"]
    });
    const handleSubmit = async (values) => {
        const mutationData = await cast(LocalSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.local.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.local.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<LocalQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(LocalSchema)} initialValues={_.get(data, "local.list[0]") || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Local" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
            <AbaseLoading open={loading || submitting} />
            <Field name="descricao" parse={defaultFieldParser}>{({ input, meta }) => (<Field name="posicaoMapa">{({ input: inputPosicaoMapa }) => (<Field name="cep">{({ input: inputCep, meta: metaCep }) => (<React.Fragment>
                <AbaseTextField meta={meta} fullWidth={true} {...input} label={LocalLabel.descricao} onBlur={async (event) => {
                    input.onBlur(event);
                    if (input.value && !inputPosicaoMapa.value) {
                        const geocodeResult = await geocode(input.value);
                        if (geocodeResult) {
                            inputPosicaoMapa.onChange(geocodeResult.latlng);
                            if (!inputCep.value && geocodeResult.cep) {
                                inputCep.onChange(geocodeResult.cep.replace("-", ""));
                            }
                        }
                    }
                }} />
                <AbaseTextField meta={metaCep} fullWidth={true} {...inputCep} label={LocalLabel.cep} />
                <div className="b-1 mt15">
                    <LatLngPicker value={inputPosicaoMapa.value} onChange={inputPosicaoMapa.onChange} />
                </div>
            </React.Fragment>)}</Field>)}</Field>)}</Field>
        </DialogForm>)} />)}
    </LocalQueryByIdComponent>);
}
export default LocalForm;
