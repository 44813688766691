import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { EquipeListComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal, { RenderListWithModal } from '../../RenderWithModal';
import EquipeDelete from './EquipeDelete';
import EquipeForm from './EquipeForm';
import EquipeLabel from './EquipeNameLabel';
import { IconButton } from '@material-ui/core';
import { PeopleOutline } from '@material-ui/icons';
import UsuarioEquipeList from '../UsuarioEquipe/UsuarioEquipeList';
import { Chip } from '@material-ui/core';
import EmpresaEquipeAreaFiltro from '../../components/EmpresaEquipeAreaFiltro';
export default function EquipeList(props) {
    const { match } = props;
    const columns = [
        {
            name: EquipeLabel.id,
            field: 'id',
            defaultVisible: false
        },
        {
            name: EquipeLabel.descricao,
            field: 'descricao'
        },
        {
            name: EquipeLabel.emp,
            field: 'emp.nomefantasia'
        },
        {
            name: 'Áreas',
            field: 'areaEquipe',
            customBodyRender: (value) => value
                ? value.map((x, i) => (<Chip key={i} title={x.are.descricao} variant={'outlined'} className="pullLeft h-auto" label={x.are.descricao} />))
                : null
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            selected.length === 1 ? (<Link to={`/cadastros/equipe/membros/${selected[0].id}`} {...props} title={`Membros da Equipe`}>
                <IconButton aria-label={`Membros da Equipe`}>
                    <PeopleOutline />
                </IconButton>
            </Link>) : null,
            // selected.length === 1 ? (
            // 	<Link to={`/cadastros/equipe/areas/${selected[0].id}`} {...props} title={`Areas da Equipe`}>
            // 		<IconButton aria-label={`Areas da Equipe`}>
            // 			<LocalActivity />
            // 		</IconButton>
            // 	</Link>
            // ) : null,
            <DeleteAction key="delete" to={`${match.path}/delete/${_.map(selected, 'id').join(',')}`} component={Link} />
        ];
    };
    const customActions = [<AddAction key="add" to={`${match.path}/add`} component={Link} />];
    const filters = () => [
        <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta} />}</Field>,
        <EmpresaEquipeAreaFiltro hideEquipe />,
        <Field name={'descricao'}>
            {({ input, meta }) => <AbaseTextField label={'Descrição'} {...input} meta={meta} />}
        </Field>

    ];
    const formName = EquipeLabel.form;
    return (<GraphqlPaperTableListener>
        {({ variables, setVariables }) => (<EquipeListComponent variables={variables} skip={variables === null}>
            {({ data, loading, refetch, error }) => (<div>
                <AbasePaperTable
                    customPlacehorderSearch={"Pesquisar por descrição"}
                    sticky={true}
                    selectMode={'single'}
                    loading={loading}
                    data={_.get(data, 'equipe.pagedList.data') || []}
                    total={_.get(data, 'equipe.pagedList.total')}
                    columns={columns}
                    customFilters={filters}
                    title={formName}
                    requestData={setVariables}
                    error={errorMessagePaperTable(error)}
                    customSelectedActions={selectedActions}
                    customActions={customActions}
                    selectListener={props.selectListener}
                    onDoubleClick={row => {
                        props.history.push(`${match.path}/edit/${row.id}`);
                    }} />
                <ReenterRouteListener callback={refetch} />
                <Route path={`${match.path}/add`} render={RenderWithModal(EquipeForm)} />
                <Route path={`${match.path}/edit/:id`} render={RenderWithModal(EquipeForm)} />
                <Route path={`${match.path}/membros/:id`} render={RenderListWithModal(UsuarioEquipeList)} />
                <Route path={`${match.path}/delete/:id`} render={RenderWithModal(EquipeDelete)} />

            </div>)}
        </EquipeListComponent>)}
    </GraphqlPaperTableListener>);
}
