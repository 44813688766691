import { AbaseLoading, AbaseTextField, AbaseDatePickerField, FormDraft, clearDraft, useAbaseDialog } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import * as Yup from "yup";
import { ProjetoEtapaQueryByIdComponent, useProjetoEtapaCreate, useProjetoEtapaUpdate } from "../../generated/graphql";
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from "../../helpers";
import { validate, cast } from "../../helpers/validations";
import ProjetoEtapaLabel from "./ProjetoEtapaNameLabel";
import { Grid } from "@material-ui/core";
import FormWithoutDialog from "../../FormWithoutDialog";
import { defaultFieldParser } from "../../helpers/finalFormDecorators";
const ProjetoEtapaSchema = Yup.object()
    .shape({
    id: Yup.string().notRequired(),
    proId: Yup.string().notRequired(),
    descricao: Yup.string().required().max(200, 'Tamanho máximo é de 200 caracteres.'),
    porcentagem: Yup.number().notRequired().default(0),
    ordem: Yup.number().required(),
    dataLimite: Yup.date().notRequired()
})
    .noUnknown();
function ProjetoEtapaForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useProjetoEtapaUpdate({ refetchQueries: ["projetoAndamento"] });
    const insert = useProjetoEtapaCreate({ refetchQueries: ["projetoAndamento"] });
    const handleSubmit = async (values) => {
        const mutationData = await cast(ProjetoEtapaSchema, values);
        try {
            if (params && params.idProjetoEtapa) {
                const result = await update({
                    variables: {
                        id: params.idProjetoEtapa,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.projetoEtapa.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.projetoEtapa.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.idProjetoEtapa;
    const isUsingDraft = notEditting;
    return (<React.Fragment>
      <ProjetoEtapaQueryByIdComponent variables={{ id: params.idProjetoEtapa }} skip={notEditting} onError={err => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(ProjetoEtapaSchema)} initialValues={_.get(data, "projetoEtapa.list[0]") || { proId: props.match.params.projetoId }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<FormWithoutDialog title="Etapa do Projeto" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                <Grid container spacing={1}>
                  <AbaseLoading open={loading || submitting}/>
                  <Grid item xs={12}>
                    <Field name="descricao" parse={defaultFieldParser}>
                      {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ProjetoEtapaLabel.descricao}/>)}
                    </Field>
                  </Grid>
                  
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="ordem">
                      {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ProjetoEtapaLabel.ordem}/>)}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="dataLimite">
                      {({ input, meta }) => (<AbaseDatePickerField meta={meta} {...input} label={ProjetoEtapaLabel.dataLimite}/>)}
                    </Field>
                  </Grid>
                  
                </Grid>
              </FormWithoutDialog>)}/>)}
      </ProjetoEtapaQueryByIdComponent>
    </React.Fragment>);
}
export default ProjetoEtapaForm;
