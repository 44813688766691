import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
export default class NotFoundPage extends React.Component {
    render() {
        return (<div>
                <Paper elevation={1} style={{ flex: 1, padding: 10, marginTop: 1 }}>
                    <div style={{ textAlign: "center", marginTop: "-62px", position: "absolute", top: "50%", width: "100%", left: 0 }}>
                        <i style={{ fontSize: 90 }} className="material-icons">cloud_off </i>
                        <Typography variant="h5">
                            <p style={{ marginTop: 0 }}>Ops! Não foi possível encontrar a página solicitada... </p>
                        </Typography>
                    </div>
                </Paper>
            </div>);
    }
}
