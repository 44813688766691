/* eslint-disable array-callback-return */
import React from 'react';
import { getInfo } from '../../services/login';
import { FormControlLabel, Dialog, FormControl, FormLabel, FormGroup, Radio } from '@material-ui/core';
import { useFiltroGeral } from '../../components/FiltroGeral';
export function SelecionarCliente(props) {
    const handleChange = function (newClient) {
        if (setCliente)
            setCliente(newClient ? newClient.id : '');
        setFiltroClienteVisivel(false);
    };
    const verifyContains = function (clienteAtual) {
        return cliente ? clienteAtual.id === cliente : false;
    };
    const clientesList = getInfo("clientesList") || [];
    localStorage.setItem("clientesList", JSON.stringify(clientesList));
    const { cliente, setCliente, filtroClienteVisivel, setFiltroClienteVisivel } = useFiltroGeral();
    return <Dialog fullWidth open={filtroClienteVisivel}
        // onClose={setFiltroClienteVisivel(false)}
        aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <FormControl style={{ margin: '20px' }}>
            <FormLabel>Clientes</FormLabel>
            <FormGroup>
                {clientesList.map(item => {
                    if (item && item.nome)
                        return <FormControlLabel value={item} control={<Radio checked={verifyContains(item)} onChange={() => handleChange(item)} value={item} />} label={item.nome} />;
                })}
            </FormGroup>
        </FormControl>
    </Dialog>;
}
