import { AbaseLoading, AbaseTextField, useAbaseDialog, AbaseDatePickerField, AbaseCheckbox } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field, Form } from 'react-final-form';
import * as Yup from 'yup';
import { UsuarioQueryByIdComponent, usePerfilUpdate } from '../../generated/graphql';
import { defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import UsuarioLabel from '../Usuario/UsuarioNameLabel';
import { Grid, withStyles, createStyles, Button } from '@material-ui/core';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import SaveIcon from '@material-ui/icons/Save';
const PerfilSchema = Yup.object()
	.shape({
		id: Yup.string().notRequired(),
		nome: Yup.string().notRequired(),
		senha: Yup.string().notRequired(),
		senhanova: Yup.string().notRequired(),
		senhanovaconfirma: Yup.string().notRequired(),
		datanascimento: Yup.string().notRequired(),
		emailAgenda: Yup.boolean().notRequired().nullable(),
		emailLembrete: Yup.boolean().notRequired().nullable(),
	})
	.noUnknown();
	
var PerfilForm = function (props) {
	var usuarioId = JSON.parse(window.localStorage.getItem('user') || '').identity.usuario;
	const { history, location } = props;
	const { showError, showSnack } = useAbaseDialog();
	const update = usePerfilUpdate();
	const handleSubmit = async (values) => {
		const mutationData = await cast(PerfilSchema, values);
		try {
			const result = await update({
				variables: {
					id: usuarioId,
					data: mutationData
				}
			});
			if (props.onSave) {
				props.onSave(_.get(result, 'data.perfil.update.id'));
			}
			redirectBackForm(history, location);
			showSnack(defaultSnack('save'));
		}
		catch (err) {
			console.log({ err });
			showError({ message: defaultErrorMessage('query_one', err) });
		}
	};
	let [showPassword, setShowPassword] = React.useState(false);
	let [showNewPassword, setShowNewPassword] = React.useState(false);
	let [showNewPasswordConfirm, setShowNewPasswordConfirm] = React.useState(false);
	const handleClose = () => redirectBackForm(history, location);
	return (<Grid container spacing={0}>
		<UsuarioQueryByIdComponent variables={{ id: usuarioId }} onError={(err) => {
			showError({ message: defaultErrorMessage('query_one', err) });
		}}>
			{({ data, loading, refetch, error }) => (<div className={props.classes.root}>
				<AbaseLoading open={loading} />
				<Form onSubmit={handleSubmit} validate={validate} initialValues={_.get(data, 'usuario.list[0]')} render={({ handleSubmit, pristine, invalid }) => (<form onSubmit={handleSubmit}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<Field name="nome" parse={defaultFieldParser}>
								{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={UsuarioLabel.nome} />)}
							</Field>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<Field name="email" parse={defaultFieldParser}>
								{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={UsuarioLabel.email} disabled />)}
							</Field>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<Field name="datanascimento">
								{({ input, meta }) => (<AbaseDatePickerField meta={meta} {...input} label={UsuarioLabel.datanascimento} />)}
							</Field>
						</Grid>
						{_.get(data, 'usuario.list[0].possuiSenha') ? (<Grid item xs={12} sm={12} md={4} lg={4}>
							<Field name="senha">
								{({ input, meta }) => {
									return (<AbaseTextField fullWidth type={showPassword ? 'text' : 'password'} label="Senha" {...input} icon={showPassword ? 'visibility_off' : 'visibility'} iconlabel="Visualizar Senha" iconclick={() => {
										setShowPassword(!showPassword);
									}} />);
								}}
							</Field>
						</Grid>) : null}
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<Field name="senhanova">
								{({ input, meta }) => {
									return (<AbaseTextField fullWidth type={showNewPassword ? 'text' : 'password'} label="Nova senha" {...input} icon={showNewPassword ? 'visibility_off' : 'visibility'} iconlabel="Visualizar Senha" iconclick={() => {
										setShowNewPassword(!showNewPassword);
									}} />);
								}}
							</Field>
						</Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<Field name="senhanovaconfirma">
								{({ input, meta }) => {
									return (<AbaseTextField fullWidth type={showNewPasswordConfirm ? 'text' : 'password'} label="Confirmar nova senha" {...input} icon={showNewPasswordConfirm ? 'visibility_off' : 'visibility'} iconlabel="Visualizar Senha" iconclick={() => {
										setShowNewPasswordConfirm(!showNewPasswordConfirm);
									}} />);
								}}
							</Field>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item>
									<Field name="emailAgenda" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"Receber Email de Agenda"} />)}</Field>
								</Grid>
								<Grid item>
									<Field name="emailLembrete" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"Receber Email de Lembrete"} />)}</Field>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<div className="right mt15">
							<Button onClick={handleClose} key="cancelar" variant="contained">
								<i className="material-icons">close</i>Cancelar
							</Button>
							<Button variant="contained" className="btnSuccess ml5" type="submit">
								<SaveIcon>send</SaveIcon> Salvar
							</Button>
						</div>
					</Grid>
					<Grid container spacing={24} style={{ marginTop: '15px' }}>
						<Grid item xs={12}>
							<div className="attention yel">
								<i className="material-icons">warning</i> Para alterar a foto basta
								alterar a foto do perfil da Google e refazer o login usando a sua conta
								Abase.
								<i className="material-icons">warning</i>
							</div>
						</Grid>
						{!_.get(data, 'usuario.list[0].possuiSenha') ? (<Grid item xs={12}>
							<div className="attention">
								<i className="material-icons">warning</i>
								A sua conta ainda não possui uma senha para login sem utilizar o
								serviço da Google com a sua conta corporativa.
								<i className="material-icons">warning</i>
							</div>
						</Grid>) : null}
					</Grid>
				</form>)} />
			</div>)}
		</UsuarioQueryByIdComponent>
	</Grid>);
};

const styles = (theme) => createStyles({
	root: {
		flexGrow: 1
	}
});

export default withStyles(styles)(PerfilForm);