import { AbaseLoading, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { AgendaAllComponent, TarefaAllComponent, AgendaTarefaQueryByIdComponent, useAgendaTarefaCreate, useAgendaTarefaUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import AgendaTarefaLabel from './AgendaTarefaNameLabel';
import { Grid } from '@material-ui/core';
const AgendaTarefaSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    ageId: Yup.string().notRequired(),
    tarId: Yup.string().notRequired(),
}).noUnknown();
function AgendaTarefaForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useAgendaTarefaUpdate();
    const insert = useAgendaTarefaCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(AgendaTarefaSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.agendaTarefa.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.agendaTarefa.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<AgendaTarefaQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
    }}>
            {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(AgendaTarefaSchema)} initialValues={_.get(data, "agendaTarefa.list[0]") || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Agenda Tarefa" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                            <AbaseLoading open={loading || submitting}/>
                           <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <AgendaAllComponent>{({ data, loading }) => (<Field name="ageId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={AgendaTarefaLabel.age} valueField={"id"} textField={"descricao"} data={_.get(data, "agenda.list", [])} {...input}/>)}</Field>)}
                                    </AgendaAllComponent>
                               </Grid>
                               <Grid item xs={12}>
                                    <TarefaAllComponent>{({ data, loading }) => (<Field name="tarId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={AgendaTarefaLabel.tar} valueField={"id"} textField={"descricao"} data={_.get(data, "tarefa.list", [])} {...input}/>)}</Field>)}
                                    </TarefaAllComponent>
                                </Grid>
                           </Grid>
                        </DialogForm>)}/>)}
        </AgendaTarefaQueryByIdComponent>);
}
export default AgendaTarefaForm;
