import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ProjetoParticipanteListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import ProjetoParticipanteDelete from "./ProjetoParticipanteDelete";
import ProjetoParticipanteForm from "./ProjetoParticipanteForm";
import ProjetoParticipanteLabel from './ProjetoParticipanteNameLabel';
export default function ProjetoParticipanteList(props) {
    const { match } = props;
    const columns = [
        {
            name: ProjetoParticipanteLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: ProjetoParticipanteLabel.proId,
            field: "proId",
            defaultVisible: false
        },
        {
            name: ProjetoParticipanteLabel.usuId,
            field: "usuId",
            defaultVisible: false
        },
        {
            name: ProjetoParticipanteLabel.pro,
            field: "pro.descricao",
            sort: false
        },
        {
            name: ProjetoParticipanteLabel.usu,
            field: "usu.nome",
            sort: false
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link}/>) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/delete/${_.map(selected, "id").join(",")}`}/>
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.url}/add`}/>,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = ProjetoParticipanteLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => {
        if (props.projetoId) {
            if (!variables || (variables.projetoId !== props.projetoId)) {
                var variaveis = variables || {};
                _.set(variaveis, 'projetoId', props.projetoId);
                setVariables(variaveis);
            }
        }
        return (<ProjetoParticipanteListComponent variables={{ ...variables, sort: "id" }} skip={variables === null}>
                        {({ data, loading, refetch, error }) => (<div>

                            <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "projetoParticipante.pagedList.data") || []} total={_.get(data, "projetoParticipante.pagedList.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
            props.history.push(`${match.url}/edit/${row.id}`);
        }}></AbasePaperTable>
                            <ReenterRouteListener callback={refetch}/>
                            <Route path={`${match.path}/add`} render={RenderWithModal(ProjetoParticipanteForm)}/>
                            <Route path={`${match.path}/edit/:participanteId`} render={RenderWithModal(ProjetoParticipanteForm)}/>
                            <Route path={`${match.path}/delete/:participanteId`} render={RenderWithModal(ProjetoParticipanteDelete)}/>

                        </div>)}
                    </ProjetoParticipanteListComponent>);
    }}
        </GraphqlPaperTableListener>);
}
