import React from "react";
import DialogForm from "../../DialogForm";
import { LocalAllComponent, useUsuarioLocalCreate } from "../../generated/graphql";
import { Field } from "react-final-form";
import AutoCompleteWithEdit from "../AutoCompleteWithEdit";
import MarkerPicker from "../MarkerPicker";
import _ from "lodash";
import { withRouter } from "react-router";
import { cast, validate } from "../../helpers/validations";
import { FormDraft } from "abase-components";
import * as Yup from "yup";
import LocalForm from "../../pages/Local/LocalForm";
export const UsuarioLocalSchema = Yup.object()
    .shape({
        usuId: Yup.string().notRequired(),
        locId: Yup.string().required(),
        data: Yup.date()
            .max(new Date())
            .notRequired()
    })
    .noUnknown();
export function OndeEstouAtualizaComponent(props) {
    var insert = useUsuarioLocalCreate();
    var submitUsuarioLocal = async (values) => {
        const mutationData = await cast(UsuarioLocalSchema, values);
        mutationData.data = new Date();
        // eslint-disable-next-line no-unused-vars
        const result = await insert({
            variables: {
                data: mutationData
            },
            refetchQueries: ["usuarioAutenticado", "usuarioLocalPorUsuario"]
        });
        props.onClose();
    };
    return props.open ? (<FormDraft useDraft={false} initialValues={{
        locId: props.currentLocal
    }} onSubmit={submitUsuarioLocal} validate={validate(UsuarioLocalSchema)} render={({ handleSubmit, form, submitting }) => {
        return (<DialogForm title="Onde Estou?" onSubmit={handleSubmit} dontUseRoute onClose={() => {
            props.onClose();
        }}>
            <LocalAllComponent>
                {({ data, loading, refetch }) => (<Field name="locId">
                    {({ input, meta }) => {
                        return (<React.Fragment>
                            <AutoCompleteWithEdit path={"cadastros/local"} form={LocalForm} ignoreMatchRoute refetch={refetch} isLoading={loading} meta={meta} label={"Local"} valueField={"id"} textField={"descricao"} data={_.get(data, "local.list", [])} notEditable {...input} />
                            <div className="colRow b-1">
                                <MarkerPicker data={_.get(data, "local.list", [])} getDescription={x => x.descricao} getMarkerFromItem={x => x.posicaoMapa} value={input.value} onChange={input.onChange} />
                            </div>
                        </React.Fragment>);
                    }}
                </Field>)}
            </LocalAllComponent>
        </DialogForm>);
    }} />) : null;
}
export default withRouter(OndeEstouAtualizaComponent);
