import { AddAction } from "abase-components";
import _ from "lodash";
import React, { useState } from "react";
import { Route } from "react-router";
import { FiltroGeralConsumer } from "../../components/FiltroGeral";
import { AgendaListComponent, AgendaTipoAllComponent } from "../../generated/graphql";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import AgendaDelete from "./AgendaDelete";
import AgendaForm from "./AgendaForm";
import Calendar from '../../components/Calendar';
import { IconButton, Grid, Paper, MenuList, MenuItem, Checkbox, FormControlLabel, withStyles } from "@material-ui/core";
import moment from "moment";
import FilterListIcon from '@material-ui/icons/FilterList';
import EmpresaEquipeAreaFiltro from "../../components/EmpresaEquipeAreaFiltro";
import { Form } from "react-final-form";

function AgendaCalendar(props) {
    const { match, classes } = props;
    const [startDate, setStartDate] = useState(moment().startOf('month').toDate().toISOString());
    const [showFilter, setShowFilter] = useState(true);
    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };
    const [endDate, setEndDate] = useState(moment().endOf('month').toDate().toISOString());
    const startMinhas = [];
    const startOutras = [];
    const [filtrosMinhasTarefas, setFiltrosMinhasTarefas] = React.useState(startMinhas);
    const [filtrosOutrasTarefas, setFiltrosOutrasTarefas] = React.useState(startOutras);

    const [exibeFiltroMinhasTarefas, setExibeFiltroMinhasTarefas] = React.useState(false);
    const [exibeFiltrosOutrasTarefas, setExibeFiltrosOutrasTarefas] = React.useState(false);
    const [exibeOutrosFiltros, setExibeOutrosFiltros] = React.useState(false);
    const [filtroEmpresaAreaEquipe, setFiltroEmpresaAreaEquipe] = React.useState({});
    const onSubmit = function (val) { console.log(val) }
    return (<div className="colCalendar">

        <FiltroGeralConsumer>{(filtroGeral) => (<AgendaListComponent variables={{
            startDate,
            endDate,
            tiposMinhasTarefas: filtrosMinhasTarefas,
            tiposOutrasTarefas: filtrosOutrasTarefas,
            status: "CONFIRMADO",
            ...filtroEmpresaAreaEquipe
        }}>
            {({ data, loading, refetch, error }) => (<div className="colHeight">
                <Grid container spacing={1}>
                    <Grid item xs={12} className="right">
                        <IconButton aria-label="Filtrar" onClick={toggleFilter}>
                            <FilterListIcon />
                        </IconButton>
                        <AddAction onClick={() => {
                            props.history.push(`${match.url}/add`);
                        }}></AddAction>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    {showFilter ? (
                        <Grid item xs={2}></Grid>
                    ) : null}
                    {showFilter ? (
                        <Grid item xs={2} className="col-Filtro-Menu">
                            <Paper>
                                <MenuList>
                                    <AgendaTipoAllComponent>
                                        {({ data, loading }) => {
                                            const tipos = _.get(data, 'agendaTipo.list');
                                            return <React.Fragment>
                                                <MenuItem onClick={() => setExibeFiltroMinhasTarefas(!exibeFiltroMinhasTarefas)}>
                                                    <b className="text-center w100">MINHAS AGENDAS</b>
                                                    <span class="material-icons">{exibeFiltroMinhasTarefas ? "expand_less" : 'expand_more'}</span>
                                                </MenuItem>
                                                {!loading && exibeFiltroMinhasTarefas ? tipos.map(tipo => {
                                                    return <MenuItem classes={{ root: classes.menuItem }}>
                                                        <FormControlLabel control={<Checkbox classes={{ root: classes.checkBox }} onChange={(event) => {
                                                            if (filtrosMinhasTarefas.indexOf(event.currentTarget.value) === -1) {
                                                                setFiltrosMinhasTarefas([...filtrosMinhasTarefas, event.currentTarget.value]);
                                                            }
                                                            else {
                                                                setFiltrosMinhasTarefas(filtrosMinhasTarefas.filter(x => x !== event.currentTarget.value));
                                                            }
                                                        }} checked={filtrosMinhasTarefas.indexOf(tipo.id) !== -1} value={tipo.id} color="primary" />} label={tipo.descricao} />
                                                    </MenuItem>;
                                                }) : null}
                                                <MenuItem onClick={() => setExibeFiltrosOutrasTarefas(!exibeFiltrosOutrasTarefas)}>
                                                    <b className="text-center w100">OUTRAS AGENDAS</b>
                                                    <span class="material-icons">{exibeFiltrosOutrasTarefas ? "expand_less" : 'expand_more'}</span>
                                                </MenuItem>
                                                {!loading && exibeFiltrosOutrasTarefas ? tipos.map(tipo => {
                                                    return <MenuItem classes={{ root: classes.menuItem }}>
                                                        <FormControlLabel className="w100" control={<Checkbox classes={{ root: classes.checkBox }} onChange={(event) => {
                                                            if (filtrosOutrasTarefas.indexOf(event.currentTarget.value) === -1) {
                                                                setFiltrosOutrasTarefas([...filtrosOutrasTarefas, event.currentTarget.value]);
                                                            }
                                                            else {
                                                                setFiltrosOutrasTarefas(filtrosOutrasTarefas.filter(x => x !== event.currentTarget.value));
                                                            }
                                                        }} value={tipo.id} checked={filtrosOutrasTarefas.indexOf(tipo.id) !== -1} color="primary" />} label={tipo.descricao} />
                                                    </MenuItem>;
                                                }) : null}
                                            </React.Fragment>;
                                        }}
                                    </AgendaTipoAllComponent>

                                    <MenuItem onClick={() => setExibeOutrosFiltros(!exibeOutrosFiltros)} >
                                        <b className="text-center w100">OUTROS FILTROS</b>
                                        <span class="material-icons">{exibeOutrosFiltros ? "expand_less" : 'expand_more'}</span>
                                    </MenuItem>
                                    {exibeOutrosFiltros ? <MenuItem style={{ height: '400px' }}>
                                        <Form style={{ height: '400px' }}
                                            onSubmit={onSubmit}
                                            initialValues={filtroEmpresaAreaEquipe}
                                            render={({ handleSubmit, form, submitting, pristine, values }) => {
                                                console.log(values)
                                                setFiltroEmpresaAreaEquipe(values)
                                                return (<form style={{ height: '400px', width: '100%' }} onSubmit={handleSubmit}>
                                                    <EmpresaEquipeAreaFiltro hideEquipe />
                                                </form>)
                                            }} />
                                    </MenuItem>
                                        : null}
                                </MenuList>
                            </Paper>
                        </Grid>) : null}
                    <Grid item xs={showFilter ? 10 : 12}>
                        <Calendar events={_.get(data, "agenda.pagedList.data", [])} onSelectEvent={item => {
                            if (item.editavel)
                                props.history.push(`${match.url}/edit/${item.id}`);
                        }} resizeEvent={({ event, start, end }) => {
                            if (event.editavel)
                                props.history.push(`${match.url}/edit/${event.id}?start=${start.toISOString()}&end=${end.toISOString()}`);
                        }} moveEvent={({ event, start, end }) => {
                            if (event.editavel)
                                props.history.push(`${match.url}/edit/${event.id}?start=${start.toISOString()}&end=${end.toISOString()}`);
                        }} onRangeChange={({ start, end }) => {
                            console.log("onRangeChange", { start, end });
                            if (start) {
                                setStartDate(start.toISOString());
                            }
                            if (end) {
                                setEndDate(end.toISOString());
                            }
                        }} onSelectSlot={({ start, end }) => {
                            props.history.push(`${match.url}/add?start=${start.toISOString()}&end=${end.toISOString()}`);
                        }} />
                    </Grid>
                </Grid>
                <ReenterRouteListener callback={refetch} />
                <Route path={`${match.url}/add`} render={RenderWithModal(AgendaForm)} />
                <Route path={`${match.url}/edit/:id`} render={RenderWithModal(AgendaForm)} />
                <Route path={`${match.url}/delete/:id`} render={RenderWithModal(AgendaDelete)} />
            </div>)}
        </AgendaListComponent>)}</FiltroGeralConsumer>
    </div>);
}
const styles = {
    checkBox: {
        padding: 0
    },
    menuItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    transform: {
        transform: '200'
    }
};
export default withStyles(styles)(AgendaCalendar);
