import React from 'react';
import { niveisEnum, getNivel } from '../services/login';
import { useFiltroGeral } from './FiltroGeral';
import { ClienteQueryByIdComponent } from '../generated/graphql';
import _ from 'lodash';
export default function BtnFiltroCliente(props) {
    const nivel = getNivel();
    const { setFiltroClienteVisivel, cliente } = useFiltroGeral();
    if (nivel === niveisEnum.parceiro) {
        if (!cliente)
            setFiltroClienteVisivel(!cliente);
    }
    return nivel === niveisEnum.parceiro && props.show ?
        <div className="location filtro-geral" style={{ cursor: "pointer" }} onClick={() => setFiltroClienteVisivel(true)}>
            <i className="material-icons">people_alt</i>Selecionar Cliente{" "}
            {cliente ? <ClienteQueryByIdComponent variables={{ id: cliente }}>{({ data, loading }) => {
                return <b title={_.get(data, 'cliente.list[0].nome')}>{_.get(data, 'cliente.list[0].nome')}</b>;
            }}</ClienteQueryByIdComponent> :
                <b>Selecionar</b>}
        </div>
        : null;
}
