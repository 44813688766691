const names = {
    "form": "Tipo de Requisição",
    "id": "ID",
    "usuId": "Usuário ID",
    "descricao": "Descrição",
    "formulario": "Formulário",
    "usu": "Usuário Responsável",
    "agendaRequisicao": "Agenda Requisição",
    'PrecisaHotel': 'Precisa de Hotel'
};
export default names;
