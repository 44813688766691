import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';
import React from 'react';
export function MaskCep(props) {
    const { inputRef, ...other } = props;
    return (<MaskedInput {...other} ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
    }} mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]} placeholderChar={'\u2000'} showMask/>);
}
export function MaskTelefone(props) {
    const { inputRef, ...other } = props;
    let mascara = [];
    let valor = props.value.replace(/\D/g, '');
    if (valor.length > 10 && valor.length < 12)
        mascara = [
            '(',
            /[0-9]/,
            /[0-9]/,
            ')',
            ' ',
            /[0-9]/,
            ' ',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/ // margem
        ];
    else
        mascara = [
            '(',
            /[0-9]/,
            /[0-9]/,
            ')',
            ' ',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/ // MARGEM
        ];
    return (<MaskedInput {...other} ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
    }} mask={mascara} placeholderChar={'\u2000'} showMask={mascara.length > 0}/>);
}
export function MaskCpfCnpj(props) {
    const { inputRef, ...other } = props;
    let mask = [];
    let cont = props.value;
    cont = cont.replace(/\D/g, '');
    if (cont.length <= 11)
        mask = [
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '.',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '.',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/ //MARGEM
        ];
    else
        mask = [
            /[0-9]/,
            /[0-9]/,
            '.',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '.',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '/',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/
        ];
    return (<MaskedInput {...other} ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
    }} mask={mask} placeholderChar={'\u2000'} showMask/>);
}
export function MaskMoney(props) {
    const { inputRef, onChange, ...other } = props;
    return (<NumberFormat {...other} getInputRef={inputRef} onValueChange={(values) => {
        onChange({
            target: {
                value: values.value
            }
        });
    }} thousandSeparator prefix="R$"/>);
}

export function formatReal(int) {
    var tmp = int + '';
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6)
        tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    return tmp;
}
