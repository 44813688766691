import EmpresaList from "./pages/Empresa/EmpresaList";
import HomePage from "./pages/HomePage/HomePage";
import HomeClientePage from "./pages/HomeClientePage/HomeClientePage";
import LoginPage from "./pages/Login/LoginPage";
import LoginCliente from "./pages/Login/LoginCliente";
import LoginParceiro from "./pages/Login/LoginParceiro";
import AgendaList from "./pages/Agenda/AgendaList";
import AgendaCalendar from "./pages/Agenda/AgendaCalendar";
import AgendaRecursoCalendar from "./pages/Agenda/AgendaRecursoCalendar";
import UsuarioList from "./pages/Usuario/UsuarioList";
import LocalList from "./pages/Local/LocalList";
import AreaList from "./pages/Area/AreaList";
import HotelList from "./pages/Hotel/HotelList";
import TarefaBoard from "./pages/Tarefa/TarefaBoard";
import UsuarioLocalList from "./pages/UsuarioLocal/UsuarioLocalList";
import ContasList from "./pages/Contas/ContasList";
import EquipeList from "./pages/Equipe/EquipeList";
import PerfilForm from "./pages/Perfil/PerfilForm";
import RequisicaoTipoList from "./pages/RequisicaoTipo/RequisicaoTipoList";
import RequisicaoResponsavelList from "./pages/RequisicaoResponsavel/RequisicaoResponsavelList";
import RecursoList from "./pages/Recurso/RecursoList";
import RecursoTipoList from "./pages/RecursoTipo/RecursoTipoList";
import AgendaTipoList from "./pages/AgendaTipo/AgendaTipoList";
import TarefaTipoList from "./pages/TarefaTipo/TarefaTipoList";
import AgendaRequisicaoList from "./pages/AgendaRequisicao/AgendaRequisicaoList";
import RestauranteList from "./pages/Restaurante/RestauranteList";
import ClienteList from "./pages/Cliente/ClienteList";
import ObservacaoTipoList from "./pages/ObservacaoTipo/ObservacaoTipoList";
import CategoriaList from "./pages/Categoria/CategoriaList";
import AtividadeList from "./pages/Atividade/AtividadeList";
import LogAuditoriaList from "./pages/LogAuditoria/LogAuditoriaList";
import OrdemServicoList from "./pages/OrdemServico/OrdemServicoList";
import { niveisEnum } from "./services/login";
import ProjetoList from "./pages/Projeto/ProjetoList";
import ProjetoApontamentoList from "./pages/ProjetoApontamento/ProjetoApontamentoList";
import ProjetoEtapaList from "./pages/ProjetoEtapa/ProjetoEtapaList";
import ModeloTermoAceiteList from "./pages/ModeloTermoAceite/ModeloTermoAceiteList";
import ProjetoParticipanteList from "./pages/ProjetoParticipante/ProjetoParticipanteList";
import TermoAceiteList from "./pages/TermoAceite/TermoAceiteList";
import ModeloProjetoList from "./pages/ModeloProjeto/ModeloProjetoList";
import ProjetoForm from "./pages/Projeto/ProjetoForm";
import ModeloProjetoForm from "./pages/ModeloProjeto/ModeloProjetoForm";
import ParceiroList from "./pages/Parceiro/ParceiroList";
import AgendaDashboard from "./pages/Dashboard/Agenda";
import SupersetRedirect from "./pages/Dashboard/SupersetRedirect";
import HomePageCadastros from "./pages/HomePage/HomePageCadastros";
const rotas = [
    { path: "/", exact: true, component: HomePage },
    { path: "/agenda", exact: true, component: HomePage },
    { path: "/home", exact: true, component: HomePage },
    { path: "/cadastros", exact: true, component: HomePageCadastros },
    { path: "/cliente/home", exact: false, component: HomeClientePage },
    { path: "/dashboards/agenda", exact: false, component: AgendaDashboard },
    { path: "/dashboards/superset", exact: false, component: SupersetRedirect },
    {
        path: "/cadastros/empresa",
        exact: false,
        component: EmpresaList,
        nivel: [niveisEnum.administrativo, niveisEnum.master]
    },
    { path: "/agenda/agenda", exact: false, component: AgendaList },
    { path: "/agenda/calendar", exact: false, component: AgendaCalendar },
    { path: "/agenda/recursolocacao", exact: false, component: AgendaRecursoCalendar },
    { path: "/agenda/tarefa", exact: false, component: TarefaBoard },
    {
        path: "/agenda/usuariolocal",
        exact: false,
        component: UsuarioLocalList,
        nivel: [niveisEnum.administrativo, niveisEnum.master]
    },
    { path: "/agenda/requisitos", exact: false, component: AgendaRequisicaoList },
    { path: "/cadastros/local", exact: false, component: LocalList },
    { path: "/cadastros/contas", exact: false, component: ContasList },
    {
        path: "/cadastros/equipe",
        exact: false,
        component: EquipeList,
        nivel: [niveisEnum.administrativo, niveisEnum.master]
    },
    {
        path: "/cadastros/usuario",
        exact: false,
        component: UsuarioList,
        nivel: [niveisEnum.master]
    },
    {
        path: "/cadastros/hotel",
        exact: false,
        component: HotelList,
        nivel: [niveisEnum.administrativo, niveisEnum.master]
    },
    {
        path: "/cadastros/area",
        exact: false,
        component: AreaList,
        nivel: [niveisEnum.administrativo, niveisEnum.master]
    },
    {
        path: "/cadastros/requisicaotipo",
        exact: false,
        component: RequisicaoTipoList
    },
    {
        path: "/cadastros/requisicaoresponsavel",
        exact: false,
        component: RequisicaoResponsavelList
    },
    {
        path: "/cadastros/recursotipo",
        exact: false,
        component: RecursoTipoList,
        nivel: [niveisEnum.administrativo, niveisEnum.master]
    },
    { path: "/cadastros/tarefatipo", exact: false, component: TarefaTipoList },
    { path: "/cadastros/recurso", exact: false, component: RecursoList },
    {
        path: "/cadastros/agendatipo",
        exact: false,
        component: AgendaTipoList,
        nivel: [niveisEnum.administrativo, niveisEnum.master]
    },
    {
        path: "/cadastros/restaurante",
        exact: false,
        component: RestauranteList,
        nivel: [niveisEnum.administrativo, niveisEnum.master]
    },
    {
        path: "/cadastros/cliente",
        exact: false,
        component: ClienteList,
        nivel: [niveisEnum.administrativo, niveisEnum.master]
    },
    { path: "/agenda/ordemdeservico", exact: false, component: OrdemServicoList },
    {
        path: "/log",
        exact: false,
        component: LogAuditoriaList,
        nivel: [niveisEnum.master]
    },
    { path: "/login", exact: true, component: LoginPage },
    { path: "/logincliente", exact: true, component: LoginCliente },
    { path: "/loginparceiro", exact: true, component: LoginParceiro },
    { path: "/perfil", exact: true, component: PerfilForm },
    { path: "/projeto/projeto", exact: false, component: ProjetoList },
    { path: "/projeto", exact: true, component: ProjetoList },
    { path: '/projeto/projetoform/edit/:projetoId/aba/:aba', component: ProjetoForm },
    { path: '/projeto/projetoform', component: ProjetoForm },
    { path: '/projeto/modeloprojetoform/edit/:modeloProjetoId/aba/:aba', component: ModeloProjetoForm },
    { path: '/projeto/modeloprojetoform', component: ModeloProjetoForm },
    {
        path: "/projeto/projetoapontamento",
        exact: false,
        component: ProjetoApontamentoList
    },
    { path: "/projeto/projetoEtapa", exact: false, component: ProjetoEtapaList },
    {
        path: "/cadastros/modelotermoaceite",
        exact: false,
        component: ModeloTermoAceiteList
    },
    {
        path: "/projeto/projetoParticipante",
        exact: false,
        component: ProjetoParticipanteList
    },
    {
        path: "/agenda/termoaceite",
        exact: false,
        component: TermoAceiteList
    },
    {
        path: "/projeto/modelo",
        exact: false,
        component: ModeloProjetoList
    },
    {
        path: "/cadastros/parceiro",
        exact: false,
        component: ParceiroList
    },
    {
        path: "/cadastros/observacaoTipo",
        exact: false,
        component: ObservacaoTipoList
    },
    {
        path: "/cadastros/categoria",
        exact: false,
        component: CategoriaList
    },
    {
        path: "/cadastros/atividade",
        exact: false,
        component: AtividadeList
    }
];
export default rotas;
