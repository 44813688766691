import { AbaseLoading, AbaseTextField, FormDraft, useAbaseDialog, AbaseAutocompleteField, AbaseDateTimePickerField } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { AgendaRequisicaoQueryByIdComponent, useAgendaRequisicaoUpdateStatus } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import { Paper, Chip, Grid } from '@material-ui/core';
const AgendaRequisicaoSchema = Yup.object()
    .shape({
        id: Yup.string().notRequired(),
        recId: Yup.string().notRequired(),
        hotId: Yup.string().notRequired().nullable(),
        req: Yup.object()
            .shape({
                id: Yup.string().required(),
                descricao: Yup.string().notRequired(),
                formulario: Yup.string().notRequired()
            })
            .required()
            .noUnknown(),
        dadosFormulario: Yup.string().notRequired(),
        status: Yup.string().required()
    })
    .noUnknown();
function AgendaRequisicaoFormEdit(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useAgendaRequisicaoUpdateStatus();
    const handleSubmit = async (values) => {
        const mutationData = await cast(AgendaRequisicaoSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        status: mutationData.status
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.AgendaRequisicao.update.id'));
                }
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const isUsingDraft = false;
    return (<AgendaRequisicaoQueryByIdComponent variables={params} skip={false} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => (<FormDraft useDraft={false} onSubmit={handleSubmit} validate={validate(AgendaRequisicaoSchema)} initialValues={_.get(data, 'agendaRequisicao.list[0]')} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Requisição da Agenda" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
            <AbaseLoading open={loading || submitting} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Field name="age.titulo">
                        {({ input, meta }) => <AbaseTextField {...input} label="Título" disabled />}
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <Field name="req.formulario">
                        {({ input, meta }) => {
                            let fieldsObj = input.value ? JSON.parse(input.value) : [];
                            let valuesObj = _.get(data, 'agendaRequisicao.list[0].dadosFormulario')
                                ? JSON.parse(_.get(data, 'agendaRequisicao.list[0].dadosFormulario'))
                                : {};
                            return (<Paper>
                                {fieldsObj.map((field) => {
                                    if (valuesObj[field.id])
                                        return (<Chip label={`${field.label}: ${valuesObj[field.id]}`} variant="outlined" className="pullLeft h-auto" />);
                                    else return <div></div>;
                                })}
                            </Paper>);
                        }}
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <Field name="age.usu.nome">
                        {({ input, meta }) => <AbaseTextField {...input} label="Usuario" disabled />}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="age.inicio">
                        {({ input, meta }) => <AbaseDateTimePickerField {...input} label="Data de Inicio" disabled />}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="age.fim">
                        {({ input, meta }) => <AbaseDateTimePickerField {...input} label="Data de Encerramento" disabled />}
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <Field name="req.descricao">
                        {({ input, meta }) => <AbaseTextField {...input} label="Requisição" disabled />}
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <Field name="status">
                        {({ input, meta }) => {
                            console.log(input.value);
                            input.value = input.value = { value: input.value };
                            return (<AbaseAutocompleteField {...input} data={[
                                { value: 'PENDENTE' },
                                { value: 'CONCLUÍDO' },
                                { value: 'CANCELADO' },
                                { value: 'ADIADO' }
                            ]} getOptionLabel={(x) => x.value} getOptionValue={(x) => x.value} onChange={(newVal) => {
                                if (newVal && newVal.value)
                                    input.onChange(newVal.value);
                            }} fullWidth label="Status" />);
                        }}
                    </Field>
                </Grid>
            </Grid>
        </DialogForm>)} />)}
    </AgendaRequisicaoQueryByIdComponent>);
}
export default AgendaRequisicaoFormEdit;
