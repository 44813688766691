import _ from "lodash";
import React from "react";
import { Route } from "react-router";
import { TarefaListComponent, useTarefaUpdateStatus } from "../../generated/graphql";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import TarefaDelete from "./TarefaDelete";
import TarefaForm from "./TarefaForm";
import { tarefaStatusList, tarefaStatus } from "./TarefaNameLabel";
import Board from "../../components/Board";
import { FiltroGeralConsumer } from "../../components/FiltroGeral";
export default function TarefaBoard(props) {
    const { match } = props;
    const updateStatus = useTarefaUpdateStatus();
    return (<div style={{ flexDirection: "column" }}>

        <div className="colPageTar">
            <FiltroGeralConsumer>
                {filtroGeral => (<TarefaListComponent variables={{
                    empId: _.get(filtroGeral, "empresa.id"),
                    areId: _.get(filtroGeral, "area.id"),
                    equId: _.get(filtroGeral, "equipe.id"),
                    usuId: _.get(filtroGeral, "usuario.id"),
                    startDate: filtroGeral.startDate ? filtroGeral.startDate.toISOString() : null,
                    endDate: filtroGeral.endDate ? filtroGeral.endDate.toISOString() : null,
                }}>
                    {({ data, refetch }) => (<React.Fragment>
                        <Board containerStyle={{
                            height: "100%",
                            marginLeft: "-5px",
                            width: "calc(100% + 0px)"
                        }} columns={tarefaStatusList.map(x => x.id)} tasks={_.get(data, "tarefa.pagedList.data", [])} getColumnTitle={id => tarefaStatus[id] ? tarefaStatus[id].text : ""} setColumns={() => { }} onAdd={status => {
                            props.history.push(`${match.url}/add?status=${status}`);
                        }} onClickItem={task => {
                            props.history.push(`${match.url}/edit/${task.id}`);
                        }} onDelete={task => {
                            props.history.push(`${match.url}/delete/${task.id}`);
                        }} updateStatus={(taskId, status) => {
                            var task = _.get(data, "tarefa.pagedList.data", []).filter(x => x.id === taskId)[0];
                            console.log({ task });
                            updateStatus({
                                variables: { id: taskId, status },
                                optimisticResponse: {
                                    tarefa: { updateStatus: { ...task, status }, __typename: "TarefaMutation" }
                                }
                            });
                            // onSave("update_status", { taskId, status })
                        }} />
                        <ReenterRouteListener callback={refetch} />
                        <Route path={`${match.path}/add`} render={RenderWithModal(TarefaForm)} />
                        <Route path={`${match.path}/edit/:id`} render={RenderWithModal(TarefaForm)} />
                        <Route path={`${match.path}/delete/:id`} render={RenderWithModal(TarefaDelete)} />
                    </React.Fragment>)}
                </TarefaListComponent>)}
            </FiltroGeralConsumer>
        </div>
    </div>);
}
