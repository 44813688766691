import { AbaseLoading, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { defaultErrorMessage, redirectBackForm } from '../../helpers';
import { Grid, Button, Typography, withStyles } from '@material-ui/core';
import { AgendaQueryByIdComponent } from '../../generated/graphql';
import DialogForm from '../../DialogForm';
import { Route } from 'react-router';
import AgendaForm from "./AgendaForm";
import RenderWithModal from "../../RenderWithModal";
import { Link } from 'react-router-dom';

function AgendaView(props) {

    const { match, history, location } = props;
    const { params } = match;
    const { showError } = useAbaseDialog();
    const notEditting = !params || !params.id;
    const onClose = () => {
        redirectBackForm(history, location);
    };
    const referenceBtnEdit = React.useRef(null)

    setTimeout(() => {//gambiarra mas worka
        referenceBtnEdit.current.focus();
    }, 100)

    return (<AgendaQueryByIdComponent variables={{ id: params.id }} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading }) => {
            const values = _.get(data, "agenda.list[0]") || {};
            return (<DialogForm onSubmit={() => onClose()} actions={
                <Grid container spacing={1}>
                    <Grid item xs={12} className="center mb15">
                        <Link to={`${match.url.replace('view', 'edit')}`}>{/* pq não queriam que abrisse os detalhes das agendas de novo */}
                            <Button buttonRef={referenceBtnEdit} color="secondary" key="editar" variant="contained">
                                <i className="material-icons">edit</i> Editar
                            </Button>
                        </Link>
                        <Button onClick={onClose} key="cancelar" className="ml5" variant="contained">
                            <i className="material-icons">close</i> Fechar
                        </Button>
                    </Grid>
                </Grid>}>
                <Route path={`${match.path}/edit`} render={RenderWithModal(AgendaForm)} />
                <AbaseLoading open={loading} />
                <div className="colRow f-12">
                    <Typography color="textSecondary" gutterBottom>
                        Titulo: <strong>{_.get(values, "titulo") || "N/A"}</strong>
                    </Typography>
                    <ul className="listaHome mt15 lnHeig">
                        <li>
                            <Grid container spacing={0}>
                                {
                                    _.get(values, "cli.razaosocial", null) ?
                                        <Grid item xs={6} sm={12} md={6} lg={6}>
                                            <p>
                                                Cliente: <strong>{_.get(values, "cli.razaosocial", null)}</strong>
                                            </p>
                                        </Grid>
                                        : null
                                }
                                <Grid item xs={6} sm={12} md={6} lg={6}>
                                    <p>
                                        Empresa: <strong>{_.get(values, "emp.razaosocial", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={6} sm={12} md={6} lg={6}>
                                    <p>
                                        Usuário: <strong>{_.get(values, "usu.nome", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                {
                                    _.get(values, "agendaUsuario", []).length > 0 ?
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <p>
                                                Participantes: <strong>{_.get(values, "agendaUsuario", []).map((agendaUsuario, index) => {
                                                return (index > 0 ? ', ' : '') + _.get(agendaUsuario, 'usu.nome')
                                            })}</strong>
                                            </p>
                                        </Grid>
                                        : null
                                }
                                <Grid item xs={4} sm={12} md={4} lg={4}>
                                    <p>
                                        Área: <strong style={{ color: _.get(values, "are.cor", '') }} >{_.get(values, "are.descricao", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={4} sm={12} md={4} lg={4}>
                                    <p>
                                        Tipo de Agenda: <strong>{_.get(values, "agendaTipo.descricao", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                {
                                    _.get(values, 'loc') ?
                                        <Grid item xs={4} sm={12} md={4} lg={4}>
                                            <p>
                                                Local: <strong>{_.get(values, "loc.descricao", 'N/A')}</strong>
                                            </p>
                                        </Grid>
                                        : null
                                }
                            </Grid>
                        </li>
                    </ul>
                    {_.get(values, "agendaRecurso", []).length > 0 ?
                        <React.Fragment>
                            <Typography color="textSecondary" gutterBottom>
                                <b>RECURSOS</b>
                            </Typography>
                            <ul className="listaHome">
                                <li>
                                    <Grid container spacing={0}>
                                        {_.get(values, "agendaRecurso", []).map((agendaRecurso, index) => {
                                            return <React.Fragment>
                                                <Grid item xs={6} sm={12} md={6} lg={6}>
                                                    <p>
                                                        Tipo: <strong>{_.get(agendaRecurso, "rec.rec.descricao", 'N/A')}</strong>
                                                    </p>
                                                </Grid>
                                                <Grid item xs={6} sm={12} md={6} lg={6}>
                                                    <p>
                                                        Nome: <strong>{_.get(agendaRecurso, "rec.nome", 'N/A')}</strong>
                                                    </p>
                                                </Grid>
                                            </React.Fragment>
                                        })}
                                    </Grid>
                                </li>
                            </ul>
                        </React.Fragment>
                        : null}
                    {_.get(values, "agendaRequisicao", []).length > 0 ?
                        <React.Fragment>
                            <Typography color="textSecondary" gutterBottom>
                                <b>REQUISIÇÕES</b>
                            </Typography>
                            <ul className="listaHome">
                                <li>
                                    <Grid container spacing={0}>
                                        {_.get(values, "agendaRequisicao", []).map((agendaRequisicao, index) => {
                                            return <React.Fragment>
                                                <Grid item xs={6} sm={12} md={6} lg={6}>
                                                    <p>
                                                        Tipo: <strong>{_.get(agendaRequisicao, "rec.rec.descricao", 'N/A')}</strong>
                                                    </p>
                                                </Grid>
                                                <Grid item xs={6} sm={12} md={6} lg={6}>
                                                    <p>
                                                        Nome: <strong>{_.get(agendaRequisicao, "rec.nome", 'N/A')}</strong>
                                                    </p>
                                                </Grid>
                                            </React.Fragment>
                                        })}
                                    </Grid>
                                </li>
                            </ul>
                        </React.Fragment>
                        : null}
                    {_.get(values, "agendaTimeline", null) ?
                        <React.Fragment>
                            <Typography color="textSecondary" gutterBottom>
                                <b>HISTÓRICO</b>
                            </Typography>
                            <ul className="listaHome">
                                <li>
                                    <Grid container spacing={2}>
                                        {_.get(values, "agendaTimeline", []).map((acao, index) => {
                                            return <React.Fragment>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                                        <p>
                                                            Ação: <strong>{_.get(acao, "acao", 'N/A')}</strong>
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2}>
                                                        <p>
                                                            Momento: <strong>{new Date(_.get(acao, "data")).toLocaleString()}</strong>
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={2} lg={2}>
                                                        <p>
                                                            Usuário: <strong>{_.get(acao, "usu.nome")}</strong>
                                                        </p>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                                        <div>  </div>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        })}
                                    </Grid>
                                </li>
                            </ul>
                        </React.Fragment>
                        : null}
                </div>
            </DialogForm>);
        }}
    </AgendaQueryByIdComponent>);
}
const styles = {
    bullet: {
        margin: '0 2px',
        transform: 'scale(0.8)',
        display: 'inline-block',
    },
    title: {
        fontSize: 18,
        color: 'black'
    }
};
export default withStyles(styles)(AgendaView);