import { AbasePaperTable, AbaseTextField, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { AgendaTarefaListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import AgendaTarefaDelete from "./AgendaTarefaDelete";
import AgendaTarefaForm from "./AgendaTarefaForm";
import AgendaTarefaLabel from './AgendaTarefaNameLabel';
import AgendaForm from "../Agenda/AgendaForm";
export default function AgendaTarefaList(props) {
    const { match } = props;
    const columns = [
        {
            name: AgendaTarefaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: "Cliente",
            field: "age.cli.nome",
            defaultVisible: true,
            sort: false,
            width: 100
        },
        {
            name: "Titulo",
            field: "age.titulo",
            defaultVisible: true,
            sort: false,
            width: 100
        },
        {
            name: "Tipo",
            field: "age.agendaTipo.descricao",
            sort: false
        },
        {
            name: "Data de Início",
            field: "age.inicio",
            customBodyRender: val => val ? new Date(val).toLocaleString() : 'N/A',
            sort: false,
            width: 100
        },
        {
            name: "Status",
            field: "age.status",
            defaultVisible: true,
            sort: false,
            width: 100
        },
        {
            name: "Usuário",
            field: "age.usu.nome",
            defaultVisible: true,
            sort: false,
            width: 100
        },
        {
            name: "Área",
            field: "age.are.descricao",
            defaultVisible: true,
            sort: false,
            width: 100
        },
        {
            name: "Equipe",
            field: "age.equ.descricao",
            defaultVisible: true,
            sort: false
        },
        {
            name: "OS",
            field: "age.ord.numero",
            defaultVisible: true,
            customBodyRender: val => val ? val : "N/A",
            width: 50,
            sort: false
        },
    ];
    const selectedActions = (selected) => {
        console.log(selected);
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].age.id}?urlOriginal=${match.url}`} component={Link}/>) : null,
        ];
    };
    const customActions = [
    // <AddAction
    //     key="add"
    //     to={`${match.path}/add`} component={Link}
    // />,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => {
        if (!_.get(variables, 'sort'))
            setVariables({ sort: "id" });
        return (<AgendaTarefaListComponent variables={{ ...variables, projetoId: match.params.projetoId, sort: "id" }} skip={variables === null}>
                        {({ data, loading, refetch, error }) => (<div>
                            <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "agendaTarefa.pagedList.data") || []} total={_.get(data, "agendaTarefa.pagedList.total")} columns={columns} customFilters={filters} title={"Agendas Relacionadas ao Projeto"} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={match.params.projetoId ? [] : customActions} selectListener={props.selectListener} onDoubleClick={row => {
            props.history.push(`${match.url}/edit/${row.age.id}?urlOriginal=${match.url}`);
        }}/>
                            <ReenterRouteListener callback={refetch}/>
                            <Route path={`${match.path}/add`} render={RenderWithModal(AgendaTarefaForm)}/>
                            <Route path={`${match.path}/edit/:id`} render={RenderWithModal(AgendaForm)}/>
                            <Route path={`${match.path}/delete/:id`} render={RenderWithModal(AgendaTarefaDelete)}/>
                        </div>)}
                    </AgendaTarefaListComponent>);
    }}
        </GraphqlPaperTableListener>);
}
