const names = {
    "form": "Projeto",
    "id": "Id",
    "liderUsuId": "Lider Usuário ID",
    "responsavelUsuId": "Responsável Usuário ID",
    "empId": "Empresa ID",
    "areId": "Área ID",
    "equId": "Equipe ID",
    "modId": "Modelo ID",
    "porcentagem": "Porcentagem",
    "descricao": "Descrição",
    "prazo": "Prazo",
    "dataLimite": "Data limite",
    "observacao": "Observação",
    "are": "Área",
    "emp": "Empresa",
    "equ": "Equipe",
    "mod": "Modelo",
    "projetoApontamento": "Apontamentos do Projeto",
    "projetoEtapa": "Etapas do Projeto",
    "projetoParticipante": "Parcicipantes do Projeto",
    "projetoAnexo": "Anexos do Projeto",
};
export default names;
