import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction, AbaseAutoCompleteFieldFinalForm } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { TermoAceiteListComponent, UsuarioColaboradorAllComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import TermoAceiteDelete from "./TermoAceiteDelete";
import TermoAceiteForm from "./TermoAceiteForm";
import TermoAceiteLabel from './TermoAceiteNameLabel';
import { IconButton } from "@material-ui/core";
import { imprimirTermoAceite } from "../../services/report";
import { formatDateTime } from "../../helpers/format";
import EmpresaEquipeAreaFiltro from "../../components/EmpresaEquipeAreaFiltro";
import AutoCompleteCliente from "../../components/AutoCompleteCliente";
export default function TermoAceiteList(props) {
    const { match } = props;
    const columns = [
        {
            name: TermoAceiteLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: TermoAceiteLabel.ordId,
            field: "ordId",
            defaultVisible: false
        },
        {
            name: TermoAceiteLabel.cliId,
            field: "cliId",
            defaultVisible: false
        },
        {
            name: TermoAceiteLabel.usuId,
            field: "usuId",
            defaultVisible: false
        },
        {
            name: TermoAceiteLabel.modId,
            field: "modId",
            defaultVisible: false
        },
        {
            name: TermoAceiteLabel.empId,
            field: "empId",
            defaultVisible: false
        },
        {
            name: TermoAceiteLabel.nomeSistema,
            field: "nomeSistema"
        },
        {
            name: TermoAceiteLabel.dataGerado,
            field: "dataGerado",
            customBodyRender: (value) => {
                return formatDateTime(value);
            }
        },
        {
            name: TermoAceiteLabel.cli,
            field: "cli.nome"
        },
        {
            name: TermoAceiteLabel.emp,
            field: "emp.nomefantasia"
        },
        {
            name: TermoAceiteLabel.mod,
            field: "mod.nome"
        },
        {
            name: TermoAceiteLabel.ord,
            field: "ord.numero"
        },
        {
            name: TermoAceiteLabel.usu,
            field: "usu.nome"
        },
    ];
    const selectedActions = (selected) => {
        console.log(selected);
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (
                <IconButton aria-label={`Imprimir`}><i className={"material-icons"} onClick={() => {
                    imprimirTermoAceite({
                        CLIENTE_NOME: _.get(selected[0], 'cli.nome', "N/A"),
                        EMPRESA_NOME: _.get(selected[0], 'emp.razaosocial', 'N/A'),
                        SISTEMA_NOME: _.get(selected[0], 'nomeSistema'),
                        TECNICO_RESPONSAVEL: _.get(selected[0], 'usu.nome', 'N/A'),
                        HORARIO_GERADO: new Date().toLocaleString()
                    }, selected[0].modId);
                }}>print</i></IconButton>) : null,
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" component={Link} to={`${match.path}/delete/${_.map(selected, "id").join(",")}`} />
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.path}/add`} />,
    ];
    const filters = () => ([
        <EmpresaEquipeAreaFiltro hideArea />,
        <UsuarioColaboradorAllComponent>{({ data, loading, refetch }) => (
            <Field name="usuId">{({ input, meta }) => (
                <React.Fragment>
                    <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Colaborador"} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input} />
                </React.Fragment>)}
            </Field>)}
        </UsuarioColaboradorAllComponent>,
        <Field name={'cliId'}>
            {({ input, meta }) => <AutoCompleteCliente {...input} />}
        </Field>,
        <Field name={"nomeSistema"}>{({ input, meta }) => <AbaseTextField label={"Nome Sistema"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = TermoAceiteLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => {
        if (props.ordemServicoId) {
            if (!variables || (variables.ordemServicoId !== props.ordemServicoId)) {
                var variaveis = variables || {};
                _.set(variaveis, 'ordemServicoId', props.ordemServicoId);
                setVariables(variaveis);
            }
        }
        return (<TermoAceiteListComponent variables={variables} skip={variables === null}>
            {({ data, loading, refetch, error }) => (<div>

                <AbasePaperTable
                    customPlacehorderSearch={"Pesuisar por Nome do cliente, nome do sistema, número da OS ou nome do responsável"}
                    sticky={true}
                    selectMode={"multi"}
                    loading={loading}
                    data={_.get(data, "termoAceite.pagedList.data") || []}
                    total={_.get(data, "termoAceite.pagedList.total")}
                    columns={columns}
                    customFilters={filters}
                    title={formName}
                    requestData={setVariables}
                    error={errorMessagePaperTable(error)}
                    customSelectedActions={selectedActions}
                    customActions={customActions}
                    selectListener={props.selectListener}
                    onDoubleClick={row => {
                        props.history.push(`${match.path}/edit/${row.id}`);
                    }}></AbasePaperTable>
                <ReenterRouteListener callback={refetch} />
                <Route path={`${match.path}/add`} render={RenderWithModal(TermoAceiteForm)} />
                <Route path={`${match.path}/edit/:id`} render={RenderWithModal(TermoAceiteForm)} />
                <Route path={`${match.path}/delete/:id`} render={RenderWithModal(TermoAceiteDelete)} />

            </div>)}
        </TermoAceiteListComponent>);
    }}
    </GraphqlPaperTableListener>);
}
