import _ from 'lodash';
export const required = (value) => (value ? undefined : 'Campo Obrigatório');
export const mustBeNumber = (value) => (isNaN(value) ? 'Deve ser um número' : undefined);
export const minValue = (min) => (value) => isNaN(value) || value >= min ? undefined : `Deve ser maior que ${min}`;
export const maxValue = (max) => (value) => isNaN(value) || value <= max ? undefined : `Deve ser menor que ${max}`;
export const minLength = (min) => (value) => value === '' || value.length >= min ? undefined : `Deve ter no mínimo ${min} caracteres`;
export const maxLength = (max) => (value) => value === '' || value.length <= max ? undefined : `Deve ter no máximo ${max} caracteres`;
export const isEmail = (value) => value === '' || (value.indexOf('@') !== -1 && value.indexOf('.') !== -1 && value.length > 5)
    ? undefined
    : `Deve ser um email válido`;
export const composeValidators = (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);
export const validate = (schema) => async (values) => {
    if (typeof schema === 'function') {
        schema = schema();
    }
    values = replaceNullsForValidation(values);
    try {
        await schema.validate(values, { abortEarly: false });
    }
    catch (e) {
        const result = e.inner.reduce((errors, error) => {
            _.set(errors, error.path, error.message);
            return errors;
        }, {});
        console.log('Validation errors', result);
        return result;
    }
};
export const replaceNullsForValidation = (values) => {
    return _.omitBy(values, _.isNull);
};
export async function cast(schema, values) {
    return await schema.cast(replaceNullsForValidation(values));
}
export function validateCnpjECpf(valor) {
    if (valor) {
        let valLimpo = valor.replace(/[^\d]/g, '');
        if (valLimpo.length === 0)
            return true;
        if (valLimpo.length < 12)
            return false;
        if (valLimpo.length === 12)
            return validateCnpj(valLimpo);
        if (valLimpo.length > 12)
            return validateCpf(valLimpo);
        else
            return false;
    }
    else {
        return true;
    }
    // return valLimpo.length >= 12 ? validateCnpj(valLimpo) : validateCpf(valLimpo);
}
export function validateCpf(strCPF) {
    strCPF = strCPF.replace(/[^\d]+/g, '');
    if (strCPF.length === 11) {
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === '00000000000')
            return false;
        for (let i = 1; i <= 9; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11)
            Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10)))
            return false;
        Soma = 0;
        for (let i = 1; i <= 10; i++)
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11)
            Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11)))
            return false;
        return true;
    }
    return false;
}
export function validateCnpj(strCNPJ) {
    strCNPJ = strCNPJ.replace(/[^\d]+/g, '');
    if (strCNPJ === '')
        return false;
    if (strCNPJ.length !== 14)
        return false;
    // Elimina CNPJs invalidos conhecidos
    if (strCNPJ === '00000000000000' ||
        strCNPJ === '11111111111111' ||
        strCNPJ === '22222222222222' ||
        strCNPJ === '33333333333333' ||
        strCNPJ === '44444444444444' ||
        strCNPJ === '55555555555555' ||
        strCNPJ === '66666666666666' ||
        strCNPJ === '77777777777777' ||
        strCNPJ === '88888888888888' ||
        strCNPJ === '99999999999999')
        return false;
    // Valida DVs
    let tamanho = strCNPJ.length - 2;
    let numeros = parseInt(strCNPJ.substring(0, tamanho));
    let digitos = parseInt(strCNPJ.substring(tamanho));
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.toString().charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.toString().charAt(0)))
        return false;
    tamanho = tamanho + 1;
    numeros = parseInt(strCNPJ.substring(0, tamanho));
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.toString().charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.toString().charAt(1)))
        return false;
    return true;
}
