const names = {
    "form": "Item de Cobrança",
    "id": "Id",
    "ordId": "Ordem ID",
    "proId": "Serviço",
    "quantidade": "Quantidade",
    "valorUnitario": "Valor Unitário",
    "valorTotal": "Valor Total",
    "ord": "Ordem",
    "pro": "Serviço",
};
export default names;
