import React, { Component } from 'react';
import styled from '@emotion/styled';
import { grid, borderRadius, colors } from './primitives/constants';
import QuoteList from './primitives/QuoteList';
import Title from './primitives/title';
const Container = styled.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  height:30px;
  justify-content: center;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  background-color: ${({ isDragging }) => isDragging ? colors.G50 : colors.N30};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${colors.G50};
  }
`;
export default class Column extends Component {
  render() {
    const title = this.props.title;
    const quotes = this.props.quotes;
    return (<Container>

      <Header isDragging={false}>
        <Title isDragging={false}>
          {title}
        </Title>
      </Header>
      <QuoteList listId={title} listType="QUOTE" style={{
        backgroundColor: false ? colors.G50 : null,
      }} quotes={quotes} internalScroll={this.props.isScrollable} isCombineEnabled={Boolean(this.props.isCombineEnabled)} onAdd={this.props.onAdd} onClickItem={this.props.onClickItem} onDelete={this.props.onDelete} />
    </Container>);
  }
}
