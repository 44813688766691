import { AbaseAutocompleteField, FormDraft, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import * as Yup from 'yup';
import DialogForm from '../../DialogForm';
import { RequisicaoTipoAllComponent, HotelAllComponent } from '../../generated/graphql';
import { defaultDecorators, redirectBackForm } from '../../helpers';
import { cast, validate } from '../../helpers/validations';
import AgendaRequisicaoLabel from './AgendaRequisicaoNameLabel';
import DynamicFormRenderer from '../../components/DynamicFormRenderer';
import { LocalQueryByIdComponent } from './../../generated/graphql';
import queryString from 'query-string';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';
import { Button, Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import withIsMobile from '../../components/withIsMobile';
const AgendaRequisicaoSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    recId: Yup.string().notRequired(),
    hotId: Yup.string().notRequired().nullable(),
    req: Yup.object().shape({
        id: Yup.string().required().label("Tipo de Requisição"),
        descricao: Yup.string().notRequired(),
        formulario: Yup.string().notRequired(),
    }).required().noUnknown(),
    dadosFormulario: Yup.string().notRequired(),
}).noUnknown();
function AgendaRequisicaoForm(props) {
    const { match, history, location, isMobile } = props;
    const { params } = match;
    const handleSubmit = async (values) => {
        const mutationData = await cast(AgendaRequisicaoSchema, values);
        console.log("SAVE", { mutationData, props, values });
        if (props.onSubmitItem) {
            props.onSubmitItem(mutationData, values.action ? values.action : "save");
            redirectBackForm(history, location);
            return;
        }
    };
    const notEditting = !params || !params.id;
    const initialValues = notEditting || !props.data ? {} : props.data.filter((x) => x.id === params.id)[0];
    let queryStringParams = queryString.parse(props.location.search);
    const iconMarkup = renderToStaticMarkup(<i className="material-icons" style={{ color: "red", fontSize: 30, transform: "translateX(-10px) translateY(-10px)" }}>all_out</i>);
    const customMarkerIcon = divIcon({
        html: iconMarkup,
    });
    const iconMarkupHotel = renderToStaticMarkup(<i className="material-icons" style={{ color: "blue", fontSize: 30, transform: "translateX(-10px) translateY(-10px)" }}>local_hotel</i>);
    const customMarkerIconHotel = divIcon({
        html: iconMarkupHotel,
    });
    const iconMarkupHotelSelected = renderToStaticMarkup(<i className="material-icons" style={{ color: "red", fontSize: 30, transform: "translateX(-10px) translateY(-10px)" }}>local_hotel</i>);
    const customMarkerIconHotelSelected = divIcon({
        html: iconMarkupHotelSelected,
    });
    const mapMarkers = (list, values, select) => {
        return list.map(x => {
            if (!x || !x.posicaoGeografica) {
                return null;
            }
            return (<Marker key={x.id} position={[x.posicaoGeografica.x, x.posicaoGeografica.y]} icon={values.hotId === x.id ? customMarkerIconHotelSelected : customMarkerIconHotel}>
                <Popup>
                    <div>
                        <strong>{x.nome}</strong><br />
                        Telefone: {x.telefone}<br />
                    </div>
                    <Button variant={"contained"} color={"primary"} onClick={() => select(x.id)} disabled={values.hotId === x.id}>Selecionar</Button>
                </Popup>
            </Marker>);
        }).filter(x => !!x);
    };
    const onClose = () => {
        redirectBackForm(history, location);
    };
    return (<FormDraft useDraft={false} onSubmit={handleSubmit} validate={validate(AgendaRequisicaoSchema)} initialValues={initialValues} decorators={defaultDecorators} render={({ handleSubmit, form, submitting, values }) => (<DialogForm title="Requisição" onSubmit={handleSubmit} actions={isMobile ? null :
        <Grid container spacing={0}>
            <Grid item xs={12} className="center mb15">
                <Button onClick={onClose} key="cancelar" variant="contained">
                    <i className="material-icons">close</i>Cancelar
                                </Button>
                <Button type="button" color="secondary" variant="contained" className="ml5" key="submit" onClick={() => {
                    form.change("action", "continue");
                    handleSubmit();
                }}>
                    <SaveIcon>send</SaveIcon>Salvar e Continuar
                                </Button>
                <Button type="button" variant="contained" className="btnSuccess ml5" key="submit" onClick={() => {
                    form.change("action", "save");
                    handleSubmit();
                }}>
                    <SaveIcon>send</SaveIcon>Salvar
                                </Button>
            </Grid>
        </Grid>}>
        <RequisicaoTipoAllComponent>{({ data, loading }) => (<React.Fragment>
            <Field name="req">{({ input, meta }) => (<AbaseAutocompleteField isLoading={loading} label={AgendaRequisicaoLabel.req} meta={meta} getOptionValue={(x) => x} textField={"descricao"} data={_.get(data, "requisicaoTipo.list", [])} {...input} />)}
            </Field>
            {values && values.req && values.req.precisaHotel ? (<HotelAllComponent>{({ data, loading }) => (<Field name="hotId">{({ input, meta }) => (<React.Fragment>
                <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Hotel"} textField={"nome"} valueField={"id"} data={_.get(data, "hotel.list", [])} {...input} />

                <LocalQueryByIdComponent variables={{
                    id: queryStringParams.local
                }}>{({ data: localData }) => {
                    if (!_.get(localData, "local.list[0].posicaoMapa"))
                        return null;
                    const localPosition = _.get(localData, "local.list[0].posicaoMapa") ? [localData.local.list[0].posicaoMapa.x, localData.local.list[0].posicaoMapa.y] : [-27.7741977, -54.230988];
                    return (<React.Fragment>

                        <div className="b-1 mt15">
                            <Map zoom={10} center={localPosition}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors" />
                                <Marker position={localPosition} icon={customMarkerIcon}>
                                    <Popup>
                                        <div>
                                            Seu destino
                                                                        </div>
                                    </Popup>
                                </Marker>
                                {mapMarkers(_.get(data, "hotel.list", []), values, input.onChange)}
                            </Map>
                        </div>
                    </React.Fragment>);
                }}</LocalQueryByIdComponent>
            </React.Fragment>)}</Field>)}
            </HotelAllComponent>) : null}
            <Field name="dadosFormulario">{({ input, meta }) => (<DynamicFormRenderer value={input.value} onChange={input.onChange} formData={values && values.req ? values.req.formulario || undefined : undefined} />)}</Field>
        </React.Fragment>)}
        </RequisicaoTipoAllComponent>
    </DialogForm>)} />);
}
export default withIsMobile(AgendaRequisicaoForm);
