import { AbaseLoading, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { UsuarioLocalViewComponent } from '../../generated/graphql';
import { defaultErrorMessage, redirectBackForm } from '../../helpers';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, Typography, withStyles } from '@material-ui/core';
import { formatDateTime } from '../../helpers/format';

function UsuarioLocalView(props) {
    const { match, history, location, classes } = props;
    const { params } = match;
    const { showError } = useAbaseDialog();
    const notEditting = !params || !params.id;
    const onClose = () => {
        redirectBackForm(history, location);
    };
    return (<UsuarioLocalViewComponent variables={{ usuId: params.id }} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => {
            const values = _.get(data, "usuarioLocal.list[0]") || {};
            return (<Dialog disableRestoreFocus open={true} onClose={onClose} aria-labelledby="scroll-dialog-title" scroll={'paper'} className="dialogs">
                <DialogTitle id="form-dialog-title">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <b>Detalhes</b>
                    </Typography>
                </DialogTitle>
                <DialogContent key="formulario" style={{ minWidth: 600, minHeight: 193, marginTop: '-15px', overflowY: 'scroll' }}>
                    <AbaseLoading open={loading} />
                    <div className="colRow f-12">
                        <ul className="listaHome">
                            <li>
                                <p><strong>Usuário:</strong> {_.get(values, "usu.nome")}</p>
                                <p><strong>Última atualização:</strong> {formatDateTime(_.get(values, "data"))}</p>
                                <p><strong>Local:</strong> {_.get(values, "loc.descricao")}</p>
                            </li>
                        </ul>
                    </div>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <b>AGENDAS</b>
                    </Typography>
                    <div className="colRow f-12">
                        <ul className="listaHome">
                            {_.get(data, "agenda.list", []).map(x => (<li key={x.id}>
                                Título: <b>{_.get(x, "titulo")}</b> <br />
                                Cliente: <b>{_.get(x, "cli.razaosocial")}</b> <br />
                                Data Inicial: <b> {formatDateTime(_.get(x, "inicio"))}</b> <br />
                                Data Final: <b> {formatDateTime(_.get(x, "fim"))}</b>
                            </li>))}
                        </ul>
                        {_.get(data, "agenda.list", []).length === 0 ? (<div className="attention">
                            <i className="material-icons">warning</i> Não há nenhuma agenda
                                     </div>) : null}
                    </div>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        <b>ÚLTIMAS ATUALIZAÇÕES DE LOCAL</b>
                    </Typography>
                    <div className="colRow f-12">
                        <ul className="listaHome">
                            {_.get(data, "usuarioLocal.list", []).filter((x, i) => i > 0).map(x => (<li key={x.id}>
                                Local: <b>{_.get(x, "loc.descricao")}</b> <br />
                                Data Limite: <b> {formatDateTime(_.get(x, "data"))}</b>
                            </li>))}
                        </ul>
                        {_.get(data, "usuarioLocal.list", []).length < 2 ? (<div className="attention">
                            <i className="material-icons">warning</i> Não há outros registros
                                    </div>) : null}
                    </div>
                </DialogContent>
                <DialogActions key="acoes">{<Grid container spacing={0}>
                    <Grid item xs={12} className="center mb15">
                        <Button onClick={onClose} key="cancelar" variant="contained">
                            <i className="material-icons">close</i> Fechar
                                    </Button>
                    </Grid>
                </Grid>}
                </DialogActions>
            </Dialog>);
        }}
    </UsuarioLocalViewComponent>);
}
const styles = {
    bullet: {
        margin: '0 2px',
        transform: 'scale(0.8)',
        display: 'inline-block',
    },
    title: {
        fontSize: 18,
        color: 'black'
    }
};
export default withStyles(styles)(UsuarioLocalView);
