const names = {
    "form": "Responsável de requisição",
    "id": "Id",
    "reqId": "Tipo",
    "usuId": "Usuário",
    "empId": "Empresa",
    "emp": "Empresa",
    "req": "Tipo",
    "usu": "Usuário",
};
export default names;
