import { AbaseLoading, AbaseTextField, AbaseDateTimePickerField, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm, AbaseAutocompleteField, AbaseCheckbox, EditAction, DeleteAction } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import { Route } from 'react-router';
import DialogForm from '../../DialogForm';
import uuid from 'node-uuid';
import * as Yup from 'yup';
import { UsuarioAllComponent, TarefaQueryByIdComponent, useTarefaCreate, useTarefaUpdate, TarefaTipoAllComponent, ProdutoServicoAllComponent, ProjetoEtapaAllComponent } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import TarefaLabel, { tarefaStatus } from './TarefaNameLabel';
import queryString from 'query-string';
import { Grid, Button, IconButton, Tooltip } from '@material-ui/core';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import UserAvatarSac from '../../components/UserAvatarSac';
import { formatDateTime, tempoTotalEmSegundos } from '../../helpers/format';
import TarefaAtividadeForm from './TarefaAtividadeForm';
import TarefaHistoricoDelete from '../TarefaHistorico/TarefaHistoricoDelete';
import withIsMobile from '../../components/withIsMobile';
import AddIcon from '@material-ui/icons/Add';
// eslint-disable-next-line no-unused-vars
function merge(...schemas) {
    const [first, ...rest] = schemas;
    const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);
    return merged;
}
const TarefaSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    etapaId: Yup.string().notRequired(),
    // etapa: Yup.object().shape({
    //     id: Yup.string().notRequired(),
    //     descricao: Yup.string().notRequired(),
    //     porcentagem: Yup.number().notRequired(),
    //     ordem: Yup.number().notRequired(),
    // }).noUnknown().notRequired().nullable(),
    usu: Yup.object().shape({
        id: Yup.string().required(),
        nome: Yup.string().notRequired(),
        email: Yup.string().notRequired(),
        avatar: Yup.string().notRequired().nullable(),
        corAvatar: Yup.string().notRequired().nullable(),
    }).noUnknown().required().nullable(),
    tar: Yup.object().shape({
        id: Yup.string().notRequired(),
        descricao: Yup.string().required(),
        unidadeMedida: Yup.string().required(),
    }).noUnknown().required(),
    datatarefa: Yup.date().notRequired(),
    descricao: Yup.string().notRequired(),
    status: Yup.string().required(),
    datainicio: Yup.date().notRequired(),
    dataconclusao: Yup.date().notRequired(),
    datalimite: Yup.date().notRequired(),
    porcentagem: Yup.number().notRequired(),
    quantidadeTempo: Yup.string().notRequired(),
    pro: Yup.object().shape({
        id: Yup.string().notRequired(),
        descricao: Yup.string().required(),
        gestorCodigoproduto: Yup.string().nullable().notRequired()
    }).noUnknown().required(),
    proId: Yup.string().notRequired().nullable(),
    urgencia: Yup.bool().notRequired().nullable(),
    previsaoInicio: Yup.date().notRequired().nullable(),
    tarefaHistorico: Yup.array(Yup.object().shape({
        id: Yup.string().notRequired().nullable(),
        usuId: Yup.string().required().label("Usuário que executou"),
        usu: Yup.object().shape({
            id: Yup.string().required(),
            nome: Yup.string().notRequired(),
            email: Yup.string().notRequired(),
            avatarUrl: Yup.string().notRequired().nullable(),
            corAvatar: Yup.string().notRequired().nullable(),
        }).noUnknown().required().nullable(),
        state: Yup.string().notRequired().nullable(),
        inicio: Yup.date().required(),
        data: Yup.date().notRequired().nullable(),
        porcentagem: Yup.number().required().nullable(),
        status: Yup.string().required().nullable(),
        fim: Yup.date().required(),
        tempoIntervalo: Yup.string().notRequired().nullable(),
        tempoDeslocamento: Yup.string().notRequired().nullable(),
        tempoOcioso: Yup.string().notRequired().nullable(),
        descritivo: Yup.string().required(),
    }).noUnknown()).nullable(),
}).noUnknown();
function TarefaForm(props) {
    const { match, history, location, isMobile } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useTarefaUpdate({ refetchQueries: ["projetoAndamento"] });
    const insert = useTarefaCreate({ refetchQueries: ["projetoAndamento"] });
    const [trazTodosServicos, setTrazTodosServicos] = React.useState(false);
    let queryStringParams = queryString.parse(props.location.search);
    const [defaultInitialValues] = React.useState(() => ({
        status: queryStringParams.status || "FAZER",
        porcentagem: 0,
        etapaId: match.params.etapaId || null,
        tarefaHistorico: []
    }));
    const handleSubmit = async (values) => {
        const mutationData = await cast(TarefaSchema, values);
        const { usu, tar, pro, tarefaHistorico, ...mutationAdapted } = { ...mutationData, tarId: mutationData.tar.id, usuId: mutationData.usu ? mutationData.usu.id : null, proId: mutationData.pro ? mutationData.pro.id : null };
        if (props.onSubmitItem) {
            props.onSubmitItem({ usu, tar, pro, tarefaHistorico, ...mutationAdapted });
            redirectBackForm(history, location);
            return;
        }
        const tarefaHistoricoSemUsu = tarefaHistorico ? tarefaHistorico.map(x => ({ ...x, usu: undefined, etapa: undefined })) : [];
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: { ...mutationAdapted, tarefaHistorico: tarefaHistoricoSemUsu }
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.tarefa.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: { ...mutationAdapted, tarefaHistorico: tarefaHistoricoSemUsu }
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.tarefa.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = false;
    const dataInitialValues = notEditting || !props.data ? null : _.first(props.data.filter((x) => x.id === params.id));
    return (<TarefaQueryByIdComponent variables={params} skip={notEditting || props.data} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
    }}>
        {({ data: dataTarefa, loading, refetch, error }) => (<React.Fragment>
            <FormDraft useDraft={isUsingDraft} onSubmit={handleSubmit} validate={validate(TarefaSchema)} initialValues={_.get(dataTarefa, "tarefa.list[0]") || dataInitialValues || defaultInitialValues} subscription={{
                submitting: true,
                initialValues: true
            }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting, values }) => (<DialogForm title="Tarefa" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined} dialogProps={isMobile ? undefined : {
                maxWidth: "md",
                fullWidth: false
            }}>
                <AbaseLoading open={loading || submitting} />
                <TarefaTipoAllComponent>{({ data, loading }) => (<React.Fragment>
                    <Grid container spacing={1}>
                        {!match.params.etapaId && match.params.projetoId ?
                            <Grid item xs={12}>
                                <ProjetoEtapaAllComponent variables={{ projetoId: match.params.projetoId }}>
                                    {({ data, loading }) => {
                                        return <Field name="etapaId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Etapa do Projeto"} textField={"descricao"} valueField={'id'} data={_.get(data, "projetoEtapa.list", [])} {...input} />)}
                                        </Field>;
                                    }}
                                </ProjetoEtapaAllComponent>
                            </Grid>
                            : null}
                        <Grid item xs={12} sm={12} md={11} lg={11}>
                            <ProdutoServicoAllComponent variables={{
                                empId: props.empId || null,
                                cliId: trazTodosServicos ? null : props.cliId || null,
                            }}>{({ data, loading }) => (<Field name="pro">{({ input, meta }) => (<AbaseAutocompleteField isLoading={loading} meta={meta} label={"Serviço"} getOptionValue={x => x} getOptionLabel={x => { return `${_.get(x, 'gestorCodigoproduto')} - ${_.get(x, 'descricao')}`; }}
                                // textField={"descricao"}
                                data={_.get(data, "produtoServico.list", []).map((x) => {
                                    if (input.value && x.id === input.value.id) {
                                        return input.value;
                                    }
                                    return x;
                                })} {...input} />)}
                            </Field>)}
                            </ProdutoServicoAllComponent>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} lg={1}>
                            <Tooltip title={trazTodosServicos ? "Trazer todos serviços" : "Trazer apenas serviços do contrato"}>
                                <IconButton onClick={() => setTrazTodosServicos(!trazTodosServicos)}  className={"p0 mt8"}><i className={"material-icons"}>{trazTodosServicos ? "zoom_out" : "zoom_in"}</i></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <UsuarioAllComponent variables={{
                                cliente: false
                            }}>{({ data, loading }) => (<Field name="usu">{({ input, meta }) => (<AbaseAutocompleteField isLoading={loading} getOptionValue={x => x} label={TarefaLabel.usu} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", []).map((x) => {
                                if (input.value && x.id === input.value.id) {
                                    return input.value;
                                }
                                return x;
                            })} {...input} />)}
                            </Field>)}
                            </UsuarioAllComponent>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Field name="tar">{({ input, meta }) => (<AbaseAutocompleteField isLoading={loading} meta={meta} label={"Tipo de tarefa"} getOptionValue={x => x} textField={"descricao"} data={_.get(data, "tarefaTipo.list", []).map((x) => {
                                if (input.value && x.id === input.value.id) {
                                    return input.value;
                                }
                                return x;
                            })} {...input} />)}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field name="descricao" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField multiline rows="2" meta={meta} fullWidth={true} {...input} label={"Observação"} />)}</Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Field name="porcentagem">{({ input: inputPorcentagem }) => (<Field name="status">{({ input, meta }) => (<div className="mt5 f-12">
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        STATUS: <b>{tarefaStatus[input.value || "FAZER"].text.toUpperCase()}</b>
                                    </Grid>
                                    <Grid item xs={6}>
                                        PROGRESSO: <b>{inputPorcentagem.value + "%"}</b>
                                    </Grid>
                                </Grid>
                            </div>)}</Field>)}</Field>
                        </Grid>
                        {_.get(dataTarefa, "tarefa.list[0].agendaTarefa[0].age.titulo") ?
                            <Grid item xs={12}>
                                <div className="colRow p-t-b-8">
                                    <label className="f-12 colRow"> Relacionado a agenda </label>
                                    <span className="f-15"> {_.get(dataTarefa, "tarefa.list[0].agendaTarefa[0].age.titulo")} </span>
                                    <Button type="button" variant="contained" size="small" className="ml5" onClick={() => props.history.push("/agenda/agenda/edit/" + _.get(dataTarefa, "tarefa.list[0].agendaTarefa[0].age.id"))}>
                                        Visualizar Agenda
                                                        <i className="material-icons">visibility</i>
                                    </Button>
                                </div>
                            </Grid>
                            : null}
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <Field name="quantidadeTempo">{({ input, meta }) => (<Field name="tar">{({ input: inputTarId }) => {
                                const tarefaTipo = inputTarId.value;
                                if (!tarefaTipo || !tarefaTipo.unidadeMedida || tarefaTipo.unidadeMedida === "NADA")
                                    return null;
                                if (tarefaTipo.unidadeMedida === "HORAS" || tarefaTipo.unidadeMedida === "DIAS") {
                                    return <AbaseTextField meta={meta} {...input} type={"number"} label={"Tempo em " + (tarefaTipo.unidadeMedida === "HORAS" ? "Horas" : "Dias")} />;
                                }
                                return null;
                            }}</Field>)}
                            </Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Field name="previsaoInicio">{({ input, meta }) => (<AbaseDateTimePickerField meta={meta} {...input} label={"Previsão Início"} />)}</Field>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Field name="urgencia">{({ input, meta }) => (<AbaseCheckbox {...input} label={"Urgência"} />)}</Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field name="tarefaHistorico">{({ input, meta }) => {
                                const filteredData = _.sortBy((input.value || []), x => new Date(x.fim)).filter(x => x.state !== "delete");
                                console.log({ filteredData, input });
                                return (<React.Fragment>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <div className="right">
                                                <Button type="button" variant="contained" className="btnSuccess" onClick={() => { props.history.push(`${match.url}/atividade/add`); }}>
                                                    <AddIcon /> Adicionar atividades da tarefa
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <div className="colRow mt15 f-12">
                                        <ul className="listaHome">
                                            {filteredData.map((item, i) => {
                                                return (<li key={i}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12} md={1} lg={1}>
                                                            <UserAvatarSac name={_.get(item, "usu.nome")} url={_.get(item, "usu.avatarUrl")} color={_.get(item, "usu.corAvatar")} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={9} lg={9}>
                                                            <b className="textTransform">{_.get(item, "descritivo")}</b><br />
                                                            Progresso: <b>{!isNaN(item.porcentagem) ? `${item.porcentagem}%` : "N/A"}</b>&nbsp;&nbsp;
                                                            Status: <b>{item.status}</b>&nbsp;&nbsp;
                                                            Data: <b>{item.data ? formatDateTime(item.data) : "N/A"}</b><br />
                                                            Início:<b>{item.inicio ? formatDateTime(item.inicio) : "N/A"}</b>&nbsp;&nbsp;
                                                            Fim: <b>{item.fim ? formatDateTime(item.fim) : "N/A"}</b>&nbsp;&nbsp;
                                                            Intervalo: <b>{item.tempoIntervalo}</b>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={1} lg={1}>
                                                            <EditAction onClick={() => {
                                                                props.history.push(`${match.url}/atividade/edit/${item.id}`);
                                                            }}></EditAction>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={1} lg={1}>
                                                            <DeleteAction onClick={() => {
                                                                props.history.push(`${match.url}/atividade/delete/${item.id}`);
                                                            }}></DeleteAction>
                                                        </Grid>
                                                    </Grid>
                                                </li>);
                                            })}
                                        </ul>
                                        {filteredData.length === 0 ? (<div className="attention">
                                            <i className="material-icons">warning</i> Não há nenhuma atividade lançada
                                                                    </div>) : null}
                                    </div>
                                </React.Fragment>);
                            }}</Field>
                        </Grid>
                    </Grid>

                </React.Fragment>)}
                </TarefaTipoAllComponent>
                <Field name="tarefaHistorico">{({ input: { value: tarefaHistorico } }) => (<Field name="porcentagem">{({ input: { value: porcentagem } }) => (<Field name="status">{({ input: { value: status } }) => (<Field name="usuId">{({ input: { value: usuId } }) => {
                    const currentValue = tarefaHistorico || [];
                    const normalizaDados = data => {
                        const newData = _.sortBy(data
                            .map(x => ({
                                ...x,
                                fimInt: new Date(x.fim).getTime(),
                                tempo: tempoTotalEmSegundos(x.inicio, x.fim, x.tempoIntervalo)
                            })), "fimInt");
                        console.log("CHANGE TarefaHistorico", newData);
                        form.change("tarefaHistorico", newData);
                        const ultimoItem = newData.filter(x => x.state !== "delete").reduce((prev, next) => {
                            if (prev === null) {
                                return next;
                            }
                            if (next.fimInt > prev.fimInt)
                                return next;
                            return prev;
                        }, null);
                        form.change("status", ultimoItem.status);
                        form.change("porcentagem", ultimoItem.porcentagem);
                        // const tempoTotal = _.sumBy(newData, "tempo")
                        // form.change("quantidadeTempo", segundosParaTempoFormatado(tempoTotal))
                    };
                    return (<React.Fragment>

                        <Route path={`${match.url}/atividade/add`} render={(props) => <TarefaAtividadeForm porcentagem={porcentagem} status={status} usuId={usuId} onSubmitItem={(item) => {
                            const newData = [...(currentValue || []), { ...item, id: uuid.v4(), state: "add" }];
                            normalizaDados(newData);
                        }} {...props} />} />
                        <Route path={`${match.url}/atividade/edit/:id`} render={(props) => <TarefaAtividadeForm data={currentValue} onSubmitItem={(item) => {
                            item = { ...item, state: item.state || "update" };
                            const newData = (currentValue || []).map(x => x.id === item.id ? item : x);
                            normalizaDados(newData);
                        }} {...props} />} />

                        <Route path={`${match.url}/atividade/delete/:id`} render={(props) => <TarefaHistoricoDelete onSubmitItem={(item) => {
                            const newData = (currentValue || []).map((x) => {
                                if (x.state === "add" && item.indexOf(x.id) !== -1)
                                    return null;
                                return item.indexOf(x.id) !== -1 ? { ...x, state: "delete" } : x;
                            }).filter((x) => x !== null);
                            normalizaDados(newData);
                        }} {...props} />} />

                    </React.Fragment>);
                }}</Field>)}</Field>)}</Field>)}</Field>

            </DialogForm>)} />

        </React.Fragment>)}
    </TarefaQueryByIdComponent>);
}
export default withIsMobile(TarefaForm);
