import { ResponsivePaperTable, AddAction, DeleteAction, EditAction } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field, FormSpy } from "react-final-form";
import { ModeloTermoAceiteAllComponent, useEmpresaQueryById } from '../../generated/graphql';
import TarefaForm from '../Tarefa/TarefaForm';
import TarefaDelete from '../Tarefa/TarefaDelete';
import TarefaCancela from '../Tarefa/TarefaCancela';
import { Route, withRouter } from 'react-router';
import uuid from 'node-uuid';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, IconButton, Tooltip } from '@material-ui/core';
import { imprimirTermoAceite } from "../../services/report";
import BuscarTarefaProjeto from './BuscarTarefaProjeto';
import { CancelScheduleSend } from '@material-ui/icons';
function StepTarefa(props) {
    return (<Field name="agendaTarefa">{({ input }) => (<TarefaBoard {...props} value={input.value} onChange={(data) => input.onChange(data)} />)}</Field>);
}
export var OrdemTarefaComponent = withRouter(function OrdemTarefaComponent(props) {
    return (<Field name="ordemTarefas">{({ input }) => (<TarefaBoard {...props} value={input.value} onChange={(data) => input.onChange(data)} />)}</Field>);
});
function TarefaBoard(props) {
    const onSave = (action, item) => {
        let dataUpdated = props.value ? [...props.value] : [];
        console.log("onSave", { action, item, dataUpdated });
        // eslint-disable-next-line default-case
        switch (action) {
            case "add":
                dataUpdated = [...dataUpdated, { id: uuid.v4(), tar: { ...item, id: uuid.v4(), state: item.state || "add" } }];
                break;
            case "update":
                dataUpdated = dataUpdated.map((x) => {
                    if (!x)
                        return null;
                    if (x.tar.id === item.id) {
                        return { ...x, tar: { ...item, state: x.tar.state || "update" } };
                    }
                    return x;
                }).filter((x) => x !== null);
                break;
            case "update_status":
                dataUpdated = dataUpdated.map((x) => {
                    if (!x)
                        return null;
                    if (x.tar.id === item.taskId) {
                        return { ...x, tar: { ...x.tar, status: item.status, state: x.tar.state || "update_status" } };
                    }
                    return x;
                }).filter((x) => x !== null);
                break;
            case "delete":
                dataUpdated = dataUpdated.map((x) => {
                    if (x.tar.state === "add" && item.indexOf(x.tar.id) !== -1)
                        return null;
                    return item.indexOf(x.tar.id) !== -1 ? { ...x, tar: { ...x.tar, state: "delete" } } : x;
                }).filter((x) => x !== null);
                break;
        }
        console.log("onChange", dataUpdated);
        props.onChange(dataUpdated);
    };
    const { match } = props;
    const empresaQueryById = useEmpresaQueryById();
    const [anchorEl, setAnchorEl] = React.useState(null);
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() {
        setAnchorEl(null);
    }
    const tasks = (props.value || []).filter(x => x && x.tar && x.tar.state !== "delete").map(x => ({ ...x.tar, agendaTarefaId: x.id, order: new Date(x.tar.datalimite).getTime() }));
    const columns = [
        {
            name: "Id",
            field: "id",
            defaultVisible: false,
            sort: false
        },
        {
            name: "Tipo",
            field: "tar.tar.descricao",
            sort: false
        },
        {
            name: "Usuário",
            field: "tar.usu.nome",
            sort: false
        },
        {
            name: "Serviço",
            field: "tar.pro.descricao",
            sort: false
        },
        // {
        //     name: "Data Limite",
        //     field: "tar.datalimite",
        //     sort: false,
        //     width: 100,
        //     customBodyRender: (value) => value ? formatDateTime(value) : "N/A"
        // },
        {
            name: "Porc.",
            field: "tar.porcentagem",
            sort: false,
            width: 60,
            customBodyRender: (value) => value ? value + "%" : "N/A"
        },
        {
            name: "Status",
            field: "tar.status",
            sort: false,
            width: 80
        },
    ];
    return (<React.Fragment>
        <div className={"colDivBloco"}>
            <FormSpy subscription={{ values: true, initialValues: true }}>{({ values }) => (
                <ResponsivePaperTable
                    sticky={true}
                    selectMode={"multi"}
                    loading={false}
                    data={(props.value || []).filter(x => _.get(x, "tar.state") !== "delete")}
                    total={0}
                    columns={columns}
                    onDoubleClick={row => {
                        props.history.push(`${match.url}/tarefa/edit/${row.tar.id}`);
                    }}
                    title={"Tarefas"}
                    customActions={[
                        <Tooltip title="Buscar tarefa de projeto" disableFocusListener>
                            <IconButton aria-label={`Buscar tarefa de projeto`} onClick={() => {
                                props.history.push(`${match.url}/tarefa/buscar`);
                            }} tabIndex={-1}>
                                <i className={"material-icons"} onClick={handleClick}>zoom_in</i>
                            </IconButton>
                        </Tooltip>,
                        <AddAction key="add" ref={props.focusRef} to={`${match.url}/tarefa/add`} component={Link} onKeyDown={(e) => {
                            if (props.navigatePrevKeyDown) {
                                props.navigatePrevKeyDown(e);
                            }
                            if (props.navigateNextKeyDown) {
                                props.navigateNextKeyDown(e);
                            }
                        }} />,
                    ]} customSelectedActions={
                        (selected) => {
                            return [
                                selected.length === 1 ?
                                    <Tooltip title="Cancelar Tarefas">
                                        <Link to={`${match.url}/tarefa/cancela/${_.map(selected, "tar.id").join(",")}`}>
                                            <IconButton aria-label={`Cancelar Tarefas`}><i className={"material-icons"}>cancel_schedule_send</i></IconButton>
                                        </Link>
                                    </Tooltip>
                                    : null,

                                ...(props.extraSelectedOptions ? props.extraSelectedOptions(selected) : []),
                                selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/tarefa/edit/${selected[0].tar.id}`} component={Link} />) : null,
                                <DeleteAction key="delete" to={`${match.url}/tarefa/delete/${_.map(selected, "tar.id").join(",")}`} component={Link} />,
                                selected.length === 1 ? (<React.Fragment>
                                    <Tooltip title="Imprimir Ordem da Tarefa">
                                        <IconButton aria-label={`Imprimir`}><i className={"material-icons"} onClick={handleClick}>print</i></IconButton>
                                    </Tooltip>
                                    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                                        <ModeloTermoAceiteAllComponent>
                                            {({ data, loading }) => {
                                                var modelos = _.get(data, 'modeloTermoAceite.list');
                                                if (loading) {
                                                    return <MenuItem>Carregando...</MenuItem>;
                                                }
                                                return !loading ? modelos.map(modelo => {
                                                    return <MenuItem onClick={async () => {
                                                        //Tela de ordem de serviço
                                                        console.log("VALUES", values);
                                                        if (selected[0].ord) {
                                                            return imprimirTermoAceite({
                                                                EMPRESA_NOME: selected[0].ord.cli.emp.nomefantasia,
                                                                CLIENTE_NOME: selected[0].ord.cli.razaosocial || selected[0].ord.cli.nome,
                                                                TECNICO_RESPONSAVEL: selected[0].tar.usu.nome,
                                                                HORARIO_GERADO: new Date().toLocaleString(),
                                                                SISTEMA_NOME: prompt("Nome do sistema")
                                                            }, modelo.id);
                                                        }
                                                        else {
                                                            const data = await empresaQueryById.refetch({ id: values.empId });
                                                            return imprimirTermoAceite({
                                                                EMPRESA_NOME: data.data.empresa.list[0].razaosocial,
                                                                CLIENTE_NOME: values.cli.razaosocial || values.cli.nome,
                                                                TECNICO_RESPONSAVEL: selected[0].tar.usu.nome,
                                                                HORARIO_GERADO: new Date().toLocaleString(),
                                                                SISTEMA_NOME: prompt("Nome do sistema")
                                                            }, modelo.id);
                                                        }
                                                    }}>{modelo.nome}</MenuItem>;
                                                }) : <div />;
                                            }}
                                        </ModeloTermoAceiteAllComponent>
                                    </Menu></React.Fragment>) : null
                            ];
                        }}
                    requestData={() => Promise.resolve([])} tableProps={{
                        paginated: false
                    }}
                    disableFilter={true}></ResponsivePaperTable>)}</FormSpy>

        </div >

        <Field name={"contaCliente"}>{({ input: { value: contaCliente } }) => (<Field name={"empId"}>{({ input: inputEmpId }) => (<React.Fragment>
            <Route path={`${match.url}/tarefa/add`} render={(props) => <TarefaForm empId={inputEmpId.value} cliId={contaCliente && contaCliente.id} onSubmitItem={onSave.bind(null, "add")} {...props} />} />
            <Route path={`${match.url}/tarefa/edit/:id`} render={(props) => <TarefaForm empId={inputEmpId.value} cliId={contaCliente && contaCliente.id} data={tasks} onSubmitItem={onSave.bind(null, "update")} {...props} />} />
            <Route path={`${match.url}/tarefa/delete/:id`} render={(props) => <TarefaDelete onSubmitItem={onSave.bind(null, "delete")} {...props} />} />
            <Route path={`${match.url}/tarefa/cancela/:id`} render={(props) => <TarefaCancela {...props} />} />
            <Route path={`${match.url}/tarefa/buscar`} render={(props) => <BuscarTarefaProjeto {...props} />} />

        </React.Fragment>)}</Field>)}</Field>

    </React.Fragment >);
}
export default withRouter(StepTarefa);
