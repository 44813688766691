import { AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import React from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import ReenterRouteListener from '../ReenterRouteListener';
import RenderWithModal from '../RenderWithModal';
import { Button } from '@material-ui/core';
const AutoCompleteWithEdit = ({ match, path, form, refetch, notEditable, infoAdicionalRota, ignoreMatchRoute, ...otherProps }) => {
    const matchUrl = ignoreMatchRoute ? "" : match.url;
    return (<React.Fragment>
			<AbaseAutoCompleteFieldFinalForm {...otherProps}/>
			<ReenterRouteListener callback={refetch}/>
			<div className="colRow mt15 mb15 center">
				<Link to={`${matchUrl}/${path}/add${infoAdicionalRota || ''}`} tabIndex={-1}>
					<Button size="small" variant="contained" color="default" className="btnSuccess">
						Adicionar
						<i className={'material-icons'}> add </i>
					</Button>
				</Link>
				{otherProps.value && !notEditable ? (<Link to={`${matchUrl}/${path}/edit/${otherProps.value}`} tabIndex={-1}>
						<Button size="small" style={{ marginLeft: '15px' }} variant="contained" color="default">
							Editar
							<i className={'material-icons'}> create </i>
						</Button>
					</Link>) : null}
			</div>
			{!ignoreMatchRoute ? <React.Fragment>
				<Route path={`${matchUrl}/${path}/add`} render={RenderWithModal(form, (value) => {
        if (refetch)
            refetch();
        otherProps.onChange(value);
    })}/>
				<Route path={`${matchUrl}/${path}/edit/:id`} render={RenderWithModal(form, otherProps.onChange)}/>
			</React.Fragment> : null}
		</React.Fragment>);
};
export default withRouter(AutoCompleteWithEdit);
