import { AbasePaperTable, AbaseTextField } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { AtividadeListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import AtividadeLabel from './AtividadeNameLabel'
import EmpresaEquipeAreaFiltro from "../../components/EmpresaEquipeAreaFiltro";


export default function AtividadeList(props) {
    const { match } = props
    const columns = [

        {
            name: AtividadeLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: "Id da Empresa",
            field: "empId",
            defaultVisible: false
        },
        {
            name: "Descrição",
            field: "descricao"
        },
        {
            name: "Código Gestor",
            field: "gestorChaveintegracao",
            width: 100
        },
        {
            name: "Ativo",
            field: "ativo",
            customBodyRender: value => value ? "S" : "N",
            width: 50
        },
        {
            name: "Empresa",
            field: "emp.nomefantasia",
            width: 150
        },
    ]
    const selectedActions = (selected) => {

        if (props.foreing)
            props.foreing(selected)
        return []
    }
    const customActions = []

    const filters = () => ([
        // <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <EmpresaEquipeAreaFiltro hideArea />,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descricao"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"gestorChaveintegracao"}>{({ input, meta }) => <AbaseTextField label={"GestorChaveintegracao"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ])
    const formName = AtividadeLabel.form

    return (

        <GraphqlPaperTableListener>{
            ({ variables, setVariables }) => (

                <AtividadeListComponent
                    variables={variables}
                    skip={variables === null}
                >
                    {({ data, loading, refetch, error }) => (<div>

                        <AbasePaperTable
                            customPlacehorderSearch={"Pesquisar por descrição, código do gestor, razão social ou nome fantasia da empresa"}
                            sticky={true}
                            selectMode={"multi"}
                            loading={loading}
                            data={_.get(data, "atividade.pagedList.data") || []}
                            total={_.get(data, "atividade.pagedList.total")}
                            columns={columns}
                            customFilters={filters}
                            title={formName}
                            requestData={setVariables}
                            error={errorMessagePaperTable(error)}
                            customSelectedActions={selectedActions}
                            customActions={customActions}
                            selectListener={props.selectListener}
                            onDoubleClick={row => {
                                props.history.push(`${match.url}/edit/${row.id}`)
                            }}
                        ></AbasePaperTable>
                        <ReenterRouteListener callback={refetch} />
                    </div>
                    )}
                </AtividadeListComponent>
            )
        }
        </GraphqlPaperTableListener>

    )

}