import { AbaseAutocompleteField } from 'abase-components';
import React from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import ReenterRouteListener from '../ReenterRouteListener';
import RenderWithModal from '../RenderWithModal';
import { Button } from '@material-ui/core';
const AutoCompleteWithEdit = ({ match, path, form, refetch, notEditable, infoAdicionalRota, ...otherProps }) => {
    return (<React.Fragment>
			<AbaseAutocompleteField {...otherProps}/>
			<ReenterRouteListener callback={refetch}/>
			<div className="mt15 colRow mb15 center">
				<Link to={`${match.url}/${path}/add${infoAdicionalRota || ''}`}>
					<Button size="small" variant="contained" color="default" className="btnSuccess">
						Adicionar
						<i className={'material-icons'}> add </i>
					</Button>
				</Link>
				{otherProps.value && !notEditable ? (<Link to={`${match.url}/${path}/edit/${otherProps.value.id}`}>
						<Button size="small" style={{ marginLeft: '15px' }} variant="contained" color="default">
							Editar
							<i className={'material-icons'}> create </i>
						</Button>
					</Link>) : null}
			</div>
			<Route path={`${match.url}/${path}/add`} render={RenderWithModal(form, otherProps.onChange)}/>
			<Route path={`${match.url}/${path}/edit/:id`} render={RenderWithModal(form, otherProps.onChange)}/>
		</React.Fragment>);
};
export default withRouter(AutoCompleteWithEdit);
