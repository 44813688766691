/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { AbaseTextField } from 'abase-components';
export default function LatLngPicker(props) {
    const validValue = !!(props.value && props.value.x && props.value.y);
    const value = validValue && props.value ? [props.value.x, props.value.y] : null;
    const center = validValue ? value : [-27.7741977, -54.230988];
    const [fieldText, setTextfield] = useState(null);
    return (<React.Fragment>
        {props.showTextfield ? (<AbaseTextField fullWidth={true} label={'Coordenadas'} value={fieldText !== null ? (fieldText) : validValue && props.value ? (props.value.x + ',' + props.value.y) : null} onChange={(e) => {
            setTextfield(e.target.value);
        }} onBlur={() => {
            const regexTest = /^(\-?[\d\.]+),(\-?[\d\.]+)$/gm;
            const result = regexTest.exec(fieldText);
            if (result) {
                const x = +result[1];
                const y = +result[2];
                if (!isNaN(x) && !isNaN(y)) {
                    if (props.onChange) {
                        props.onChange({ x, y });
                    }
                }
            }
            setTextfield(null);
        }} />) : null}
        <Map center={center} zoom={13} onClick={(e) => {
            if (props.onChange) {
                props.onChange({ x: e.latlng.lat, y: e.latlng.lng });
                setTextfield(e.latlng.lat + ',' + e.latlng.lng);
            }
        }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors" />
            {validValue ? <Marker position={value} onClick={() => props.onChange && props.onChange(null)} /> : null}
        </Map>
    </React.Fragment>);
}
