import React from 'react';
import styled from '@emotion/styled';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import QuoteItem, { QuoteItemPlaceholder } from './QuoteItem';
import { grid, colors } from './constants';
import Title from './title';
const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
    if (isDraggingOver) {
        return colors.R50;
    }
    if (isDraggingFrom) {
        return colors.T50;
    }
    return colors.N30;
};
const Wrapper = styled.div `
    width: 100%;
    height: 100%;
    user-select: none;
    border: 1px solid #e0e0e0;
    background-color: ${(props) => getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
    display: flex;
    flex-direction: column;
    opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
    padding: ${grid}px;
    padding-bottom: 0;
    transition: background-color 0.2s ease, opacity 0.1s ease;
`;
const scrollContainerHeight = 350;
const DropZone = styled.div `
    /* stop the list collapsing when empty */
    min-height: ${scrollContainerHeight}px;
    /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
    */
    padding-bottom: ${grid}px;
`;
const ScrollContainer = styled.div `
    overflow-x: hidden;
    overflow-y: auto;
    max-height: ${scrollContainerHeight}px;
`;
/* stylelint-disable block-no-empty */
const Container = styled.div `    
`;
class InnerQuoteList extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.quotes !== this.props.quotes) {
            return true;
        }
        return false;
    }
    render() {
        return this.props.quotes.map((quote, index) => (<Draggable key={quote.id} draggableId={quote.id} index={index} shouldRespectForceTouch={false}>
                {(dragProvided, dragSnapshot) => (<QuoteItem key={quote.id} quote={quote} isDragging={dragSnapshot.isDragging} isGroupedOver={Boolean(dragSnapshot.combineTargetFor)} provided={dragProvided} onClickItem={this.props.onClickItem} onDelete={this.props.onDelete}/>)}
            </Draggable>));
    }
}
class InnerList extends React.Component {
    render() {
        const { quotes, dropProvided, onAdd } = this.props;
        const title = this.props.title ? <Title>{this.props.title}</Title> : null;
        return (<Container>
                {title}
                <DropZone ref={dropProvided.innerRef}>
                    <InnerQuoteList quotes={quotes} onClickItem={this.props.onClickItem} onDelete={this.props.onDelete}/>
                    <QuoteItemPlaceholder onAdd={onAdd.bind(null, title)}/>

                    {dropProvided.placeholder}
                </DropZone>
            </Container>);
    }
}
export default class QuoteList extends React.Component {
    render() {
        const { ignoreContainerClipping, internalScroll, scrollContainerStyle, isDropDisabled, isCombineEnabled, listId, listType, style, quotes, title, } = this.props;
        return (<Droppable droppableId={listId} type={listType} ignoreContainerClipping={ignoreContainerClipping} isDropDisabled={isDropDisabled} isCombineEnabled={isCombineEnabled}>
                {(dropProvided, dropSnapshot) => (<Wrapper style={style} isDraggingOver={dropSnapshot.isDraggingOver} isDropDisabled={isDropDisabled} isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)} {...dropProvided.droppableProps}>

                            {internalScroll ? (<ScrollContainer style={scrollContainerStyle}>
                                    <InnerList quotes={quotes} title={title} onAdd={this.props.onAdd} onClickItem={this.props.onClickItem} onDelete={this.props.onDelete} dropProvided={dropProvided}/>
                                </ScrollContainer>) : (<InnerList quotes={quotes} onAdd={this.props.onAdd} onClickItem={this.props.onClickItem} onDelete={this.props.onDelete} title={title} dropProvided={dropProvided}/>)}
                        </Wrapper>)}
            </Droppable>);
    }
}
QuoteList.defaultProps = {
    listId: 'LIST',
};
