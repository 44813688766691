import React from 'react';
import _ from 'lodash';
import { AgendaQueryByIdComponent } from '../../generated/graphql';
import { CardContent, Typography, CardActions, Grid } from '@material-ui/core';
function ClienteAgendaDetalhe(props) {
	const { match } = props;
	const { params } = match;
	const listaTarefas = (agendaTarefa) => {
		return agendaTarefa.map((agendaTarefa) => {
			return (<li>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						Responsável: <b>{_.get(agendaTarefa, 'tar.usu.nome', 'N/A')}</b> <br />
						Descrição:
							<b>{_.get(agendaTarefa, 'tar.descricao') || _.get(agendaTarefa, 'tar.tar.descricao')}</b>
						<br />
						Porcentagem: <b>{_.get(agendaTarefa, 'tar.porcentagem', 'N/A')}</b> <br />
						Status: <b>{_.get(agendaTarefa, 'tar.status', 'N/A')}</b>
						<br />
					</Grid>

					<Grid item xs={12} sm={12} md={6} lg={6}>
						Data Início: <b>{_.get(agendaTarefa, 'tar.dataInicio', 'N/A')}</b>
						<br />
						Data Limite: <b>{_.get(agendaTarefa, 'tar.datalimite', 'N/A')}</b>
						<br />
						Data Tarefa: <b>{_.get(agendaTarefa, 'tar.datatarefa', 'N/A')}</b>
						<br />
						Tempo: <b>{_.get(agendaTarefa, 'tar.quantidadeTempo', 'N/A')}</b>
						<br />
					</Grid>
				</Grid>
			</li>);
		});
	};
	return (<React.Fragment>
		<div />
		<AgendaQueryByIdComponent variables={{ id: _.get(params, 'id') }}>
			{({ data, loading }) => {
				let agenda = _.get(data, 'agenda.list[0]');
				console.log(agenda);
				return (<React.Fragment>
					<CardContent>
						<Typography color="textSecondary" gutterBottom>
							<b>Agenda {_.get(agenda, 'titulo')}</b>
						</Typography>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<div className="colRow f-12">
									<ul className="listaHome">
										<li>
											Título: <b>{_.get(agenda, 'titulo')}</b> <br />
											Status: <b>{_.get(agenda, 'status')}</b> <br />
											Início: <b>{new Date(_.get(agenda, 'inicio')).toLocaleString()}</b>
											<br />
											Fim: <b>{_.get(agenda, 'fim') ? (new Date(_.get(agenda, 'fim')).toLocaleString()) : ('Não Informado')}</b>
											<br />
										</li>
									</ul>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<div className="colRow f-12">
									<ul className="listaHome">
										<li>
											Usuário: <b>{_.get(agenda, 'agendaUsuario.usu.nome')}</b> <br />
											Local: <b>{_.get(agenda, 'loc.descricao')}</b> <br />
											Equipe: <b>{_.get(agenda, 'equ.descricao')}</b> <br />
											Fim:
													<b>
												{_.get(agenda, 'fim') ? (new Date(_.get(agenda, 'fim')).toLocaleString()) : ('Não Cadastrado')}
											</b>
											<br />
										</li>
									</ul>
								</div>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Typography color="textSecondary" gutterBottom>
								<b>Tarefas</b>
							</Typography>
							<div className="colRow f-12">
								<ul className="listaHome">{listaTarefas(agenda ? agenda.agendaTarefa : [])}</ul>
							</div>
						</Grid>
					</CardContent>
					<CardActions className="colRow text-center" />
				</React.Fragment>);
			}}
		</AgendaQueryByIdComponent>
	</React.Fragment>);
}
export default ClienteAgendaDetalhe;
