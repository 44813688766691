import { AbaseDialogConfirm, AbaseDialogConsumer, AbaseLoading } from 'abase-components';
import React from 'react';
import { OrdemItemCobrancaDeleteComponent } from '../../generated/graphql';
import { defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
export default function OrdemItemCobrancaDelete(props) {
    const params = props.match.params;
    return (<AbaseDialogConsumer>
            {({ showError, showSnack }) => (<OrdemItemCobrancaDeleteComponent onCompleted={(data) => {
        showSnack(defaultSnack("delete"));
        redirectBackForm(props.history, props.location);
    }} onError={(err) => {
        showError({ message: defaultErrorMessage("delete", err) });
    }}>{(deleteItem, { loading }) => (<React.Fragment>
                    <AbaseDialogConfirm open={true} onCancel={() => redirectBackForm(props.history, props.location)} onConfirm={() => deleteItem({ variables: { ids: params.id.split(",") } })} message={"Tem certeza que deseja excluir o registro?"}></AbaseDialogConfirm>
                    <AbaseLoading open={loading}/>

                </React.Fragment>)}</OrdemItemCobrancaDeleteComponent>)}
        </AbaseDialogConsumer>);
}
