import { AbaseDialogConfirm, AbaseDialogConsumer, AbaseLoading } from 'abase-components';
import React from 'react';
import { UsuarioResetPasswordComponent } from '../../generated/graphql';
import { defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
export default function UsuarioResetPassword(props) {
    const params = props.match.params;
    return (<AbaseDialogConsumer>
        {({ showError, showSnack }) => (<UsuarioResetPasswordComponent onCompleted={(data) => {
            showSnack(defaultSnack("resetPassword"));
            redirectBackForm(props.history, props.location);
        }} onError={(err) => {
            showError({ message: defaultErrorMessage("resetPassword", err) });
        }}>{(resetPassword, { loading }) => (<React.Fragment>
            <AbaseDialogConfirm open={true} onCancel={() => redirectBackForm(props.history, props.location)} onConfirm={() =>
                resetPassword({ variables: { id: params.id } })
            } message={"Tem certeza que deseja resetar a senha do usuário?"}></AbaseDialogConfirm>
            <AbaseLoading open={loading} />

        </React.Fragment>)}
        </UsuarioResetPasswordComponent>)}
    </AbaseDialogConsumer>);
}
