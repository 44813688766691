import { ResponsivePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { TarefaListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import TarefaDelete from "./TarefaDelete";
import TarefaForm from "./TarefaForm";
import TarefaLabel from './TarefaNameLabel';
import { IconButton } from "@material-ui/core";
export default function TarefaList(props) {
    const { match } = props;
    const columns = [
        {
            name: TarefaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: "Tipo",
            field: "tar.descricao",
            sort: false
        },
        {
            name: TarefaLabel.descricao,
            field: "descricao"
        },
        {
            name: TarefaLabel.usu,
            field: "usu.nome",
            sort: false,
            width: 150
        },
        {
            name: "Etapa",
            field: "etapa.descricao",
            customBodyRender: val => val ? val : "N/A",
            sort: false,
            width: 150
        },
        {
            name: TarefaLabel.status,
            field: "status",
            width: 100
        },
        {
            name: "Porcentagem",
            field: "porcentagem",
            customBodyRender: (value) => value ? value + "%" : "N/A",
            width: 100
        },
        {
            name: "Possui Agenda",
            field: "agendaTarefa",
            customBodyRender: (val) => val.length > 0 ? "SIM" : "NÃO",
            width: 60
        },
    ];
    const selectedActions = (selected) => {
        console.log(props);
        if (props.foreing)
            props.foreing(selected);
        return [
            (!match.params.projetoId || match.params.etapaId) && selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" to={`${match.url}/delete/${_.map(selected, "id").join(",")}`} component={Link} />,
            selected.length > 0 ? (<Link to={`/agenda/agenda/add?tarefaIds=${selected.map(x => x.id).join(",")}&urlOrigem=${match.url}&projetoId=${props.projetoId}`} {...props} title={`Gerar Agenda`}>
                <IconButton aria-label={`Gerar Agenda`}>
                    <i className={"material-icons"}>calendar_today</i>
                </IconButton>
            </Link>) : null,
        ];
    };
    const customActions = [
        (<AddAction key="add" to={`${match.url}/add`} component={Link} />),
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descrição"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = TarefaLabel.form;
    return (<React.Fragment>
        <div className={"colDivBloco"}>
            <GraphqlPaperTableListener extraVariables={{
                projetoId: props.projetoId,
                etapaId: match.params.etapaId
            }}>{({ variables, setVariables }) => {
                return (<TarefaListComponent variables={variables} skip={variables === null}>
                    {({ data, loading, refetch, error }) => (<div>

                        <ResponsivePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "tarefa.pagedList.data") || []} total={_.get(data, "tarefa.pagedList.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
                            props.history.push(`${match.url}/edit/${row.id}`);
                        }} />
                        <ReenterRouteListener callback={refetch} />
                        <Route path={`${match.path}/add`} render={RenderWithModal(TarefaForm)} />
                        <Route path={`${match.path}/edit/:id`} render={RenderWithModal(TarefaForm)} />
                        <Route path={`${match.path}/delete/:id`} render={RenderWithModal(TarefaDelete)} />

                    </div>)}
                </TarefaListComponent>);
            }}
            </GraphqlPaperTableListener>
        </div>
    </React.Fragment>);
}
