import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction, AbaseDatePickerField, AbaseAutoCompleteFieldFinalForm } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ProjetoListComponent, AreaAllComponent, EmpresaAllComponent, EquipeAllComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal, { RenderListWithModal } from "../../RenderWithModal";
import ProjetoDelete from "./ProjetoDelete";
import ProjetoLabel from './ProjetoNameLabel';
import { imprimirProjeto } from './ProjetoRelatorio';
import { Tooltip, IconButton } from '@material-ui/core';
import { PrintSharp } from "@material-ui/icons";
import { niveisEnum, getNivel } from "../../services/login";
import { defaultFieldParser } from "../../helpers/finalFormDecorators";
import ClienteProjetoDetalhe from "../HomeClientePage/ClienteProjetoDetalhe";
import { useFiltroGeral } from "../../components/FiltroGeral";
export default function ProjetoList(props) {
    const { match } = props;
    const columns = [
        {
            name: ProjetoLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: ProjetoLabel.liderUsuId,
            field: "liderUsuId",
            defaultVisible: false
        },
        {
            name: ProjetoLabel.responsavelUsuId,
            field: "responsavelUsuId",
            defaultVisible: false
        },
        {
            name: ProjetoLabel.empId,
            field: "empId",
            defaultVisible: false
        },
        {
            name: ProjetoLabel.areId,
            field: "areId",
            defaultVisible: false
        },
        {
            name: ProjetoLabel.equId,
            field: "equId",
            defaultVisible: false
        },
        {
            name: ProjetoLabel.modId,
            field: "modId",
            defaultVisible: false
        },
        {
            name: ProjetoLabel.descricao,
            field: "descricao"
        },
        {
            name: "Cliente Id",
            field: "cli.id",
            defaultVisible: false,
            sort: false
        },
        {
            name: "Cliente",
            field: "cli.nome",
            sort: false
        },
        {
            name: ProjetoLabel.observacao,
            field: "observacao",
            defaultVisible: false
        },
        {
            name: ProjetoLabel.are,
            field: "are.descricao",
            sort: false,
            defaultVisible: false
        },
        {
            name: ProjetoLabel.emp,
            field: "emp.nomefantasia",
            sort: false
        },
        {
            name: 'Parceiro',
            field: "par.nome",
            sort: false,
            width: 150,
        },
        {
            name: ProjetoLabel.equ,
            field: "equ.descricao",
            sort: false,
            defaultVisible: false
        },
        {
            name: ProjetoLabel.prazo,
            field: "prazo",
            width: 100,
            customBodyRender: (val) => (val ? new Date(val).toLocaleDateString() : 'N/A')
        },
        {
            name: ProjetoLabel.dataLimite,
            field: "dataLimite",
            width: 100,
            customBodyRender: (val) => (val ? new Date(val).toLocaleDateString() : 'N/A')
        },
        {
            name: "Porc.",
            field: "porcentagem",
            width: 50,
            customBodyRender: (val) => (val ? val : 0) + "%"
        },
        {
            name: 'Conf.',
            field: "confidencial",
            sort: true,
            width: 50,
            customBodyRender: val => val ? "SIM" : "NÃO"
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            <Tooltip title="Imprimir Projeto">
                <IconButton aria-label="Local" onClick={() => {
                var ids = selected.map(x => x.id);
                imprimirProjeto(ids, 'download');
            }}>
                    <PrintSharp />
                </IconButton>
            </Tooltip>,
            selected.length === 1 ? (<EditAction key="edit" to={`/projeto/projetoform/edit/${selected[0].id}/aba/projeto`} component={Link}/>) : null,
            selected.length === 1 ? <DeleteAction key="delete" component={Link} to={`${match.path}/delete/${_.map(selected, "id").join(",")}`}/> : null,
            selected.length === 1 ? (<Link to={`/cliente/home/agenda/detalhe/${selected[0].id}`} {...props} title={`Visualizar detalhes`}>
                    <IconButton aria-label={`Areas da Equipe`}>
                        <i className={'material-icons'}>info</i>
                    </IconButton>
                </Link>) : null
        ];
    };
    const selectedActionsCliente = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            <Tooltip title="Imprimir Projeto">
                <IconButton aria-label="Local" onClick={() => {
                var ids = selected.map(x => x.id);
                imprimirProjeto(ids, 'download');
            }}>
                    <PrintSharp />
                </IconButton>
            </Tooltip>,
            selected.length === 1 ? (<Link to={`/projeto/projeto/detalhe/${selected[0].id}`} {...props} title={`Detalhes do Projeto`}>
                    <IconButton aria-label={`Detalhes do Projeto`}>
                        <i className={'material-icons'}>info</i>
                    </IconButton>
                </Link>) : null
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`/projeto/projetoform`}/>,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"descricao"} parse={defaultFieldParser}>{({ input, meta }) => <AbaseTextField label={"Descrição"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"observacao"} parse={defaultFieldParser}>{({ input, meta }) => <AbaseTextField label={"Observação"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"prazoMinimo"} parse={defaultFieldParser}>{({ input, meta }) => <AbaseDatePickerField label={"Prazo Mínimo"} {...input} meta={meta}></AbaseDatePickerField>}
        </Field>,
        <Field name={"prazoMaximo"} parse={defaultFieldParser}>{({ input, meta }) => <AbaseDatePickerField label={"Prazo Máximo"} {...input} meta={meta}></AbaseDatePickerField>}
        </Field>,
        <AreaAllComponent>
            {({ data, loading }) => (<Field name="areId">
                    {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={"Área"} valueField={"id"} textField={"descricao"} data={_.get(data, "area.list", [])} {...input}/>)}
                </Field>)}
        </AreaAllComponent>,
        <EmpresaAllComponent>
            {({ data, loading }) => (<Field name="empId">
                    {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={ProjetoLabel.emp} valueField={"id"} textField={"razaosocial"} data={_.get(data, "empresa.list", [])} {...input}/>)}
                </Field>)}
        </EmpresaAllComponent>,
        <EquipeAllComponent>
            {({ data, loading }) => (<Field name="equId">
                    {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={ProjetoLabel.equ} valueField={"id"} textField={"descricao"} data={_.get(data, "equipe.list", [])} {...input}/>)}
                </Field>)}
        </EquipeAllComponent>
    ]);
    const formName = ProjetoLabel.form;
    const nivel = getNivel();
    const { cliente } = useFiltroGeral();
    return (<React.Fragment>
            <Route path={`/projeto/projeto/detalhe/:id`} render={RenderListWithModal(ClienteProjetoDetalhe)}/>
            <GraphqlPaperTableListener extraVariables={{ cliId: cliente }}>{({ variables, setVariables }) => {
        console.log(variables);
        return (<ProjetoListComponent variables={variables} skip={variables === null}>
                        {({ data, loading, refetch, error }) => (<div>
                            {(!match.params.projetoId) ? <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "projeto.pagedList.data") || []} total={_.get(data, "projeto.pagedList.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={nivel === niveisEnum.cliente ? selectedActionsCliente : selectedActions} customActions={nivel === niveisEnum.cliente ? [] : customActions} selectListener={props.selectListener} onDoubleClick={row => {
            nivel === niveisEnum.cliente ?
                props.history.push(`/projeto/projeto/detalhe/${row.id}`) :
                props.history.push(`/projeto/projetoform/edit/${row.id}/aba/projeto`);
        }}/>
            : ''}
                            <Route path={`${match.path}/delete/:projetoId`} render={RenderWithModal(ProjetoDelete)}/>
                            <ReenterRouteListener callback={refetch}/>

                        </div>)}
                    </ProjetoListComponent>);
    }}
            </GraphqlPaperTableListener>
        </React.Fragment>);
}
;
