import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseCheckbox } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { RequisicaoTipoQueryByIdComponent, useRequisicaoTipoCreate, useRequisicaoTipoUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import RequisicaoTipoLabel from './RequisicaoTipoNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { Grid, withStyles } from '@material-ui/core';
import DynamicFormEditor from '../../components/DynamicFormEditor';
import withIsMobile from "../../components/withIsMobile";
const RequisicaoTipoSchema = Yup.object()
    .shape({
    id: Yup.string().notRequired(),
    usuId: Yup.string().notRequired(),
    descricao: Yup.string().required(),
    formulario: Yup.string().required(),
    precisaHotel: Yup.bool().notRequired()
})
    .noUnknown();
function RequisicaoTipoForm(props) {
    const { match, history, location } = props;
    const { classes } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useRequisicaoTipoUpdate();
    const insert = useRequisicaoTipoCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(RequisicaoTipoSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.requisicaoTipo.update.id'));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.requisicaoTipo.create.id'));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    // var initial: any[] = [];
    return (<RequisicaoTipoQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
    }}>
			{({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(RequisicaoTipoSchema)} initialValues={_.get(data, 'requisicaoTipo.list[0]') || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Tipo de Requisição" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
							<AbaseLoading open={loading || submitting}/>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Field name="descricao" parse={defaultFieldParser}>
										{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RequisicaoTipoLabel.descricao}/>)}
									</Field>
								</Grid>
								<Grid item xs={12}>
									<Field name="precisaHotel">
										{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={RequisicaoTipoLabel.PrecisaHotel}/>)}
									</Field>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<div className={classes.grids}>
										<Field name="formulario">
											{({ input, meta }) => {
        return <div className={"colDivBloco p0"}><DynamicFormEditor {...input}/></div>;
    }}
										</Field>
									</div>
								</Grid>
							</Grid>
						</DialogForm>)}/>)}
		</RequisicaoTipoQueryByIdComponent>);
}
const styles = {
    grids: {
        width: '100%',
        padding: '15px',
        marginTop: '15px',
        display: 'inline-block',
        backgroundColor: '#f8f8f8',
        border: '1px solid #e0e0e0',
        '& label': {
            backgroundColor: '#f8f8f8',
        },
        '& input': {
            backgroundColor: '#f8f8f8',
        },
        '& textarea': {
            backgroundColor: '#f8f8f8',
        },
    }
};
export default withIsMobile(withStyles(styles)(RequisicaoTipoForm));
