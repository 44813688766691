import React, { createElement } from 'react';
import { Dialog } from '@material-ui/core';
import DialogList from './DialogList';
function ModalComponentWrapper(props) {
    return <Dialog open={true} fullScreen={props.fullScreen}>{createElement(props.component, props)}</Dialog>;
}
export default function RenderWithModal(component, onSave, fullScreen) {
    return (props) => <ModalComponentWrapper component={component} {...props} fullScreen={fullScreen} onSave={onSave}/>;
}
export function RenderListWithModal(component, fullScreen) {
    return (props) => <DialogList fullScreen={fullScreen}>{createElement(component, props)}</DialogList>;
}
