const names = {
    form: 'Contatos da Conta',
    id: 'ID',
    contaId: 'Conta ID',
    idcontatoIntegracao: 'Contato Integração ID',
    nome: 'Nome',
    email: 'E-mail',
    telefone: 'Telefone',
    setor: 'Setor',
    cargo: 'Cargo',
    conta: 'Conta'
};
export default names;
