var CACHE_NAME = 'cache-v1';
var urlsToCache = [
    '/',
];


const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export default function register() {
    if ('serviceWorker' in navigator) {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location)
        if (publicUrl.origin !== window.location.origin) {
            return
        }


        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

            if (!isLocalhost) {
                // Is not local host. Just register service worker
                registerValidSW(swUrl)
            } else {
                // This is running on localhost. Lets check if a service worker still exists or not.
                // por hora não quero service worker em localhost
                // checkValidServiceWorker(swUrl)
            }
        })

        window.self.addEventListener('pushMessage', function (event) {
            console.log('[Service Worker] Push Received.');
            console.log(`[Service Worker] Push had this data: "${event.data.text()}"`);

            const title = 'Push Codelab';
            const options = {
                body: 'Yay it works.',
                icon: 'images/icon.png',
                badge: 'images/badge.png'
            };

            event.waitUntil(window.registration.showNotification(title, options));
        });

    } else {
    }
}

function registerValidSW(swUrl, config) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            registration.onupdatefound = () => {
                console.log('teve atualização')
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // At this point, the updated precached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed.
                            console.log(
                                'New content is available and will be used when all ' +
                                'tabs for this page are closed. See https://bit.ly/CRA-PWA.',
                            );

                            // Execute callback
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log('Content is cached for offline use.');

                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
            registration.addEventListener('pushMessage', function (event) {
                console.log('[Service Worker] Push Received.');
                console.log(`[Service Worker] Push had this data: "${event.data.text()}"`);

                const title = 'Push Codelab';
                const options = {
                    body: 'Yay it works.',
                    icon: 'images/icon.png',
                    badge: 'images/badge.png'
                };

                event.waitUntil(window.registration.showNotification(title, options));
            });
        })
        .catch(error => {
            console.error('Error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
        .then(response => {
            // Ensure service worker exists, and that we really are getting a JS file.
            if (
                response.status === 404 ||
                response.headers.get('content-type').indexOf('javascript') === -1
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        window.location.reload()
                    })
                })
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl)
            }
        })
        .catch(() => {
            console.log(
                'No internet connection found. App is running in offline mode.'
            )
        })
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister()
        })
    }
}