import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ProjetoApontamentoListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import ProjetoApontamentoDelete from "./ProjetoApontamentoDelete";
import ProjetoApontamentoForm from "./ProjetoApontamentoForm";
import ProjetoApontamentoLabel from "./ProjetoApontamentoNameLabel";
import { formatDate } from "../../helpers/format";
export default function ProjetoApontamentoList(props) {
    const { match } = props;
    const columns = [
        {
            name: ProjetoApontamentoLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: ProjetoApontamentoLabel.proId,
            field: "proId",
            defaultVisible: false
        },
        {
            name: ProjetoApontamentoLabel.usuId,
            field: "usuId",
            defaultVisible: false
        },
        {
            name: ProjetoApontamentoLabel.descricao,
            field: "descricao"
        },
        {
            name: ProjetoApontamentoLabel.data,
            field: "data",
            customBodyRender: (value) => {
                return formatDate(value);
            },
            width: 100
        },
        {
            name: ProjetoApontamentoLabel.confidencial,
            field: "confidencial",
            customBodyRender: (value) => {
                return value ? "SIM" : "NÃO";
            },
            width: 100
        },
        {
            name: ProjetoApontamentoLabel.pro,
            field: "pro.descricao",
            sort: false,
            defaultVisible: false
        },
        {
            name: ProjetoApontamentoLabel.usu,
            field: "usu.nome",
            sort: false,
            width: 150
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link}/>) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/delete/${_.map(selected, "id").join(",")}`}/>
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.url}/add`}/>
    ];
    const filters = () => [
        <Field name={"id"}>
      {({ input, meta }) => (<AbaseTextField label={"ID"} {...input} meta={meta}/>)}
    </Field>,
        <Field name={"descricao"}>
      {({ input, meta }) => (<AbaseTextField label={"Descricao"} {...input} meta={meta}/>)}
    </Field>
    ];
    return (<React.Fragment>
      <div className={"colDivBloco"}>
        <GraphqlPaperTableListener extraVariables={{ projetoId: props.projetoId }}>
          {({ variables, setVariables }) => {
        return (<ProjetoApontamentoListComponent variables={variables} skip={variables === null}>
                {({ data, loading, refetch, error }) => (<div>
                    <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "projetoApontamento.pagedList.data") || []} total={_.get(data, "projetoApontamento.pagedList.total")} columns={columns} customFilters={filters} title={`Apontamentos do projeto`} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
            props.history.push(`${match.url}/edit/${row.id}`);
        }}/>
                    <ReenterRouteListener callback={refetch}/>
                    <Route path={`${match.path}/add`} render={RenderWithModal(ProjetoApontamentoForm)}/>
                    <Route path={`${match.path}/edit/:apontamentoId`} render={RenderWithModal(ProjetoApontamentoForm)}/>
                    <Route path={`${match.path}/delete/:apontamentoId`} render={RenderWithModal(ProjetoApontamentoDelete)}/>
                  </div>)}
              </ProjetoApontamentoListComponent>);
    }}
        </GraphqlPaperTableListener>
      </div>
    </React.Fragment>);
}
