const names = {
    "form": "Usuário Equipe",
    "id": "ID",
    "equId": "Equipe ID",
    "usuId": "Usuário ID",
    "equ": "Equipe",
    "usu": "Usuário",
    "emp": "Empresa"
};
export default names;
