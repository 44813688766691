import React from 'react';
import { LogAuditoriaQueryByIdComponent } from '../../generated/graphql';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import MuiTreeView from 'material-ui-treeview';
export default function LogAuditoriaDetalhes(props) {
    const { match } = props;
    const addTree = function (dados, arvore) {
        // eslint-disable-next-line array-callback-return
        Object.keys(dados).map((key) => {
            if (dados[key])
                if (typeof dados[key] !== 'object')
                    arvore.push({
                        value: `${key}: ${dados[key]}`
                    });
                else {
                    var nodos = [];
                    addTree(dados[key], nodos);
                    arvore.push({
                        value: key,
                        nodes: nodos
                    });
                }
        });
    };
    return (<LogAuditoriaQueryByIdComponent variables={{ id: match.params.id }}>
			{({ data, loading }) => {
        const dados = JSON.parse(_.get(data, 'logAuditoria.list[0].json', '{}'));
        const mensagem = _.get(data, 'logAuditoria.list[0].mensagem', 'N/A');
        const hora = _.get(data, 'logAuditoria.list[0].data', 'N/A');
        const nomeUsuario = _.get(data, 'logAuditoria.list[0].nomeUsuario', 'N/A');
        var arvore = [
            {
                value: `Mensagem: ${mensagem}`
            },
            {
                value: `Momento: ${new Date(hora).toLocaleString()}`
            },
            {
                value: `Nome Usuário: ${nomeUsuario}`
            }
        ];
        addTree(dados, arvore);
        return !loading ? (<div className="colRow f-12">
						<Typography color="textSecondary" gutterBottom>
							<b>Detalhes do Log</b>
						</Typography>
						<MuiTreeView tree={arvore} listItemProps={{ dense: true, divider: true, className: 'listaHome', disableGutters: true }}/>
					</div>) : null;
    }}
		</LogAuditoriaQueryByIdComponent>);
}
