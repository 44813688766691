import React from 'react';
import { AbaseAutocompleteField } from 'abase-components';
const fixTypescript = AbaseAutocompleteField;
const CardContato = ({ data, ...otherProps }) => {
    let style = { borderBottom: "1px dashed #cccccc", width: "100%", fontSize: '12px', padding: '5px 0' };
    const cssInativo = { color: "#898989" };
    if (data.ativo === false) {
        style = { ...style, ...cssInativo };
    }
    if (data.clienteContato) {
        data = data.clienteContato;
    }
    return (<fixTypescript.Components.Option {...otherProps}>
            <div style={style}>
                <strong>Nome</strong>: {data.nome}<br />
                <strong>Contato:</strong>: {data.telefone}{data.telefone && data.email ? " / " : null}{data.email}<br />
                <strong>Setor:</strong>{data.setor || "N/A"}&nbsp;<strong>Cargo:</strong>{data.cargo || "N/A"} {data.ativo === false ? <strong>(inativo)&nbsp;</strong> : null}
            </div>
        </fixTypescript.Components.Option>);
};
export default CardContato;
