const names = {
    "form": "Contas",
    "id": "ID",
    "idcontaIntegracao": "Conta Integração ID",
    "origemconta": "Origem",
    "nomerazaosocial": "Nome/Razão Social",
    "nomefantasia": "Nome Fantasia",
    "cpfcnpj": "CPF/CNPJ",
    "tipopessoa": "Tipo de Pessoa",
    "ativo": "Ativo",
    "telefone": "Telefone",
    "email": "E-mail",
    "inscricaoestadual": "Inscrição Estadual",
    "datanascimentofundacao": "Nascimento/Fundação",
    "observacao": "Observação",
    "datacriacao": "Criação do Registro",
    "cep": "CEP",
    "logradouro": "Logradouro",
    "bairro": "Bairro",
    "cidade": "Cidade",
    "uf": "UF",
    "complemento": "Complemento",
    "numero": "Número",
    "cliente": "Cliente",
    "contasContatos": "Contas do Contatos",
};
export default names;
