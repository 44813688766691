import { AbaseLoading, AbaseTextField, AbaseCheckbox, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { RestauranteQueryByIdComponent, useRestauranteCreate, useRestauranteUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import RestauranteLabel from './RestauranteNameLabel';
import { Grid } from '@material-ui/core';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { MaskTelefone } from '../../helpers/mask';
import Slider from '@material-ui/core/Slider';
import LatLngPicker from '../../components/LatLngPicker';
import { Money } from 'walletjs';
const RestauranteSchema = Yup.object()
    .shape({
        id: Yup.string().notRequired(),
        nome: Yup.string().required(),
        telefone: Yup.string().notRequired(),
        email: Yup.string().notRequired(),
        site: Yup.string().notRequired(),
        cidade: Yup.string().required(),
        endereco: Yup.string().notRequired(),
        celular: Yup.string().notRequired(),
        tipo: Yup.string().notRequired(),
        referencia: Yup.string().notRequired(),
        preferencia: Yup.number().notRequired(),
        cafeManha: Yup.bool().notRequired(),
        janta: Yup.bool().notRequired(),
        almoco: Yup.bool().notRequired(),
        precoKg: Yup.number().notRequired(),
        precoBuffetLivre: Yup.number().notRequired(),
        posicaoGeografica: Yup.object()
            .shape({
                x: Yup.number().required(),
                y: Yup.number().required()
            })
            .noUnknown()
    })
    .noUnknown();
function RestauranteForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useRestauranteUpdate();
    const insert = useRestauranteCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(RestauranteSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.restaurante.update.id'));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.restaurante.create.id'));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    let [precoBuffetLivre, setPrecoBuffetLivre] = React.useState(0);
    let [precoKg, setPrecoKg] = React.useState(0);
    return (<RestauranteQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(RestauranteSchema)} initialValues={_.get(data, 'restaurante.list[0]') || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Restaurante" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
            <Grid container spacing={1}>
                <AbaseLoading open={loading || submitting} />
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="nome" parse={defaultFieldParser}>
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RestauranteLabel.nome} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="email" parse={defaultFieldParser}>
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RestauranteLabel.email} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Field name="telefone">
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RestauranteLabel.telefone} InputProps={{
                            inputComponent: MaskTelefone
                        }} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Field name="celular">
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RestauranteLabel.celular} InputProps={{
                            inputComponent: MaskTelefone
                        }} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Field name="tipo" parse={defaultFieldParser}>
                        {({ input, meta }) => {
                            var tipos = [
                                { nome: 'SORVETERIA' },
                                { nome: 'TEMÁTICO' },
                                { nome: 'BAR' },
                                { nome: 'BISTRO' },
                                { nome: 'CANTINA' },
                                { nome: 'CHURRASCARIA' },
                                { nome: 'BUFFET' },
                                { nome: 'DOÇARIA' },
                                { nome: 'DRIVE-IN' },
                                { nome: 'FOOD TRUCK' },
                                { nome: 'LANCHONETE' },
                                { nome: 'PIZZARIA' },
                                { nome: 'RANCHO' },
                                { nome: 'FAST FOOD' },
                                { nome: 'CAFÉ' }
                            ];
                            return (<AbaseAutoCompleteFieldFinalForm data={tipos} label={RestauranteLabel.tipo} valueField="nome" textField="nome" {...input} />);
                        }}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Field name="site">
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RestauranteLabel.site} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Field name="preferencia">
                        {({ input, meta }) => {
                            if (input.value > 10) {
                                input.onChange(10);
                            }
                            else if (input.value < 0) {
                                input.onChange(0);
                            }
                            return (<React.Fragment>
                                <Grid container spacing={0}>
                                    <Grid item xs={5}>
                                        <AbaseTextField label="Preferência" {...input} type="number" />
                                    </Grid>
                                    <Grid item xs={7} style={{ marginTop: '29px', paddingLeft: '15px' }}>
                                        <Slider min={0} max={10} step={1} value={input.value} onChange={(event, value) => input.onChange(value)} />
                                    </Grid>
                                </Grid>
                            </React.Fragment>);
                        }}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="precoBuffetLivre" formatOnBlur={precoBuffetLivre > 0} format={(val) => {
                        if (val) {
                            val = Money.init(val).getValue();
                            setPrecoBuffetLivre(precoBuffetLivre + 1);
                        }
                        return val;
                    }}>
                        {({ input, meta }) => (<AbaseTextField type="number" meta={meta} fullWidth={true} {...input} label={RestauranteLabel.precoBuffetLivre} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="precoKg" formatOnBlur={precoKg > 0} format={(val) => {
                        if (val) {
                            val = Money.init(val).getValue();
                            setPrecoKg(precoKg + 1);
                        }
                        return val;
                    }}>
                        {({ input, meta }) => (<AbaseTextField type="number" meta={meta} fullWidth={true} {...input} label={RestauranteLabel.precoKg} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="endereco" parse={defaultFieldParser}>
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RestauranteLabel.endereco} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="referencia" parse={(val) => val.toUpperCase()}>
                        {({ input, meta }) => {
                            return (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RestauranteLabel.referencia} />);
                        }}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="cidade" parse={defaultFieldParser}>
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RestauranteLabel.cidade} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={0}>
                        <Field name="almoco">
                            {({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={RestauranteLabel.almoco} />)}
                        </Field>
                        <Field name="janta">
                            {({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={RestauranteLabel.janta} />)}
                        </Field>
                        <Field name="cafeManha">
                            {({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={RestauranteLabel.cafeManha} />)}
                        </Field>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Field name="posicaoGeografica">
                        {({ input, meta }) => (<React.Fragment>
                            <Grid item xs={12}>
                                <LatLngPicker showTextfield value={input.value} onChange={input.onChange} />
                            </Grid>
                        </React.Fragment>)}
                    </Field>
                </Grid>
            </Grid>
        </DialogForm>)} />)}
    </RestauranteQueryByIdComponent>);
}
export default RestauranteForm;
