import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import DialogForm from '../../DialogForm';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { ModeloTermoAceiteQueryByIdComponent, useModeloTermoAceiteCreate, useModeloTermoAceiteUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import ModeloTermoAceiteLabel from './ModeloTermoAceiteNameLabel';
const ModeloTermoAceiteSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string().required(),
    modelo: Yup.string().required(),
}).noUnknown();
function ModeloTermoAceiteForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useModeloTermoAceiteUpdate();
    const insert = useModeloTermoAceiteCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(ModeloTermoAceiteSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.modeloTermoAceite.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.modeloTermoAceite.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<ModeloTermoAceiteQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
            {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(ModeloTermoAceiteSchema)} initialValues={_.get(data, "modeloTermoAceite.list[0]") || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Modelo de Termo de Aceite" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                            <AbaseLoading open={loading || submitting}/>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field name="nome">{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ModeloTermoAceiteLabel.nome}/>)}</Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field name="modelo">{({ input, meta }) => (
    // <JsReportDesign {...input} dataSet={dataSetJsReportDesigns} />
    <AbaseTextField meta={meta} fullWidth={true} {...input} label={ModeloTermoAceiteLabel.modelo}/>)}</Field>
                                </Grid>
                            </Grid>
                        </DialogForm>)}/>)}
        </ModeloTermoAceiteQueryByIdComponent>);
}
export default ModeloTermoAceiteForm;
