import './YupTraducao';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { AbaseDialogProvider } from "abase-components";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptBR from "date-fns/locale/pt-BR";
import React from 'react';
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import { client } from './apollo';
import App from './App';
import browserHistory from './browserHistory';
import NotFoundPage from './pages/404Page';
import register, { unregister } from './registerServiceWorker';
import rotas from "./rotas";
import { FiltroGeralProvider } from './components/FiltroGeral';
import moment from 'moment';
import 'moment/locale/pt-br';
import { getNivel } from './services/login';
moment.locale('pt-br');
const theme = createMuiTheme({
  direction: 'ltr',
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#3f51b5',
      light: '#e0e0e0',
      contrastText: '#fff',
      dark: '3f51b5',
    },
    secondary: {
      main: '#3f51b5',
      light: '#e0e0e0',
      contrastText: '#fff',
      dark: '3f51b5'
    },
  },
});
const rootElement = document.getElementById('root');
const nivel = getNivel();
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + (h * 60 * 60 * 1000));
  return this;
}
ReactDOM.render(<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
  <MuiThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <AbaseDialogProvider>
          <FiltroGeralProvider>
            <Router history={browserHistory}>
              <App>
                <Switch>
                  {rotas.map((rota, index) => {
                    if (rota.nivel) {
                      if (rota.nivel.includes(nivel)) {
                        return <Route key={rota.path + index} path={rota.path} exact={rota.exact} component={rota.component} />;
                      } else return null;
                    }
                    else
                      return <Route key={rota.path + index} path={rota.path} exact={rota.exact} component={rota.component} />;
                  })}
                  <Route key="404" component={NotFoundPage} />
                </Switch>
              </App>
            </Router>
          </FiltroGeralProvider>
        </AbaseDialogProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  </MuiThemeProvider>

</MuiPickersUtilsProvider>, rootElement);
// unregister(); //remove o registro do service worker.
navigator.serviceWorker.getRegistration().then(x => {
  if (x) {
    console.log('E o X da questão é: ', x)
    switch (x.active.state) {
      case 'activated': break;
      case 'activating': break;
      case 'installed': break;
      case 'installing': break;
      default:
        register();
        break;
    }
  } else {
    register();
  }
})
