import { Button, Grid } from '@material-ui/core';
import { FormDraft, useAbaseDialog } from 'abase-components';
import React from 'react';
import { Field } from 'react-final-form';
import AutoCompleteCliente from '../../components/AutoCompleteCliente';
import DialogForm from '../../DialogForm';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import OrdemServicoLabel from './OrdemServicoNameLabel';
import { imprimirOrdemServicoCliente } from './OrdemServicoRelatorio';
function OrdemServicoRelatorioCliente(props) {
    const { history, location } = props;
    const { showError, showSnack } = useAbaseDialog();
    const handleSubmit = async (values) => {
        try {
            if (!values.cli) {
                return;
            }
            imprimirOrdemServicoCliente(values.cli.id);
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const handleClose = () => redirectBackForm(history, location);
    return (<FormDraft useDraft={false} onSubmit={handleSubmit} initialValues={{}} decorators={defaultDecorators} subscription={{
        submitting: true,
        initialValues: true
    }} render={({ handleSubmit, values, form, submitting }) => (<DialogForm title="Relatório de Ordem de Serviço" onSubmit={handleSubmit} actions={<Grid container spacing={0}>
        <Grid item xs={12} className="center mb15">
            <Button onClick={handleClose} key="cancelar" variant="contained">
                Cancelar
							<i className="material-icons">close</i>
            </Button>

            <Button type="button" variant="contained" className="btnSuccess ml5" key="submit" onClick={() => {
                form.change("action", "print");
                handleSubmit();
            }}>
                Imprimir
			<i className={"material-icons"}>print</i>
            </Button>
        </Grid>
    </Grid>}>
        <Grid container spacing={1}>


            <Grid item xs={12}>

                <Field name="cli">
                    {({ input }) => (<AutoCompleteCliente fullWidth label={OrdemServicoLabel.cli} {...input} />)}
                </Field>
            </Grid>

        </Grid>
    </DialogForm>)} />);
}
export default OrdemServicoRelatorioCliente;
