import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { ContasContatosQueryByIdComponent, useContasContatosCreate, useContasContatosUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import ContasContatosLabel from './ContasContatosNameLabel';
import queryString from 'query-string';
import { MaskTelefone } from '../../helpers/mask';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { Grid } from '@material-ui/core';
const ContasContatosSchema = Yup.object()
    .shape({
    id: Yup.string().notRequired(),
    contaId: Yup.string().required(),
    nome: Yup.string().required(),
    email: Yup.string().required(),
    telefone: Yup.string().required(),
    setor: Yup.string().required(),
    cargo: Yup.string().required()
})
    .noUnknown();
function ContasContatosForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useContasContatosUpdate();
    const insert = useContasContatosCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(ContasContatosSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.contasContatos.update.id'));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.contasContatos.create.id'));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = false;
    let queryStringParams = queryString.parse(props.location.search);
    return (<ContasContatosQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
        redirectBackForm(history, location);
    }}>
			{({ data, loading, refetch, error }) => (<FormDraft useDraft={isUsingDraft} onSubmit={handleSubmit} validate={validate(ContasContatosSchema)} initialValues={_.get(data, 'contasContatos.list[0]') || {
        contaId: queryStringParams.conta
    }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Contato da Conta" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
							<AbaseLoading open={loading || submitting}/>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Field name="nome" parse={defaultFieldParser}>
										{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasContatosLabel.nome}/>)}
									</Field>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Field name="email" parse={defaultFieldParser}>
										{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasContatosLabel.email}/>)}
									</Field>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Field name="telefone">
										{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} InputProps={{
        inputComponent: MaskTelefone
    }} label={ContasContatosLabel.telefone}/>)}
									</Field>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Field name="setor" parse={defaultFieldParser}>
										{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasContatosLabel.setor}/>)}
									</Field>
								</Grid>
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Field name="cargo" parse={defaultFieldParser}>
										{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasContatosLabel.cargo}/>)}
									</Field>
								</Grid>
							</Grid>
						</DialogForm>)}/>)}
		</ContasContatosQueryByIdComponent>);
}
export default ContasContatosForm;
