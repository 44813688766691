import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router';
import { redirectBackForm } from './helpers';
function DialogList(props) {
    const onClose = () => {
        if (!props.dontUseRoute)
            redirectBackForm(props.history, props.location);
        if (props.onClose)
            props.onClose();
    };
    const { children } = props;
    const actions = props.actions ? props.actions : [<Grid container spacing={0}/>];
    return (<Dialog open onClose={onClose} aria-labelledby="scroll-dialog-title" scroll={'paper'} maxWidth={false} fullWidth fullScreen={props.fullScreen}>
			<IconButton onClick={onClose} style={{ position: 'absolute', right: '10px', top: '10px' }}>
				<i className="material-icons" title="Fechar">
					close
				</i>
			</IconButton>
			<DialogTitle id="form-dialog-title"> {props.title} </DialogTitle>
			<DialogContent key="listagem" style={{ minWidth: '100%', minHeight: 150, overflowY: 'inherit', marginTop: '20px' }}>
				{children}
			</DialogContent>
			<DialogActions key="acoes">{actions}</DialogActions>
		</Dialog>);
}
export default withRouter(DialogList);
