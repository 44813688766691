/* eslint-disable no-unused-vars */
import { AbaseAutoCompleteFieldFinalForm } from "abase-components";
import _ from "lodash";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Route } from "react-router";
import { FiltroGeralConsumer } from "../../components/FiltroGeral";
import { AgendaRecursoCalendarioComponent, RecursoTipoAllComponent, RecursoAllComponent } from "../../generated/graphql";
import { redirectBackForm } from "../../helpers";
import { formatDateTime } from '../../helpers/format';
import ReenterRouteListener from "../../ReenterRouteListener";
import Typography from '@material-ui/core/Typography';
import CalendarRecurso from '../../components/CalendarRecurso';
import { Grid, withStyles, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import moment from "moment";
import AddIcon from '@material-ui/icons/Add';
import { Button } from "semantic-ui-react";
function InfoItem({ data, match, history, location }) {
    const onClose = () => {
        redirectBackForm(history, location);
    };
    const id = match.params.id;
    const item = _.first(data.filter(x => x.id === id));
    return (<Dialog disableRestoreFocus open={true} onClose={onClose} aria-labelledby="scroll-dialog-title" scroll={'paper'} className="dialogs">
        <DialogTitle id="form-dialog-title">
            <Typography color="textSecondary" gutterBottom>
                <b>Detalhes</b>
            </Typography>
        </DialogTitle>
        <DialogContent key="formulario" style={{ minWidth: 600, minHeight: 193, marginTop: '-15px', overflowY: 'scroll' }}>
            <div className="colRow f-12">
                <ul className="listaHome">
                    <li>
                        <p><strong>Recurso:</strong> {_.get(item, "rec.nome")}</p>
                        <p><strong>Usuário:</strong> {_.get(item, "age.usu.nome")}</p>
                        <p><strong>Tipo de agenda:</strong> {_.get(item, "age.agendaTipo.descricao")}</p>
                        <p><strong>Título:</strong> {_.get(item, "age.titulo")}</p>
                        <p><strong>Início:</strong> {formatDateTime(_.get(item, "age.inicio"))}</p>
                        <p><strong>Fim:</strong> {_.get(item, "age.fim") ? formatDateTime(_.get(item, "age.fim")) : "-"}</p>
                        <p><strong>Local:</strong> {_.get(item, "age.loc.descricao")}</p>
                    </li>
                </ul>
            </div>
        </DialogContent>
        <DialogActions key="acoes">{<Grid container spacing={0}>
            <Grid item xs={12} className="center mb15">
                <Button onClick={onClose} key="cancelar" variant="contained">
                    Fechar
                    </Button>
            </Grid>
        </Grid>}</DialogActions>
    </Dialog>);
}
function AgendaCalendar(props) {
    const { match, classes } = props;
    const [startDate, setStartDate] = useState(moment().startOf('month').toDate().toISOString());
    const [showFilter, setShowFilter] = useState(false);
    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };
    const [endDate, setEndDate] = useState(moment().endOf('month').toDate().toISOString());
    const startMinhas = [];
    const startOutras = [];
    const [filtrosMinhasTarefas, setFiltrosMinhasTarefas] = React.useState(startMinhas);
    const [filtrosOutrasTarefas, setFiltrosOutrasTarefas] = React.useState(startOutras);
    const [tipoRecursoId, setTipoRecursoId] = React.useState("");

    return (<div className="colCalendar">

        <Form onSubmit={() => {
        }} render={({ handleSubmit, form, submitting, values }) => (<FiltroGeralConsumer>{(filtroGeral) => (<AgendaRecursoCalendarioComponent variables={{
            dataInicio: startDate,
            dataFim: endDate,
            recursoTipoId: values.recursoTipoId,
            recursoId: values.recursoId
            // empId: _.get(filtroGeral, "empresa.id"),
            // areId: _.get(filtroGeral, "area.id"),
            // equId: _.get(filtroGeral, "equipe.id"),
            // usuId: _.get(filtroGeral, "usuario.id"),
            // tiposMinhasTarefas: filtrosMinhasTarefas,
            // tiposOutrasTarefas: filtrosOutrasTarefas,
        }}>
            {({ data, loading, refetch, error }) => (<div className="colHeight">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <RecursoTipoAllComponent>
                                    {({ data, loading }) => (<Field name="recursoTipoId">
                                        {({ input, meta }) => {
                                            return (<AbaseAutoCompleteFieldFinalForm isLoading={loading} valueField={"id"} textField={"descricao"} label={"Tipo de recurso"} data={_.get(data, "recursoTipo.list", [])} {...input} />)
                                        }}</Field>)}
                                </RecursoTipoAllComponent>
                            </Grid>
                            <Grid item xs={5}>
                                <RecursoAllComponent variables={{ recursoTipoId: _.get(form.getFieldState("recursoTipoId"), 'value', null) }}>
                                    {({ data, loading }) => (<Field name="recursoId">
                                        {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} valueField={"id"} textField={"nome"} label={"Recurso"} data={_.get(data, "recurso.list", [])} {...input} />)}</Field>)}
                                </RecursoAllComponent>
                            </Grid>
                            <Grid item xs={2} className="right">
                                <Button size="small" className="btnSuccess btAdd" onClick={() => { props.history.push(`/agenda/agenda/add`); }}>
                                    <AddIcon /> Adicionar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mt15">
                        <CalendarRecurso events={_.get(data, "agendaRecurso.list", [])} onSelectEvent={item => {
                            props.history.push(`${match.url}/info/${item.id}`);
                        }} onRangeChange={({ start, end }) => {
                            setStartDate(start);
                            setEndDate(end);
                        }} />
                    </Grid>
                </Grid>
                <ReenterRouteListener callback={refetch} />
                <Route path={`${match.url}/info/:id`} render={props => <InfoItem {...props} data={_.get(data, "agendaRecurso.list", [])} />} />
            </div>)}
        </AgendaRecursoCalendarioComponent>)}</FiltroGeralConsumer>)}></Form>
    </div>);
}

const styles = {
    checkBox: {
        padding: 0
    },
    menuItem: {
        paddingTop: 0,
        paddingBottom: 0
    }
};

export default withStyles(styles)(AgendaCalendar);