import { AbasePaperTable, AbaseDateTimePickerField, AbaseAutoCompleteFieldFinalForm, AbaseTextField } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import LogAuditoriaLabel from "./LogAuditoriaNameLabel";
import { AsyncRequest } from "../../services/request";
import { RenderListWithModal } from "../../RenderWithModal";
import LogAuditoriaDetalhes from "./LogAuditoriaDetalhes";
import { Tooltip, IconButton } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { UsuarioAllComponent } from "../../generated/graphql";
export default function LogAuditoriaList(props) {
    const { match } = props;
    let [loadingTable, setLoadingTable] = React.useState(true);
    const columns = [
        {
            name: LogAuditoriaLabel.mensagem,
            field: "mensagem",
            width: "60%",
            customBodyRender: val => (val ? val : "N/A")
        },
        {
            name: "Usuario",
            field: "nomeUsuario",
        },
        {
            name: LogAuditoriaLabel.usuId,
            field: "usuId",
            defaultVisible: false
        },
        {
            name: LogAuditoriaLabel.data,
            field: "data",
            customBodyRender: data => new Date(data).toLocaleString()
        }
    ];
    const selectedActions = (selected) => {
        return [
            selected.length === 1 ? (<Tooltip title="Detalhes">
                <Link to={`${match.path}/detalhes/${selected[0].id}`}>
                    <IconButton aria-label="Detalhes">
                        <Info />
                    </IconButton>
                </Link>
            </Tooltip>) : null
        ];
    };
    const filters = () => [
        <UsuarioAllComponent>
            {({ data, loading }) => (<Field name="usuarioId">
                {({ input }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={"Usuário"} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input} />)}
            </Field>)}
        </UsuarioAllComponent>,
        <Field name={"mensagem"}>
            {({ input, meta }) => (<AbaseTextField label={"mensagem"} {...input} meta={meta} />)}
        </Field>,
        <Field name={"dataInicio"}>
            {({ input, meta }) => (<AbaseDateTimePickerField label={"Data Início"} {...input} meta={meta} />)}
        </Field>,
        <Field name={"dataFim"}>
            {({ input, meta }) => (<AbaseDateTimePickerField label={"Data Fim"} {...input} meta={meta} />)}
        </Field>,
        <Field name={"operacao"}>
            {({ input }) => {
                var tipos = [
                    { nome: "Insert", value: 1 },
                    { nome: "Update", value: 2 },
                    { nome: "Delete", value: 3 },
                    { nome: "TentativaLogin", value: 4 },
                    { nome: "Visualização", value: 5 }
                ];
                return (<AbaseAutoCompleteFieldFinalForm data={tipos} label={"Operação"} valueField="value" textField="nome" {...input} />);
            }}
        </Field>,
        <Field name={"resultado"}>
            {({ input }) => {
                var tipos = [
                    { nome: "Sucesso" },
                    { nome: "Falha" },
                    { nome: "Proibido" },
                    { nome: "Bloqueado" },
                    { nome: "Erro" }
                ];
                return (<AbaseAutoCompleteFieldFinalForm data={tipos} label={"Resultado"} valueField="nome" textField="nome" {...input} />);
            }}
        </Field>,
    ];
    const formName = LogAuditoriaLabel.form;
    const buscarLogs = function (data) {
        if (!loadingTable) {
            setLoadingTable(true);
        }
        data = {
            ...data,
            Operacao: _.get(data, "filters.operacao"),
            Resultado: _.get(data, "filters.resultado"),
            Inicio: _.get(data, "filters.dataInicio"),
            Fim: _.get(data, "filters.dataFim"),
            Query: _.get(data, "filters.query"),
            UsuarioId: _.get(data, "filters.usuarioId"),
            Mensagem: _.get(data, 'filters.mensagem')
        };
        AsyncRequest({
            method: "post",
            path: "api/logauditoria/lista",
            parameters: data
        }).then(ret => {
            setLoadingTable(false);
            if (ret.status === 200 && ret.data) {
                console.log(ret.data);
                if (logs !== ret.data)
                    setLogs(ret.data);
            }
        });
    };
    let [logs, setLogs] = React.useState({
        lista: [],
        total: 0
    });
    return (<div>
        <Route path={`${match.path}/detalhes/:id`} render={RenderListWithModal(LogAuditoriaDetalhes)} />
        <AbasePaperTable sticky={true} selectMode={"single"} loading={loadingTable} data={_.get(logs, "lista")} total={_.get(logs, "total")} columns={columns} customFilters={filters} title={formName} requestData={buscarLogs}
            // error={errorMessagePaperTable(error)}
            onDoubleClick={row => {
                props.history.push(`${match.path}/detalhes/${row.id}`);
            }}
            customSelectedActions={selectedActions} customActions={[]} selectListener={props.selectListener} />
    </div>);
}
