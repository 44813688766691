import { List, ListItem, Stepper, withStyles, Step, StepButton, Button, Radio, Grid } from '@material-ui/core';
import { AbaseAutoCompleteFieldFinalForm, FormDraft, AbaseDatePickerField } from 'abase-components';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field, FormSpy } from "react-final-form";
import * as Yup from 'yup';
import DialogForm from '../../DialogForm';
import { AgendaRecursoSugestaoComponent, LocalQueryByIdComponent, RecursoTipoAllLocadoComponent } from '../../generated/graphql';
import { defaultDecorators, redirectBackForm } from '../../helpers';
import { cast, validate } from '../../helpers/validations';
import DynamicFormView from '../../components/DynamicFormView';
import SaveIcon from '@material-ui/icons/Save';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { formatDateTime } from '../../helpers/format';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';
import queryString from 'query-string';
import withIsMobile from '../../components/withIsMobile';
const AgendaRecursoSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    rec: Yup.object().shape({
        id: Yup.string().required(),
        nome: Yup.string().required()
    }).noUnknown().required(),
    state: Yup.string().notRequired()
}).noUnknown();
const styles = {
    stepper: {
        marginTop: '15px',
        padding: '0 15px',
        marginBottom: '15px'
    },
    floatLeft: {
        float: 'left',
        padding: '5px 16px 5px 5px',
    },
    floatRight: {
        float: 'right',
        padding: '5px 5px 5px 16px',
    },
    dialogPaper: {
        minHeight: "80vh",
        maxHeight: "100vh"
    }
};
function AgendaRecursoForm(props) {
    const { match, history, location, classes, isMobile } = props;
    const { params } = match;
    const [sugestaoVisivel, setSugestaoVisivel] = React.useState(false);
    let queryStringParams = queryString.parse(props.location.search);
    const [inicioFiltro, setInicioFiltro] = React.useState(queryStringParams.inicio);
    const [fimFiltro, setFimFiltro] = React.useState(queryStringParams.fim || queryStringParams.inicio);
    const handleSubmit = async (values) => {
        const mutationData = await cast(AgendaRecursoSchema, values);
        mutationData.state = "add";
        if (props.onSubmitItem) {
            props.onSubmitItem({ ...mutationData, recId: mutationData.rec.id }, values.action ? values.action : "save");
            redirectBackForm(history, location);
            return;
        }
    };
    const joinAgenda = async (values, rec) => {
        if (props.onSubmitItem) {
            values = { ...values, rec };
            const mutationData = await cast(AgendaRecursoSchema, values);
            mutationData.state = "join";
            props.onSubmitItem({ ...mutationData, recId: mutationData.rec.id }, values.action ? values.action : "save");
            redirectBackForm(history, location);
            return;
        }
    };
    const steps = [{
        label: "TIPO",
        optional: false
    },
    {
        label: "SUGESTÕES",
        optional: false
    },
    {
        label: "RECURSO",
        optional: false
    }];
    const notEditting = !params || !params.id;
    const initialValues = notEditting || !props.data ? {} : props.data.filter((x) => x.id === params.id)[0];
    const [activeStep, setActiveStep] = useState(0);
    const [completedSteps] = useState(steps.map(() => false));
    const onClose = () => redirectBackForm(history, location);
    const prevStep = () => {
        if (!sugestaoVisivel && activeStep === 2) {
            setActiveStep(activeStep - 2);
        }
        else if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };
    const nextStep = () => {
        if (!sugestaoVisivel && activeStep === 0) {
            setActiveStep(activeStep + 2);
        }
        else if (activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
        }
    };
    const mapMarkers = (list, values) => {
        const groupedData = _.groupBy(list, (x) => x.recId);
        console.log("MARKERS", { groupedData, list });
        return Object.keys(groupedData || {}).map(key => {
            const items = groupedData[key];
            console.log("RECURSO");
            return items.map(oneAgenda => (<Marker key={oneAgenda.id} position={[_.get(oneAgenda, "age.loc.posicaoMapa.x"), _.get(oneAgenda, "age.loc.posicaoMapa.y")]}>
                <Popup>
                    <div>
                        <strong>Resumo</strong><br />
                            Recurso: {_.get(items[0], "rec.nome")}<br />
                            Lotação: {_.get(items[0], "rec.lotacao") || "N/A"}<br />
                            Espaços ocupados: {_.sum(items.map(x => x.age.lotacao))}<br />
                        <hr />
                    </div>
                    {items.map((item) => (<div key={_.get(item, "id")}>
                        Responsável: <React.Fragment>
                            {(_.get(item, "age.usu.nome") && (!_.get(item, "age.agendaUsuario") || _.get(item, "age.agendaUsuario.length") === 0)) ? (<React.Fragment>{_.get(item, "age.usu.nome")}<br /></React.Fragment>) : null}
                            {_.get(item, "age.agendaUsuario", []).map(x => (<React.Fragment>{_.get(x, "age.usu.nome")}<br /></React.Fragment>))}
                        </React.Fragment><br />
                                Título: {_.get(item, "age.titulo")}<br />
                                Período: {formatDateTime(_.get(item, "age.inicio"))} - {formatDateTime(_.get(item, "age.fim"))}<br />
                                Espaços ocupados: {_.get(item, "age.lotacao") || "N/A"}<br />
                        <hr />
                    </div>))}
                    <Button variant={"contained"} color={"primary"} onClick={joinAgenda.bind(null, values, _.get(items[0], "rec"))}>Participar</Button>
                </Popup>
            </Marker>));
        });
    };
    const iconMarkup = renderToStaticMarkup(<i className="material-icons" style={{ color: "red", fontSize: 30, transform: "translateX(-10px) translateY(-10px)" }}>all_out</i>);
    const customMarkerIcon = divIcon({
        html: iconMarkup,
    });
    return (<FormDraft useDraft={false} onSubmit={handleSubmit} validate={validate(AgendaRecursoSchema)} initialValues={initialValues} decorators={defaultDecorators} render={({ handleSubmit, form, values }) => (<DialogForm title="Locar Recurso" onSubmit={handleSubmit} dialogProps={isMobile ? null : {
        classes: {
            paper: classes.dialogPaper
        }
    }} actions={(<div className="prl15 mb15">
        <Grid container spacing={1}>
            {activeStep === 1 ? <Grid item xs={12} className="center mb15">
                <Button type="button" variant="contained" className="btWarning" onClick={nextStep}>
                    Nenhuma destas se encaixa
                </Button>
            </Grid> : null}
            <Grid item xs={12} sm={12} md={6} lg={6}>
                {activeStep > 0 ? (<Button type="button" disabled={activeStep === 0} variant="contained" className={classes.floatLeft} color="primary" onClick={prevStep}>
                    <i className={"material-icons"}>keyboard_arrow_left</i> Voltar
                </Button>) : null}
            </Grid>
            <FormSpy subscription={{ values: true }}>{({ values }) => (<Grid item xs={12} sm={12} md={6} lg={6}>
                {activeStep !== steps.length - 1 && activeStep !== 1 ?
                    <Button type="button" className={classes.floatRight} variant="contained" disabled={activeStep === 1 || !values.recursoTipoId} color="primary" onClick={nextStep}>
                        Avançar
                        <i className={"material-icons"}>keyboard_arrow_right</i>
                    </Button> : null}
            </Grid>)}</FormSpy>
            {isMobile ? null : <Grid item xs={12} className="center">
                <Button onClick={onClose} key="cancelar" variant="contained">
                    <i className="material-icons">close</i>
                    Cancelar
                </Button>
                {activeStep === steps.length - 1 ?
                    <React.Fragment>
                        <Button type="button" variant="contained" color="secondary" onClick={() => {
                            form.change("action", "continue");
                            handleSubmit();
                        }} className="ml5">
                            <SaveIcon>send</SaveIcon>Salvar e Continuar
                        </Button>
                        <Button type="button" variant="contained" onClick={() => {
                            form.change("action", "save");
                            handleSubmit();
                        }} className="btnSuccess ml5">
                            <SaveIcon>send</SaveIcon>Salvar
                        </Button>
                    </React.Fragment>
                    : null}
            </Grid>}
        </Grid>
    </div>)}>
        <RecursoTipoAllLocadoComponent variables={{
            dataInicio: queryStringParams.inicio,
            dataFim: (queryStringParams.fim || undefined),
        }}>{({ data, loading }) => (<Field name="recursoTipoId">{({ input: inputTipo, meta }) => (<React.Fragment>
            <Stepper nonLinear alternativeLabel activeStep={activeStep} className={classes.stepper}>
                {steps.map(({ label }, index) => {
                    const props = {};
                    const buttonProps = {};
                    return (<Step key={label} {...props}>
                        <StepButton completed={completedSteps[index]} disabled={true} {...buttonProps}>
                            {label}
                        </StepButton>
                        )}
                    </Step>);
                })}
            </Stepper>
            {activeStep === 0 ? (<React.Fragment>
                <div className="mt15 txt">Selecione o tipo de recurso que você deseja reservar</div>
                <AbaseAutoCompleteFieldFinalForm isLoading={loading} valueField={"id"} textField={"descricao"} data={_.get(data, "recursoTipo.list", [])} {...inputTipo} onChange={value => {
                    if (value) {
                        const item = _.first(_.get(data, "recursoTipo.list", []).filter(x => x.id === value));
                        if (item) {
                            setSugestaoVisivel(item.descricao.indexOf("VEÍ") !== -1);
                        }
                    }
                    inputTipo.onChange(value);
                }} />
            </React.Fragment>) : null}
            {activeStep === 1 ? (<React.Fragment>
                <LocalQueryByIdComponent variables={{
                    id: queryStringParams.local
                }}>{({ data: localData }) => localData && localData.local ? (<AgendaRecursoSugestaoComponent variables={{
                    recursoTipoId: inputTipo.value,
                    dataInicio: inicioFiltro,
                    dataFim: fimFiltro,
                    agendaOrigem: (queryStringParams.agendaOrigem || undefined),
                }}>{({ data }) => {
                    const localPosition = _.get(localData, "local.list[0].posicaoMapa") ? [localData.local.list[0].posicaoMapa.x, localData.local.list[0].posicaoMapa.y] : [-27.7741977, -54.230988];
                    return (<React.Fragment>
                        <div className="mt15 txt">
                            Verifique as locações deste tipo de recurso para esta data. Caso haja alguma agenda que pode se encaixar no seu roteiro clique no marcador e clique em participar. Você também pode filtrar as datas da busca.
                        </div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <AbaseDatePickerField meta={meta} value={inicioFiltro} onChange={setInicioFiltro} label={"Início"} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <AbaseDatePickerField meta={meta} value={fimFiltro} onChange={setFimFiltro} label={"Fim"} />
                            </Grid>
                        </Grid>
                        <div className="b-1 mt15">
                            <Map zoom={10} center={localPosition}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors" />
                                <Marker position={localPosition} icon={customMarkerIcon}>
                                    <Popup>
                                        <div>
                                            Seu destino
                                        </div>
                                    </Popup>
                                </Marker>
                                {mapMarkers(_.get(data, "agendaRecurso.list", []), values)}
                            </Map>
                        </div>
                    </React.Fragment>);
                }}</AgendaRecursoSugestaoComponent>) : null}</LocalQueryByIdComponent>
            </React.Fragment>) : null}
            {activeStep === 2 ? (<Field name="rec">{({ input }) => {
                const recursoTipo = _.first(_.get(data, "recursoTipo.list", []).filter((x) => x.id === inputTipo.value));
                const recursos = _.get(recursoTipo, "recurso", []).filter((x) => !x.locado);
                return (<React.Fragment>
                    <div className="txt mt15 mb15"> Selecione abaixo o recurso que você deseja </div>
                    <List className="p0">
                        {recursos.map((x) => (<ListItem className="listView">
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Radio disabled={x.locado} checked={input.value && input.value.id === x.id} value={x} onChange={() => input.onChange(x)} className="p0"></Radio>
                                    &nbsp;&nbsp;{x.nome}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <div>
                                        <DynamicFormView formData={_.get(recursoTipo, "formulario")} value={x.dadosFormulario} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={4}>
                                    <div>
                                        Locado:{x.locado ? "Sim" : "Não"}
                                    </div>
                                </Grid>
                            </Grid>
                        </ListItem>))}
                    </List>
                    {recursos.length === 0 ? (<div className="attention">
                        <i className="material-icons">warning</i> Não há nenhum recurso disponível
                    </div>) : null}
                </React.Fragment>);
            }}</Field>) : null}
        </React.Fragment>)}</Field>)}
        </RecursoTipoAllLocadoComponent>
    </DialogForm>)} />);
}
export default withIsMobile(withStyles(styles)(AgendaRecursoForm));