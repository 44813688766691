/* eslint-disable array-callback-return */
import _ from "lodash";
export function redirectBackForm2(history, match) {
    let newRoute = match.path
        .replace("/edit/:id", "")
        .replace("/delete/:id", "")
        .replace("/add", "");
    if (newRoute.endsWith("/")) {
        newRoute = newRoute.substr(0, newRoute.length - 1);
    }
    console.log("newRoute", { newRoute, path: match.path });
    if (match.path.indexOf("/delete/:id") !== -1) {
        history.replace(newRoute);
    }
    else {
        history.push(newRoute);
    }
}
export function redirectBackForm(history, match) {
    const count = (str, ch) => _.countBy(str)[ch] || 0;
    const isInsideAnotherRoute = count(match.pathname, "/") >= 5;
    const uuidRegex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/;
    if (isInsideAnotherRoute) {
        const editRegex = /\/\w+\/edit\/[\w\d-]+\/?$/gm;
        const deleteRegex = /\/\w+\/delete\/[\w\d-,]+\/?$/gm;
        const addRegex = /\/\w+\/add\/?$/gm;
        let newRoute = match.pathname;
        if (newRoute.indexOf("/aba/") !== -1 || newRoute.indexOf("/modelo/") !== -1) {
            let rota = "";
            let caminhos = newRoute.split("/");
            if (uuidRegex.test(caminhos[caminhos.length - 1])) {
                caminhos.map((val, index) => {
                    if (index < caminhos.length - 2)
                        rota += (index > 0 ? "/" : "") + val;
                });
            }
            else {
                caminhos.map((val, index) => {
                    if (index < caminhos.length - 1)
                        rota += (index > 0 ? "/" : "") + val;
                });
            }
            history.push(rota);
        }
        else if (editRegex.test(newRoute)) {
            history.push(newRoute.replace(editRegex, ""));
        }
        else if (addRegex.test(newRoute)) {
            history.push(newRoute.replace(addRegex, ""));
        }
        else if (deleteRegex.test(newRoute)) {
            history.replace(newRoute.replace(deleteRegex, ""));
        }
        else {
            history.goBack();
        }
    }
    else {
        const editRegex = /\/edit\/[\w\d-]+\/?$/gm;
        const deleteRegex = /\/delete\/[\w\d-,]+\/?$/gm;
        const addRegex = /\/add\/?$/gm;
        let newRoute = match.pathname;
        if (newRoute.indexOf("/aba/") !== -1 || newRoute.indexOf("/modelo/") !== -1) {
            let rota = "";
            let caminhos = newRoute.split("/");
            if (uuidRegex.test(caminhos[caminhos.length - 1])) {
                caminhos.map((val, index) => {
                    if (index < caminhos.length - 2)
                        rota += (index > 0 ? "/" : "") + val;
                });
            }
            else {
                caminhos.map((val, index) => {
                    if (index < caminhos.length - 1)
                        rota += (index > 0 ? "/" : "") + val;
                });
            }
            history.push(rota);
        }
        else if (editRegex.test(newRoute)) {
            history.push(newRoute.replace(editRegex, ""));
        }
        else if (addRegex.test(newRoute)) {
            history.push(newRoute.replace(addRegex, ""));
        }
        else if (deleteRegex.test(newRoute)) {
            history.replace(newRoute.replace(deleteRegex, ""));
        }
        else {
            history.goBack();
        }
    }
}
