import { Grid } from '@material-ui/core';
import { AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import React from 'react';
import { Field } from "react-final-form";
import _ from 'lodash';
import { MinhasEmpresasComponent } from '../generated/graphql';
export default class EmpresaEquipeAreaFiltro extends React.Component {
    render() {
        console.log(this.props)
        const getArea = (data, empresaId) => {
            if (!data || !data.empresa) {
                return [];
            }
            const empresa = data.empresa.minhasEmpresas.filter(x => x.id === empresaId);
            if (empresa.length === 0) {
                return [];
            }
            return empresa[0].area;
        };
        const getEquipe = (areas, areaId) => {
            if (!areas) {
                return [];
            }
            const area = areas.filter(x => x.id === areaId);
            if (area.length === 0) {
                return [];
            }
            return area[0].areaEquipe.map(x => x.equ);
        };
        return (<React.Fragment>
            <MinhasEmpresasComponent>
                {({ data, loading }) => {
                    return (<React.Fragment>
                        <Field name="areId">{({ input: inputAre, meta: metaAre }) => (<Field name="equId">{({ input: inputEq, meta: metaEq }) => (<Field name="empId" >{({ input, meta }) => {
                            const areas = getArea(data, input.value);
                            const equipes = getEquipe(areas, inputAre.value)
                            return (<React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <AbaseAutoCompleteFieldFinalForm forwardRef={this.props.empresaRef} isDisabled={this.props.disableEmpresa} isLoading={loading} label={"Empresa"} valueField={"id"} textField={"razaosocial"} data={data && data.empresa && data.empresa.minhasEmpresas} {...input} meta={meta} />
                                    </Grid>
                                    {
                                        this.props.hideArea ? null :
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <AbaseAutoCompleteFieldFinalForm isDisabled={this.props.disabled} isLoading={loading} label={"Área"} valueField={"id"} textField={"descricao"} data={areas} {...inputAre} meta={metaAre} />
                                            </Grid>
                                    }{
                                        this.props.hideArea || this.props.hideEquipe ? null :
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <AbaseAutoCompleteFieldFinalForm isDisabled={this.props.disabled} isLoading={loading} label={"Equipe"} valueField={"id"} textField={"descricao"} data={equipes} {...inputEq} meta={metaEq} />
                                            </Grid>
                                    }
                                </Grid>
                            </React.Fragment>);
                        }}</Field>)}
                        </Field>)}
                        </Field>
                    </React.Fragment>)
                }}
            </MinhasEmpresasComponent>
        </React.Fragment>);
    }
}
