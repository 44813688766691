import React from 'react';
import _ from 'lodash';
import { ProjetoQueryByIdComponent, UsuarioQueryByIdComponent, ProjetoApontamentoListComponent, ProjetoEtapaListComponent, TarefaListComponent } from '../../generated/graphql';
import { CardContent, Typography, CardActions, Grid } from '@material-ui/core';
function ClienteProjetoDetalhe(props) {
	const { match } = props;
	const { params } = match;
	const listaApontamentos = (projetoApontamentos) => {
		return projetoApontamentos.map((apontamento) => {
			return (<li>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						Data: <b>{new Date(_.get(apontamento, 'data', 'N/A')).toLocaleString()}</b>
						<br />
						Descrição: <b>{_.get(apontamento, 'descricao', 'N/A')}</b>
						<br />
					</Grid>
				</Grid>
			</li>);
		});
	};
	const listaEtapas = (projetoEtapas) => {
		return projetoEtapas.map((Etapa) => {
			return (<li>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						Data Limite: <b>{new Date(_.get(Etapa, 'dataLimite', 'N/A')).toLocaleDateString()}</b>
						<br />
						Descrição: <b>{_.get(Etapa, 'descricao', 'N/A')}</b>
						<br />
						Porcentagem: <b>{_.get(Etapa, 'porcentagem', 'N/A')}</b>
						<br />
						Ordem: <b>{_.get(Etapa, 'ordem', 'N/A')}</b>
						<br />
						<TarefaListComponent variables={{ etapaId: _.get(Etapa, 'id', 'naovainao') }}>
							{({ data, loading }) => loading || _.get(data, 'tarefa.pagedList.total') === 0 ? null :
								<React.Fragment>
									<Typography color="textSecondary" gutterBottom>
										<b>Tarefas da Etapa - {_.get(data, 'tarefa.pagedList.total', 0)}</b>
									</Typography>
									<ul className="listaHome">
										{listaTarefa(_.get(data, 'tarefa.pagedList.data', []))}
									</ul>
								</React.Fragment>}
						</TarefaListComponent>
					</Grid>
				</Grid>
			</li>);
		});
	};
	const listaTarefa = (etapaTarefa) => {
		return etapaTarefa.map((tarefa) => {
			return (<li>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						Descrição: <b>{_.get(tarefa, 'descricao', 'N/A')}</b>
						<br />
						Status: <b>{_.get(tarefa, 'status', 'N/A')}</b>
						<br />
						Usuario: <b>{_.get(tarefa, 'usu.nome', 'N/A')}</b>
						<br />
					</Grid>
				</Grid>
			</li>);
		});
	};
	return (<React.Fragment>
		<ProjetoQueryByIdComponent variables={{ id: _.get(params, 'id') }}>
			{({ data }) => {
				let projeto = _.get(data, 'projeto.list[0]');
				return (<React.Fragment>
					<CardContent>
						<Typography color="textSecondary" gutterBottom>
							<b>Projeto {_.get(projeto, 'descricao')}</b>
						</Typography>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<div className="colRow f-12">
									<ul className="listaHome">
										<li>
											Descrição: <b>{_.get(projeto, 'descricao')}</b> <br />
											Observação: <b>{_.get(projeto, 'observacao')}</b> <br />
											Prazo: <b>{new Date(_.get(projeto, 'prazo')).toLocaleString()}</b><br />
										</li>
									</ul>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<div className="colRow f-12">
									<ul className="listaHome">
										<li>
											Responsável: <b><UsuarioQueryByIdComponent variables={{ id: _.get(projeto, 'responsavelUsuId') }}>
												{({ data, loading }) => {
													return loading ? "" : _.get(data, 'usuario.list[0].nome');
												}}
											</UsuarioQueryByIdComponent></b> <br />
											Área:<b>{_.get(projeto, 'are.descricao')}</b><br />
											Equipe: <b>{_.get(projeto, 'equ.descricao')}</b> <br />
											Limite:
													<b>
												{_.get(projeto, 'Limite') ? (new Date(_.get(projeto, 'limite')).toLocaleString()) : ('Não Cadastrado')}
											</b>
											<br />
										</li>
									</ul>
								</div>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Typography color="textSecondary" gutterBottom>
								<b>Apontamentos</b>
							</Typography>
							<ProjetoApontamentoListComponent variables={{ projetoId: params.id }}>
								{({ data, loading }) => loading ? null :
									<div className="colRow f-12">
										<ul className="listaHome">{listaApontamentos(_.get(data, 'projetoApontamento.pagedList.data', []))}</ul>
									</div>}
							</ProjetoApontamentoListComponent>
						</Grid>
						<Grid item xs={12}>
							<Typography color="textSecondary" gutterBottom>
								<b>Etapas</b>
							</Typography>
							<ProjetoEtapaListComponent variables={{ projetoId: params.id }}>
								{({ data, loading }) => loading ? null :
									<div className="colRow f-12">
										<ul className="listaHome">{listaEtapas(_.get(data, 'projetoEtapa.pagedList.data', []))}</ul>
									</div>}
							</ProjetoEtapaListComponent>
						</Grid>
					</CardContent>
					<CardActions className="colRow text-center" />
				</React.Fragment>);
			}}
		</ProjetoQueryByIdComponent>
	</React.Fragment>);
}
export default ClienteProjetoDetalhe;
