import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ModeloTarefaListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import ModeloTarefaDelete from "./ModeloTarefaDelete";
import ModeloTarefaForm from "./ModeloTarefaForm";
import ModeloTarefaLabel from './ModeloTarefaNameLabel';
export default function ModeloTarefaList(props) {
    const { match } = props;
    const columns = [
        {
            name: ModeloTarefaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: ModeloTarefaLabel.descricao,
            field: "descricao"
        },
        {
            name: ModeloTarefaLabel.tempo,
            field: "tempo"
        },
        {
            name: ModeloTarefaLabel.pro,
            field: "pro.descricao"
        },
        {
            name: ModeloTarefaLabel.tar,
            field: "tar.descricao"
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link}/>) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/delete/${_.map(selected, "id").join(",")}`}/>
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.url}/add`}/>,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descrição"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = ModeloTarefaLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => {
        if (match.params.idModeloEtapa && (!variables || (variables.idModeloEtapa !== match.params.idModeloEtapa))) {
            setVariables({ ...variables, idModeloEtapa: match.params.idModeloEtapa });
        }
        if (match.params.modeloProjetoId && (!variables || (variables.modeloProjetoId !== match.params.modeloProjetoId))) {
            setVariables({ ...variables, modeloProjetoId: match.params.modeloProjetoId });
        }
        return (<div className={"colDivBloco"}>
                        <ModeloTarefaListComponent variables={variables} skip={variables === null}>
                            {({ data, loading, refetch, error }) => (<div>
                                <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "modeloTarefa.pagedList.data") || []} total={_.get(data, "modeloTarefa.pagedList.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={val => props.history.push(match.url + "/edit/" + val.id)}></AbasePaperTable>
                                <ReenterRouteListener callback={refetch}/>
                                <Route path={`${match.path}/add`} render={RenderWithModal(ModeloTarefaForm)}/>
                                <Route path={`${match.path}/edit/:idModeloTarefa`} render={RenderWithModal(ModeloTarefaForm)}/>
                                <Route path={`${match.path}/delete/:idModeloTarefa`} render={RenderWithModal(ModeloTarefaDelete)}/>
                            </div>)}
                        </ModeloTarefaListComponent>
                    </div>);
    }}
        </GraphqlPaperTableListener>);
}
