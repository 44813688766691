const names = {
    "form": "Requisição da Agenda",
    "id": "ID",
    "ageId": "Agenda ID",
    "reqId": "Requisição ID",
    "dadosFormulario": "Dados do Formulário",
    "age": "Título da Agenda",
    "req": "Requisição",
    'status': "Status"
};
export default names;
