const names = {
    "form": "Projeto Anexo",
    "id": "Id",
    "proId": "Projeto ID",
    "usuId": "Usuário ID",
    "descricao": "Descrição",
    "tipo": "Tipo",
    "pro": "Projeto",
    "usu": "Usuário",
};
export default names;
