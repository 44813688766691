import React from 'react';
import { Grid } from '@material-ui/core';
import { AbaseTextField, AbaseAutocompleteField } from 'abase-components';
import { withRouter } from 'react-router-dom';
import DialogForm from '../DialogForm';
class DynamicFormEditorFields extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            idAtual: '',
            labelAtual: '',
            typeAtual: ''
        };
        this.handleChange = (evt) => {
            this.setState({ [evt.target.name]: evt.target.value });
        };
        this.handleChangeTipo = (valor) => {
            this.setState({ typeAtual: valor.name });
        };
    }
    onSubmit() {
        let value = {
            id: this.state.idAtual,
            label: this.state.labelAtual,
            type: this.state.typeAtual
        };
        this.props.onChange(value);
        this.setState({ idAtual: '', labelAtual: '', typeAtual: '' });
    }
    render() {
        return (<React.Fragment>
				<DialogForm title="Tipo de Requisição" onSubmit={this.onSubmit.bind(this)} dontUseRoute onClose={this.props.onClose}>
					<form>
						<Grid container spacing={1}>
							<Grid item xs={4}>
								<AbaseTextField label="Id" name={'idAtual'} value={this.state.idAtual} onChange={this.handleChange}/>
							</Grid>
							<Grid item xs={8}>
								<AbaseTextField label="Descrição" name={'labelAtual'} value={this.state.labelAtual} onChange={this.handleChange}/>
							</Grid>
							<Grid item xs={12}>
								
								<AbaseAutocompleteField data={[{ name: 'text' }]} valueField="name" textField="name" label="Tipo" name={'typeAtual'} onChange={this.handleChangeTipo}/>
							</Grid>
						</Grid>
					</form>
				</DialogForm>
			</React.Fragment>);
    }
}
export default withRouter(DynamicFormEditorFields);
