import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { AbaseAutoCompleteFieldFinalForm, AbaseCheckbox, AbaseDatePickerField, AbaseLoading, AbaseTextField, clearDraft, FormDraft, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Field } from 'react-final-form';
import { Message } from 'semantic-ui-react';
import * as Yup from 'yup';
import AlertaCliente from '../../components/AlertaCliente';
import AutoCompleteCliente from '../../components/AutoCompleteCliente';
import CardContato from '../../components/CardContato';
import { isMobileMediaQuery } from '../../components/withIsMobile';
import DialogForm from '../../DialogForm';
import { EmpresaAllComponent, useOrdemServicoCreate, useOrdemServicoQueryById, useOrdemServicoUpdate, UsuarioAllComponent, UsuarioContatoClienteAllComponent, useUsuarioAutenticado } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { cast, validate } from '../../helpers/validations';
import { OrdemTarefaComponent } from '../Agenda/StepTarefa';
import OrdemItemCobrancaList from '../OrdemItemCobranca/OrdemItemCobrancaList';
import OrdemServicoLabel, { ordemServicoStatus, ordemServicoTipos } from './OrdemServicoNameLabel';
import { imprimirOrdemServico } from './OrdemServicoRelatorio';
const OrdemServicoSchema = Yup.object()
    .shape({
        id: Yup.string().notRequired(),
        usuId: Yup.string().notRequired(),
        empId: Yup.string().notRequired(),
        cliId: Yup.string().notRequired(),
        contatoId: Yup.string().notRequired(),
        data: Yup.date().required(),
        tipo: Yup.string().required().max(10),
        status: Yup.string().required(),
        observacao: Yup.string().notRequired().nullable(),
        valor: Yup.number().notRequired().nullable(),
        distancia: Yup.number().notRequired(),
        possuiCobranca: Yup.bool().notRequired().nullable(),
        ordemTarefas: Yup.array(Yup.object()
            .shape({
                id: Yup.string().notRequired(),
                tar: Yup.object()
                    .shape({
                        id: Yup.string().notRequired().nullable(),
                        usuId: Yup.string().notRequired().nullable(),
                        tarId: Yup.string().notRequired().nullable(),
                        tar: Yup.object()
                            .shape({
                                id: Yup.string().notRequired().nullable(),
                                descricao: Yup.string().required(),
                                unidadeMedida: Yup.string().required()
                            })
                            .noUnknown()
                            .required(),
                        datatarefa: Yup.date().notRequired().nullable(),
                        descricao: Yup.string().notRequired().nullable(),
                        status: Yup.string().required(),
                        datainicio: Yup.date().notRequired().nullable(),
                        dataconclusao: Yup.date().notRequired().nullable(),
                        datalimite: Yup.date().notRequired().nullable(),
                        porcentagem: Yup.number().notRequired().nullable(),
                        quantidadeTempo: Yup.string().notRequired().nullable(),
                        state: Yup.string().notRequired().nullable(),
                        usuarioExecutouId: Yup.string().notRequired().nullable(),
                        inicio: Yup.date().notRequired().nullable(),
                        fim: Yup.date().notRequired().nullable(),
                        tempoIntervalo: Yup.string().notRequired().nullable(),
                        descritivo: Yup.string().notRequired().nullable(),
                        proId: Yup.string().notRequired().nullable(),
                        possuiCobranca: Yup.bool().notRequired().nullable(),
                        urgencia: Yup.bool().notRequired().nullable(),
                        valor: Yup.number().notRequired().nullable(),
                        previsaoInicio: Yup.date().notRequired().nullable(),
                        tarefaHistorico: Yup.array(Yup.object().shape({
                            id: Yup.string().notRequired().nullable(),
                            usuId: Yup.string().required().label("Usuário que executou"),
                            state: Yup.string().notRequired().nullable(),
                            inicio: Yup.date().required(),
                            data: Yup.date().notRequired().nullable(),
                            porcentagem: Yup.number().required().nullable(),
                            status: Yup.string().required().nullable(),
                            fim: Yup.date().required(),
                            tempoIntervalo: Yup.string().notRequired().nullable(),
                            descritivo: Yup.string().required(),
                        }).noUnknown()).nullable(),
                    })
                    .noUnknown()
                    .required()
            })
            .noUnknown()).notRequired()
    })
    .noUnknown();
function OrdemServicoForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useOrdemServicoUpdate();
    const insert = useOrdemServicoCreate();

    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    const ordId = params.id;
    const { data: usuarioAutenticadoData } = useUsuarioAutenticado();
    const podeLancarOs = _.get(usuarioAutenticadoData, "usuario.me.lancarOs");
    const { data, loading } = useOrdemServicoQueryById({
        variables: { id: params.id },
        skip: notEditting,
    });
    React.useEffect(() => {
        if (_.get(data, 'ordemServico.list[0].id')) {
            const os = _.get(data, 'ordemServico.list[0]');
            if (os.status === "FATURADA") {
                showError({
                    message: "ATENÇÃO: A ordem não pode ser alterada por que já esta faturada"
                });
            }
        }
    }, [_.get(data, 'ordemServico.list[0].id')]);
    const handleSubmit = async (values) => {
        // if (nivel !== niveisEnum.administrativo) {
        // 	const id = params.id
        // 	if (values.action === "print" && id) {
        // 		imprimirOrdemServico([id])
        // 	}
        // 	return
        // }
        const mutationData = await cast(OrdemServicoSchema, values);
        mutationData.cliId = _.get(values, "cli.id");
        try {
            let id;
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                id = _.get(result, "data.ordemServico.update.id");
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.ordemServico.update.id'));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                id = _.get(result, "data.ordemServico.create.id");
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.ordemServico.create.id'));
                }
                clearDraft();
            }
            if (values.action === "print" && id) {
                imprimirOrdemServico([id]);
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };

    let [empresaCliente, setEmpresacliente] = React.useState('');
    const isMobile = isMobileMediaQuery();
    const handleClose = () => redirectBackForm(history, location);
    return (<FormDraft useDraft={false} onSubmit={handleSubmit} validate={validate(OrdemServicoSchema)} initialValues={_.get(data, 'ordemServico.list[0]') || {}} decorators={defaultDecorators} subscription={{
        submitting: true,
        initialValues: true
    }} render={({ handleSubmit, values, form, submitting }) => (<DialogForm title="Ordem de Serviço" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined} actions={isMobile ? null :
        <Grid container spacing={1}>
            <Grid item xs={12} className="center mb15">
                <Button onClick={handleClose} key="cancelar" variant="contained">
                    <i className="material-icons">close</i>Cancelar
								</Button>
                <Button type="button" variant="contained" className="btnSuccess ml5" key="submit" onClick={() => {
                    form.change("action", "save");
                    handleSubmit();
                }}>
                    <SaveIcon>send</SaveIcon> Salvar
								</Button>
                <Button type="button" color="secondary" variant="contained" className="ml5" key="submit" onClick={() => {
                    form.change("action", "print");
                    handleSubmit();
                }}>
                    <i className={"material-icons"}>print</i> Salvar e Imprimir
								</Button>
            </Grid>
        </Grid>}>
        <AbaseLoading open={loading || submitting} />
        <Grid container spacing={1}>
            <Field name="dadosOsGestor">
                {({ input: { value: dadosOsGestor } }) => (dadosOsGestor ? (<Grid item xs={12} style={{ marginTop: 15 }}>
                    <Message info header='Informações do GESTOR' content={<div>{dadosOsGestor.split("\n").map(x => <p>{x}</p>)}</div>} />
                </Grid>) : null)}</Field>
            <Grid item xs={12} sm={12} md={2} lg={2}>
                <Field name="data">
                    {({ input, meta }) => (<AbaseDatePickerField disabled={!podeLancarOs} meta={meta} {...input} label={OrdemServicoLabel.data} />)}
                </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7}>
                <EmpresaAllComponent>
                    {({ data, loading }) => (<Field name="empId">
                        {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} isDisabled={!notEditting || !podeLancarOs} label={"Empresa"} valueField={'id'} textField={'razaosocial'} data={_.get(data, 'empresa.list', [])} {...input} />)}
                    </Field>)}
                </EmpresaAllComponent>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <Field name="tipo" parse={defaultFieldParser}>
                    {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm label={"Tipo"} meta={meta} disabled={!podeLancarOs} valueField={'id'} textField={'text'} data={ordemServicoTipos} {...input} />)}
                </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <Field name="status" parse={defaultFieldParser}>
                    {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm label={"Status"} meta={meta} valueField={'id'} textField={'text'} isOptionDisabled={x => x.id === "FATURADA"} data={ordemServicoStatus} isDisabled={!podeLancarOs} {...input} />)}
                </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <Field name="distancia">
                    {({ input, meta }) => (<AbaseTextField meta={meta} disabled={!podeLancarOs} type="number" fullWidth={true} {...input} label={OrdemServicoLabel.distancia} />)}
                </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <UsuarioAllComponent variables={{
                    cliente: false
                }}>
                    {({ data, loading }) => (<Field name="usuId">
                        {({ input }) => (<AbaseAutoCompleteFieldFinalForm fullWidth isLoading={loading} label={OrdemServicoLabel.usu} valueField={'id'} isDisabled={!podeLancarOs} textField={'nome'} data={_.get(data, 'usuario.list', [])} {...input} />)}
                    </Field>)}
                </UsuarioAllComponent>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field name={'empId'}>
                    {({ input: { value: empId } }) => (<Field name="cli">
                        {({ input }) => (<AutoCompleteCliente fullWidth empId={empId} label={OrdemServicoLabel.cli} isDisabled={!podeLancarOs} {...input} onChange={(cli) => {
                            input.onChange(cli);
                            setEmpresacliente(cli.empId);
                        }} />)}
                    </Field>)}
                </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Field name={'cli'}>{({ input: { value: cli } }) => (<UsuarioContatoClienteAllComponent skip={!cli} variables={{
                    cliId: _.get(cli, "id")
                }}>
                    {({ data, loading }) => (<Field name={'contatoId'}>
                        {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isDisabled={!podeLancarOs} isLoading={loading} itemHeight={73} components={{ Option: CardContato }} label={'Contato'} valueField={'id'} textField={'nome'} data={_.get(data, 'usuario.list')} {...input} />)}
                    </Field>)}
                </UsuarioContatoClienteAllComponent>)}
                </Field>
            </Grid>
            <Grid item xs={12}>
                <Field name="observacao" parse={defaultFieldParser}>
                    {({ input, meta }) => (<AbaseTextField multiline meta={meta} fullWidth={true} {...input} rows="2" label={OrdemServicoLabel.observacao} disabled={!podeLancarOs} />)}
                </Field>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <Field name="possuiCobranca">{({ input, meta }) => (<AbaseCheckbox {...input} label={"Possui Cobrança"} disabled={!podeLancarOs} />)}</Field>
            </Grid>
            <Grid item xs={12}>
                <Field name="possuiCobranca">{({ input, meta }) => {
                    const possuiCobranca = input.value;
                    return (<OrdemTarefaComponent empId={empresaCliente} nameField="ordemTarefas" extraSelectedOptions={selected => {
                        if (!possuiCobranca || selected.length !== 1)
                            return [];
                        return [
                            <Tooltip title="Criar cobrança de tarefa" disableFocusListener>
                                <IconButton aria-label={`Criar cobrança de tarefa`} onClick={() => {
                                    props.history.push(`${match.url}/cobranca/add?proId=${selected[0].tar.pro.id}`);
                                }}>
                                    <i className={"material-icons"}>receipt</i>
                                </IconButton>
                            </Tooltip>
                        ];
                    }} />);
                }}</Field>
            </Grid>
            <Grid item xs={12}>
                <Field name={"ordemTarefas"}>{({ input }) => {
                    const sumDurations = (durations) => {
                        if (durations.length === 0) {
                            return moment.duration("00:00");
                        }
                        return durations
                            .reduce((prev, cur) => cur === null ? prev : moment.duration(cur).add(prev), moment.duration(moment.duration("00:00")));
                    };
                    const results = (input.value || []).map(x => {
                        const tempoTotalDurations = (x.tar.tarefaHistorico || [])
                            .map(x => moment(x.fim).diff(moment(x.inicio)));
                        const tempoTotal = sumDurations(tempoTotalDurations);
                        const temposIntervalo = _.map((x.tar.tarefaHistorico || []), "tempoIntervalo").filter(x => x !== null);
                        const tempoIntervaloTotal = sumDurations(temposIntervalo);
                        return { tempoTotal, tempoIntervaloTotal };
                    });
                    const tempoTotal = sumDurations(results.map(x => x.tempoTotal));
                    const tempoTotalIntervalo = sumDurations(results.map(x => x.tempoIntervaloTotal));
                    const tempoNormal = moment.duration("00:00").add(tempoTotal).subtract(tempoTotalIntervalo);
                    return (<div className="mt15">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Tempo total: <b>{moment.utc(tempoTotal.as('milliseconds')).format("HH:mm")}</b>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Tempo de intervalo: <b>{moment.utc(tempoTotalIntervalo.as('milliseconds')).format("HH:mm")}</b>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                Tempo contabilizado: <b>{moment.utc(tempoNormal.as('milliseconds')).format("HH:mm")}</b>
                            </Grid>
                        </Grid>
                    </div>);
                }}</Field>
            </Grid>
            <Grid item xs={12}>
                <Field name="possuiCobranca">{({ input, meta }) => {
                    const possuiCobranca = input.value;
                    return notEditting || !possuiCobranca ? null : (<Grid item xs={12}>
                        <div className="mt15">
                            <Field name="cli">
                                {({ input }) => (<OrdemItemCobrancaList ordId={ordId} cliId={input.value ? input.value.id : null} />)}</Field>
                        </div>
                    </Grid>);
                }}</Field>
            </Grid>

            <Grid item xs={12} className="text-center">
                <Field name={'cli'}>{({ input: { value } }) => (<AlertaCliente clienteId={_.get(value, "id")} showAlert={true} />)}</Field>
            </Grid>
        </Grid>
    </DialogForm>)} />);
}
export default OrdemServicoForm;
