import { Grid, List } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import React from 'react';
import { withRouter } from 'react-router';
import Bar from './Bar';
import { createStyles } from "@material-ui/core";
import BreadcrumbsWithRouter from './BreadcrumbsWithRouter';
import withIsMobile from './withIsMobile';
const drawerWidth = 280;
const styles = (theme) => createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        marginTop: '84px',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: '#e0e0e0',
    },
    drawerHeader: {
        display: 'flex',
        padding: '0 8px',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        marginTop: 40,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
});
function AgendaNavbar(props) {
    // propTypes = {
    // 	classes: PropTypes.object.isRequired,
    // 	theme: PropTypes.object.isRequired,
    // 	listaMenu: PropTypes.object.isRequired 
    // };
    const [open, setOpen] = React.useState(true);
    const handleDrawer = () => {
        setOpen(!open);
    };
    React.useEffect(() => {
        let tamanhoDisponivelPagina = window.innerHeight;
        let bar = document.getElementById('appBar');
        let tamanhoPaginaConteudo = 0;
        if (bar)
            tamanhoPaginaConteudo = tamanhoDisponivelPagina - bar.clientHeight;
        if (tamanhoDisponivelPagina > 0)
            window.localStorage.setItem('tamanhoPagina', tamanhoPaginaConteudo.toString());
        if (props.showDrawer === false) {
            setOpen(false);
        }
    });
    const isMobile = props.isMobile;
    const { classes } = props;
    const showDrawer = props.showDrawer === false ? false : true;
    if (!props.ocultar)
        return (<Grid container item xs={12}>
            <div className={classes.root}>
                <CssBaseline />
                <Bar classes={classes} aberto={open} showDrawer={showDrawer} clique={handleDrawer} topItems={props.topItems} />
                {showDrawer ? (<Drawer anchor="left" open={open} variant="temporary" onClose={() => setOpen(false)}>
                    <List key="listaNavBar">{React.cloneElement(props.leftMenuItems, { hide: () => setOpen(false) })}</List>
                </Drawer>) : null}
                <main className={classNames(classes.content, {
                    [classes.contentShift]: open
                })}>
                    <div className={classNames(classes.appBar, 'conteudo', {
                        [classes.appBarShift]: false //open
                    })} id="conteudoExibePagina">
                        <div className="dentroConteudo">
                            {!isMobile ? <BreadcrumbsWithRouter /> : null}

                            {props.children}
                        </div>
                    </div>
                </main>
            </div>
        </Grid>);
    else
        return (props.children);
}
export default withIsMobile(withStyles(styles, { withTheme: true })(withRouter(AgendaNavbar)));
