import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { LocalListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import LocalDelete from "./LocalDelete";
import LocalForm from "./LocalForm";
import LocalLabel from './LocalNameLabel';
export default function LocalList(props) {
    const { match } = props;
    const columns = [
        {
            name: LocalLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: LocalLabel.cep,
            field: "cep",
            width: 100,
        },
        {
            name: LocalLabel.descricao,
            field: "descricao"
        },
        {
            name: LocalLabel.posicaoMapa,
            field: "posicaoMapa",
            customBodyRender: (value) => {
                if (value) {
                    return value.x + "," + value.y;
                }
                return "N/A";
            },
            defaultVisible: false
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" to={`${match.path}/delete/${_.map(selected, "id").join(",")}`} component={Link} />
        ];
    };
    const customActions = [
        <AddAction key="add" to={`${match.path}/add`} component={Link} />,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descrição"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"cep"}>{({ input, meta }) => <AbaseTextField label={"CEP"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = LocalLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<LocalListComponent variables={variables} skip={variables === null}>
        {({ data, loading, refetch, error }) => (<div>

            <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "local.pagedList.data") || []} total={_.get(data, "local.pagedList.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
                props.history.push(`${match.path}/edit/${row.id}`);
            }}></AbasePaperTable>
            <ReenterRouteListener callback={refetch} />
            <Route path={`${match.path}/add`} render={RenderWithModal(LocalForm)} />
            <Route path={`${match.path}/edit/:id`} render={RenderWithModal(LocalForm)} />
            <Route path={`${match.path}/delete/:id`} render={RenderWithModal(LocalDelete)} />

        </div>)}
    </LocalListComponent>)}
    </GraphqlPaperTableListener>);
}
