import { ResponsivePaperTable, AddAction, DeleteAction, EditAction } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router';
import { formatDateTime } from '../../helpers/format';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@material-ui/core';
import { DialogFormComponent } from '../../DialogForm';
function BuscarTarefaProjeto(props) {
    const { match } = props;
    const [, setAnchorEl] = React.useState(null);
    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }
    const columns = [
        {
            name: "Id",
            field: "id",
            defaultVisible: false,
            sort: false
        },
        {
            name: "Tipo",
            field: "tar.tar.descricao",
            sort: false
        },
        {
            name: "Usuário",
            field: "tar.usu.nome",
            sort: false
        },
        {
            name: "Serviço",
            field: "tar.pro.descricao",
            sort: false
        },
        {
            name: "Data Limite",
            field: "tar.datalimite",
            sort: false,
            width: 100,
            customBodyRender: (value) => value ? formatDateTime(value) : "N/A"
        },
        {
            name: "Porc.",
            field: "tar.porcentagem",
            sort: false,
            width: 60,
            customBodyRender: (value) => value ? value + "%" : "N/A"
        },
        {
            name: "Status",
            field: "tar.status",
            sort: false,
            width: 80
        },
    ];
    return (<DialogFormComponent title={"Buscar Tarefas"} onSubmit={() => {
    }} onClose={() => {
        props.history.goBack();
    }}>
            <div className={"colDivBloco"}>

                <ResponsivePaperTable sticky={true} selectMode={"multi"} loading={false} data={([]).filter(x => _.get(x, "tar.state") !== "delete")} total={0} columns={columns} onDoubleClick={row => {
        props.history.push(`${match.url}/tarefa/edit/${row.tar.id}`);
    }} title={""} customActions={[
        <Tooltip title="Buscar tarefa de projeto" disableFocusListener>
                            <IconButton aria-label={`Buscar tarefa de projeto`}>
                                <i className={"material-icons"} onClick={handleClick}>zoom_in</i>
                            </IconButton>
                        </Tooltip>,
        <AddAction key="add" to={`${match.url}/tarefa/add`} component={Link}/>,
    ]} customSelectedActions={(selected) => {
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/tarefa/edit/${selected[0].tar.id}`} component={Link}/>) : null,
            <DeleteAction key="delete" to={`${match.url}/tarefa/delete/${_.map(selected, "tar.id").join(",")}`} component={Link}/>,
        ];
    }} requestData={() => Promise.resolve([])} tableProps={{
        paginated: false
    }} disableFilter={true}></ResponsivePaperTable>


            </div>

        </DialogFormComponent>);
}
export default withRouter(BuscarTarefaProjeto);
