import { AbaseDialogConfirm, AbaseDialogConsumer, AbaseLoading } from 'abase-components';
import React from 'react';
import { TarefaHistoricoDeleteComponent } from '../../generated/graphql';
import { defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
export default function TarefaHistoricoDelete(props) {
    const params = props.match.params;
    return (<AbaseDialogConsumer>
            {({ showError, showSnack }) => (<TarefaHistoricoDeleteComponent onCompleted={(data) => {
        showSnack(defaultSnack("delete"));
        redirectBackForm(props.history, props.location);
    }} onError={(err) => {
        showError({ message: defaultErrorMessage("delete", err) });
    }}>{(deleteItem, { loading }) => (<React.Fragment>
                    <AbaseDialogConfirm open={true} onCancel={() => redirectBackForm(props.history, props.location)} onConfirm={() => {
        if (props.onSubmitItem) {
            props.onSubmitItem(params.id.split(","));
            redirectBackForm(props.history, props.location);
            return;
        }
        else {
            deleteItem({ variables: { ids: params.id.split(",") } });
        }
    }} message={"Tem certeza que deseja excluir o registro?"}></AbaseDialogConfirm>
                    <AbaseLoading open={loading}/>

                </React.Fragment>)}</TarefaHistoricoDeleteComponent>)}
        </AbaseDialogConsumer>);
}
