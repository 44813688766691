const names = {
    "form": "Cliente",
    "id": "ID",
    "empId": "ID Empresa",
    "cpfcnpj": "CPF/CNPJ",
    "inscricaoestadual": "Inscrição Estadual",
    "rg": "RG",
    "nome": "Nome",
    "razaosocial": "Razão Social",
    "telefone": "Telefone",
    "email": "E-mail",
    "cidade": "Cidade",
    "uf": "UF",
    "cep": "CEP",
    "bairro": "Bairro",
    "logradouro": "Logradouro",
    "numero": "Número",
    "complemento": "Complemento",
    "emp": "Empresa",
    "agenda": "Agenda",
    "clienteContato": "Contato",
};
export default names;
