import { postJsonRequest, AsyncRequest } from "./request";
import _ from "lodash";
export async function authenticate(user, password) {
    const response = await postJsonRequest("api/authorization", {
        user,
        password
    });
    addUserStorage(response);
}
export async function authenticateCliente(user, password) {
    const response = await postJsonRequest("api/authorization/cliente", {
        user,
        password
    });
    addUserStorage(response);
}
export async function authenticateParceiro(user, password) {
    const response = await postJsonRequest("api/authorization/parceiro", {
        user,
        password
    });
    addUserStorage(response);
}
export function addUserStorage(userInfo) {
    localStorage.setItem("user", JSON.stringify(userInfo));
}
export function logout() {
    localStorage.removeItem("user");
}
export async function loginGoogle(data) {
    return await AsyncRequest({
        method: "get",
        path: "api/authorization/LoginGoogle",
        headers: {
            access_token: data.tokenObj.access_token
        }
    })
        .then(resposta => {
            if (resposta.data && resposta.data.error) {
                return Promise.reject(resposta.data.error);
            }
            addUserStorage(resposta.data);
            return { error: false };
        })
        .catch(error => {
            return { error: true, message: error };
        });
}
export const niveisEnum = {
    administrativo: 0,
    usuario: 1,
    cliente: 2,
    parceiro: 3,
    master: 4
};
export function getNivel() {
    const user = localStorage.getItem("user");
    if (!user) {
        return null;
    }
    const userJson = JSON.parse(user);
    if (!userJson) {
        return null;
    }
    const nivel = _.get(userJson, "identity.permissao");
    if (isNaN(+nivel)) {
        return null;
    }
    return +nivel;
}
export function getInfo(atributo) {
    const user = localStorage.getItem("user");
    if (!user) {
        return null;
    }
    const userJson = JSON.parse(user);
    if (!userJson) {
        return null;
    }
    const value = _.get(userJson, `identity.${atributo}`);
    return value;
}
export function getUserId() {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    console.log(user);
    const id = _.get(user, "identity.usuario" || "");
    console.log(id);
    return id;
}
export function getToken() {
    try {
        const user = JSON.parse(localStorage.getItem("user") || "");
        return user.token;

    } catch (e) {
        return null
    }
}

export function canShowBi() {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const exibeBi = _.get(user, "identity.exibeBi" || false);
    return exibeBi;
}
