const names = {
    form: 'Hotel',
    id: 'ID',
    nome: 'Nome',
    posicaoGeografica: 'Localização',
    telefone: 'Telefone Fixo',
    agendaRequisicao: 'Agenda Requisição',
    email: 'E-mail',
    celular: 'Celular',
    endereco: 'Endereço',
    preferencia: 'Prefêrencia',
    cidade: 'Cidade',
    precoStandard: 'Preço Standard',
    precoDuplo: 'Preço Duplo',
    precoTriplo: 'Preço Triplo',
    site: 'Site',
    tipo: 'Tipo',
    referencia: 'Referência'
};
export default names;
