import React from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import OndeEstouAtualizaComponent from "./OndeEstouAtualizaComponent";
import { UsuarioLocalPorUsuarioComponent } from "../../generated/graphql";
import { AsyncRequest } from "../../services/request";
import { getToken } from "../../services/login";
function OndeEstouComponent(props) {
    let [openUpdate, setOpenUpdate] = React.useState(false);
    let anchorOndeEstou = React.createRef();
    return (<React.Fragment>
        <UsuarioLocalPorUsuarioComponent pollInterval={120000}>
            {({ data, loading }) => {
                if (!loading && !openUpdate) {
                    let ultimaLocalizacao = _.get(data, 'usuarioLocal.usuarioLocalPorUsuario', null);
                    if (ultimaLocalizacao) {
                        let dia = new Date(ultimaLocalizacao.data);
                        let hoje = new Date();
                        if (dia.toLocaleDateString() !== hoje.toLocaleDateString() && !openUpdate) {
                            if (ultimaLocalizacao.loc.descricao.toUpperCase().includes("ABASE".toUpperCase())) {
                                let opcoes = {
                                    method: "get",
                                    path: "http://colaborador.abase.local"
                                };
                                AsyncRequest(opcoes).then(async (x) => {
                                    let opcoesInsert = {
                                        method: "post",
                                        path: "api/usuariolocal",
                                        parameters: ultimaLocalizacao,
                                        headers: {
                                            authorization: "Bearer " + getToken()
                                        }
                                    };
                                    AsyncRequest(opcoesInsert).then(e => {
                                        console.log(e);
                                    }).catch(e => {
                                        console.log('houve um erro no insert', e);
                                    });
                                }).catch(x => {
                                    setOpenUpdate(true);
                                });
                            }
                            else {
                                setOpenUpdate(true);
                            }
                        }
                    }
                    else if (!openUpdate) {
                        setOpenUpdate(true);
                    }
                }
                return null;
            }}
        </UsuarioLocalPorUsuarioComponent>
        <div className="location" ref={anchorOndeEstou} onClick={() => {
            setOpenUpdate(true);
        }} style={{ cursor: "pointer" }}>
            <i className="material-icons">place</i>Onde estou:{" "}
            <b>{_.get(props, "local.descricao", "Local Desconhecido")}</b>
        </div>
        <OndeEstouAtualizaComponent open={openUpdate} currentLocal={_.get(props, "local.id")} onClose={() => {
            props.atualizar();
            setOpenUpdate(false);
        }} />
    </React.Fragment>);
}
export default withRouter(OndeEstouComponent);
