import { AbasePaperTable, AbaseTextField, AddAction, EditAction } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { ContasListComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal, { RenderListWithModal } from '../../RenderWithModal';
import ContasForm from './ContasForm';
import ContasLabel from './ContasNameLabel';
import { ContactPhone } from '@material-ui/icons';
import ContasContatosList from '../ContasContatos/ContasContatosList';
import { IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { useFiltroGeral } from '../../components/FiltroGeral';
import EmpresaEquipeAreaFiltro from '../../components/EmpresaEquipeAreaFiltro';
export default function ContasList(props) {
    const { match } = props;
    const filtroGeral = useFiltroGeral();
    const columns = [
        {
            name: "Empresa",
            field: "emp.nomefantasia",
            sort: false,
            width: 200,
            defaultVisible: true
        },
        {
            name: ContasLabel.cpfcnpj,
            field: 'cpfcnpj',
            width: 140,
            customBodyRender: function (val) {
                if (val) {
                    return (<p>
                        {val.length === 11 ? (val.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')) : val.length === 14 ? (val.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')) : null}
                    </p>);
                }
                else
                    return <p>N/A</p>;
            }
        },
        {
            name: ContasLabel.id,
            field: 'id',
            defaultVisible: false
        },
        {
            name: ContasLabel.idcontaIntegracao,
            field: 'idcontaIntegracao',
            defaultVisible: false
        },
        {
            name: ContasLabel.origemconta,
            field: 'origemconta',
            defaultVisible: false
        },
        {
            name: ContasLabel.nomerazaosocial,
            field: 'nomerazaosocial',
            width: "50%"
        },
        {
            name: ContasLabel.nomefantasia,
            field: 'nomefantasia'
        },
        {
            name: "Tipo",
            field: 'tipopessoa',
            cellProps: {
                style: {
                    textAlign: "center"
                }
            },
            width: 50,
            customBodyRender: function (val) {
                return (<p>
                    {val && val === 'FISICA' ? ("PF") : val && val === 'JURIDICA' ? ("PJ") : ('N/A')}
                </p>);
            }
        },
        {
            name: ContasLabel.telefone,
            field: 'telefone',
            defaultVisible: false
        },
        {
            name: ContasLabel.email,
            field: 'email',
            defaultVisible: false
        },
        {
            name: ContasLabel.inscricaoestadual,
            field: 'inscricaoestadual',
            defaultVisible: false
        },
        {
            name: ContasLabel.observacao,
            field: 'observacao',
            defaultVisible: false
        },
        // {
        // 	name: ContasLabel.datacriacao,
        // 	field: 'datacriacao',
        // 	defaultVisible: false
        // },
        {
            name: ContasLabel.cep,
            field: 'cep',
            defaultVisible: false
        },
        {
            name: ContasLabel.cidade,
            field: 'cidade',
            defaultVisible: true
        },
        {
            name: ContasLabel.ativo,
            field: 'ativo',
            width: 50,
            cellProps: {
                style: {
                    textAlign: "center"
                }
            },
            customBodyRender: function (val) {
                return <p style={{ textAlign: 'center' }}>{val ? <DoneIcon /> : <NotInterestedIcon />}</p>;
            }
        },
        {
            name: ContasLabel.cliente,
            field: 'cliente',
            width: 50,
            cellProps: {
                style: {
                    textAlign: "center"
                }
            },
            customBodyRender: function (val) {
                return <p>{val ? <DoneIcon /> : <NotInterestedIcon />}</p>;
            }
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            selected.length === 1 ? (<Link to={`${match.path}/contascontatos/${selected[0].id}`} {...props} title={`Contatos da Conta`}>
                <IconButton aria-label={`Contatos da Conta`}>
                    <ContactPhone />
                </IconButton>
            </Link>) : null
            // <DeleteAction
            // 	key="delete"
            // 	to={`${match.path}/delete/${_.map(selected, 'id').join(',')}`}
            // 	component={Link}
            // />
        ];
    };
    const customActions = [<AddAction key="add" to={`${match.path}/add`} component={Link} />];
    const filters = () => [
        // <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta} />}</Field>,

        <EmpresaEquipeAreaFiltro hideArea />,

        <Field name={'origemconta'}>
            {({ input, meta }) => <AbaseTextField label={'Origem Conta'} {...input} meta={meta} />}
        </Field>,
        <Field name={'nomerazaosocial'}>
            {({ input, meta }) => <AbaseTextField label={'Nome Razão Social'} {...input} meta={meta} />}
        </Field>,
        <Field name={'nomefantasia'}>
            {({ input, meta }) => <AbaseTextField label={'Nome Fantasia'} {...input} meta={meta} />}
        </Field>,
        <Field name={'cpfcnpj'}>
            {({ input, meta }) => <AbaseTextField label={'CPF/CNPJ'} {...input} meta={meta} />}
        </Field>,
        <Field name={'tipopessoa'}>
            {({ input, meta }) => <AbaseTextField label={'Tipo Pessoa'} {...input} meta={meta} />}
        </Field>,
        <Field name={'telefone'}>
            {({ input, meta }) => <AbaseTextField label={'Telefone'} {...input} meta={meta} />}
        </Field>,
        <Field name={'email'}>{({ input, meta }) => <AbaseTextField label={'E-mail'} {...input} meta={meta} />}</Field>,
        <Field name={'inscricaoestadual'}>
            {({ input, meta }) => <AbaseTextField label={'Inscricao Estadual'} {...input} meta={meta} />}
        </Field>,
        <Field name={'observacao'}>
            {({ input, meta }) => <AbaseTextField label={'Observação'} {...input} meta={meta} />}
        </Field>,
        <Field name={'cep'}>{({ input, meta }) => <AbaseTextField label={'CEP'} {...input} meta={meta} />}</Field>,
        <Field name={'cidade'}>
            {({ input, meta }) => <AbaseTextField label={'Cidade'} {...input} meta={meta} />}
        </Field>,

    ];
    const formName = ContasLabel.form;
    return (<GraphqlPaperTableListener>
        {({ variables, setVariables }) => (<ContasListComponent variables={variables} skip={variables === null}>
            {({ data, loading, refetch, error }) => (<div>
                <AbasePaperTable
                    customPlacehorderSearch={"Pesquisar por nome fantasia ou razão social"}
                    sticky={true}
                    selectMode={'single'}
                    loading={loading}
                    data={_.get(data, 'contas.pagedList.data') || []}
                    total={_.get(data, 'contas.pagedList.total')}
                    columns={columns}
                    customFilters={filters}
                    title={formName}
                    requestData={setVariables}
                    error={errorMessagePaperTable(error)}
                    customSelectedActions={selectedActions}
                    customActions={customActions}
                    selectListener={props.selectListener}
                    onDoubleClick={row => {
                        props.history.push(`${match.path}/edit/${row.id}`);
                    }} />
                <ReenterRouteListener callback={refetch} />
                <Route path={`${match.url}/contascontatos/:id`} render={RenderListWithModal(ContasContatosList)} />
                <Route path={`${match.path}/add`} render={RenderWithModal(ContasForm)} />
                <Route path={`${match.path}/edit/:id`} render={RenderWithModal(ContasForm)} />


            </div>)}
        </ContasListComponent>)}
    </GraphqlPaperTableListener>);
}
