import React from 'react';
import { Message } from 'semantic-ui-react';
import { useClienteAlertas } from '../generated/graphql';
import _ from 'lodash';
import { useAbaseDialog } from 'abase-components';
export default function AlertaCliente({ clienteId, showAlert }) {
    const { data } = useClienteAlertas({ skip: !clienteId, variables: { id: clienteId } });
    const { showError } = useAbaseDialog();
    React.useEffect(() => {
        if (clienteId && _.get(data, "cliente.list[0].possuiPendenciaFinanceira") && showAlert) {
            console.log("SHOW ALERT");
            showError({
                message: "ATENÇÃO: Cliente possui pendência financeira no sistema GESTOR"
            });
        }
    }, [clienteId, _.get(data, "cliente.list[0].possuiPendenciaFinanceira"), showAlert]);
    if (!clienteId)
        return null;
    if (!_.get(data, "cliente.list[0]")) {
        return null;
    }
    const cliente = data.cliente.list[0];
    const headerMessage = (text) => text.indexOf(":") !== -1 ? text.substring(0, text.indexOf(":")) : "";
    const bodyMessage = (text) => text.indexOf(":") !== -1 ? text.substring(text.indexOf(":")) : "";
    return (<React.Fragment>
            <div style={{ marginTop: 15 }}>

                {cliente.possuiPendenciaFinanceira ? <Message negative header='CLIENTE POSSUI PENDÊNCIAS FINANCEIRAS' content="O cliente possui pendências financeiras no sistema gestor"/> : null}
                {cliente.alertas.map((x, index) => {
        return (<Message info key={index} header={headerMessage(x)} content={bodyMessage(x)}/>);
    })}
            </div>
        </React.Fragment>);
}
