import { AbasePaperTable, AbaseTextField } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { CategoriaListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import CategoriaLabel from './CategoriaNameLabel'
import EmpresaEquipeAreaFiltro from "../../components/EmpresaEquipeAreaFiltro";

export default function CategoriaList(props) {
    const { match } = props
    const columns = [

        {
            name: CategoriaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: CategoriaLabel.empId,
            field: "empId",
            defaultVisible: false
        },
        {
            name: "Descrição",
            field: "descricao"
        },
        {
            name: "Cód. Gestor",
            width: 100,
            field: "gestorChaveintegracao"
        },
        {
            name: "Ativo",
            field: "ativo",
            width: 50,
            customBodyRender: value => value ? "S" : "N",
        },
        {
            name: "Empresa",
            field: "emp.nomefantasia",
            width: 150
        },
    ]
    const selectedActions = (selected) => {

        if (props.foreing)
            props.foreing(selected)
        return []
    }
    const customActions = []
    const filters = () => ([
        // <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <EmpresaEquipeAreaFiltro hideArea />,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descricao"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"gestorChaveintegracao"}>{({ input, meta }) => <AbaseTextField label={"GestorChaveintegracao"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ])
    const formName = CategoriaLabel.form

    return (

        <GraphqlPaperTableListener>{
            ({ variables, setVariables }) => (

                <CategoriaListComponent
                    variables={variables}
                    skip={variables === null}
                >
                    {({ data, loading, refetch, error }) => (<div>

                        <AbasePaperTable
                            customPlacehorderSearch={"Pesquisar por descrição ou código do gestor"}
                            sticky={true}
                            selectMode={"multi"}
                            loading={loading}
                            data={_.get(data, "categoria.pagedList.data") || []}
                            total={_.get(data, "categoria.pagedList.total")}
                            columns={columns}
                            customFilters={filters}
                            title={formName}
                            requestData={setVariables}
                            error={errorMessagePaperTable(error)}
                            customSelectedActions={selectedActions}
                            customActions={customActions}
                            selectListener={props.selectListener}
                            onDoubleClick={row => {
                                props.history.push(`${match.url}/edit/${row.id}`)
                            }}
                        ></AbasePaperTable>
                        <ReenterRouteListener callback={refetch} />
                    </div>
                    )}
                </CategoriaListComponent>
            )
        }
        </GraphqlPaperTableListener>
    )
}
