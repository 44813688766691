import { ResponsivePaperTable, AbaseTextField, AddAction, AbaseDatePickerField } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { AgendaRecursoListComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RecursoLabel from './RecursoNameLabel';
export default function RecursoHistorico(props) {
    const { match } = props;
    const columns = [
        {
            name: RecursoLabel.id,
            field: 'id',
            defaultVisible: true
        },
        {
            name: "Titulo",
            field: 'age.titulo',
            defaultVisible: true,
            sort: false,
            customBodyRender: value => value ? value : "N/A"
        },
        {
            name: "Tipo de Agenda",
            field: 'age.agendaTipo.descricao',
            defaultVisible: true,
            sort: false,
            customBodyRender: value => value ? value : "N/A"
        },
        {
            name: "Usuario",
            field: 'age.usu.nome',
            defaultVisible: true,
            sort: false,
            customBodyRender: value => value ? value : "N/A"
        },
        {
            name: "Data Retirada",
            field: 'age.inicio',
            defaultVisible: true,
            sort: false,
            customBodyRender: value => value ? new Date(value).toLocaleString() : "N/A"
        }, {
            name: "Data Retorno",
            field: 'age.fim',
            defaultVisible: true,
            sort: false,
            customBodyRender: value => value ? new Date(value).toLocaleString() : "N/A"
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [];
    };
    const customActions = [<AddAction key="add" to={`${match.path}/add`} component={Link}/>];
    const filters = () => [
        <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta}/>}</Field>,
        <Field name={'titulo'}>{({ input, meta }) => <AbaseTextField label={'Título'} {...input} meta={meta}/>}</Field>,
        <Field name={'dataInicio'}>{({ input, meta }) => <AbaseDatePickerField meta={meta} {...input} label={"Data Inicial"}/>}</Field>,
        <Field name={'dataFim'}>{({ input, meta }) => <AbaseDatePickerField meta={meta} {...input} label={"Data Final"}/>}</Field>,
    ];
    return (<GraphqlPaperTableListener>
            {({ variables, setVariables }) => {
        if (match.params.recursoId && (!variables || (variables.recursoId !== match.params.recursoId))) {
            setVariables({ ...variables, recursoId: match.params.recursoId });
        }
        return (<AgendaRecursoListComponent variables={variables} skip={variables === null}>
                        {({ data, loading, refetch, error }) => (<div>
                                <ResponsivePaperTable sticky={true} selectMode={'single'} loading={loading} data={_.get(data, 'agendaRecurso.pagedList.data') || []} total={_.get(data, 'agendaRecurso.pagedList.total')} columns={columns} customFilters={filters} title={'Histórico do Recurso'} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener}/>
                                <ReenterRouteListener callback={refetch}/>
                            </div>)}
                    </AgendaRecursoListComponent>);
    }}
        </GraphqlPaperTableListener>);
}
;
