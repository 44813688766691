import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
const UserAvatar = require('react-user-avatar');
const preloadedImages = {};
export default React.forwardRef(function UserAvatarSac({ url, size, ...props }, ref) {
    if (url && !preloadedImages[url]) {
        const newImg = new Image();
        newImg.src = url;
        preloadedImages[url] = newImg;
    }
    return (<Tooltip title={props.name} aria-label={props.name}>
        <IconButton onClick={(e) => {
        e.stopPropagation();
        if (props.onClick)
            props.onClick(e);
    }} style={{ padding: 0, fontSize: 13 }} buttonRef={ref} tabIndex={-1}>
            <UserAvatar size={size || "32"} {...props} src={url}/>
        </IconButton>
    </Tooltip>);
});
