import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm, AbaseTable } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { AgendaTipoQueryByIdComponent, useAgendaTipoCreate, useAgendaTipoUpdate, EmpresaAllComponent } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import AgendaTipoLabel from './AgendaTipoNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { agendaTabs } from '../Agenda/AgendaNameLabel';
import { Checkbox } from 'semantic-ui-react';
import { Grid } from '@material-ui/core';
const AgendaTipoSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    descricao: Yup.string().required(),
    empId: Yup.string().required(),
    tabs: Yup.string().notRequired().nullable(),
}).noUnknown();
function AgendaTipoForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useAgendaTipoUpdate();
    const insert = useAgendaTipoCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(AgendaTipoSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.agendaTipo.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.agendaTipo.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<AgendaTipoQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(AgendaTipoSchema)} initialValues={_.get(data, "agendaTipo.list[0]") || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Tipo de Agenda" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
            <AbaseLoading open={loading || submitting} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <EmpresaAllComponent>
                        {({ data, loading }) => (<Field name={'empId'}>
                            {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={'Empresa'} valueField={'id'} textField={'razaosocial'} data={_.get(data, 'empresa.list')} {...input} />)}
                        </Field>)}
                    </EmpresaAllComponent>
                </Grid>
                <Grid item xs={12}>
                    <Field name="descricao" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={AgendaTipoLabel.descricao} />)}</Field>
                </Grid>
                <Grid item xs={12}>
                    <Field name="tabs">{({ input, meta }) => (<TabsSelect {...input} />)}</Field>
                </Grid>
            </Grid>
        </DialogForm>)} />)}
    </AgendaTipoQueryByIdComponent>);
}
function TabsSelect({ onChange, value }) {
    const objectValue = !value || value.startsWith("{") ? [] : JSON.parse(value);
    const tabs = _.sortBy(Object.keys(agendaTabs)
        .map(x => {
            const id = agendaTabs[x];
            const objectItem = _.first(objectValue.filter(x => x.id === id)) || {};
            const visible = objectItem.visible || false;
            const required = objectItem.required || false;
            const order = objectItem.order || 0;
            return {
                visible,
                required,
                name: x,
                id,
                order,
            };
        }), "order")
        .map((x, index) => ({ ...x, order: index }));
    console.log("TABS", { tabs });
    const arrayMoveMutate = (array, from, to) => {
        array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    };
    const arrayMove = (array, from, to) => {
        array = array.slice();
        arrayMoveMutate(array, from, to);
        return array;
    };
    const reorder = (list, startIndex, endIndex) => {
        const r = arrayMove(list, startIndex, endIndex).map((x, i) => ({ ...x, order: i }));
        return r;
    };
    return (<div style={{
        marginTop: 10
    }}>
        <div className={"colDivBloco tableModal"}>
            <AbaseTable selectMode={false} columns={[
                {
                    field: "name",
                    name: "DESCRIÇÃO",
                    cellProps: {
                        style: {
                            paddingLeft: "10px"
                        }
                    },
                    sort: false,
                    width: "50%",
                    customBodyRender: value => value.toUpperCase()
                },
                {
                    field: "visible",
                    name: "VISÍVEL",
                    sort: false,
                    cellProps: {
                        style: {
                            textAlign: "center"
                        }
                    },
                    customBodyRender: (value, row, column) => {
                        return (<Checkbox checked={value} onChange={(e, value) => {
                            const r = tabs.map(x => {
                                if (x.id === row.id) {
                                    return { ...x, required: !value.checked ? false : row.required, visible: value.checked };
                                }
                                return x;
                            });
                            onChange(JSON.stringify(r));
                        }} />);
                    }
                },
                {
                    sort: false,
                    cellProps: {
                        style: {
                            textAlign: "center"
                        }
                    },
                    field: "required",
                    name: "OBRIGATÓRIO",
                    customBodyRender: (value, row, column) => {
                        return (<Checkbox checked={value} onChange={(e, value) => {
                            const r = tabs.map(x => {
                                if (x.id === row.id) {
                                    return { ...x, visible: value.checked ? true : row.visible, required: value.checked };
                                }
                                return x;
                            });
                            onChange(JSON.stringify(r));
                        }} />);
                    }
                },
                {
                    sort: false,
                    cellProps: {
                        style: {
                            textAlign: "center"
                        }
                    },
                    field: "order",
                    name: "ORDEM",
                    customBodyRender: (value, row, column) => {
                        return (<React.Fragment>
                            <span title={"Mover para cima"} style={{ cursor: "pointer" }} onClick={() => {
                                if (row.order === 0) {
                                    return;
                                }
                                const r = reorder(tabs, row.order, row.order - 1);
                                onChange(JSON.stringify(r));
                            }}><i className={"material-icons"}>expand_less</i></span>
                            <span title={"Mover para baixo"} style={{ cursor: "pointer" }} onClick={() => {
                                if (row.order >= tabs.length - 1)
                                    return;
                                const r = reorder(tabs, row.order, row.order + 1);
                                console.log({ r });
                                onChange(JSON.stringify(r));
                            }}><i className={"material-icons"}>expand_more</i></span>
                        </React.Fragment>);
                    }
                }
            ]} loading={false} paginated={false} data={tabs} />
        </div>
    </div>);
}
export default AgendaTipoForm;
