import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { UsuarioEquipeListComponent, EquipeQueryByIdComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal from '../../RenderWithModal';
import UsuarioEquipeDelete from './UsuarioEquipeDelete';
import UsuarioEquipeForm from './UsuarioEquipeForm';
import UsuarioEquipeLabel from './UsuarioEquipeNameLabel';
export default function UsuarioEquipeList(props) {
    const { match } = props;
    const columns = [
        {
            name: UsuarioEquipeLabel.id,
            field: 'id',
            defaultVisible: false,
            sort: true
        },
        {
            name: UsuarioEquipeLabel.equId,
            field: 'equId',
            defaultVisible: false
        },
        {
            name: UsuarioEquipeLabel.usuId,
            field: 'usuId',
            defaultVisible: false
        },
        {
            name: UsuarioEquipeLabel.usu,
            field: 'usu.nome',
            defaultVisible: true,
            sort: false
        },
        {
            name: UsuarioEquipeLabel.emp,
            field: 'equ.emp.nomefantasia',
            defaultVisible: true,
            sort: false
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            <DeleteAction key="delete" to={`${match.url}/delete/${_.map(selected, 'id').join(',')}`} component={Link} />
        ];
    };
    const customActions = [<AddAction key="add" to={`${match.url}/add`} params={match.params} component={Link} />];
    const filters = () => [
        <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta} />}</Field>
    ];
    return (<EquipeQueryByIdComponent variables={{
        id: match.params['id']
    }}>
        {({ data, loading, refetch, error }) => {
            const formName = `Membros da Equipe ${_.get(data, 'equipe.list[0].descricao', '"Equipe Não Cadastrada"')}`;
            return (<GraphqlPaperTableListener>
                {({ variables, setVariables }) => {
                    return (<UsuarioEquipeListComponent variables={{ equId: match.params['id'], ...variables, sort: null }} skip={variables === null}>
                        {({ data, loading, refetch, error }) => (<div className="spaceForms">
                            <AbasePaperTable sticky={true} selectMode={'multi'} loading={loading} data={_.get(data, 'usuarioEquipe.pagedList.data') || []} total={_.get(data, 'usuarioEquipe.pagedList.total')} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} />
                            <ReenterRouteListener callback={refetch} />
                            <Route path={`${match.path}/add`} render={RenderWithModal(UsuarioEquipeForm)} />
                            <Route path={`${match.url}/delete/:idUsuarioEquipe`} render={RenderWithModal(UsuarioEquipeDelete)} />
                        </div>)}
                    </UsuarioEquipeListComponent>);
                }}
            </GraphqlPaperTableListener>);
        }}
    </EquipeQueryByIdComponent>);
}
