import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm, AbaseAutocompleteMultipleField } from 'abase-components';
import { Field } from 'react-final-form';
import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { AreaAllComponent } from './../../generated/graphql';
import { EquipeQueryByIdComponent, useEquipeCreate, useEquipeUpdate, EmpresaAllComponent } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { validate, cast } from '../../helpers/validations';
import DialogForm from '../../DialogForm';
import EquipeLabel from './EquipeNameLabel';
import { Grid } from '@material-ui/core';
const EquipeSchema = Yup.object()
    .shape({
    id: Yup.string().notRequired(),
    descricao: Yup.string().required(),
    empId: Yup.string().required(),
    areaEquipe: Yup.array(Yup.object().shape({
        id: Yup.string().notRequired(),
        are: Yup.object().shape({
            id: Yup.string().notRequired(),
            descricao: Yup.string().notRequired()
        }).noUnknown()
    }).noUnknown())
})
    .noUnknown();
function EquipeForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useEquipeUpdate();
    const insert = useEquipeCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(EquipeSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.equipe.update.id'));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.equipe.create.id'));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<EquipeQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
    }}>
			{({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(EquipeSchema)} initialValues={_.get(data, 'equipe.list[0]') || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Equipe" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
							<AbaseLoading open={loading || submitting}/>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Field name="descricao" parse={defaultFieldParser}>
										{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={EquipeLabel.descricao}/>)}
									</Field>
								</Grid>
								<Grid item xs={12}>
									<EmpresaAllComponent>
										{({ data, loading }) => (<Field name="empId">
												{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={EquipeLabel.emp} valueField={'id'} textField={'razaosocial'} data={_.get(data, 'empresa.list', [])} {...input}/>)}
											</Field>)}
									</EmpresaAllComponent>
								</Grid>
								<Grid item xs={12}>
									<AreaAllComponent>
										{({ data, loading }) => (<Field name="areaEquipe">
												{({ input, meta }) => (<React.Fragment>
														<AbaseAutocompleteMultipleField isLoading={loading} label={"Áreas"} getOptionValue={x => x} getOptionLabel={x => x.are.descricao} data={_.get(data, 'area.list', []).map(x => {
        const existingValue = input.value ? _.first(input.value.filter(y => y.are.id === x.id)) : false;
        if (existingValue) {
            return existingValue;
        }
        return { are: x };
    })} {...input}/>
													</React.Fragment>)}
											</Field>)}
									</AreaAllComponent>
								</Grid>
							</Grid>
						</DialogForm>)}/>)}
		</EquipeQueryByIdComponent>);
}
export default EquipeForm;
