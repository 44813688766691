const LoginPageStyles = (theme) => {
    return {
        main: {
            width: "auto",
            display: "block",
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
                top: "50%",
                width: 400,
                left: "50%",
                marginTop: "-131px",
                marginLeft: "-200px",
                position: "absolute"
            }
        },
        paper: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme
                .spacing.unit * 3}px`
        },
        avatar: {
            margin: theme.spacing.unit,
            backgroundColor: theme.palette.secondary.main
        },
        form: {
            width: "100%",
            marginTop: theme.spacing.unit
        },
        submit: {
            marginTop: theme.spacing(3)
        },
        cliente: {
            marginTop: theme.spacing.unit
        },
        loginGmail: {
            marginTop: theme.spacing.unit
        },
        googleButton: {
            marginTop: "10px",
            height: "40px",
            borderWidth: 0,
            background: "white",
            color: "#737373",
            borderRadius: "5px",
            cursor: "pointer",
            whiteSpace: "nowrap",
            boxShadow: "1px 1px 0px 1px rgba(0, 0, 0, 0.05)",
            transitionProperty: "background - color, box - shadow",
            transitionDuration: "150ms",
            transitionTimingFunction: "ease-in -out",
            padding: 0,
            width: "100%",
            "button:hover": {
                boxShadow: "0px 2px 2px 0px rgba(0,0,0,0.1)"
            },
            "&:active": {
                backgroundColor: "#e5e5e5",
                boxShadow: "none",
                transitionDuration: "10ms"
            }
        },
        googleButtonIcon: {
            display: "inline-block",
            verticalAlign: "middle",
            //   margin: "8px 0 8px 8px",
            width: "14px",
            height: "14px",
            boxSizing: "border-box"
        },
        googleButtonText: {
            display: "inline-block",
            verticalAlign: "middle",
            padding: "0 24px",
            fontSize: "14px",
            fontWeight: "bold"
            // fontFamily: 'Roboto arial sans-serif',
        }
    };
};
export { LoginPageStyles };
