import { ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";
import MenuWithSubmenu from "./components/MenuWithSubmenu";
import "./scss/custom.scss";
import { useFiltroGeral } from "./components/FiltroGeral";
import AgendaNavbar from "./components/AgendaNavbar";
import withIsMobile from "./components/withIsMobile";
import { getNivel, niveisEnum, logout, canShowBi } from "./services/login";
import { UsuarioAutenticadoComponent } from "./generated/graphql";
import OndeEstouAtualizaComponent from "./components/OndeEstou/OndeEstouAtualizaComponent";
import _ from "lodash";

import { getToken } from './services/login';
import { getConfig } from './config';
import { SelecionarCliente } from "./pages/HomeClientePage/SelecionarCliente";
const LeftMenuItems = withRouter(({ history, match, hide, nivel }) => {
    const [openOndeEstou, setOpenOndeEstou] = React.useState(false);
    const filtroGeral = useFiltroGeral();
    return (<React.Fragment>
        <ListItem button onClick={() => {
            history.push("/agenda/agenda");
            hide();
        }}>
            <ListItemIcon>
                <i className={"material-icons"}>calendar_today</i>
            </ListItemIcon>
            <ListItemText primary="Agendas" />
        </ListItem>
        <ListItem button onClick={() => {
            history.push("/cadastros/cliente");
            hide();
        }}>
            <ListItemIcon>
                <i className={"material-icons"}>person</i>
            </ListItemIcon>
            <ListItemText primary="Clientes" />
        </ListItem>

        <ListItem button onClick={() => filtroGeral.setFiltroVisivel(true)}>
            <ListItemIcon>
                <i className={"material-icons"}>filter_list</i>
            </ListItemIcon>
            <ListItemText primary="Filtro Geral" />
        </ListItem>
        {nivel === niveisEnum.parceiro ?
            <ListItem button onClick={() => filtroGeral.setFiltroClienteVisivel(true)}>
                <ListItemIcon>
                    <i className={"material-icons"}>people_alt</i>
                </ListItemIcon>
                <ListItemText primary="Selecionar Cliente" />
            </ListItem>
            : null}
        <UsuarioAutenticadoComponent pollInterval={120000}>
            {({ data }) => (<React.Fragment>
                <ListItem button onClick={() => {
                    setOpenOndeEstou(true);
                }}>
                    <ListItemIcon>
                        <i className={"material-icons"}>all_out</i>
                    </ListItemIcon>

                    <ListItemText primary="Onde Estou" />
                </ListItem>
                <OndeEstouAtualizaComponent open={openOndeEstou} currentLocal={_.get(data, "usuario.me.ultimaLocalizacao.locId")} onClose={() => {
                    setOpenOndeEstou(false);
                }} />
            </React.Fragment>)}
        </UsuarioAutenticadoComponent>
        <ListItem button onClick={() => {
            const nivel = getNivel();
            logout();
            hide();
            if (nivel !== niveisEnum.cliente) {
                history.replace("/login");
            }
            else {
                history.replace("/logincliente");
            }
        }}>
            <ListItemIcon>
                <i className={"material-icons"}>exit_to_app</i>
            </ListItemIcon>
            <ListItemText primary="Sair" />
        </ListItem>
    </React.Fragment>);
});
class App extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isThemeLight: true
        };
        this.onRequestData = (event) => {
            console.log("onRequestData", event);
            this.setState({ variables: { ...event, ...event.filters } });
        };
        this.ocultarNavBar = ['/login', '/logincliente', '/loginparceiro', '/agenda/ordemdeservico/relatorio/:uuid', '/report'];
    }
    mapData(loading, data) {
        if (!loading)
            return ((data && data.pagedOrganizacao && data.pagedOrganizacao.data) || []);
        return [];
    }
    render() {
        const { children } = this.props;
        const { isMobile } = this.props;
        const nivel = getNivel();
        const jwt = getToken();
        const { supersetUrl } = getConfig();
        const supersetUrlLogin = `${supersetUrl}/login/?jwt=${encodeURIComponent(jwt)}&redirect=${encodeURIComponent(`${supersetUrl}/superset/welcome`)}`;

        const menuCliente = (<React.Fragment>
            <MenuWithSubmenu label={"Agenda"} items={[{ label: "Minhas agendas", route: "/cliente/home" }]} />
            <MenuWithSubmenu label={"Projeto"} items={[{ label: "Projetos Envolvido", route: "/projeto" }]} />
        </React.Fragment>);
        const topItems = (nivel === niveisEnum.cliente || nivel === niveisEnum.parceiro) ? (menuCliente) : (<React.Fragment>
            <MenuWithSubmenu label={"Agenda"} items={[
                { label: "Agenda", route: "/agenda/agenda" },
                { label: "Calendário", route: "/agenda/calendar" },
                // { label: 'Tarefas', route: '/agenda/tarefa' },
                { label: "Encontrar Pessoa", route: "/agenda/usuariolocal" },
                { label: "Requisições", route: "/agenda/requisitos" },
                { label: "Ordem de Serviço", route: "/agenda/ordemdeservico" },
                { label: "Reserva de recursos", route: "/agenda/recursolocacao" },
                nivel === niveisEnum.master ? { label: "Log", route: "/log" } : null,
                (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ? { label: "Termo de Aceite", route: "/agenda/termoaceite" } : null,
            ]} />

            <MenuWithSubmenu label={"Cadastros"} items={
                [
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Áreas", route: "/cadastros/area" } : null,
                    (true) ?
                        { label: "Atividade", route: "/cadastros/atividade" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Categoria", route: "/cadastros/categoria" } : null,
                    (true) ?
                        { label: "Clientes", route: "/cadastros/cliente" } : null,
                    (true) ?
                        { label: "Contas", route: "/cadastros/contas" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Empresa", route: "/cadastros/empresa" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Equipes", route: "/cadastros/equipe" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Hoteis", route: "/cadastros/hotel" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Localizações", route: "/cadastros/local" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Modelo de Termo de Aceite", 'route': '/cadastros/modelotermoaceite' } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Observacao Tipo", route: "/cadastros/observacaoTipo" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Parceiros", route: "/cadastros/parceiro" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Recursos", route: "/cadastros/recurso" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Responsável de Requisição", route: "/cadastros/requisicaoresponsavel" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Restaurantes", route: "/cadastros/restaurante" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Tipo de Recurso", route: "/cadastros/recursotipo" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Tipo de Requisição", route: "/cadastros/requisicaotipo" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Tipo de Tarefa", route: "/cadastros/tarefatipo" } : null,
                    (nivel === niveisEnum.administrativo || nivel === niveisEnum.master) ?
                        { label: "Tipos de Agenda", route: "/cadastros/agendatipo" } : null,
                    (nivel === niveisEnum.master) ?
                        { label: "Usuários", route: "/cadastros/usuario" } : null,
                ]
            } />

            {(nivel === niveisEnum.master) ?
                <MenuWithSubmenu label={"Projeto"} items={[
                    { label: "Projeto", route: "/projeto/projeto" },
                    { label: "Modelo", route: "/projeto/modelo" }
                ]} />
                : null}

            {(nivel === niveisEnum.administrativo || nivel === niveisEnum.master) || canShowBi() ? (<MenuWithSubmenu label={"Gestão"} items={[
                {
                    label: "Superset", route: supersetUrlLogin
                },
                { label: "Agenda", route: "/dashboards/agenda" }
            ]} />) : null}

        </React.Fragment>);
        return (<AgendaNavbar key="navBar" leftMenuItems={<LeftMenuItems nivel={nivel} />} showDrawer={isMobile} topItems={topItems}
            // regex substitui os uuid por :uuid, sendo assim, quando a rota tiver um uuid pode ser colocado apenas com :uuid no lugar da variavel
            ocultar={this.ocultarNavBar.includes(this.props.location.pathname.replace(/\b[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}\b/, ":uuid"))}>
            <SelecionarCliente />
            {children}
        </AgendaNavbar>);
    }
}
App.displayName = App.name;
export default withIsMobile(withRouter(App));
