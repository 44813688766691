const names = {
    "form": "Modelo de Tarefa",
    "id": "Id",
    "modId": "Modelo ID",
    "proId": "Projeto ID",
    "tarId": "Tarefa ID",
    "descricao": "Descrição",
    "tempo": "Tempo",
    "mod": "Etapa",
    "pro": "Produto",
    "tar": "Tipo de Tarefa",
};
export default names;
