/* eslint-disable default-case */
import { ResponsivePaperTable, AddAction, DeleteAction, useAbaseDialog } from "abase-components";
import _ from "lodash";
import uuid from 'node-uuid';
import React from "react";
import { Route, withRouter } from "react-router";
import { Link } from "react-router-dom";
import AgendaRecursoDelete from "./../AgendaRecurso/AgendaRecursoDelete";
import AgendaRecursoForm from "./../AgendaRecurso/AgendaRecursoForm";
import AgendaRecursoLabel from './../AgendaRecurso/AgendaRecursoNameLabel';
import { FormSpy } from "react-final-form";
function AgendaRecursoList(props) {
    const { showError } = useAbaseDialog();
    const location = props.match.url + "/recurso";
    const columns = [
        {
            name: AgendaRecursoLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: "Recurso",
            field: "rec.nome"
        }
    ];
    const selectedActions = (selected) => {
        return [
            // selected.length === 1 ? (<EditAction
            //     key="edit"
            //     to={`${location}/edit/${selected[0].id}`} component={Link}
            // />) : null,
            <DeleteAction key="delete" to={`${location}/delete/${_.map(selected, "id").join(",")}`} component={Link} />
        ];
    };
    const onSave = (values, action, item, nextAction) => {
        let dataUpdated = props.data ? [...props.data] : [];
        switch (action) {
            case "add":
                dataUpdated = [...dataUpdated, { ...item, id: uuid.v4(), state: item.state || "add" }];
                break;
            case "update":
                console.log("UPDATING", dataUpdated, item.id);
                dataUpdated = dataUpdated.map(x => {
                    if (!x)
                        return null;
                    if (x.id === item.id) {
                        return { ...x, ...item, state: x.state || "update" };
                    }
                    return x;
                }).filter(x => x !== null);
                break;
            case "delete":
                dataUpdated = dataUpdated.map((x) => {
                    if (x.state === "add" && item.indexOf(x.id) !== -1)
                        return null;
                    return item.indexOf(x.id) !== -1 ? { ...x, state: "delete" } : x;
                }).filter(x => x !== null);
                document.getElementById('saveAndContinueBtn').click()
                break;
        }
        props.onChangeData(dataUpdated);
        if (nextAction === "continue") {
            setTimeout(() => {
                const url = `${location}/add?inicio=${values.inicio}&fim=${values.fim || ""}&local=${values.locId}&agendaOrigem=${values.id || ""}`;
                props.history.push(url);
            }, 100);
        }
    };
    const { data } = props;
    const otheProps = {
        tableProps: {
            paginated: false
        }
    };
    return (<React.Fragment>
        <div className={"colDivBloco"}>
            <FormSpy subscription={{ values: true, initialValues: true }}>{({ values }) => (<React.Fragment>

                <ResponsivePaperTable sticky={true} selectMode={"multi"} loading={false} data={data && data.filter((x) => x.state !== "delete")} total={0} columns={columns} customFilters={null} title={""} requestData={() => Promise.resolve([])} customSelectedActions={selectedActions} customActions={[
                    <AddAction key="add" forwardRef={props.focusRef} onClick={() => {
                        if (!values.locId)
                            return showError({
                                message: "Informe o local de destino para locar um recurso"
                            });
                        if (!values.inicio)
                            return showError({
                                message: "Informe o a data de início para locar um recurso"
                            });
                        const url = `${location}/add?inicio=${values.inicio}&fim=${values.fim || ""}&local=${values.locId}&agendaOrigem=${values.id || ""}`;
                        props.history.push(url);
                    }} onKeyDown={(e) => {
                        if (props.navigatePrevKeyDown) {
                            props.navigatePrevKeyDown(e);
                        }
                        if (props.navigateNextKeyDown) {
                            props.navigateNextKeyDown(e);
                        }
                    }} />
                ]} disableFilter={true} {...otheProps}></ResponsivePaperTable>
                <Route path={`${location}/add`} render={(props) => <AgendaRecursoForm onSubmitItem={onSave.bind(null, values, "add")} {...props} />} />
                <Route path={`${location}/edit/:id`} render={(props) => <AgendaRecursoForm data={data} onSubmitItem={onSave.bind(null, values, "update")} {...props} />} />
                <Route path={`${location}/delete/:id`} render={(props) => <AgendaRecursoDelete onSubmitItem={onSave.bind(null, values, "delete")} {...props} />} />
            </React.Fragment>)}</FormSpy>

        </div>
    </React.Fragment>);
}
export default withRouter(AgendaRecursoList);
