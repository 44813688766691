import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ProjetoEtapaListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal, { RenderListWithModal } from "../../RenderWithModal";
import ProjetoEtapaDelete from "./ProjetoEtapaDelete";
import ProjetoEtapaForm from "./ProjetoEtapaForm";
import ProjetoEtapaLabel from './ProjetoEtapaNameLabel';
import TarefaList from "../Tarefa/TarefaList";
export default function ProjetoEtapaList(props) {
    const { match } = props;
    const columns = [
        {
            name: ProjetoEtapaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: ProjetoEtapaLabel.ordem,
            field: "ordem",
            sort: true,
            width: 50
        },
        {
            name: ProjetoEtapaLabel.proId,
            field: "proId",
            defaultVisible: false
        },
        {
            name: ProjetoEtapaLabel.descricao,
            field: "descricao"
        },
        {
            name: ProjetoEtapaLabel.porcentagem,
            field: "porcentagem",
            customBodyRender: (value) => value ? value + "%" : "N/A",
            width: 100
        },
        {
            name: ProjetoEtapaLabel.dataLimite,
            field: "dataLimite",
            customBodyRender: (val) => (val ? new Date(val).toLocaleDateString() : 'N/A'),
            width: 100
        },
        {
            name: ProjetoEtapaLabel.pro,
            field: "pro.descricao",
            sort: false,
            defaultVisible: !match.params.projetoId
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            // selected.length === 1 ? (
            //     <Link to={`${match.url}/tarefas/${selected[0].id}`} {...props} title={`Visualizar tarefas`}>
            //         <IconButton aria-label={`Tarefas`}>
            //             <i className={"material-icons"}>toc</i>
            //         </IconButton>
            //     </Link>
            // ) : null,
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link}/>) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/delete/${_.map(selected, "id").join(",")}`}/>,
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.url}/add`}/>,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descrição"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    return (<React.Fragment>
            <div className={"colDivBloco"}>
                <GraphqlPaperTableListener>{({ variables, setVariables }) => {
        if (props.projetoId) {
            if (!variables || (variables.projetoId !== props.projetoId)) {
                var variaveis = variables || {};
                _.set(variaveis, 'projetoId', props.projetoId);
                setVariables(variaveis);
            }
        }
        return (<ProjetoEtapaListComponent variables={variables} skip={variables === null}>
                            {({ data, loading, refetch, error }) => {
            return (<div>
                                    <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "projetoEtapa.pagedList.data") || []} total={_.get(data, "projetoEtapa.pagedList.total")} columns={columns} customFilters={filters} title={`Etapas do Projeto`} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
                props.history.push(`${match.url}/edit/${row.id}`);
            }}/>
                                    <ReenterRouteListener callback={refetch}/>
                                    <Route path={`${match.path}/add`} render={RenderWithModal(ProjetoEtapaForm)}/>
                                    <Route path={`${match.path}/tarefas/:etapaId`} render={RenderListWithModal(TarefaList)}/>
                                    <Route path={`${match.path}/edit/:idProjetoEtapa`} render={RenderWithModal(ProjetoEtapaForm)}/>
                                    <Route path={`${match.path}/delete/:idProjetoEtapa`} render={RenderWithModal(ProjetoEtapaDelete)}/>
                                </div>);
        }}
                        </ProjetoEtapaListComponent>);
    }}
                </GraphqlPaperTableListener>
            </div>
        </React.Fragment>);
}
