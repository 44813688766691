import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { AbaseButtonDefault, AbaseDialogConsumer, AbaseLoading, AbaseTextField } from 'abase-components';
import LogoAbase from 'abase-components/dist/styles/imgSacG.png';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { composeValidators, isEmail, minLength, required } from '../../helpers/validations';
import { authenticate, loginGoogle } from '../../services/login';
import { LoginPageStyles } from './LoginStyle';
import { getConfig } from '../../config';
import { GoogleLogin } from 'react-google-login';
import { Button, Grid } from '@material-ui/core';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
class LoginPage extends React.Component {
    constructor() {
        super(...arguments);
        this.handleSubmit = async (showError, showSnack, data) => {
            try {
                await authenticate(data.email, data.senha);
                showSnack({
                    message: 'Usuário autenticado com sucesso'
                });
                this.props.history.push('/agenda/agenda');
            }
            catch (error) {
                console.log({ error });
                showError({
                    message: error.message
                });
            }
        };
        this.state = {
            icon: 'visibility',
            typePassword: 'password'
        };
    }
    render() {
        const { classes } = this.props;
        return (<main className={classes.main}>
            <CssBaseline />
            <AbaseDialogConsumer>
                {({ showError, showSnack }) => (<div>
                    <Paper className={classes.paper}>
                        <img src={LogoAbase} style={{ maxWidth: '85px' }} alt="Logo da empresa Abase Sistemas" />
                        <Form onSubmit={this.handleSubmit.bind(null, showError, showSnack)}>
                            {({ handleSubmit, submitting }) => (<form className={classes.form} onSubmit={handleSubmit}>
                                <AbaseLoading open={submitting} />
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Field name={'email'} validate={composeValidators(required, minLength(4), isEmail)} parse={defaultFieldParser}>
                                            {({ input, meta }) => (<AbaseTextField fullWidth label="Email" {...input} meta={meta} />)}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field name={'senha'} validate={composeValidators(required, minLength(3))}>
                                            {({ input, meta }) => (<AbaseTextField fullWidth type={this.state.typePassword} label="Senha" {...input} meta={meta} iconclick={() => {
                                                if (this.state.icon === 'visibility') {
                                                    this.setState({
                                                        icon: 'visibility_off',
                                                        typePassword: 'text'
                                                    });
                                                }
                                                else {
                                                    this.setState({
                                                        icon: 'visibility',
                                                        typePassword: 'password'
                                                    });
                                                }
                                            }} />)}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AbaseButtonDefault label="Entrar" type={'submit'} fullWidth variant="contained" color="primary" className="btnSuccess" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GoogleLogin clientId={getConfig().googleClientId} buttonText="Logar utitlizando a conta do Google/Abase" render={(renderProps) => (<Button onClick={renderProps.onClick} fullWidth variant="contained" color="secondary" className={classes.loginGmail}>
                                            <span className={classes.googleButtonIcon}>
                                                <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335" />
                                                    <path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05" />
                                                    <path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4" />
                                                    <path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853" />
                                                </svg>
                                            </span>
                                            &nbsp;&nbsp; Login com Gmail
                                        </Button>)} onSuccess={async (resp) => {
                                            let perfil = resp.profileObj;
                                            let resposta = await loginGoogle(resp);
                                            if (resposta.error) {
                                                showError({
                                                    message: `Houve um erro ao efetuar o login utilizando sua conta google... "${resposta.message}"`
                                                });
                                            }
                                            else {
                                                showSnack({
                                                    message: `Usuário autenticado com sucesso, Bem vindo ${perfil.name}`
                                                });
                                                this.props.history.push('/agenda/agenda');
                                            }
                                        }} onFailure={(resp) => {
                                            showSnack({
                                                message: 'Houve um erro no login'
                                            });
                                        }} cookiePolicy={'single_host_origin'} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AbaseButtonDefault label="Entrar como cliente" type={'button'} onClick={() => this.props.history.push("/logincliente")} fullWidth variant="contained" color="primary" className="btnDefault" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <AbaseButtonDefault label="Entrar como parceiro" type={'button'} onClick={() => this.props.history.push("/loginparceiro")} fullWidth variant="contained" color="primary" className="btnDefault" />
                                    </Grid>
                                </Grid>
                            </form>)}
                        </Form>
                    </Paper>
                </div>)}
            </AbaseDialogConsumer>
        </main>);
    }
}
export default withStyles(LoginPageStyles)(LoginPage);
