import { Component } from "react";
import { withRouter } from "react-router";
import _ from "lodash";
class GraphqlPaperTableListener extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            variables: null
        };
        this.setVariables = (variables) => {
            let extraVariables = this.props.extraVariables || {};
            variables = { ...variables, ...(variables.filters || {}), ...extraVariables };
            this.setState({ variables });
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.extraVariables, nextProps.extraVariables)) {
            this.setState({ variables: { ...(this.state.variables || {}), ...(nextProps.extraVariables || {}) } });
        }
    }
    render() {
        const { variables } = this.state;
        const { setVariables } = this;
        const event = {
            variables,
            setVariables
        };
        return this.props.children(event);
    }
}
export default withRouter(GraphqlPaperTableListener);
