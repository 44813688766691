import React from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import _ from 'lodash';
export default class MarkerPicker extends React.Component {
    render() {
        const props = this.props;
        const itemOfValue = props.data ? _.first(props.data.filter(x => x.id === props.value)) : null;
        const value = itemOfValue ? props.getMarkerFromItem(itemOfValue) : null;
        const center = value ? [value.x, value.y] : [-27.7741977, -54.230988];
        return (<Map center={center} zoom={props.value ? 16 : 13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors" />
            {props.data ? props.data.map((item, index) => {
                const latLng = props.getMarkerFromItem(item);
                if (!latLng)
                    return null;
                return (<Marker position={[latLng.x, latLng.y]} onClick={() => props.onChange && props.onChange(item.id)}>
                    <Popup>{props.getDescription(item)}</Popup>
                </Marker>);
            }) : null}

        </Map>);
    }
}
