import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ModeloProjetoListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal, { RenderListWithModal } from "../../RenderWithModal";
import ModeloProjetoDelete from "./ModeloProjetoDelete";
import ModeloProjetoLabel from './ModeloProjetoNameLabel';
import ModeloEtapaList from "../ModeloEtapa/ModeloEtapaList";
export default function ModeloProjetoList(props) {
    const { match } = props;
    const columns = [
        {
            name: ModeloProjetoLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: ModeloProjetoLabel.descricao,
            field: "descricao"
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            // selected.length === 1 ? (
            //     <Link to={`${match.url}/etapa/${selected[0].id}`} {...props} title={`Adicionar Etapa`}>
            //         <IconButton aria-label={`Adicionar Etapa`}>
            //             <i className={"material-icons"}>calendar_today</i>
            //         </IconButton>
            //     </Link>) : null,
            selected.length === 1 ? (<EditAction key="edit" to={`/projeto/modeloprojetoform/edit/${selected[0].id}/aba/etapas`} component={Link}/>) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/delete/${_.map(selected, "id").join(",")}`}/>
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`/projeto/modeloprojetoform`}/>,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descrição"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = ModeloProjetoLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<ModeloProjetoListComponent variables={variables} skip={variables === null}>
                    {({ data, loading, refetch, error }) => (<div>

                        <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "modeloProjeto.pagedList.data") || []} total={_.get(data, "modeloProjeto.pagedList.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
        props.history.push(`/projeto/modeloprojetoform/edit/${row.id}/aba/etapas`);
    }}/>
                        <ReenterRouteListener callback={refetch}/>
                        
                        
                        <Route path={`${match.path}/delete/:idModeloProjeto`} render={RenderWithModal(ModeloProjetoDelete)}/>
                        <Route path={`${match.path}/etapa/:idModeloProjeto`} render={RenderListWithModal(ModeloEtapaList, false)}/>
                    </div>)}
                </ModeloProjetoListComponent>)}
        </GraphqlPaperTableListener>);
}
