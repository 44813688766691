import { ResponsivePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { RecursoListComponent, EmpresaAllComponent, RecursoTipoAllComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal, { RenderListWithModal } from '../../RenderWithModal';
import RecursoDelete from './RecursoDelete';
import RecursoForm from './RecursoForm';
import RecursoLabel from './RecursoNameLabel';
import { Chip, IconButton } from '@material-ui/core';
import RecursoHistorico from "./RecursoHistorico";
export default function RecursoList(props) {
    const { match } = props;
    const columns = [
        {
            name: RecursoLabel.id,
            field: 'id',
            defaultVisible: false
        },
        // {
        //     name: RecursoLabel.empId,
        //     field: "empId",
        //     defaultVisible: false
        // },
        // {
        //     name: RecursoLabel.recId,
        //     field: "recId",
        //     defaultVisible: false
        // },
        {
            name: RecursoLabel.nome,
            field: 'nome'
        },
        {
            name: RecursoLabel.emp,
            field: 'emp.nomefantasia'
        },
        {
            name: RecursoLabel.rec,
            field: 'rec.descricao'
        },
        {
            name: RecursoLabel.dadosFormulario,
            field: 'dadosFormulario',
            customBodyRender: function (val) {
                let values = JSON.parse(val);
                let keyNames = Object.keys(values);
                return keyNames.map((key) => {
                    let title = ` ${key.toUpperCase()} = ${values[key].toUpperCase()}`;
                    return (<Chip title={title} label={`${key.toUpperCase()}: ${values[key].toUpperCase()}`} variant="outlined" className="pullLeft h-auto" />);
                });
            }
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<Link to={match.url + `/historico/${selected[0].id}`} {...props} title={`Visualizar Histórico`}>
                <IconButton aria-label={`Histórico do Recurso`}>
                    <i className={"material-icons"}>history</i>
                </IconButton>
            </Link>) : null,
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" to={`${match.path}/delete/${_.map(selected, 'id').join(',')}`} component={Link} />
        ];
    };
    const customActions = [<AddAction key="add" to={`${match.path}/add`} component={Link} />];
    const filters = () => [
        <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta} />}</Field>,
        <Field name={'nome'}>{({ input, meta }) => <AbaseTextField label={'Nome'} {...input} meta={meta} />}</Field>,
        <EmpresaAllComponent>{({ data, loading, refetch }) => (<Field name="empId">{({ input, meta }) => (<React.Fragment>
            <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Empresa"} valueField={"id"} textField={"razaosocial"} data={_.get(data, "empresa.list", [])} {...input} />
        </React.Fragment>)}</Field>)}
        </EmpresaAllComponent>,
        <RecursoTipoAllComponent>
            {({ data, loading }) => (<Field name="recursoTipoId">
                {({ input, meta }) => {
                    return (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={RecursoLabel.rec} valueField={'id'} textField={'descricao'} data={_.get(data, 'recursoTipo.list', [])} {...input} />)
                }}
            </Field>)}
        </RecursoTipoAllComponent>
    ];
    const formName = RecursoLabel.form;
    return (<GraphqlPaperTableListener>
        {({ variables, setVariables }) => (<RecursoListComponent variables={variables} skip={variables === null}>
            {({ data, loading, refetch, error }) => (<div>
                <ResponsivePaperTable
                    customPlacehorderSearch={'Pesquisar por nome'}
                    sticky={true}
                    selectMode={'multi'}
                    loading={loading}
                    data={_.get(data, 'recurso.pagedList.data') || []}
                    total={_.get(data, 'recurso.pagedList.total')}
                    columns={columns}
                    customFilters={filters}
                    title={formName}
                    requestData={setVariables}
                    error={errorMessagePaperTable(error)}
                    customSelectedActions={selectedActions}
                    customActions={customActions}
                    selectListener={props.selectListener}
                    onDoubleClick={row => {
                        props.history.push(`${match.path}/edit/${row.id}`);
                    }} />
                <ReenterRouteListener callback={refetch} />
                <Route path={`${match.path}/add`} render={RenderWithModal(RecursoForm)} />
                <Route path={`${match.path}/edit/:id`} render={RenderWithModal(RecursoForm)} />
                <Route path={`${match.path}/delete/:id`} render={RenderWithModal(RecursoDelete)} />
                <Route path={`${match.path}/historico/:recursoId`} render={RenderListWithModal(RecursoHistorico, true)} />
            </div>)}
        </RecursoListComponent>)}
    </GraphqlPaperTableListener>);
}
