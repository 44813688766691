import { AbaseTextField, AddAction, DeleteAction, EditAction, ResponsivePaperTable } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { OrdemItemCobrancaListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import OrdemItemCobrancaDelete from "./OrdemItemCobrancaDelete";
import OrdemItemCobrancaForm from "./OrdemItemCobrancaForm";
import OrdemItemCobrancaLabel from './OrdemItemCobrancaNameLabel';
function OrdemItemCobrancaList(props) {
    const { match } = props;
    const columns = [
        {
            name: OrdemItemCobrancaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: OrdemItemCobrancaLabel.ordId,
            field: "ordId",
            defaultVisible: false
        },
        {
            name: OrdemItemCobrancaLabel.proId,
            field: "proId",
            defaultVisible: false
        },
        {
            name: "Seq.",
            field: "sequencia",
            width: 50
        },
        {
            name: "Contrato",
            field: "con.numeroContrato",
            width: 70
        },
        {
            name: "Serviço ",
            field: "pro",
            customBodyRender: values => values.descricao
        },
        {
            name: OrdemItemCobrancaLabel.quantidade,
            field: "quantidade",
            width: 70
        },
        {
            name: OrdemItemCobrancaLabel.valorUnitario,
            field: "valorUnitario",
            width: 70
        },
        {
            name: OrdemItemCobrancaLabel.valorTotal,
            field: "valorTotal",
            width: 70
        },
        {
            name: OrdemItemCobrancaLabel.ord,
            field: "ord.id",
            defaultVisible: false
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/cobranca/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/cobranca/delete/${_.map(selected, "id").join(",")}`} />
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.url}/cobranca/add`} />,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    return (<GraphqlPaperTableListener extraVariables={{
        ordId: props.ordId
    }}>{({ variables, setVariables }) => (<OrdemItemCobrancaListComponent variables={variables} skip={variables === null}>
        {({ data, loading, refetch, error }) => (<div className={"colDivBloco"}>

            <ResponsivePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "ordemItemCobranca.pagedList.data") || []} total={_.get(data, "ordemItemCobranca.pagedList.total")} columns={columns} customFilters={filters} title={"Itens de Cobrança"} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
                props.history.push(`${match.url}/cobranca/edit/${row.id}`);
            }} tableProps={{
                paginated: false,
                defaultOrderBy: "sequencia",
                defaultOrderDirection: "asc"
            }} disableFilter={true}></ResponsivePaperTable>
            <ReenterRouteListener callback={refetch} />
            <Route path={`${match.url}/cobranca/add`} render={(p) => <OrdemItemCobrancaForm {...p} ordId={props.ordId} cliId={props.cliId} sequencia={_.get(data, "ordemItemCobranca.pagedList.total") + 1} />} />
            <Route path={`${match.url}/cobranca/edit/:id`} render={(p) => <OrdemItemCobrancaForm {...p} ordId={props.ordId} cliId={props.cliId} sequencia={_.get(data, "ordemItemCobranca.pagedList.total") + 1} />} />
            <Route path={`${match.url}/cobranca/delete/:id`} render={RenderWithModal(OrdemItemCobrancaDelete)} />

        </div>)}
    </OrdemItemCobrancaListComponent>)}
    </GraphqlPaperTableListener>);
}
export default withRouter(OrdemItemCobrancaList);
