import { AbaseTextField, ResponsivePaperTable, AbaseCheckbox } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ClienteListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import ClienteLabel from './ClienteNameLabel';
import { defaultFieldParser } from "../../helpers/finalFormDecorators";
import { IconButton } from "@material-ui/core";
import ClienteView from "./ClienteView";
import { formataCpfCnpj } from "../../helpers/format";
import { useFiltroGeral } from "../../components/FiltroGeral";
import EmpresaEquipeAreaFiltro from "../../components/EmpresaEquipeAreaFiltro";

export default function ClienteList(props) {
    const { match } = props;
    const filtroGeral = useFiltroGeral();
    const columns = [

        {
            name: ClienteLabel.id,
            field: "id",
            defaultVisible: false,
            sort: false
        },
        {
            name: ClienteLabel.empId,
            field: "empId",
            defaultVisible: false,
            sort: false
        },
        {
            name: ClienteLabel.emp,
            field: "emp.nomefantasia",
            sort: false,
            width: 120,
            defaultVisible: true
        },
        {
            name: "Código Gestor",
            field: "gestorCodigocliente",
            defaultVisible: true,
            width: 100
        },
        {
            name: "C. Ativo",
            field: "contratoativo",
            defaultVisible: false,
            width: 50,
            customBodyRender: (value) => value ? "S" : "N"
        },
        {
            name: ClienteLabel.cpfcnpj,
            field: "cpfcnpj",
            customBodyRender: (value) => formataCpfCnpj(value) || "N/A",
            width: 130
        },
        {
            name: ClienteLabel.inscricaoestadual,
            field: "inscricaoestadual",
            defaultVisible: false
        },
        {
            name: ClienteLabel.rg,
            field: "rg",
            defaultVisible: false
        },
        {
            name: ClienteLabel.nome,
            field: "nome",
        },
        {
            name: ClienteLabel.razaosocial,
            field: "razaosocial"
        },
        {
            name: "Atividade",
            field: "atividade.descricao",
            width: 150
        },
        {
            name: "Categoria",
            field: "categoria.descricao",
            width: 150
        },
        {
            name: ClienteLabel.telefone,
            field: "telefone",
            width: 100
        },
        {
            name: ClienteLabel.email,
            field: "email",
            defaultVisible: false
        },
        {
            name: ClienteLabel.cidade,
            field: "cidade",
            width: 100
        },
        {
            name: ClienteLabel.uf,
            field: "uf",
            width: 80
        },
        {
            name: ClienteLabel.cep,
            field: "cep",
            defaultVisible: false
        },
        {
            name: ClienteLabel.bairro,
            field: "bairro",
            defaultVisible: false
        },
        {
            name: ClienteLabel.logradouro,
            field: "logradouro",
            defaultVisible: false
        },
        {
            name: ClienteLabel.numero,
            field: "numero",
            defaultVisible: false
        },
        {
            name: ClienteLabel.complemento,
            field: "complemento",
            defaultVisible: false
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? <Link to={`${match.url}/view/${selected[0].id}`} {...props} title={`Visualizar detalhes`}>
                <IconButton aria-label={`Detalhes`}>
                    <i className={"material-icons"}>info</i>
                </IconButton>
            </Link>
                : null
        ];
    };
    const customActions = [
        // <AddAction
        //     key="add"
        //     to={`${match.path}/add`} component={Link}
        // />,
    ];
    const filters = () => ([
        <EmpresaEquipeAreaFiltro hideArea />,
        <Field name={'contratoAtivo'}>
            {({ input, meta }) => <AbaseCheckbox label={"Contrato Ativo"} {...input} />}
        </Field>,
        <Field parse={defaultFieldParser} name={"gestorCodigocliente"}>{({ input, meta }) => <AbaseTextField label={"Código Gestor"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field parse={defaultFieldParser} name={"cpfcnpj"}>{({ input, meta }) => <AbaseTextField label={"CPF/CNPJ"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field parse={defaultFieldParser} name={"inscricaoestadual"}>{({ input, meta }) => <AbaseTextField label={"Inscrição Estadual"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field parse={defaultFieldParser} name={"nome"}>{({ input, meta }) => <AbaseTextField label={"Nome"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field parse={defaultFieldParser} name={"razaosocial"}>{({ input, meta }) => <AbaseTextField label={"Razão Social"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field parse={defaultFieldParser} name={"telefone"}>{({ input, meta }) => <AbaseTextField label={"Telefone"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field parse={defaultFieldParser} name={"email"}>{({ input, meta }) => <AbaseTextField label={"E-mail"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field parse={defaultFieldParser} name={"cidade"}>{({ input, meta }) => <AbaseTextField label={"Cidade"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field parse={defaultFieldParser} name={"uf"}>{({ input, meta }) => <AbaseTextField label={"UF"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field parse={defaultFieldParser} name={"cep"}>{({ input, meta }) => <AbaseTextField label={"CEP"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = ClienteLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<ClienteListComponent variables={variables} skip={variables === null}>
        {({ data, loading, refetch, error }) => (<div>
            <ResponsivePaperTable
                customPlacehorderSearch={"Pesquisar por CPF/CNPJ, código do cliente no Gestor, inscrição estadual, RG, nome, razão social, telefone, email, cidade, uf, cep, bairro, logradouro, número e complemento"}
                sticky={true}
                selectMode={"single"}
                loading={loading}
                data={_.get(data, "cliente.pagedList.data") || []}
                total={_.get(data, "cliente.pagedList.total")}
                columns={columns}
                customFilters={filters}
                title={formName}
                requestData={setVariables}
                error={errorMessagePaperTable(error)}
                customSelectedActions={selectedActions}
                customActions={customActions}
                selectListener={props.selectListener}
                onDoubleClick={row => {
                    props.history.push(`${match.url}/view/${row.id}`);
                }} tableProps={{
                    defaultOrderBy: "razaosocial"
                }}></ResponsivePaperTable>
            <ReenterRouteListener callback={refetch} />
            <Route path={`${match.url}/view/:id`} render={RenderWithModal(ClienteView)} />
        </div>)}
    </ClienteListComponent>)}
    </GraphqlPaperTableListener>);
}
