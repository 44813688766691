const names = {
    "form": "Apontamentos do Projeto",
    "id": "Id",
    "proId": "Projeto ID",
    "usuId": "Usuário ID",
    "descricao": "Descrição",
    "data": "Data",
    "confidencial": "Confidencial",
    "pro": "Projeto",
    "usu": "Usuário",
};
export default names;
