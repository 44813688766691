import styled from '@emotion/styled';
import { colors, grid } from './constants';
import { Typography } from '@material-ui/core';
import React from 'react';
const H4Styled = styled.h4 `
  padding: ${grid}px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  color: rgba(0, 0, 0, 0.54);
  &:focus {
    outline: 2px solid ${colors.P100};
    outline-offset: 2px;
  }
  `;
export default (props) => (<Typography>
  <H4Styled>{props.children}</H4Styled>
</Typography>);
