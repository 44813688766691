import React from 'react';
import BigCalendar from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.less';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import _ from 'lodash';
const localizer = BigCalendar.momentLocalizer(moment);
require('globalize/lib/cultures/globalize.culture.pt');
const DragAndDropCalendar = withDragAndDrop(BigCalendar);
const defaultColors = [
    '#d73d32',
    '#7e3794',
    '#4285f4',
    '#67ae3f',
    '#d61a7f',
    '#ff4080'
];
function _stringAsciiPRNG(value, m) {
    // Xn+1 = (a * Xn + c) % m
    // 0 < a < m
    // 0 <= c < m
    // 0 <= X0 < m
    const charCodes = [...value].map(letter => letter.charCodeAt(0));
    const len = charCodes.length;
    const a = (len % (m - 1)) + 1;
    const c = charCodes.reduce((current, next) => current + next) % m;
    let random = charCodes[0] % m;
    for (let i = 0; i < len; i++)
        random = ((a * random) + c) % m;
    return random;
}
function getRandomColor(value, colors = defaultColors) {
    // console.log("random color for " + value);
    // if no value is passed, always return transparent color otherwise
    // a rerender would show a new color which would will
    // give strange effects when an interface is loading
    // and gets rerendered a few consequent times
    if (!value)
        return 'transparent';
    // value based random color index
    // the reason we don't just use a random number is to make sure that
    // a certain value will always get the same color assigned given
    // a fixed set of colors
    const colorIndex = _stringAsciiPRNG(value, colors.length);
    return colors[colorIndex];
}
const eventStyleGetter = function (event, start, end, isSelected) {
    // console.log(event);
    var backgroundColor = getRandomColor(_.get(event, "rec.nome"));
    var style = {
        background: backgroundColor,
    };
    return {
        style: style
    };
};
class Dnd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: BigCalendar.Views.MONTH
        };
    }
    newEvent(event) {
        // let idList = this.state.events.map(a => a.id)
        // let newId = Math.max(...idList) + 1
        // let hour = {
        //   id: newId,
        //   title: 'New Event',
        //   allDay: event.slots.length === 1,
        //   start: event.start,
        //   end: event.end,
        // }
        // this.setState({
        //   events: this.state.events.concat([hour]),
        // })
    }
    render() {
        return (<div className={this.props.classes.fullHeight}>

                <DragAndDropCalendar selectable messages={{
            date: 'Data',
            time: 'Hora',
            event: 'Evento',
            allDay: 'Todo Dia',
            week: 'Semana',
            work_week: 'Dias uteis',
            day: 'Dia',
            month: 'Mês',
            previous: 'Anterior',
            next: 'Próximo',
            yesterday: 'Ontem',
            tomorrow: 'Amanhã',
            today: 'Hoje',
            agenda: 'Agenda',
            noEventsInRange: 'Não há eventos nesse perído.',
            showMore: function showMore(total) {
                return "+" + total + " mais";
            }
        }} localizer={localizer} events={this.props.events} startAccessor={x => {
            const d = new Date(_.get(x, "age.inicio"));
            return d;
        }} endAccessor={x => {
            const d = !_.get(x, "age.fim") ? moment(new Date(_.get(x, "age.inicio"))).endOf("day").toDate() : new Date(_.get(x, "age.fim"));
            return d;
        }} titleAccessor={x => _.get(x, "rec.nome")} onSelectEvent={this.props.onSelectEvent} defaultView={BigCalendar.Views.MONTH} defaultDate={new Date()} components={{
        // event: Event
        }} eventPropGetter={(eventStyleGetter)} culture={"pt"} onRangeChange={this.props.onRangeChange} view={this.state.view} onView={(view) => {
            this.setState({ view });
        }} onSelectSlot={this.props.onSelectSlot}/>
            </div>);
    }
}
const styles = {
    fullHeight: {
        height: window.innerHeight - 237,
        position: "relative",
    }
};
export default withStyles(styles)(Dnd);
