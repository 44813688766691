const names = {
    "form": "Etapas do Projeto",
    "id": "Id",
    "proId": "Projeto ID",
    "descricao": "Descrição",
    "porcentagem": "Porcentagem",
    "ordem": "Ordem",
    "dataLimite": "Data Limite",
    "pro": "Projeto",
};
export default names;
