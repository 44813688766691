import Axios from "axios";
export const getLatLngFromText = (value) => {
    if (!value) {
        return null;
    }
    // eslint-disable-next-line no-useless-escape
    var regexLatLng = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/gm;
    if (regexLatLng.test(value)) {
        const latLngResult = value.split(",");
        return [+latLngResult[0], +latLngResult[1]];
    }
    return null;
};
export const getTextFromLatLng = (value) => {
    if (!value) {
        return "";
    }
    return value.lat + "," + value.lng;
};
export async function geocode(name) {
    const result = await Axios.get("https://us1.locationiq.com/v1/search.php", {
        params: {
            key: "60dd536e66b1a6",
            format: "json",
            q: name
        }
    });
    if (result.data && result.data.length > 0) {
        let cep = null;
        if (result.data[0].display_name) {
            const foundCep = /\d{5}-\d{3}/gm.exec(result.data[0].display_name);
            if (foundCep) {
                cep = foundCep[0];
            }
        }
        return {
            latlng: { x: result.data[0].lat, y: result.data[0].lon },
            cep
        };
    }
    return null;
}
export async function cidadeDeCep(cep) {
    const result = await Axios.get("https://api.postmon.com.br/v1/cep/" + cep);
    return result.data.cidade;
}
