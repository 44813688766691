import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction, AbaseAutocompleteMultipleField, AbaseDatePickerField } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { AgendaRequisicaoListComponent, UsuarioAllComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal from '../../RenderWithModal';
import AgendaRequisicaoDelete from './AgendaRequisicaoDelete';
import AgendaRequisicaoForm from './AgendaRequisicaoForm';
import AgendaRequisicaoFormEdit from './AgendaRequisicaoFormEdit';
import AgendaRequisicaoLabel from './AgendaRequisicaoNameLabel';
import { Chip } from '@material-ui/core';
import { getUserId, getNivel, niveisEnum } from '../../services/login';
export default function AgendaRequisicaoList(props) {
    const { match } = props;
    const columns = [
        {
            name: AgendaRequisicaoLabel.id,
            field: 'id',
            defaultVisible: false
        },
        {
            name: AgendaRequisicaoLabel.ageId,
            field: 'ageId',
            defaultVisible: false
        },
        {
            name: AgendaRequisicaoLabel.reqId,
            field: 'reqId',
            defaultVisible: false
        },
        {
            name: AgendaRequisicaoLabel.status,
            field: 'status'
        },
        {
            name: AgendaRequisicaoLabel.dadosFormulario,
            field: 'dadosFormulario',
            customBodyRender: (fields, row) => {
                let fieldsObj = JSON.parse(fields);
                const formTipoReq = JSON.parse(row.req.formulario);
                if (fieldsObj)
                    return Object.keys(fieldsObj).map((field) => {
                        var attr = formTipoReq.filter((x) => x.id === field)[0];
                        return (<Chip label={`${attr.label}: ${fieldsObj[field]}`} variant="outlined" className="pullLeft h-auto" />);
                    });
                else
                    return 'N/A';
            }
        },
        {
            name: AgendaRequisicaoLabel.age,
            field: 'age',
            customBodyRender: (agenda) => {
                return agenda.titulo;
            }
        },
        {
            name: AgendaRequisicaoLabel.req,
            field: 'req.descricao'
        },
        {
            name: 'Formulário Requisito',
            field: 'req.formulario',
            customBodyRender: (fields) => {
                let fieldsObj = JSON.parse(fields);
                return fieldsObj.map((field) => {
                    return <Chip label={field.label} variant="outlined" className="pullLeft h-auto" />;
                });
            },
            defaultVisible: false
        },
        {
            name: 'Usuário',
            field: 'age.usu.nome',
            defaultVisible: true,
            sort: false
        },
        {
            name: 'Data Inicial',
            field: 'age.inicio',
            defaultVisible: true,
            sort: true,
            customBodyRender: (data) => new Date(data).toLocaleString()
        },
        {
            name: 'Data Final',
            field: 'age.fim',
            defaultVisible: true,
            sort: false,
            customBodyRender: (data) => (data ? new Date(data).toLocaleString() : 'N/A')
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" to={`${match.path}/delete/${_.map(selected, 'id').join(',')}`} component={Link} />
        ];
    };
    const customActions = [<AddAction key="add" to={`${match.path}/add`} component={Link} />];
    const filters = () => [
        // <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta} />}</Field>,
        <Field name={'dadosFormulario'}>
            {({ input, meta }) => <AbaseTextField label={'Dados Formulário'} {...input} meta={meta} />}
        </Field>,
        <Field name={'titulo'}>{({ input, meta }) => <AbaseTextField label={'Titulo'} {...input} meta={meta} />}</Field>,
        <Field name={'dataInicial'}>
            {({ input, meta }) => <AbaseDatePickerField label={'Data Inicial'} {...input} meta={meta} />}
        </Field>,
        <Field name={'dataFinal'}>
            {({ input, meta }) => <AbaseDatePickerField label={'Data Final'} {...input} meta={meta} />}
        </Field>,
        <Field name={'status'}>
            {({ input, meta }) => (<AbaseAutocompleteMultipleField {...input} data={[
                { value: 'PENDENTE' },
                { value: 'CONCLUÍDO' },
                { value: 'CANCELADO' },
                { value: 'ADIADO' }
            ]} getOptionLabel={(x) => x.value} getOptionValue={(x) => x.value} label="Status" />)}
        </Field>,
        <UsuarioAllComponent key={'usuarios'} variables={{ cliente: false }}>
            {({ data, loading, refetch }) => (<Field name={'usuarios'}>
                {({ input, meta }) => getNivel() === niveisEnum.administrativo ? (<React.Fragment>
                    <AbaseAutocompleteMultipleField isLoading={loading} label={'Participantes'} data={_.get(data, 'usuario.list', [])} value={input.value} onChange={input.onChange} getOptionValue={(x) => x} getOptionLabel={(x) => _.get(x, 'nome')} />
                </React.Fragment>) : null}
            </Field>)}
        </UsuarioAllComponent>
    ];
    const formName = AgendaRequisicaoLabel.form;
    return (<GraphqlPaperTableListener>
        {({ variables, setVariables }) => (<AgendaRequisicaoListComponent variables={{
            ...variables,
            usuId: getUserId(),
            usuarios: _.get(variables, 'usuarios') ? _.get(variables, 'usuarios', []).map((x) => x.id) : [],
            status: _.get(variables, 'status') ? _.get(variables, 'status', []).map((x) => x.value) : []
        }} skip={variables === null}>
            {({ data, loading, refetch, error }) => (<div>
                <AbasePaperTable
                    customPlacehorderSearch={"Pesquisar por título da agenda ou descrição da requisição"}
                    sticky={true}
                    selectMode={'multi'}
                    loading={loading}
                    data={_.get(data, 'agendaRequisicao.pagedList.data') || []}
                    total={_.get(data, 'agendaRequisicao.pagedList.total')}
                    columns={columns}
                    customFilters={filters}
                    title={formName}
                    requestData={setVariables}
                    error={errorMessagePaperTable(error)}
                    customSelectedActions={selectedActions}
                    customActions={customActions}
                    selectListener={props.selectListener}
                    onDoubleClick={row => {
                        props.history.push(`${match.url}/edit/${row.id}`);
                    }} />
                <ReenterRouteListener callback={refetch} />
                <Route path={`${match.path}/add`} render={RenderWithModal(AgendaRequisicaoForm)} />
                <Route path={`${match.path}/edit/:id`} render={RenderWithModal(AgendaRequisicaoFormEdit)} />
                <Route path={`${match.path}/delete/:id`} render={RenderWithModal(AgendaRequisicaoDelete)} />
            </div>)}
        </AgendaRequisicaoListComponent>)}
    </GraphqlPaperTableListener>);
}
