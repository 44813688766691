import React from 'react';
import { AbaseTextField } from 'abase-components';
import { defaultFieldParser } from '../helpers/finalFormDecorators';
export default class DynamicFormRenderer extends React.Component {
    constructor() {
        super(...arguments);
        this.handleChange = (values, event) => {
            const id = event.target.name;
            const value = event.target.value;
            const newData = { ...values, [id]: defaultFieldParser(value) };
            this.props.onChange(JSON.stringify(newData));
        };
        this.renderField = (values, field) => {
            const value = values[field.id] || "";
            const meta = { touched: true, error: !value && field.required ? "Campo obrigatório" : "" };
            switch (field.type) {
                case "text":
                    return <AbaseTextField key={field.id} label={field.label} value={value} name={field.id} onChange={this.handleChange.bind(this, values)} meta={meta} />;
                default:
                    return null;
            }
        };
    }
    render() {
        const form = this.props.formData ? JSON.parse(this.props.formData) : [];
        const values = this.props.value ? JSON.parse(this.props.value) : {};
        return (<React.Fragment>
            {form.map(this.renderField.bind(this, values))}
        </React.Fragment>);
    }
}
