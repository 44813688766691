import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction, AbaseAutoCompleteFieldFinalForm, AbaseDatePickerField, AbaseCheckbox } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { OrdemServicoListComponent, UsuarioColaboradorAllComponent, AreaAllComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal from '../../RenderWithModal';
import OrdemServicoDelete from './OrdemServicoDelete';
import OrdemServicoForm from './OrdemServicoForm';
import OrdemServicoLabel, { ordemServicoStatus, ordemServicoTipos } from './OrdemServicoNameLabel';
import { imprimirOrdemServico } from './OrdemServicoRelatorio';
import { Tooltip, IconButton } from '@material-ui/core';
import { PrintSharp } from '@material-ui/icons';
import AutoCompleteCliente from '../../components/AutoCompleteCliente';
import { getNivel, niveisEnum } from '../../services/login';
import OrdemServicoRelatorioCliente from './OrdemServicoRelatorioCliente';
import EmpresaEquipeAreaFiltro from '../../components/EmpresaEquipeAreaFiltro';
export default function OrdemServicoList(props) {
    const { match } = props;
    const columns = [
        {
            name: "Num.",
            field: 'numero',
            width: 40
        },
        {
            name: OrdemServicoLabel.data,
            width: 100,
            field: 'data',
            customBodyRender: (val) => (val ? new Date(val).toLocaleDateString() : 'N/A')
        },
        {
            name: OrdemServicoLabel.id,
            field: 'id',
            defaultVisible: false
        },
        {
            name: OrdemServicoLabel.cli,
            field: 'cli.nome'
        },
        {
            name: OrdemServicoLabel.usu,
            field: 'usu.nome'
        },
        {
            name: OrdemServicoLabel.usuId,
            field: 'usuId',
            defaultVisible: false
        },
        {
            name: OrdemServicoLabel.cliId,
            field: 'cliId',
            defaultVisible: false
        },
        {
            name: OrdemServicoLabel.status,
            field: 'status',
            customBodyRender: (val) => val ? _.get(_.first(ordemServicoStatus.filter(x => x.id === val)), "text") : 'N/A'
        },
        {
            name: OrdemServicoLabel.tipo,
            field: 'tipo',
            customBodyRender: (val) => val ? _.get(_.first(ordemServicoTipos.filter(x => x.id === val)), "text") : 'N/A'
        },
        {
            name: OrdemServicoLabel.observacao,
            field: 'observacao',
            customBodyRender: (val) => val ? val : 'N/A',
            defaultVisible: false
        },
        {
            name: OrdemServicoLabel.distancia,
            field: 'distancia',
            customBodyRender: (val) => (val ? val + 'KM' : 'N/A'),
            width: 100,
            defaultVisible: false
        },
        {
            name: OrdemServicoLabel.valor,
            field: 'valor',
            customBodyRender: (value) => value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : 'N/A',
            width: 100
        },
        {
            name: OrdemServicoLabel.ordemTarefas,
            field: 'ordemTarefas',
            customBodyRender: (value) => {
                return value.length;
            },
            width: 100
        },
        {
            name: "Cobrança",
            field: 'possuiCobranca',
            customBodyRender: (value) => {
                return value ? "S" : "N";
            },
            width: 100
        },
    ];
    const nivel = getNivel();
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            <Tooltip title="Imprimir Ordem de Serviço">
                <IconButton aria-label="Local" onClick={() => {
                    var ids = selected.map(x => x.id);
                    imprimirOrdemServico(ids, 'download', `Ordem_Servico${selected.map(x => "_" + x['numero'])}`);
                }}>
                    <PrintSharp />
                </IconButton>
            </Tooltip>,
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            nivel === niveisEnum.administrativo ? <DeleteAction key="delete" component={Link} to={`${match.path}/delete/${_.map(selected, 'id').join(',')}`} /> : null
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.path}/add`} />,
        <Tooltip title="Relatório de cliente" key={"cliente"}>

            <IconButton onClick={() => {
                props.history.push(`${match.path}/relatoriocliente`);
            }}>
                <PrintSharp />
            </IconButton>
        </Tooltip>
    ];
    const filters = () => [
        // <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta} />}</Field>,
        <EmpresaEquipeAreaFiltro hideArea />,

        <UsuarioColaboradorAllComponent>{({ data, loading, refetch }) => (
            <Field name="usuarioId">{({ input, meta }) => (
                <React.Fragment>
                    <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Colaborador"} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input} />
                </React.Fragment>)}
            </Field>)}
        </UsuarioColaboradorAllComponent>,
        <AreaAllComponent>
            {({ data, loading, refetch }) => (
                <Field name="areId">{({ input, meta }) => (
                    <React.Fragment>
                        <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Àrea"} valueField={"id"} textField={"descricao"} data={_.get(data, "area.list", [])} {...input} />
                    </React.Fragment>)}
                </Field>)}
        </AreaAllComponent>,
        <Field name={'cliId'}>
            {({ input, meta }) => <AutoCompleteCliente {...input} />}
        </Field>,

        <Field name={"gestorCodigocliente"}>{({ input, meta }) => <AbaseTextField label={"Código Gestor"} {...input} meta={meta}></AbaseTextField>}</Field>,

        <Field name={'tipo'}>{({ input, meta }) => <AbaseAutoCompleteFieldFinalForm label={"Tipo"} meta={meta}
            // disabled={!inputOrdemServico.value}
            valueField={'id'} textField={'text'} data={ordemServicoTipos} {...input} />}</Field>,
        <Field name={'numero'}>{({ input, meta }) => <AbaseTextField label={'Número'} {...input} meta={meta} />}</Field>,

        <Field name={'status'}>
            {({ input, meta }) => <AbaseAutoCompleteFieldFinalForm label={"Status"} meta={meta} valueField={'id'} textField={'text'} data={ordemServicoStatus} {...input} />}
        </Field>,

        <Field name={'observacao'}>
            {({ input, meta }) => <AbaseTextField label={'Observação'} {...input} meta={meta} />}
        </Field>,

        <Field name={'inicio'}>
            {({ input, meta }) => <AbaseDatePickerField label={'Início'} {...input} meta={meta} />}
        </Field>,

        <Field name={'fim'}>
            {({ input, meta }) => <AbaseDatePickerField label={'Fim'} {...input} meta={meta} />}
        </Field>,

        <Field name={'cobranca'} type={"checkbox"}>
            {({ input, meta }) => <AbaseCheckbox indeterminate={typeof input.value !== "boolean"} label={"Cobrança"} {...input} />}
        </Field>
    ];
    const formName = OrdemServicoLabel.form;
    return (<GraphqlPaperTableListener>
        {({ variables, setVariables }) => (<OrdemServicoListComponent partialRefetch variables={variables} skip={variables === null}>
            {({ data, loading, refetch, error }) => (<div>
                <AbasePaperTable
                    customPlacehorderSearch={"Pesquise pelo nome do responsável, observação, numero, código do cliente no gestor ou a razão social do cliente"}
                    tableProps={{
                        defaultOrderBy: "data",
                        defaultOrderDirection: "desc"
                    }} onDoubleClick={row => {
                        props.history.push(`${match.path}/edit/${row.id}`);
                    }}
                    sticky={true}
                    selectMode={'multi'}
                    loading={loading}
                    data={_.get(data, 'ordemServico.pagedList.data') || []}
                    total={_.get(data, 'ordemServico.pagedList.total')}
                    columns={columns}
                    customFilters={filters}
                    title={formName}
                    requestData={setVariables}
                    error={errorMessagePaperTable(error)}
                    customSelectedActions={selectedActions}
                    customActions={customActions}
                    selectListener={props.selectListener} />
                <ReenterRouteListener callback={refetch} />
                <Route path={`${match.path}/add`} render={RenderWithModal(OrdemServicoForm)} />
                <Route path={`${match.path}/edit/:id`} render={RenderWithModal(OrdemServicoForm)} />
                <Route path={`${match.path}/delete/:id`} render={RenderWithModal(OrdemServicoDelete)} />
                <Route path={`${match.path}/relatoriocliente`} render={RenderWithModal(OrdemServicoRelatorioCliente)} />

            </div>)}
        </OrdemServicoListComponent>)}
    </GraphqlPaperTableListener>);
}
