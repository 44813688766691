import { AbaseDialogConfirm, AbaseDialogConsumer, AbaseLoading } from 'abase-components';
import React from 'react';
import { defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { TarefaCancelaComponent } from '../../generated/graphql'
export default function TarefaCancela(props) {
    const params = props.match.params;
    return (<AbaseDialogConsumer>
        {({ showError, showSnack }) => (
            <TarefaCancelaComponent onCompleted={(data) => {
                showSnack(defaultSnack("cancela"));
                redirectBackForm(props.history, props.location);
            }} onError={(err) => {
                showError({ message: defaultErrorMessage("cancela", err) });
            }}>{(cancelaItem, { loading }) => (<React.Fragment>
                <AbaseDialogConfirm open={true} onCancel={() => redirectBackForm(props.history, props.location)} onConfirm={() => {
                    if (props.onSubmitItem) {
                        props.onSubmitItem(params.id.split(","));
                        redirectBackForm(props.history, props.location);
                        return;
                    }
                    else {
                        cancelaItem({ variables: { ids: params.id.split(",") } });
                    }
                }} message={"Tem certeza que deseja cancelar esta Tarefa?"}></AbaseDialogConfirm>
                <AbaseLoading open={loading} />

            </React.Fragment>)}</TarefaCancelaComponent>)}
    </AbaseDialogConsumer>);
}
