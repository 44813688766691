import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction, AbaseAutoCompleteFieldFinalForm } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { RequisicaoResponsavelListComponent, UsuarioColaboradorAllComponent, RequisicaoTipoAllComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import RequisicaoResponsavelDelete from "./RequisicaoResponsavelDelete";
import RequisicaoResponsavelForm from "./RequisicaoResponsavelForm";
import RequisicaoResponsavelLabel from './RequisicaoResponsavelNameLabel';
import EmpresaEquipeAreaFiltro from "../../components/EmpresaEquipeAreaFiltro";
export default function RequisicaoResponsavelList(props) {
    const { match } = props;
    const columns = [
        {
            name: RequisicaoResponsavelLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: RequisicaoResponsavelLabel.emp,
            field: "emp.nomefantasia"
        },
        {
            name: RequisicaoResponsavelLabel.req,
            field: "req.descricao"
        },
        {
            name: RequisicaoResponsavelLabel.usu,
            field: "usu.nome"
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/delete/${_.map(selected, "id").join(",")}`} />
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.url}/add`} />,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <EmpresaEquipeAreaFiltro hideArea />,
        <UsuarioColaboradorAllComponent>{({ data, loading, refetch }) => (
            <Field name="usuId">{({ input, meta }) => (
                <React.Fragment>
                    <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Colaborador"} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input} />
                </React.Fragment>)}
            </Field>)}
        </UsuarioColaboradorAllComponent>,
        <RequisicaoTipoAllComponent>{({ data, loading }) => (
            <Field name="reqId">{({ input, meta }) => (
                <AbaseAutoCompleteFieldFinalForm isLoading={loading} label={RequisicaoResponsavelLabel.req} valueField={"id"} textField={"descricao"} data={_.get(data, "requisicaoTipo.list", [])} {...input} />)}
            </Field>)}
        </RequisicaoTipoAllComponent>
    ]);
    const formName = RequisicaoResponsavelLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<RequisicaoResponsavelListComponent variables={variables} skip={variables === null}>
        {({ data, loading, refetch, error }) => (<div>

            <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "requisicaoResponsavel.pagedList.data") || []} total={_.get(data, "requisicaoResponsavel.pagedList.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
                props.history.push(`${match.url}/edit/${row.id}`);
            }}></AbasePaperTable>
            <ReenterRouteListener callback={refetch} />
            <Route path={`${match.url}/add`} render={RenderWithModal(RequisicaoResponsavelForm)} />
            <Route path={`${match.url}/edit/:id`} render={RenderWithModal(RequisicaoResponsavelForm)} />
            <Route path={`${match.url}/delete/:id`} render={RenderWithModal(RequisicaoResponsavelDelete)} />

        </div>)}
    </RequisicaoResponsavelListComponent>)}
    </GraphqlPaperTableListener>);
}
