const names = {
    "form": "Usuário",
    "id": "ID",
    "nome": "Nome",
    "email": "E-mail",
    "senha": "Senha",
    "confirmsenha": "Confirmar Senha",
    "datanascimento": "Data de nascimento",
    "agenda": "Agenda",
    "agendaTimeline": "Agenda Timeline",
    "agendaUsuario": "Agenda Usuário",
    "clienteContato": "Cliente Contato",
    "requisicaoTipo": "Requisição Tipo",
    "tarefa": "Tarefa",
    "usuarioEmpresa": "Usuário Empresa",
    "usuarioEquipe": "Usuário Equipe",
    "avatar": "Imagem"
};
export const nivelList = [
    {
        id: 4,
        text: "MASTER"
    },
    {
        id: 0,
        text: "ADMINISTRADOR"
    },
    {
        id: 1,
        text: "FUNCIONÁRIO"
    },
    {
        id: 2,
        text: "CLIENTE/CONTATO"
    },
    {
        id: 3,
        text: "PARCEIRO"
    },
];
export default names;
