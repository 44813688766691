import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseDropZone } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { ParceiroQueryByIdComponent, useParceiroCreate, useParceiroUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import ParceiroLabel from './ParceiroNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { Grid } from '@material-ui/core';
import { MaskTelefone } from '../../helpers/mask';
const ParceiroSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    nome: Yup.string().required(),
    endereco: Yup.string().required(),
    site: Yup.string().required().url(),
    logo: Yup.string().notRequired(),
    email: Yup.string().required().email(),
    telefone: Yup.string().required().min(14).max(15),
}).noUnknown();
function ParceiroForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useParceiroUpdate();
    const insert = useParceiroCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(ParceiroSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.parceiro.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.parceiro.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<ParceiroQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
            {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(ParceiroSchema)} initialValues={_.get(data, "parceiro.list[0]") || {}} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Parceiro" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                            <AbaseLoading open={loading || submitting}/>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Field name="nome" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ParceiroLabel.nome}/>)}</Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Field name="endereco" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ParceiroLabel.endereco}/>)}</Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Field name="site">{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ParceiroLabel.site}/>)}</Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Field name="email">{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ParceiroLabel.site}/>)}</Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Field name="telefone">{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ParceiroLabel.site} InputProps={{
        inputComponent: MaskTelefone
    }}/>)}</Field>
                                </Grid>
                                <Grid item xs={12} className="mt15"> 
                                    <Field name="logo">
                                        {({ input, meta }) => {
        return <React.Fragment>
                                                <AbaseDropZone accept="image/*" width="100%" onDrop={(accepted, rejected, event) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                const binaryStr = reader.result;
                var base64 = btoa(binaryStr);
                input.onChange(base64);
            };
            accepted.forEach(file => {
                reader.readAsBinaryString(file);
            });
        }}/>
                                            </React.Fragment>;
    }}
                                    </Field>
                                </Grid>
                            </Grid>
                        </DialogForm>)}/>)}
        </ParceiroQueryByIdComponent>);
}
export default ParceiroForm;
