import React from 'react';
import BigCalendar from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.less';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import UserAvatarSac from './UserAvatarSac';
import _ from 'lodash';
const localizer = BigCalendar.momentLocalizer(moment);
require('globalize/lib/cultures/globalize.culture.pt');
const DragAndDropCalendar = withDragAndDrop(BigCalendar);
function Event({ event: rowData }) {
    const value = _.get(rowData, "usu.nome");
    const cliente = _.get(rowData, "contaCliente.nome");
    return (<span>
        {cliente ? cliente : rowData.titulo}
        <React.Fragment>
            {(value && (!rowData.agendaUsuario || rowData.agendaUsuario.length === 0)) ? (<UserAvatarSac name={value} color={_.get(rowData, "usu.corAvatar")} url={_.get(rowData, "usu.avatarUrl")} />) : null}
            {rowData.agendaUsuario.map(x => (<UserAvatarSac name={x.usu.nome} color={_.get(x, "usu.corAvatar")} url={_.get(x, "usu.avatarUrl")} />))}
        </React.Fragment>
    </span>);
}
class Dnd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view: BigCalendar.Views.WEEK
        };
    }
    newEvent(event) {
        // let idList = this.state.events.map(a => a.id)
        // let newId = Math.max(...idList) + 1
        // let hour = {
        //   id: newId,
        //   title: 'New Event',
        //   allDay: event.slots.length === 1,
        //   start: event.start,
        //   end: event.end,
        // }
        // this.setState({
        //   events: this.state.events.concat([hour]),
        // })
    }
    render() {
        return (<div className={this.props.classes.fullHeight}>

            <DragAndDropCalendar selectable views={['month', 'week', 'work_week', 'day']} messages={{
                date: 'Data',
                time: 'Hora',
                event: 'Evento',
                allDay: 'Todo Dia',
                week: 'Semana',
                work_week: 'Dias uteis',
                day: 'Dia',
                month: 'Mês',
                previous: 'Anterior',
                next: 'Próximo',
                yesterday: 'Ontem',
                tomorrow: 'Amanhã',
                today: 'Hoje',
                agenda: 'Agenda',
                noEventsInRange: 'Não há eventos nesse perído.',
                showMore: function showMore(total) {
                    return "+" + total + " mais";
                }
            }} localizer={localizer} events={this.props.events} onEventDrop={this.props.moveEvent} resizable startAccessor={x => {
                const d = new Date(x.inicio);
                return d;
            }} endAccessor={x => {
                const d = !x.fim ? moment(new Date(x.inicio)).add(1, "hour").toDate() : new Date(x.fim);
                return d;
            }} titleAccessor={x => x.titulo} onEventResize={this.props.resizeEvent} onSelectEvent={this.props.onSelectEvent} defaultView={BigCalendar.Views.WEEK} defaultDate={new Date()} components={{
                event: Event
            }} culture={"pt"} onRangeChange={this.props.onRangeChange} onNavigate={((data, data2) => {
                console.log("onNavgate");
            })} view={this.state.view} onView={(view) => {
                console.log("VIEW", view);
                this.setState({ view });
            }} onSelectSlot={this.props.onSelectSlot} />
        </div>);
    }
}
const styles = {
    fullHeight: {
        height: window.innerHeight - 237,
        position: "relative",
    }
};
export default withStyles(styles)(Dnd);
