import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm, AbaseDatePickerField, AbaseAutocompleteMultipleField, AbaseCheckbox } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { UsuarioQueryByIdComponent, useUsuarioCreate, useUsuarioUpdate, AreaAllComponent, EmpresaAllComponent, EquipeAllComponent, useClienteAll, ParceiroAllComponent } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import UsuarioLabel, { nivelList } from './UsuarioNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { Grid } from '@material-ui/core';
import { MaskTelefone } from '../../helpers/mask';
const UsuarioSchema = Yup.object()
    .shape({
        id: Yup.string().notRequired(),
        nome: Yup.string().required(),
        email: Yup.string().required(),
        login: Yup.string().notRequired(),
        senha: Yup.string().notRequired(),
        datanascimento: Yup.date().notRequired(),
        avatar: Yup.string().notRequired(),
        permissao: Yup.number().required(),
        corAvatar: Yup.string().notRequired(),
        cliente: Yup.boolean().notRequired(),
        setor: Yup.string().notRequired(),
        cargo: Yup.string().notRequired(),
        telefone: Yup.string().notRequired(),
        ramal: Yup.string().notRequired(),
        ativo: Yup.boolean().notRequired(),
        todasEmpresas: Yup.boolean().notRequired(),
        todasAreas: Yup.boolean().notRequired(),
        todasEquipes: Yup.boolean().notRequired(),
        visualizaAgenda: Yup.boolean().notRequired().nullable(),
        lancarVeiculo: Yup.boolean().notRequired().nullable(),
        lancarOs: Yup.boolean().notRequired().nullable(),
        emailAgenda: Yup.boolean().notRequired().nullable(),
        emailLembrete: Yup.boolean().notRequired().nullable(),
        acessoBi: Yup.boolean().notRequired().nullable().default(false),
        usuarioArea: Yup.array(Yup.object({
            areId: Yup.string().notRequired()
        }).noUnknown()).notRequired(),
        usuarioEmpresa: Yup.array(Yup.object({
            empId: Yup.string().notRequired()
        }).noUnknown()).notRequired(),
        usuarioEquipe: Yup.array(Yup.object({
            equId: Yup.string().notRequired()
        }).noUnknown()).notRequired(),
        usuarioCliente: Yup.array(Yup.object({
            cliId: Yup.string().notRequired()
        }).noUnknown()).notRequired(),
        parId: Yup.string().notRequired(),
    })
    .noUnknown();
const todasEmpresas = { empId: "TODOS", emp: { razaosocial: "TODAS" } };
const todasAreas = { areId: "TODOS", are: { descricao: "TODAS" } };
const todasEquipes = { equId: "TODOS", equ: { descricao: "TODAS" } };
function UsuarioForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useUsuarioUpdate();
    const insert = useUsuarioCreate();
    const { data: clientes } = useClienteAll();
    const clientesMemo = React.useMemo(() => {
        return _.get(clientes, 'cliente.list', []).map(x => {
            return { cli: x, cliId: x.id };
        });
    }, [_.get(clientes, 'cliente.list', []).length]);
    const handleSubmit = async (values) => {
        values.telefone = values.telefone ? values.telefone.replace(/[^\d]/g, '') : null;
        const mutationData = await cast(UsuarioSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.usuario.update.id'));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.usuario.create.id'));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    let [showPassword, setShowPassword] = React.useState(false);
    let [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    return (<UsuarioQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
    }}>
        {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(UsuarioSchema)} initialValues={_.get(data, 'usuario.list[0]') || { ativo: true, cliente: false }} decorators={defaultDecorators} subscription={{
            submitting: true
        }} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Usuário" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
            <AbaseLoading open={loading || submitting} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="nome" parse={defaultFieldParser}>
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={UsuarioLabel.nome} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="email" parse={defaultFieldParser}>
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={UsuarioLabel.email} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="datanascimento">
                        {({ input, meta }) => (<AbaseDatePickerField meta={meta} {...input} label={UsuarioLabel.datanascimento} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="permissao">
                        {({ input, meta }) => {
                            if (input.value && notEditting)
                                switch (input.value) {
                                    case nivelList[0].id:
                                        form.change("visualizaAgenda", true);
                                        form.change("lancarVeiculo", true);
                                        form.change("lancarOs", true);
                                        form.change("emailAgenda", true);
                                        form.change("emailLembrete", true);
                                        form.change("ativo", true);
                                        form.change("todasAreas", true);
                                        form.change("todasEquipes", true);
                                        break;
                                    case nivelList[1].id:
                                        form.change("visualizaAgenda", true);
                                        form.change("lancarVeiculo", false);
                                        form.change("lancarOs", false);
                                        form.change("emailAgenda", true);
                                        form.change("emailLembrete", true);
                                        form.change("todasAreas", true);
                                        form.change("todasEquipes", true);
                                        form.change("ativo", true);
                                        break;
                                    case nivelList[2].id:
                                        form.change("visualizaAgenda", false);
                                        form.change("lancarVeiculo", false);
                                        form.change("lancarOs", false);
                                        form.change("emailAgenda", false);
                                        form.change("emailLembrete", false);
                                        form.change("ativo", true);
                                        break;
                                    case nivelList[3].id:
                                        form.change("visualizaAgenda", false);
                                        form.change("lancarVeiculo", false);
                                        form.change("lancarOs", false);
                                        form.change("emailAgenda", false);
                                        form.change("emailLembrete", false);
                                        form.change("ativo", true);
                                        break;
                                    case nivelList[4].id:
                                        form.change("visualizaAgenda", true);
                                        form.change("lancarVeiculo", true);
                                        form.change("lancarOs", true);
                                        form.change("emailAgenda", true);
                                        form.change("emailLembrete", true);
                                        form.change("ativo", true);
                                        break;
                                    default:
                                        form.change("visualizaAgenda", false);
                                        form.change("lancarVeiculo", false);
                                        form.change("lancarOs", false);
                                        form.change("emailAgenda", false);
                                        form.change("emailLembrete", false);
                                        form.change("ativo", false);
                                        break;
                                }
                            return (<AbaseAutoCompleteFieldFinalForm label={"Nível"} meta={meta} valueField={'id'} textField={'text'} data={nivelList} {...input} />)
                        }}
                    </Field>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <EmpresaAllComponent>
                        {({ data, loading }) => {
                            return (<Field name="todasEmpresas">
                                {({ input: { value: todasEmpresasSelected, onChange: onChangeTodasEmpresas } }) => (<Field name="usuarioEmpresa">
                                    {({ input, meta }) => {
                                        return (<React.Fragment>
                                            <AbaseAutocompleteMultipleField fullWidth isLoading={loading} data={[todasEmpresas, ..._.get(data, 'empresa.list', []).map(x => {
                                                if (input.value) {
                                                    const existingData = _.first(input.value.filter((y) => y.emp.id === x.id));
                                                    if (existingData) {
                                                        return existingData;
                                                    }
                                                }
                                                return { emp: x, empId: x.id };
                                            })]} label="Empresas" getOptionValue={(a) => a} getOptionLabel={(a) => a.emp.razaosocial} value={todasEmpresasSelected ? [todasEmpresas] : input.value} onChange={value => {
                                                onChangeTodasEmpresas(value.filter(x => x.empId === "TODOS").length > 0);
                                                input.onChange(value.filter(x => x.empId !== "TODOS"));
                                            }} />
                                        </React.Fragment>);
                                    }}
                                </Field>)}</Field>);
                        }}
                    </EmpresaAllComponent>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <AreaAllComponent>
                        {(areas) => {
                            return (<Field name="todasAreas">
                                {({ input: { value: todasAreasSelected, onChange: onChangeTodasAreas } }) => (<Field name="usuarioArea">
                                    {({ input, meta }) => {
                                        return (<AbaseAutocompleteMultipleField fullWidth data={[todasAreas, ..._.get(areas, 'data.area.list', []).map(x => {
                                            if (input.value) {
                                                const existingData = _.first(input.value.filter((y) => y.are.id === x.id));
                                                if (existingData) {
                                                    return existingData;
                                                }
                                            }
                                            return { are: x, areId: x.id };
                                        })]} label="Áreas" getOptionValue={(a) => a} textField={"are.descricao"} value={todasAreasSelected ? [todasAreas] : input.value} onChange={value => {
                                            onChangeTodasAreas(value.filter(x => x.areId === "TODOS").length > 0);
                                            input.onChange(value.filter(x => x.areId !== "TODOS"));
                                        }} />);
                                    }}
                                </Field>)}</Field>);
                        }}
                    </AreaAllComponent>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <EquipeAllComponent>
                        {({ data, loading }) => {
                            return (<Field name="todasEquipes">
                                {({ input: { value: todasEquipesSelected, onChange: onChangeTodasEquipes } }) => (<Field name="usuarioEquipe">
                                    {({ input, meta }) => {
                                        return (<AbaseAutocompleteMultipleField fullWidth isLoading={loading} data={[todasEquipes, ..._.get(data, 'equipe.list', []).map(x => {
                                            if (input.value) {
                                                const existingData = _.first(input.value.filter((y) => y.equ.id === x.id));
                                                if (existingData) {
                                                    return existingData;
                                                }
                                            }
                                            return { equ: x, equId: x.id };
                                        })]} label="Equipes" getOptionValue={(a) => a} getOptionLabel={(a) => a.equ.descricao} value={todasEquipesSelected ? [todasEquipes] : input.value} onChange={value => {
                                            onChangeTodasEquipes(value.filter(x => x.equId === "TODOS").length > 0);
                                            input.onChange(value.filter(x => x.equId !== "TODOS"));
                                        }} />);
                                    }}
                                </Field>)}</Field>);
                        }}
                    </EquipeAllComponent>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field name="parId">
                        {({ input, meta }) => {
                            return (<ParceiroAllComponent>{({ data, loading }) => {
                                return !loading ? <AbaseAutoCompleteFieldFinalForm label={"Parceiro"} meta={meta} valueField={'id'} textField={'nome'} data={_.get(data, 'parceiro.list', [])} {...input} /> : null;
                            }}</ParceiroAllComponent>);
                        }}
                    </Field>
                </Grid>
                <Grid container spacing={0} className="colCheck">
                    <Grid>
                        <Field name="visualizaAgenda" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"VISUALIZAR TODAS AGENDAS"} />)}</Field>
                    </Grid>
                    <Grid>
                        <Field name="lancarVeiculo" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"LANÇAR VEÍCULO"} />)}</Field>
                    </Grid>
                    <Grid>
                        <Field name="lancarOs" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"LANÇAR ORDEM DE SERVIÇO"} />)}</Field>
                    </Grid>
                    <Grid>
                        <Field name="emailAgenda" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"Receber Email de Agenda"} />)}</Field>
                    </Grid>
                    <Grid>
                        <Field name="emailLembrete" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"Receber Email de Lembrete"} />)}</Field>
                    </Grid>
                    <Grid>
                        <Field name="acessoBi" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"Acessar BI"} />)}</Field>
                    </Grid>
                    <Grid>
                        <Field name="cliente" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"USUÁRIO DE CLIENTE"} />)}</Field>
                    </Grid>
                </Grid>
                <Field name="cliente">{({ input: { value: cliente } }) => (cliente ? (<React.Fragment>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="login" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={"Login Softdesk"} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="setor" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={"Setor"} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Field name="cargo" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={"cargo"} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Field name="telefone" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={"Telefone"} InputProps={{
                                inputComponent: MaskTelefone
                            }} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Field name="ramal" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={"Ramal"} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12}>
                        <Field name="usuarioCliente">
                            {({ input, meta }) => {
                                return (<AbaseAutocompleteMultipleField fullWidth isLoading={!clientesMemo || clientesMemo.length === 0} data={clientesMemo} label="Clientes" getOptionValue={(a) => a} value={input.value} textField={"cli.nome"} onChange={input.onChange} />);
                            }}
                        </Field>
                    </Grid>
                </React.Fragment>) : null)}</Field>
                {!params.id ? (<React.Fragment>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="senha">
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} type={showPassword ? 'text' : 'password'} icon={showPassword ? 'visibility_off' : 'visibility'} {...input} label={UsuarioLabel.senha} iconclick={() => {
                                setShowPassword(!showPassword);
                            }} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="confirmsenha">
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} type={showConfirmPassword ? 'text' : 'password'} icon={showConfirmPassword ? 'visibility_off' : 'visibility'} {...input} label={UsuarioLabel.confirmsenha} iconclick={() => {
                                setShowConfirmPassword(!showConfirmPassword);
                            }} />)}
                        </Field>
                    </Grid>
                </React.Fragment>) : null}
                <Grid container spacing={0} className="colCheck">
                    <Grid item xs={12}>
                        <Field name="ativo" type={"checkbox"}>{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"ATIVO"} />)}</Field>
                    </Grid>
                </Grid>
            </Grid>
        </DialogForm>)} />)}
    </UsuarioQueryByIdComponent>);
}
export default UsuarioForm;
