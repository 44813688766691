import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { HotelListComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal from '../../RenderWithModal';
import HotelDelete from './HotelDelete';
import HotelForm from './HotelForm';
import HotelLabel from './HotelNameLabel';
import HotelLocalizacao from './HotelLocalizacao';
import { Tooltip, IconButton } from '@material-ui/core';
import { LocalHotelOutlined } from '@material-ui/icons';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
export default function HotelList(props) {
    const { match } = props;
    const columns = [
        {
            name: HotelLabel.id,
            field: 'id',
            defaultVisible: false
        },
        {
            name: HotelLabel.nome,
            field: 'nome',
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: HotelLabel.telefone,
            field: 'telefone',
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: HotelLabel.celular,
            field: 'celular',
            defaultVisible: false,
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: HotelLabel.email,
            field: 'email',
            defaultVisible: false,
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: HotelLabel.site,
            field: 'site',
            defaultVisible: false,
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: HotelLabel.endereco,
            field: 'endereco',
            defaultVisible: false,
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: HotelLabel.cidade,
            field: 'codigoIbgeNavigation',
            customBodyRender: (value) => (_.get(value, 'nome') ? _.get(value, 'nome') : 'N/A')
        },
        {
            name: HotelLabel.tipo,
            field: 'tipo',
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: HotelLabel.precoStandard,
            field: 'precoStandard',
            customBodyRender: (value) => value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : 'N/A'
        },
        {
            name: HotelLabel.precoDuplo,
            field: 'precoDuplo',
            customBodyRender: (value) => value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : 'N/A'
        },
        {
            name: HotelLabel.precoTriplo,
            field: 'precoTriplo',
            customBodyRender: (value) => value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : 'N/A'
        }
    ];
    const selectedActions = (selected) => {
        let localizacao = {};
        if (selected.length === 1)
            localizacao = selected[0]['posicaoGeografica'];
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" to={`${match.path}/delete/${_.map(selected, 'id').join(',')}`} component={Link} />,
            selected.length === 1 ? (<Tooltip title="Visualizar Local">
                <IconButton aria-label="Local" to={`${match.path}/localizacao/${localizacao.x}/${localizacao.y}/`}
                    //@ts-ignore
                    component={Link}>
                    <LocalHotelOutlined />
                </IconButton>
            </Tooltip>) : null
        ];
    };
    const customActions = [<AddAction key="add" to={`${match.path}/add`} component={Link} />];
    const filters = () => [
        <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta} />}</Field>,
        <Field name={'nome'} parse={defaultFieldParser}>
            {({ input, meta }) => <AbaseTextField label={'Nome'} {...input} meta={meta} />}
        </Field>,
        <Field name={'telefone'}>
            {({ input, meta }) => <AbaseTextField label={'Telefone'} {...input} meta={meta} />}
        </Field>,
        <Field name={'cidade'} parse={defaultFieldParser}>
            {({ input, meta }) => <AbaseTextField label={'Cidade'} {...input} meta={meta} />}
        </Field>,
        <Field name={'endereco'} parse={defaultFieldParser}>
            {({ input, meta }) => <AbaseTextField label={'Endereço'} {...input} meta={meta} />}
        </Field>,
        <Field name={'email'} parse={defaultFieldParser}>
            {({ input, meta }) => <AbaseTextField label={'E-mail'} {...input} meta={meta} />}
        </Field>,
        <Field name={'site'}>{({ input, meta }) => <AbaseTextField label={'Site'} {...input} meta={meta} />}</Field>,
        <Field name={'preferenciaMin'}>
            {({ input, meta }) => {
                if (input.value > 10) {
                    input.onChange(10);
                }
                else if (input.value < 0) {
                    input.onChange(0);
                }
                return <AbaseTextField label={'Preferencia Minima'} {...input} meta={meta} type="number" />;
            }}
        </Field>,
        <Field name={'preferenciaMax'}>
            {({ input, meta }) => {
                if (input.value > 10) {
                    input.onChange(10);
                }
                else if (input.value < 0) {
                    input.onChange(0);
                }
                return <AbaseTextField label={'Preferencia Máxima'} {...input} meta={meta} type="number" />;
            }}
        </Field>
    ];
    const formName = HotelLabel.form;
    return (<React.Fragment>
        <Route path={`${match.path}/add`} render={RenderWithModal(HotelForm)} />
        <Route path={`${match.path}/localizacao/:lat/:long`} render={RenderWithModal(HotelLocalizacao)} />
        <Route path={`${match.path}/edit/:id`} render={RenderWithModal(HotelForm)} />
        <Route path={`${match.path}/delete/:id`} render={RenderWithModal(HotelDelete)} />

        <GraphqlPaperTableListener>
            {({ variables, setVariables }) => (<HotelListComponent variables={variables} skip={variables === null}>
                {({ data, loading, refetch, error }) => (<div>
                    <AbasePaperTable
                        customPlacehorderSearch={'Pesquisar por nome'}
                        sticky={true}
                        selectMode={'multi'}
                        loading={loading}
                        data={_.get(data, 'hotel.pagedList.data') || []}
                        total={_.get(data, 'hotel.pagedList.total')}
                        columns={columns}
                        customFilters={filters}
                        title={formName}
                        requestData={setVariables}
                        error={errorMessagePaperTable(error)}
                        customSelectedActions={selectedActions}
                        customActions={customActions}
                        selectListener={props.selectListener}
                        onDoubleClick={row => {
                            props.history.push(`${match.path}/edit/${row.id}`);
                        }} />
                    <ReenterRouteListener callback={refetch} />
                </div>)}
            </HotelListComponent>)}
        </GraphqlPaperTableListener>
    </React.Fragment>);
}
