import { AbaseAutoCompleteFieldFinalForm, AbaseLoading, AbaseTextField, clearDraft, FormDraft, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import * as Yup from 'yup';
import AutoCompleteCliente from '../../components/AutoCompleteCliente';
import DialogForm from '../../DialogForm';
import { EmpresaAllComponent, ModeloTermoAceiteAllComponent, TermoAceiteQueryByIdComponent, useTermoAceiteCreate, useTermoAceiteUpdate, UsuarioAllComponent } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { cast, validate } from '../../helpers/validations';
import TermoAceiteLabel from './TermoAceiteNameLabel';
import { Grid } from '@material-ui/core';
export const TermoAceiteSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    ordId: Yup.string().notRequired(),
    cliId: Yup.string().notRequired(),
    usuId: Yup.string().notRequired(),
    modId: Yup.string().notRequired(),
    empId: Yup.string().notRequired(),
    nomeSistema: Yup.string().required(),
    dataGerado: Yup.date().notRequired(),
}).noUnknown();
function TermoAceiteForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useTermoAceiteUpdate();
    const insert = useTermoAceiteCreate();
    const handleSubmit = async (values) => {
        console.log("handleSubmit", values);
        values = { ...values, cliId: values.cli.id };
        const mutationData = await cast(TermoAceiteSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.termoAceite.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.termoAceite.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = false;
    return (<TermoAceiteQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
            {({ data, loading, refetch, error }) => (<FormDraft useDraft={isUsingDraft} onSubmit={handleSubmit} validate={validate(TermoAceiteSchema)} initialValues={_.get(data, "termoAceite.list[0]") || {
        dataGerado: new Date().toISOString()
    }} subscription={{ submitting: true }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Termo de Aceite" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                            <AbaseLoading open={loading || submitting}/>
                            <Grid container spacing={1} className="padb63">
                                <Grid item xs={12}>
                                    <Field name="nomeSistema" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={TermoAceiteLabel.nomeSistema}/>)}</Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field name="cli">{({ input, meta }) => (<AutoCompleteCliente {...input}/>)}
                                    </Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <EmpresaAllComponent>{({ data, loading }) => (<Field name="empId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={TermoAceiteLabel.emp} valueField={"id"} textField={"razaosocial"} data={_.get(data, "empresa.list", [])} {...input}/>)}</Field>)}
                                    </EmpresaAllComponent>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                                        
                                    <ModeloTermoAceiteAllComponent>{({ data, loading }) => (<Field name="modId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={TermoAceiteLabel.mod} valueField={"id"} textField={"nome"} data={_.get(data, "modeloTermoAceite.list", [])} {...input} onChange={value => {
        input.onChange(value);
    }}/>)}</Field>)}
                                    </ModeloTermoAceiteAllComponent>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    
                                    <UsuarioAllComponent variables={{
        cliente: false
    }}>{({ data, loading }) => (<Field name="usuId">{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={TermoAceiteLabel.usu} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input}/>)}</Field>)}
                                    </UsuarioAllComponent>
                                </Grid>
                            </Grid>
                        </DialogForm>)}/>)}
        </TermoAceiteQueryByIdComponent>);
}
export default TermoAceiteForm;
