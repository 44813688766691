const names = {
    "form": "Agenda",
    "id": "ID",
    "cliId": "Cliente ID",
    "conId": "Contato ID",
    "empId": "Empresa ID",
    "usuId": "Usuário ID",
    "tipo": "Tipo",
    "titulo": "Título",
    "descricao": "Descrição",
    "inicio": "Data Inicial",
    "fim": "Data Final",
    "local": "Local",
    "particular": "Particular",
    "status": "Status",
    "cli": "Cliente",
    "emp": "Empresa",
    "usu": "Usuário",
    "agendaRecurso": "Agenda Recurso",
    "agendaRequisicao": "Agenda Requisição",
    "agendaTarefa": "Agenda Tarefa",
    "agendaTimeline": "Agenda Timeline",
    "agendaUsuario": "Agenda Usuário",
    "con": "Conta"
};
export default names;
const tabs = {
    cliente: 0,
    participantes: 1,
    local: 2,
    requisicao: 3,
    recurso: 4,
    tarefa: 5,
    ordemServico: 6,
};
export const agendaTabs = tabs;
export const agendaTipos = {
    'REUNIAO': {
        text: "REUNIÃO",
        icon: "meeting_room",
        tabs: {
            [tabs.cliente]: {
                visible: true,
                required: true
            },
            [tabs.participantes]: {
                visible: true,
                required: true
            },
            [tabs.recurso]: {
                visible: true,
                required: false
            },
            [tabs.local]: {
                visible: true,
                required: true
            },
            [tabs.requisicao]: {
                visible: false,
                required: false
            },
            [tabs.tarefa]: {
                visible: false,
                required: false
            },
        }
    },
    'REUNIAOLOCAL': {
        text: "REUNIÃO INTERNA",
        icon: "meeting_room",
        tabs: {
            [tabs.cliente]: {
                visible: false,
                required: false
            },
            [tabs.participantes]: {
                visible: true,
                required: true
            },
            [tabs.recurso]: {
                visible: true,
                required: false
            },
            [tabs.local]: {
                visible: true,
                required: true
            },
            [tabs.requisicao]: {
                visible: false,
                required: false
            },
            [tabs.tarefa]: {
                visible: false,
                required: false
            },
        }
    },
    'AGENDA': {
        text: "AGENDA",
        icon: "rv_hookup",
        tabs: {
            [tabs.cliente]: {
                visible: true,
                required: true
            },
            [tabs.participantes]: {
                visible: true,
                required: false
            },
            [tabs.recurso]: {
                visible: true,
                required: false
            },
            [tabs.local]: {
                visible: true,
                required: true
            },
            [tabs.requisicao]: {
                visible: true,
                required: false
            },
            [tabs.tarefa]: {
                visible: true,
                required: false
            },
        }
    },
    'VIAGEM': {
        text: "VIAGEM",
        icon: "beach_access",
        tabs: {
            [tabs.cliente]: {
                visible: true,
                required: false
            },
            [tabs.participantes]: {
                visible: true,
                required: false
            },
            [tabs.recurso]: {
                visible: true,
                required: false
            },
            [tabs.local]: {
                visible: true,
                required: true
            },
            [tabs.requisicao]: {
                visible: true,
                required: false
            },
            [tabs.tarefa]: {
                visible: true,
                required: false
            },
        }
    },
    'VIAGEMPOUSO': {
        text: "VIAGEM COM POUSO",
        icon: "beach_access",
        tabs: {
            [tabs.cliente]: {
                visible: true,
                required: false
            },
            [tabs.participantes]: {
                visible: true,
                required: true
            },
            [tabs.recurso]: {
                visible: true,
                required: false
            },
            [tabs.local]: {
                visible: true,
                required: true
            },
            [tabs.requisicao]: {
                visible: true,
                required: true
            },
            [tabs.tarefa]: {
                visible: true,
                required: false
            },
        }
    },
    'ATENDIMENTO': {
        text: "ATENDIMENTO",
        icon: "beach_access",
        tabs: {
            [tabs.cliente]: {
                visible: true,
                required: true
            },
            [tabs.participantes]: {
                visible: false,
                required: false
            },
            [tabs.recurso]: {
                visible: false,
                required: false
            },
            [tabs.local]: {
                visible: true,
                required: false
            },
            [tabs.requisicao]: {
                visible: false,
                required: false
            },
            [tabs.tarefa]: {
                visible: true,
                required: false
            },
        }
    },
    'DEMONSTRACAO': {
        text: "DEMONSTRAÇÃO",
        icon: "beach_access",
        tabs: {
            [tabs.cliente]: {
                visible: true,
                required: false
            },
            [tabs.participantes]: {
                visible: true,
                required: false
            },
            [tabs.recurso]: {
                visible: true,
                required: false
            },
            [tabs.local]: {
                visible: true,
                required: false
            },
            [tabs.requisicao]: {
                visible: true,
                required: false
            },
            [tabs.tarefa]: {
                visible: true,
                required: false
            },
        }
    },
    'IMPLANTACAO': {
        text: "IMPLANTAÇÃO",
        icon: "beach_access"
    },
    'EVENTO': {
        text: "EVENTO LOCAL",
        icon: "beach_access",
        tabs: {
            [tabs.cliente]: {
                visible: false,
                required: false
            },
            [tabs.participantes]: {
                visible: true,
                required: false
            },
            [tabs.recurso]: {
                visible: true,
                required: false
            },
            [tabs.local]: {
                visible: true,
                required: false
            },
            [tabs.requisicao]: {
                visible: true,
                required: false
            },
            [tabs.tarefa]: {
                visible: true,
                required: false
            },
        }
    },
    'EVENTOLONGE': {
        text: "EVENTO EM OUTRO LOCAL",
        icon: "beach_access",
        tabs: {
            [tabs.cliente]: {
                visible: false,
                required: false
            },
            [tabs.participantes]: {
                visible: true,
                required: false
            },
            [tabs.recurso]: {
                visible: true,
                required: false
            },
            [tabs.local]: {
                visible: true,
                required: true
            },
            [tabs.requisicao]: {
                visible: true,
                required: false
            },
            [tabs.tarefa]: {
                visible: true,
                required: false
            },
        }
    },
};
export const agendaTiposList = Object.keys(agendaTipos).map(x => ({ id: x, ...agendaTipos[x] }));
export const agendaStatus = {
    'CONFIRMADO': {
        text: "CONFIRMADO",
        icon: "check_box"
    },
    'AGUARDANDO': {
        text: "AGUARDANDO",
        icon: "intermediate_checkbox"
    },
    'CANCELADO': {
        text: "CANCELADO",
        icon: "close"
    },
};
export const agendaStatusList = Object.keys(agendaStatus).map(x => ({ id: x, ...agendaStatus[x] }));
