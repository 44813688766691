import { createStyles, withStyles, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import React from 'react';
import { withRouter } from 'react-router';
import UserAvatarSac from './UserAvatarSac';
import _ from 'lodash';
import { UsuarioAutenticadoComponent, ParceiroLogoAllComponent } from '../generated/graphql';
import { logout, getNivel, niveisEnum } from '../services/login';
import OndeEstouComponent from './OndeEstou/OndeEstouComponent';
import { IconButton } from '@material-ui/core';
import withIsMobile from './withIsMobile';
import FiltroGeralTopo from './FiltroGeralTopo';
import MenuIcon from '@material-ui/icons/Menu';
import BtnFiltroCliente from './BtnFiltroCliente';
const styles = createStyles(() => ({
    toolbar: {
        color: "#404040",
        paddingLeft: "15px",
        paddingRight: "15px",
        background: "#e1e8ed",
    },
    menuButton: {
        marginLeft: 0,
        marginRight: 0,
    },
    root: {},
    appBar: {},
    appBarShift: {},
    hide: {},
    drawer: {},
    drawerPaper: {},
    drawerHeader: {},
    content: {},
    contentShift: {},
    sectionDesktop: {},
    sectionMobile: {}
}));
class Bar extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { openProfile: false, show: true };
        this.anchorProfile = React.createRef();
        this.logout = () => {
            const nivel = getNivel();
            logout();
            if (nivel !== niveisEnum.cliente) {
                this.props.history.replace("/login");
            }
            else {
                this.props.history.replace("/logincliente");
            }
        };
    }
    atualizar() {
        // this.setState({ show: false })
    }
    render() {
        const { classes, aberto, showDrawer } = this.props;
        const nivel = getNivel();
        return (<AppBar className={classNames(classes.appBar)} position="fixed" id="appBar">
            <Toolbar disableGutters={aberto} className={classes.toolbar}>
                <UsuarioAutenticadoComponent pollInterval={60000}>
                    {({ data }) => <Grid container spacing={0}>
                        <Grid item xs={4}>
                            {showDrawer ? (<IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.props.clique}>
                                <MenuIcon />
                            </IconButton>) : (<div className="menuTopo">
                                <List component="nav">
                                    {this.props.topItems}
                                </List>
                            </div>)}
                        </Grid>
                        <Grid item xs={4} className="center" style={{ cursor: "pointer" }} title={"home"} onClick={() => {
                            if (nivel !== niveisEnum.cliente) {
                                this.props.history.push("/");
                            }
                            else {
                                this.props.history.push("/cliente/home");
                            }
                        }}>
                            <img className="logoPage" alt={'Logo'} />
                            {showDrawer ? null : getNivel() === niveisEnum.parceiro ?
                                <ParceiroLogoAllComponent variables={{ parId: _.get(data, 'usuario.me.parId') }}>
                                    {({ data, loading }) => {
                                        return loading ? null : !_.get(data, 'parceiroLogo.list[0].id') ? null :
                                            <img src={"/api/parceirologo/logo?id=" + _.get(data, 'parceiroLogo.list[0].id')} title="Logo" height="40px" alt="Logo Empresa" />;
                                    }}
                                </ParceiroLogoAllComponent>
                                : <img src="/img/abase.png" title="Logo" height="40px" alt="Logo Empresa Abase Sistemas" />}
                        </Grid>
                        <Grid item xs={4} className="right">
                            {_.get(data, "usuario.me.nome") ? <React.Fragment>
                                <UserAvatarSac name={_.get(data, "usuario.me.nome")} ref={this.anchorProfile} color={_.get(data, "usuario.me.corAvatar")} onClick={() => this.setState({ openProfile: true })} url={_.get(data, "usuario.me.avatarUrl")} size={40} />
                                <Menu anchorEl={this.anchorProfile.current} open={this.state.openProfile} onClose={() => this.setState({ openProfile: false })} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} transformOrigin={{ vertical: "top", horizontal: "center" }} getContentAnchorEl={null} className="menuIten">
                                    {nivel !== niveisEnum.cliente ? <MenuItem onClick={() => this.props.history.push("/perfil")}>Perfil</MenuItem> : null}
                                    <MenuItem onClick={this.logout}>Sair</MenuItem>
                                </Menu>
                                {nivel !== niveisEnum.cliente && nivel !== niveisEnum.parceiro && !showDrawer ? <OndeEstouComponent local={_.get(data, "usuario.me.ultimaLocalizacao.loc")} atualizar={this.atualizar.bind(this)} /> : null}
                            </React.Fragment>
                                : null}
                            <BtnFiltroCliente show={!showDrawer} />
                        </Grid>
                    </Grid>}</UsuarioAutenticadoComponent>
            </Toolbar>
        </AppBar>);
    }
}
export default withIsMobile(withRouter(withStyles(styles)(Bar)));
