import { withRouter } from 'react-router';
import { Component } from 'react';
class ReenterRouteListener extends Component {
    componentDidMount() {
        this.listener = this.props.history.listen((newLocation) => {
            if (newLocation.pathname === this.props.match.url) {
                console.log('REFETCH');
                if (this.props.callback)
                    setTimeout(() => this.props.callback(), 100);
            }
            else if (newLocation.pathname.indexOf('projeto') !== -1 && (this.props.match.url.indexOf("add") !== -1 || this.props.match.url.indexOf("edit") !== -1)) {
                if (this.props.callback)
                    setTimeout(() => this.props.callback(), 100);
            }
        });
    }
    componentWillUnmount() {
        if (this.listener) {
            this.listener();
        }
    }
    render() {
        return null;
    }
}
export default withRouter(ReenterRouteListener);
