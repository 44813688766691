import { AbasePaperTable, AbaseAutoCompleteFieldFinalForm, AbaseCheckbox } from "abase-components";
import _ from "lodash";
import React from "react";
import { Link } from 'react-router-dom';
import { Field } from "react-final-form";
import { Route } from "react-router";
import { UsuarioLocalListComponent, LocalAllComponent, UsuarioAllComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import UsuarioLocalLabel from './UsuarioLocalNameLabel';
import { IconButton } from "@material-ui/core";
import RenderWithModal from "../../RenderWithModal";
import UsuarioLocalView from './UsuarioLocalView';
const UserAvatar = require('react-user-avatar');
export default function UsuarioLocalList(props) {
    const { match } = props;
    const columns = [
        {
            name: "",
            field: 'usu',
            width: 30,
            customBodyRender: (val, row) => {
                // return null;
                return <div><UserAvatar name={_.get(val, 'nome')} size="32" src={_.get(val, 'avatar')}/></div>;
            }
        },
        {
            name: UsuarioLocalLabel.usu,
            field: 'usu.nome',
            width: 200,
        },
        {
            name: UsuarioLocalLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: UsuarioLocalLabel.loc,
            field: 'loc.descricao',
            defaultVisible: true
        },
        {
            name: UsuarioLocalLabel.data,
            field: "data",
            width: 200,
            customBodyRender: (val) => new Date(val).toLocaleString()
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<Link to={`/agenda/usuariolocal/view/${selected[0].usuId}`} {...props} title={`Visualizar detalhes`}>
                    <IconButton aria-label={`Áreas da Equipe`}>
                        <i className={"material-icons"}>info</i>
                    </IconButton>
                </Link>) : null,
        ];
    };
    const customActions = [];
    const filters = () => ([
        // <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <LocalAllComponent>{({ data, loading, refetch }) => (<Field name="locId">{({ input, meta }) => (<React.Fragment>
                    <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Local"} valueField={"id"} textField={"descricao"} data={_.get(data, "local.list", [])} {...input}/>
                </React.Fragment>)}</Field>)} 
        </LocalAllComponent>,
        <UsuarioAllComponent variables={{ cliente: false }}>{({ data, loading, refetch }) => (<Field name="usuId">{({ input, meta }) => (<React.Fragment>
                    <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Usuário"} valueField={"id"} textField={"nome"} data={_.get(data, "usuario.list", [])} {...input}/>
                </React.Fragment>)}</Field>)}
        </UsuarioAllComponent>,
        <Field name="atualizadosHoje">{({ input, meta }) => (<AbaseCheckbox name="atualizadosHoje" label="Atualizados Hoje" {...input}/>)}</Field>
    ]);
    const formName = UsuarioLocalLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<UsuarioLocalListComponent variables={variables} skip={variables === null}>
                    {({ data, loading, refetch, error }) => (<div>
                        <AbasePaperTable sticky={true} selectMode={"single"} onDoubleClick={row => {
        props.history.push(`/agenda/usuariolocal/view/${row.usuId}`);
    }} loading={loading} data={_.get(data, "usuarioLocal.pagedListUltimaLocalizacao.data") || []} total={_.get(data, "usuarioLocal.pagedListUltimaLocalizacao.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener}></AbasePaperTable>
                        <ReenterRouteListener callback={refetch}/>
                        <Route path={`${match.path}/view/:id`} render={RenderWithModal(UsuarioLocalView)}/>
                    </div>)}
                </UsuarioLocalListComponent>)}
        </GraphqlPaperTableListener>);
}
