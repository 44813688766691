import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ModeloEtapaListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal, { RenderListWithModal } from "../../RenderWithModal";
import ModeloEtapaDelete from "./ModeloEtapaDelete";
import ModeloEtapaForm from "./ModeloEtapaForm";
import ModeloEtapaLabel from './ModeloEtapaNameLabel';
import ModeloTarefaList from "../ModeloTarefa/ModeloTarefaList";
export default function ModeloEtapaList(props) {
    const { match } = props;
    const columns = [
        {
            name: ModeloEtapaLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: ModeloEtapaLabel.descricao,
            field: "descricao"
        },
        {
            name: ModeloEtapaLabel.ordem,
            field: "ordem"
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            // selected.length === 1 ? (
            //     <Link to={`${match.url}/tarefa/${selected[0].id}`} {...props} title={`Adicionar Tarefas`}>
            //         <IconButton aria-label={`Adicionar Tarefas`}>
            //             <i className={"material-icons"}>assignment</i>
            //         </IconButton>
            //     </Link>) : null,
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link}/>) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/delete/${_.map(selected, "id").join(",")}`}/>
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.url}/add`}/>,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descrição"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = ModeloEtapaLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => {
        if (match.params.modeloProjetoId && (!variables || (variables.modeloProjetoId !== match.params.modeloProjetoId))) {
            setVariables({ ...variables, modeloProjetoId: match.params.modeloProjetoId });
        }
        return (<div className={"colDivBloco"}>
                        <ModeloEtapaListComponent variables={variables} skip={variables === null}>
                            {({ data, loading, refetch, error }) => (<div>
                                <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "modeloEtapa.pagedList.data") || []} total={_.get(data, "modeloEtapa.pagedList.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={val => props.history.push(match.url + "/edit/" + val.id)}/>
                                <ReenterRouteListener callback={refetch}/>
                                <Route path={`${match.path}/add`} render={RenderWithModal(ModeloEtapaForm)}/>
                                <Route path={`${match.path}/edit/:idEtapa`} render={RenderWithModal(ModeloEtapaForm)}/>
                                <Route path={`${match.path}/delete/:idEtapa`} render={RenderWithModal(ModeloEtapaDelete)}/>
                                <Route path={`${match.path}/tarefa/:idModeloEtapa`} render={RenderListWithModal(ModeloTarefaList)}/>
                            </div>)}
                        </ModeloEtapaListComponent>
                    </div>);
    }}
        </GraphqlPaperTableListener>);
}
