const names = {
    "form":"Categoria",
    "id":"Id",
    "empId":"EmpId",
    "descricao":"Descricao",
    "gestorChaveintegracao":"GestorChaveintegracao",
    "ativo":"Ativo",
    "emp":"Emp",
    "cliente":"Cliente",
}
export default names