import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { Droppable, DragDropContext, } from 'react-beautiful-dnd';
import Column from './Column';
import { colors } from './primitives/constants';
const ParentContainer = styled.div `
  height: ${({ height }) => height};
  overflow-x: hidden;
  overflow-y: auto;  
`;
const Container = styled.div `
  background-color: transparent;
  /* like display:flex but will allow bleeding over the window width */
  min-width: 100%;
  display: inline-flex;
`;
;
;
export default class Board extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            ordered: [],
        };
        // static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        //     return { ordered: Object.keys(nextProps.columns || {}) }
        // }
        this.onDragEnd = (result) => {
            console.log("onDragEnd", result);
            // if (result.combine) {
            //     if (result.type === 'COLUMN') {
            //         const shallow: string[] = [...this.state.ordered];
            //         shallow.splice(result.source.index, 1);
            //         this.setState({ ordered: shallow });
            //         return;
            //     }
            //     const column: Task[] = this.props.columns[result.source.droppableId];
            //     const withQuoteRemoved: Task[] = [...column];
            //     withQuoteRemoved.splice(result.source.index, 1);
            //     const columns: QuoteMap = {
            //         ...this.props.columns,
            //         [result.source.droppableId]: withQuoteRemoved,
            //     };
            //     this.props.setColumns(columns);
            //     return;
            // }
            // // dropped nowhere
            if (!result.destination) {
                return;
            }
            const source = result.source;
            const destination = result.destination;
            // // did not move anywhere - can bail early
            if (source.droppableId === destination.droppableId &&
                source.index === destination.index) {
                return;
            }
            this.props.updateStatus(result.draggableId, destination.droppableId);
            // // reordering column
            // if (result.type === 'COLUMN') {
            //     const ordered: string[] = reorder(
            //         this.state.ordered,
            //         source.index,
            //         destination.index,
            //     );
            //     this.setState({
            //         ordered,
            //     });
            //     return;
            // }
            // const data = reorderQuoteMap({
            //     quoteMap: this.props.columns,
            //     source,
            //     destination,
            // });
            // this.props.setColumns(data.quoteMap)
        };
    }
    render() {
        const columns = this.props.columns;
        const { containerHeight } = this.props;
        console.log({ columns });
        const board = (<Droppable droppableId="board" type="COLUMN" direction="horizontal" ignoreContainerClipping={Boolean(containerHeight)} isCombineEnabled={this.props.isCombineEnabled}>
                {(provided) => (<Container ref={provided.innerRef} {...provided.droppableProps} style={this.props.containerStyle}>
                        {columns.map((key, index) => (<React.Fragment>
                                <Column key={key} index={index} title={key} quotes={(this.props.tasks || [])
            .filter(x => x.status === key)} isScrollable={this.props.withScrollableColumns} isCombineEnabled={this.props.isCombineEnabled} onAdd={this.props.onAdd.bind(null, key)} onClickItem={this.props.onClickItem} onDelete={this.props.onDelete}/>

                            </React.Fragment>))}
                        {provided.placeholder}
                    </Container>)}
            </Droppable>);
        // title={this.props.getColumnTitle(key)}
        // quotes={_.filter<Task>((this.props.tasks || []) as any, x => x.status === key)}
        return (<React.Fragment>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    {containerHeight ? (
        //@ts-ignore
        <ParentContainer height={containerHeight}>{board}</ParentContainer>) : (board)}
                </DragDropContext>
                <Global styles={css `
            body {
              background: ${colors.B200};
            }
          `}/>
            </React.Fragment>);
    }
}
/* eslint-disable react/sort-comp */
Board.defaultProps = {
    isCombineEnabled: false,
};
