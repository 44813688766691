import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Field, FormSpy } from 'react-final-form';
import { AbaseTextField, AbaseDatePickerField, AbaseAutoCompleteFieldFinalForm, AbaseCheckbox } from 'abase-components';
import { ordemServicoTipos } from '../OrdemServico/OrdemServicoNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import AlertaCliente from '../../components/AlertaCliente';
import _ from 'lodash';
export default function StepOrdemServico() {
	return (<React.Fragment>

		<Field name={"particular"}>{({ input, meta }) => {
			return input.value ? "Agendas particulares não podem gerar ordens de serviço." :
				<Grid container spacing={1}>
					<Field name="ordId">{({ input: inputOrdId, meta }) => (<Field name="inicio">{({ input: inputInicio }) => (<FormSpy>{({ form }) => (<Field name="titulo">{({ input: inputTitulo, meta }) => (<Field name="gerarOrdemServico">{({ input: inputOrdemServico, meta }) => (<React.Fragment>
						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item>
									<AbaseCheckbox {...inputOrdemServico} disabled={inputOrdemServico.value && inputOrdId.value} onChange={value => {
										inputOrdemServico.onChange(value);
										form.change("ord.tipo", "EXTERNO");
										form.change("ord.status", "ABERTA");
										form.change("ord.data", inputInicio.value);
										form.change("ord.observacao", inputTitulo.value);
									}} label={"Gerar ordem de serviço"} />
								</Grid>
								<Grid item>
									<Field name="ord.possuiCobranca">{({ input, meta }) => (<AbaseCheckbox {...input} label={"Possui Cobrança"} />)}</Field>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Field name="ord.numero">{({ input }) => (input.value ?
										<span style={{
											textAlign: "right",
											float: "right",
											marginTop: "28px",
											fontWeight: "bold"
										}}>
											Ordem de serviço: {input.value}
										</span> : null)}
									</Field>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<Field name="ord.data">
										{({ input, meta }) => (<AbaseDatePickerField meta={meta} disabled={!inputOrdemServico.value} {...input} label={"Data"} />)}
									</Field>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<Field name="ord.tipo">
										{({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm label={"Tipo"} meta={meta} disabled={!inputOrdemServico.value} valueField={'id'} textField={'text'} data={ordemServicoTipos} {...input} />)}
									</Field>
								</Grid>
								<Grid item xs={12} sm={12} md={4} lg={4}>
									<Field name="ord.tipo">
										{({ input: inputTipo }) => (<Field name="ord.distancia">
											{({ input, meta }) => (<AbaseTextField disabled={!inputOrdemServico.value || inputTipo.value === "INTERNO"} meta={meta} fullWidth={true} {...input} value={inputTipo.value === "INTERNO" ? "" : input.value} label={"Distância"} />)}
										</Field>)}</Field>

								</Grid>
								<Grid item xs={12}>
									<Field name="ord.observacao" parse={defaultFieldParser}>
										{({ input, meta }) => (<AbaseTextField multiline disabled={!inputOrdemServico.value} rows="2" meta={meta} fullWidth={true} {...input} label={"Observação"} />)}
									</Field>
								</Grid>
								<Grid item xs={12}>
									<div style={{ paddingTop: 10, textAlign: "right" }}>
										<FormSpy>{({ form, values }) => (<Button variant="contained" className=" ml5" disabled={!inputOrdemServico.value} onClick={() => {
											form.change("action", "print");
											form.submit();
										}}>
											<i className={"material-icons"}>print</i>Salvar e Imprimir
										</Button>)}</FormSpy>
									</div>
								</Grid>
								<Grid item xs={12}>
									<Field name={'contaCliente'}>{({ input: { value } }) => (<AlertaCliente clienteId={_.get(value, "id")} showAlert={false} />)}</Field>
								</Grid>
							</Grid>
						</Grid>
					</React.Fragment>)}</Field>)}</Field>)}</FormSpy>)}</Field>)}</Field>
				</Grid>
		}}</Field>
	</React.Fragment>);
}
