import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction, AbaseCheckbox, AbaseAutoCompleteFieldFinalForm } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { UsuarioListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import UsuarioDelete from "./UsuarioDelete";
import UsuarioResetPassword from "./UsuarioResetPassword";
import UsuarioForm from "./UsuarioForm";
import UsuarioLabel, { nivelList } from './UsuarioNameLabel';
import { Chip } from "@material-ui/core";
import { formatDate } from "../../helpers/format";
export default function UsuarioList(props) {
    const { match } = props;
    const columns = [
        {
            name: UsuarioLabel.id,
            field: "id",
            defaultVisible: false
        },
        // {
        //     name: UsuarioLabel.avatar,
        //     field: "avatarUrl",
        //     customBodyRender: (value, rowData) => {
        //         console.log("value", { value, rowData })
        //         return <React.Fragment>
        //             {(value) ?
        //                 (<div>
        //                     Não contém img
        //                 </div>)
        //                 :
        //                 <UserAvatarSac name={rowData.nome} color={rowData.corAvatar} url={rowData.avatarUrl} />
        //             }
        //         </React.Fragment>
        //     },
        //     sort: false
        // },
        {
            name: UsuarioLabel.nome,
            field: "nome",
            sort: true
        },
        {
            name: UsuarioLabel.email,
            field: "email"
        },
        {
            name: "Nível",
            field: "permissao",
            customBodyRender: (value) => {
                return _.get(_.first(nivelList.filter(x => x.id === value)), "text");
            }
        },
        {
            name: "Visualizar Agendas",
            field: "visualizaAgenda",
            customBodyRender: (value) => {
                return value ? "S" : "N";
            },
            defaultVisible: false
        },
        {
            name: "Veículo",
            field: "lancarVeiculo",
            customBodyRender: (value) => {
                return value ? "S" : "N";
            },
            defaultVisible: false
        },
        {
            name: "OS",
            field: "lancarOs",
            customBodyRender: (value) => {
                return value ? "S" : "N";
            },
            defaultVisible: false
        },
        {
            name: UsuarioLabel.datanascimento,
            field: "datanascimento",
            defaultVisible: false,
            customBodyRender: (value) => {
                return formatDate(value);
            }
        },
        {
            name: 'Empresas',
            field: 'usuarioEmpresa',
            defaultVisible: false,
            customBodyRender: (value, row) => <React.Fragment>
                {row.todasEmpresas ? <Chip title={"Todas"} variant={'outlined'} className="pullLeft h-auto" label={"Todas"} /> : null}
                {value
                    ? value.map((x, i) => (<Chip key={i} title={x.emp.razaosocial} variant={'outlined'} className="pullLeft h-auto" label={x.emp.razaosocial} />))
                    : null}
            </React.Fragment>
        },
        {
            name: 'Equipe',
            defaultVisible: false,
            field: 'usuarioEquipe',
            customBodyRender: (value, row) => <React.Fragment>

                {row.todasEquipes ? <Chip title={"Todas"} variant={'outlined'} className="pullLeft h-auto" label={"Todas"} /> : null}
                {value
                    ? value.map((x, i) => (<Chip key={i} title={x.equ.descricao} variant={'outlined'} className="pullLeft h-auto" label={x.equ.descricao} />))
                    : null}
            </React.Fragment>
        },
        {
            name: 'Áreas',
            defaultVisible: false,
            field: 'usuarioArea',
            customBodyRender: (value, row) => <React.Fragment>
                {row.todasAreas ? <Chip title={"Todas"} variant={'outlined'} className="pullLeft h-auto" label={"Todas"} /> : null}
                {value
                    ? value.map((x, i) => (<Chip key={i} title={x.are.descricao} variant={'outlined'} className="pullLeft h-auto" label={x.are.descricao} />))
                    : null}
            </React.Fragment>
        },
        {
            name: "Ativo",
            field: "ativo",
            customBodyRender: (value) => {
                return value ? "S" : "N";
            },
            defaultVisible: false
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        console.log(selected)
        return [

            selected.length === 1 ? <Link to={`${match.url}/resetPassword/${selected[0].id}`} {...props} title={`Resetar Password`}>
                <i className={"material-icons"}>clear</i>
            </Link>
                : null,

            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" to={`${match.path}/delete/${_.map(selected, "id").join(",")}`} component={Link} />
        ];
    };
    const customActions = [
        <AddAction key="add" to={`${match.path}/add`} component={Link} />,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"nome"}>{({ input, meta }) => <AbaseTextField label={"Nome"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"email"}>{({ input, meta }) => <AbaseTextField label={"E-mail"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name="permissao">
            {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm label={"Nível"} meta={meta} valueField={'id'} textField={'text'} data={nivelList} {...input} />)}
        </Field>,
        <Field name={"inativos"} type="checkbox">{({ input, meta }) => <AbaseCheckbox label={"Exibe Inativo"} {...input} meta={meta}></AbaseCheckbox>}</Field>,
        <Field name={"trazerContatos"} type="checkbox">{({ input, meta }) => <AbaseCheckbox label={"Trazer Contatos"} {...input} meta={meta}></AbaseCheckbox>}</Field>,
    ]);
    const formName = UsuarioLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<UsuarioListComponent variables={{ ...(variables || {}), trazerContatos: variables && typeof variables.trazerContatos !== "undefined" ? variables.trazerContatos : false }} skip={variables === null}>
        {({ data, loading, refetch, error }) => (<div>

            <AbasePaperTable
                customPlacehorderSearch={'Pesquisar por nome ou email'}
                sticky={true}
                selectMode={"multi"}
                loading={loading}
                data={_.get(data, "usuario.pagedList.data") || []}
                total={_.get(data, "usuario.pagedList.total")}
                columns={columns}
                customFilters={filters}
                title={formName}
                requestData={setVariables}
                error={errorMessagePaperTable(error)}
                customSelectedActions={selectedActions}
                customActions={customActions}
                selectListener={props.selectListener}
                onDoubleClick={row => {
                    props.history.push(`${match.path}/edit/${row.id}`);
                }}></AbasePaperTable>
            <ReenterRouteListener callback={refetch} />
            <Route path={`${match.path}/add`} render={RenderWithModal(UsuarioForm)} />
            <Route path={`${match.path}/edit/:id`} render={RenderWithModal(UsuarioForm)} />
            <Route path={`${match.path}/delete/:id`} render={RenderWithModal(UsuarioDelete)} />
            <Route path={`${match.path}/resetPassword/:id`} render={RenderWithModal(UsuarioResetPassword)} />

        </div>)}
    </UsuarioListComponent>)}
    </GraphqlPaperTableListener>);
}
