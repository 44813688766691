import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction, AbaseAutoCompleteFieldFinalForm } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { AgendaTipoListComponent, EmpresaAllComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import AgendaTipoDelete from "./AgendaTipoDelete";
import AgendaTipoForm from "./AgendaTipoForm";
import AgendaTipoLabel from './AgendaTipoNameLabel';
export default function AgendaTipoList(props) {
    const { match } = props;
    const columns = [
        {
            name: AgendaTipoLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: AgendaTipoLabel.descricao,
            field: "descricao"
        },
        {
            name: "Empresa",
            field: "emp.nomefantasia",
            sort: false
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" component={Link} to={`${match.path}/delete/${_.map(selected, "id").join(",")}`} />
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.path}/add`} />,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"descricao"}>{({ input, meta }) => <AbaseTextField label={"Descrição"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <EmpresaAllComponent>{({ data, loading, refetch }) => (<Field name="empId">{({ input, meta }) => (<React.Fragment>
            <AbaseAutoCompleteFieldFinalForm isLoading={loading} meta={meta} label={"Empresa"} valueField={"id"} textField={"razaosocial"} data={_.get(data, "empresa.list", [])} {...input} />
        </React.Fragment>)}</Field>)}
        </EmpresaAllComponent>,
    ]);
    const formName = AgendaTipoLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<AgendaTipoListComponent variables={variables} skip={variables === null}>
        {({ data, loading, refetch, error }) => (<div>

            <AbasePaperTable sticky={true} selectMode={"multi"} loading={loading} data={_.get(data, "agendaTipo.pagedList.data") || []} total={_.get(data, "agendaTipo.pagedList.total")} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
                props.history.push(`${match.path}/edit/${row.id}`);
            }}></AbasePaperTable>
            <ReenterRouteListener callback={refetch} />
            <Route path={`${match.path}/add`} render={RenderWithModal(AgendaTipoForm)} />
            <Route path={`${match.path}/edit/:id`} render={RenderWithModal(AgendaTipoForm)} />
            <Route path={`${match.path}/delete/:id`} render={RenderWithModal(AgendaTipoDelete)} />

        </div>)}
    </AgendaTipoListComponent>)}
    </GraphqlPaperTableListener>);
}
