import axios from "./axios";
export async function imprimirTermoAceite(data, modelo) {
    function saveFile(name, bb) {
        let a = document.createElement("a");
        a.style = "display: none";
        var url = window.URL.createObjectURL(bb);
        a.setAttribute("href", url);
        a.setAttribute("download", name);
        const body = document.getElementsByTagName("body")[0];
        body.appendChild(a);
        a.click();
        // window.URL.revokeObjectURL(url);
        // a.remove();
    }
    // { "name": "EMPRESA_NOME", "type": "string" },
    // { "name": "CLIENTE_NOME", "type": "string" },
    // { "name": "SISTEMA_NOME", "type": "string" },
    // { "name": "TECNICO_RESPONSAVEL", "type": "string" },
    const response = await axios.post(`/api/modelotermoaceite/gerarTermoAceite`, {
        dados: data,
        modelo: modelo
    }, {
        responseType: 'blob',
        timeout: 30000
    });
    saveFile("termo_aceite.pdf", response.data);
    //  {
    //     EMPRESA_NOME: selected[0].ord.cli.emp.razaosocial,
    //     CLIENTE_NOME: selected[0].ord.cli.razaosocial,
    //     SISTEMA_NOME: selected[0].tar.pro.descricao,
    //     TECNICO_RESPONSAVEL: selected[0].tar.usu.nome,
    //     HORARIO_GERADO: new Date().toLocaleString()
    // };
    // var Window: any = window;
    // Window.jsreports.export({
    //     report_def: modelo,
    //     datasets: [Object.assign(dataSetJsReportDesigns, { data: [obj] })],
    //     format: 'pdf',
    //     filename: "Termo de Aceite",
    //     outputHandler: function (dados) {
    //         console.log("OPEN DADOS", dados)
    //     }
    // });
    // const insert = useTermoAceiteCreate()
}
