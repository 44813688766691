import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm, AbaseDialogConfirm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field, FormSpy } from "react-final-form";
import { withRouter } from 'react-router';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import moment from 'moment';
import { ProdutoServicoAllComponent, OrdemItemCobrancaQueryByIdComponent, useOrdemItemCobrancaCreate, useOrdemItemCobrancaUpdate, useContratoAll } from '../../generated/graphql';
import { Money } from 'walletjs';
import queryString from 'query-string';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import OrdemItemCobrancaLabel from './OrdemItemCobrancaNameLabel';
import { Grid } from '@material-ui/core';
function MesField(props) {
    const [value, setValue] = React.useState("");
    React.useEffect(() => {
        if ((props.value ? moment(props.value, "YYYYMM").format("YYYYMM") : "") !== (value ? moment(value || "2000-01", "YYYY-MM").format("YYYYMM") : "")) {
            if (props.value) {
                setValue(moment(props.value, "YYYYMM").format("YYYY-MM"));
            }
            else {
                setValue("");
            }
        }
    }, [props.value]);
    return (<AbaseTextField type="month" {...props} value={value} onChange={e => {
        if (e.target.value) {
            const valueFormatted = moment(e.target.value, "YYYY-MM").format("YYYYMM");
            props.onChange(valueFormatted);
        }
        else {
            props.onChange("");
        }
    }} />);
}
const OrdemItemCobrancaSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    proId: Yup.string().required(),
    conId: Yup.string().notRequired().nullable(),
    sequencia: Yup.number().required(),
    anomesCobranca: Yup.number().required(),
    quantidade: Yup.number().nullable(),
    valorUnitario: Yup.number().nullable(),
    valorTotal: Yup.number().required(),
    observacao: Yup.string().notRequired().nullable(),
}).noUnknown();
function OrdemItemCobrancaForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const [confirmDialogValue, setConfirmDialogValue] = React.useState(null);
    let queryStringParams = queryString.parse(props.location.search);
    const update = useOrdemItemCobrancaUpdate({
        refetchQueries: ["ordemItemCobrancaList"]
    });
    const insert = useOrdemItemCobrancaCreate({
        refetchQueries: ["ordemItemCobrancaList"]
    });
    const { data: dataContratos } = useContratoAll({
        variables: {
            cliId: props.cliId
        }
    });
    const handleSubmit1 = values => {
        setConfirmDialogValue(values);
    };
    const handleSubmit2 = async (values) => {
        const mutationData = await cast(OrdemItemCobrancaSchema, values);
        const ordId = props.ordId;
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: { ...mutationData, ordId }
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.ordemItemCobranca.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: { ...mutationData, ordId }
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.ordemItemCobranca.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<OrdemItemCobrancaQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading }) => (<FormDraft useDraft={false} onSubmit={handleSubmit1} validate={validate(OrdemItemCobrancaSchema)} initialValues={_.get(data, "ordemItemCobranca.list[0]") || {
            anomesCobranca: moment().add(1, "month").format("YYYYMM"),
            sequencia: props.sequencia,
            proId: queryStringParams.proId || null,
            conId: _.get(dataContratos, "contrato.list[0].id") || null
        }} decorators={defaultDecorators} subscription={{ submitting: true }} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Item de cobrança" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
            <AbaseLoading open={loading || submitting} />
            <Grid container spacing={1}>
                <FormSpy subscription={{ values: true }}>{({ values }) => (<React.Fragment>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="sequencia">{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={"Sequência"} />)}</Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <ProdutoServicoAllComponent>{({ data, loading }) => (<Field name="proId">{({ input }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={OrdemItemCobrancaLabel.pro} valueField={"id"} getOptionLabel={x => { return `${_.get(x, 'gestorCodigoproduto')} - ${_.get(x, 'descricao')}`; }}
                            // textField={"descricao"} 
                            data={_.get(data, "produtoServico.list", [])} {...input} />)}
                        </Field>)}
                        </ProdutoServicoAllComponent>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                        <Field name="conId">{({ input }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={"Contrato"} valueField={"id"} getOptionLabel={x => {
                            const itens = x.contratoItem.map(x => x.pro.descricao);
                            return x.numeroContrato + " - " + itens.join(", ");
                        }} data={_.get(dataContratos, "contrato.list") || []} {...input} />)}</Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Field name="quantidade">{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={OrdemItemCobrancaLabel.quantidade} onBlur={e => {
                            input.onBlur(e);
                            if (values.quantidade && values.valorUnitario) {
                                form.change("valorTotal", values.quantidade * values.valorUnitario);
                            }
                        }} />)}</Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="valorUnitario" format={(val) => {
                            if (val) {
                                val = Money.init(val).getValue();
                            }
                            return val;
                        }} formatOnBlur={true}>{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={OrdemItemCobrancaLabel.valorUnitario} onBlur={e => {
                            input.onBlur(e);
                            if (values.quantidade && values.valorUnitario) {
                                form.change("valorTotal", values.quantidade * values.valorUnitario);
                            }
                        }} />)}</Field>
                    </Grid>
                </React.Fragment>)}</FormSpy>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Field name="valorTotal" format={(val) => {
                        if (val) {
                            val = Money.init(val).getValue();
                        }
                        return val;
                    }} formatOnBlur={true}>{({ input, meta }) => (<AbaseTextField type="number" meta={meta} fullWidth={true} {...input} label={OrdemItemCobrancaLabel.valorTotal} />)}</Field>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Field name="anomesCobranca">{({ input, meta }) => (<MesField meta={meta} fullWidth={true} {...input} label={"Mês de Cobrança"} />)}
                    </Field>
                </Grid>
                <Grid item xs={12} >
                    <Field name="observacao">{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={"Observação"} />)}</Field>
                </Grid>
            </Grid>
            <AbaseDialogConfirm open={confirmDialogValue} onCancel={() => setConfirmDialogValue(null)} onConfirm={() => handleSubmit2(confirmDialogValue)} message={(<div>Tem certeza que deseja lançar o item de cobrança <b>{(confirmDialogValue && confirmDialogValue.conId ? "COM CONTRATO" : "SEM CONTRATO")}</b></div>)}></AbaseDialogConfirm>
        </DialogForm>)} />)}
    </OrdemItemCobrancaQueryByIdComponent>);
}
export default withRouter(OrdemItemCobrancaForm);
