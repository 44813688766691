import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { RecursoTipoListComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal from '../../RenderWithModal';
import RecursoTipoDelete from './RecursoTipoDelete';
import RecursoTipoForm from './RecursoTipoForm';
import RecursoTipoLabel from './RecursoTipoNameLabel';
import { Chip } from '@material-ui/core';
export default function RecursoTipoList(props) {
    const { match } = props;
    const columns = [
        {
            name: RecursoTipoLabel.id,
            field: 'id',
            defaultVisible: false
        },
        {
            name: RecursoTipoLabel.descricao,
            field: 'descricao'
        },
        {
            name: RecursoTipoLabel.formulario,
            field: 'formulario',
            defaultVisible: false,
            sort: false,
            customBodyRender: (fields) => {
                let fieldsObj = JSON.parse(fields);
                if (fieldsObj)
                    return fieldsObj.map((field) => {
                        return <Chip label={field.label} variant="outlined" className="pullLeft h-auto"/>;
                    });
                else
                    return 'N/A';
            }
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link}/>) : null,
            <DeleteAction key="delete" to={`${match.path}/delete/${_.map(selected, 'id').join(',')}`} component={Link}/>
        ];
    };
    const customActions = [<AddAction key="add" to={`${match.path}/add`} component={Link}/>];
    const filters = () => [
        <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta}/>}</Field>,
        <Field name={'descricao'}>
			{({ input, meta }) => <AbaseTextField label={'Descrição'} {...input} meta={meta}/>}
		</Field>
    ];
    const formName = RecursoTipoLabel.form;
    return (<GraphqlPaperTableListener>
			{({ variables, setVariables }) => (<RecursoTipoListComponent variables={variables} skip={variables === null}>
					{({ data, loading, refetch, error }) => (<div>
							<AbasePaperTable sticky={true} selectMode={'multi'} loading={loading} data={_.get(data, 'recursoTipo.pagedList.data') || []} total={_.get(data, 'recursoTipo.pagedList.total')} columns={columns} customFilters={filters} title={formName} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={selectedActions} customActions={customActions} selectListener={props.selectListener} onDoubleClick={row => {
        props.history.push(`${match.path}/edit/${row.id}`);
    }}/>
							<ReenterRouteListener callback={refetch}/>
							<Route path={`${match.path}/add`} render={RenderWithModal(RecursoTipoForm)}/>
							<Route path={`${match.path}/edit/:id`} render={RenderWithModal(RecursoTipoForm)}/>
							<Route path={`${match.path}/delete/:id`} render={RenderWithModal(RecursoTipoDelete)}/>
						</div>)}
				</RecursoTipoListComponent>)}
		</GraphqlPaperTableListener>);
}
