import { AppBar, Button, Dialog, DialogContent, Grid, IconButton, Step, StepButton, Stepper, Toolbar, Typography, withStyles, StepLabel, Tabs, Tab } from '@material-ui/core';
import { AbaseAutoCompleteFieldFinalForm, AbaseCheckbox, AbaseDateTimePickerField, AbaseLoading, AbaseTextField, clearDraft, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React, { useState } from 'react';
import { Field, FormSpy, Form } from 'react-final-form';
import { Route } from 'react-router';
import { Transition } from 'react-transition-group';
import * as Yup from 'yup';
import { AgendaQueryByIdComponent, useAgendaCreate, useAgendaUpdate, useAgendaTipoAll, useTarefaListaIds, ParceiroAllComponent, useProjetoQueryById } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { cast, validate } from '../../helpers/validations';
import AgendaLabel, { agendaStatusList } from './AgendaNameLabel';
import StepCliente from './StepCliente';
import StepLocal from './StepLocal';
import StepParticipantes from './StepParticipantes';
import StepTarefa from './StepTarefa';
import StepRequisicao from './StepRequisicao';
import StepRecurso from './StepRecurso';
import EmpresaEquipeArea from './EmpresaEquipeArea';
import SaveIcon from '@material-ui/icons/Save';
import { useFiltroGeral } from '../../components/FiltroGeral';
import queryString from 'query-string';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import StepOrdemServico from './StepOrdemServico';
import { imprimirOrdemServico } from '../OrdemServico/OrdemServicoRelatorio';
import moment from 'moment';
import AgendaTimelineView from '../AgendaTimeline/AgendaTimelineView';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import withIsMobile from '../../components/withIsMobile';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
const AgendaSchema = Yup.object()
    .shape({
        id: Yup.string().notRequired(),
        empId: Yup.string().notRequired(),
        areId: Yup.string().notRequired(),
        equId: Yup.string().notRequired(),
        locId: Yup.string().notRequired(),
        agendaTipoId: Yup.string().required().label("Tipo de agenda"),
        titulo: Yup.string().required().max(1000),
        inicio: Yup.date().required(),
        parId: Yup.string().notRequired(),
        fim: Yup.date().notRequired().when('inicio', (inicio) => {
            return inicio && Yup.date().min(inicio);
        }),
        status: Yup.string().required(),
        particular: Yup.boolean().notRequired().nullable(),
        agendaRequisicao: Yup.array(Yup.object()
            .shape({
                id: Yup.string().notRequired(),
                hotId: Yup.string().notRequired().nullable(),
                reqId: Yup.string().notRequired().nullable(),
                req: Yup.object({
                    id: Yup.string().notRequired(),
                    descricao: Yup.string().notRequired(),
                    formulario: Yup.string().notRequired()
                })
                    .notRequired()
                    .nullable()
                    .noUnknown(),
                dadosFormulario: Yup.string().notRequired().nullable(),
                status: Yup.string().required(),
                state: Yup.string().notRequired()
            })
            .noUnknown()).notRequired(),
        agendaRecurso: Yup.array(Yup.object()
            .shape({
                id: Yup.string().notRequired(),
                recId: Yup.string().required(),
                state: Yup.string().notRequired()
            })
            .noUnknown()).notRequired(),
        agendaUsuario: Yup.array(Yup.object()
            .shape({
                id: Yup.string().notRequired(),
                usuId: Yup.string().notRequired()
            })
            .noUnknown()).notRequired(),
        agendaTarefa: Yup.array(Yup.object()
            .shape({
                id: Yup.string().notRequired(),
                tar: Yup.object()
                    .shape({
                        id: Yup.string().notRequired().nullable(),
                        usuId: Yup.string().notRequired().nullable(),
                        tarId: Yup.string().notRequired().nullable(),
                        etapaId: Yup.string().notRequired().nullable(),
                        tar: Yup.object()
                            .shape({
                                id: Yup.string().notRequired().nullable(),
                                descricao: Yup.string().required(),
                                unidadeMedida: Yup.string().required()
                            })
                            .noUnknown()
                            .required(),
                        datatarefa: Yup.date().notRequired().nullable(),
                        descricao: Yup.string().notRequired().nullable(),
                        status: Yup.string().required(),
                        datainicio: Yup.date().notRequired().nullable(),
                        dataconclusao: Yup.date().notRequired().nullable(),
                        datalimite: Yup.date().notRequired().nullable(),
                        porcentagem: Yup.number().notRequired().nullable(),
                        quantidadeTempo: Yup.string().notRequired().nullable(),
                        state: Yup.string().notRequired().nullable(),
                        usuarioExecutouId: Yup.string().notRequired().nullable(),
                        inicio: Yup.date().notRequired().nullable(),
                        fim: Yup.date().notRequired().nullable(),
                        tempoIntervalo: Yup.string().notRequired().nullable(),
                        descritivo: Yup.string().notRequired().nullable(),
                        proId: Yup.string().notRequired().nullable(),
                        urgencia: Yup.bool().notRequired().nullable(),
                        previsaoInicio: Yup.date().notRequired().nullable(),
                        tarefaHistorico: Yup.array(Yup.object().shape({
                            id: Yup.string().notRequired().nullable(),
                            usuId: Yup.string().required().label("Usuário que executou"),
                            state: Yup.string().notRequired().nullable(),
                            inicio: Yup.date().required(),
                            data: Yup.date().notRequired().nullable(),
                            porcentagem: Yup.number().required().nullable(),
                            status: Yup.string().required().nullable(),
                            fim: Yup.date().required(),
                            tempoIntervalo: Yup.string().notRequired().nullable(),
                            tempoDeslocamento: Yup.string().notRequired().nullable(),
                            tempoOcioso: Yup.string().notRequired().nullable(),
                            descritivo: Yup.string().required(),
                        }).noUnknown()).nullable(),
                    })
                    .noUnknown()
                    .required()
            })
            .noUnknown()).notRequired(),
        cliId: Yup.string().nullable(),
        conId: Yup.string().nullable(),
        contaContatId: Yup.string().nullable(),
        clienteContatoUsuId: Yup.string().nullable(),
        gerarOrdemServico: Yup.bool(),
        ordId: Yup.string().nullable(),
        ord: Yup.object().when("gerarOrdemServico", {
            is: true,
            then: Yup.object()
                .shape({
                    distancia: Yup.number().notRequired().nullable(),
                    data: Yup.date().required().label("Data da ordem de serviço"),
                    tipo: Yup.string().required().label("Tipo da ordem de serviço"),
                    status: Yup.string().required().label("Status da ordem de serviço"),
                    observacao: Yup.string().notRequired().nullable(),
                    possuiCobranca: Yup.bool().notRequired().nullable(),
                })
                .noUnknown()
        }),
        lembrete: Yup.boolean().nullable(),
        lembreteTempo: Yup.string().nullable().default('00:15:00'),
    })
    .noUnknown();
function TabStep({ label, error, completed, ...otherProps }) {
    const style = error ? { color: "#f44336" } : {};
    const icon = completed ? <CheckIcon style={style} /> : (error ? <WarningIcon style={style} /> : <CancelIcon style={style} />);
    return <Tab label={label} {...otherProps} icon={icon} />;
}
function AgendaForm(props) {
    const focusRef = React.useRef(null);
    const tituloRef = React.useRef(null);
    const empresaRef = React.useRef(null);
    const fimRef = React.useRef(null);
    const { match, history, location, isMobile } = props;
    const classes = props.classes;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useAgendaUpdate({ refetchQueries: ["projetoAndamento"] });
    const insert = useAgendaCreate({ refetchQueries: ["projetoAndamento"] });
    const [initialDate] = useState(new Date().toISOString());
    const [activeStepWrapped, setActiveStep] = useState(-1);
    const [currentTipo, setCurrentTipo] = useState(null);
    const [skippedSteps] = useState([]);
    const { empresa, equipe, area } = useFiltroGeral();
    const [empId, setEmpId] = useState(_.get(empresa, "id"));
    const [printed, setPrinted] = useState(false);
    const [showInfo, setShowInfo] = useState(isMobile ? true : false);
    const { data: agendaTipoData, loading: agendaTipoLoading } = useAgendaTipoAll({
        variables: {
            empId: empId || null
        },
        skip: !empId
    });
    const steps = [
        {
            label: 'CLIENTE',
            optional: false
        },
        {
            label: 'PARTICIPANTES',
            optional: false
        },
        {
            label: 'LOCAL',
            optional: false
        },
        {
            label: 'REQUISIÇÕES',
            optional: false
        },
        {
            label: 'RECURSOS',
            optional: false
        },
        {
            label: 'ITENS',
            optional: false
        },
        {
            label: 'ORDEMS DE SERVIÇO',
            optional: false
        }
    ];
    const [completedSteps, setCompletedSteps] = useState(steps.map(() => false));
    const [touchedSteps, setTouchedSteps] = useState(steps.map(() => false));
    const isStepSkipped = (index) => skippedSteps.indexOf(index) !== -1;
    const updateStepCompleted = _.debounce((values) => {
        const newStepsCompletedValue = steps.map((x, i) => isStepCompleted(values, i));
        if (!_.isEqual(newStepsCompletedValue, completedSteps))
            setCompletedSteps(newStepsCompletedValue);
    }, 500);
    const isStepCompleted = (values, index) => {
        switch (index) {
            case 0:
                return !!values.contaCliente;
            case 1:
                return values.agendaUsuario && values.agendaUsuario.length > 0;
            case 2:
                return values.locId;
            case 3:
                return values.agendaRequisicao && values.agendaRequisicao.length > 0;
            case 4:
                return values.agendaRecurso && values.agendaRecurso.length > 0;
            case 5:
                return values.agendaTarefa && values.agendaTarefa.length > 0;
            case 6:
                return values.gerarOrdemServico;
            default:
                return false;
        }
    };
    const getStepsWithMeta = (currentTipo) => {
        const filteredAgendaTipo = _.get(agendaTipoData, "agendaTipo.list", []).filter(x => x.id === currentTipo);
        const firstTipo = _.first(filteredAgendaTipo);
        const data = _.get(firstTipo, "tabs");
        return data ? _.sortBy(JSON.parse(data), "order").filter(x => x.visible).map(x => ({ ...x, completed: completedSteps[x.id], label: steps[x.id].label })) : [];
        // return steps.map((x, index) => {
        // 	let meta = { visible: true, required: false }
        // 	if (currentTipo) {
        // 		const tabs = agendaTipos[currentTipo].tabs
        // 		if (tabs && tabs[index]) {
        // 			meta = tabs[index]
        // 		}
        // 	}
        // 	return { ...x, index, meta, completed: completedSteps[index] }
        // })
    };
    const navigateNextKeyDown = (event) => {
        if (event.keyCode === 9 && !event.shiftKey) {
            if (nextId) {
                event.stopPropagation();
                nextStep();
                return false;
            }
        }
    };
    const navigatePrevKeyDown = (event) => {
        if (event.keyCode === 9 && event.shiftKey) {
            if (prevId) {
                event.stopPropagation();
                previousStep();
                return false;
            }
        }
    };
    const getContentForStep = () => {
        if (showInfo) {
            return null;
        }
        const customProps = { navigatePrevKeyDown, navigateNextKeyDown, focusRef };
        switch (activeStep) {
            case 0:
                return <StepCliente {...customProps} />;
            case 1:
                return <StepParticipantes {...customProps} />;
            case 2:
                return <StepLocal {...customProps} />;
            case 3:
                return <StepRequisicao {...customProps} />;
            case 4:
                return <StepRecurso {...customProps} />;
            case 5:
                return <StepTarefa {...customProps} />;
            case 6:
                return <StepOrdemServico />;
            default:
                return null;
        }
    };
    const stepsWithMeta = getStepsWithMeta(currentTipo);
    const activeStep = activeStepWrapped === -1 && stepsWithMeta.length > 0 ? stepsWithMeta[0].id : activeStepWrapped;
    const currentIndex = _.findIndex(stepsWithMeta, x => x.id === activeStep);
    const nextId = currentIndex === -1 || stepsWithMeta.length <= currentIndex + 1 ? false : stepsWithMeta[currentIndex + 1].id;
    const prevId = currentIndex <= 0 ? false : stepsWithMeta[currentIndex - 1].id;
    const nextStep = () => {
        if (isMobile && showInfo) {
            setShowInfo(false);
        }
        else {
            if (typeof nextId === "number") {
                setTouchedSteps(touchedSteps.map((x, index) => x || index === activeStep));
                setActiveStep(nextId);
            }
        }
    };
    const goToStep = (step) => {
        setTouchedSteps(touchedSteps.map((x, index) => x || index === activeStep));
        setActiveStep(step);
        setShowInfo(false);
        setTimeout(() => {
            if (focusRef.current && focusRef.current.focus) {
                focusRef.current.focus();
            }
        }, 100);
    };
    const previousStep = () => {
        if (typeof prevId === "number") {
            setActiveStep(prevId);
        }
        else if (isMobile && !showInfo) {
            setShowInfo(true);
        }
    };
    const handleSubmit = async (values) => {
        // debugger
        console.clear()
        console.log(values)
        if (!values.empId) {
            return showError({
                message: "Empresa é obrigatório"
            });
        }
        if (!values.equId && !values.particular) {
            return showError({
                message: "Equipe é obrigatório"
            });
        }
        if (!values.areId && !values.particular) {
            return showError({
                message: "Área é obrigatório"
            });
        }
        const incompletedSteps = stepsWithMeta.filter(x => x.required && x.visible && !x.completed);
        if (incompletedSteps.length > 0) {
            showError({
                message: "Preencha os dados da etapa " + incompletedSteps[0].label
            });
            setActiveStep(incompletedSteps[0].id);
            return;
        }
        const mutationData = await cast(AgendaSchema, values);
        if (values.contaCliente) {
            if (values.contaCliente.__typename === "ClienteType") {
                mutationData.clienteContatoUsuId = values.clienteContatoUsuId || null;
                mutationData.cliId = values.contaCliente.id;
                mutationData.contaContatId = null;
                mutationData.conId = null;
            }
            else {
                mutationData.contaContatId = values.contaContatId;
                mutationData.conId = values.contaCliente.id;
                mutationData.clienteContatoUsuId = null;
                mutationData.cliId = null;
            }
        }
        try {
            console.log('try, mutation', mutationData)
            let id = null;
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    },
                    refetchQueries: ["agendaQueryById"]
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.agenda.update.id'));
                }
                id = _.get(result, 'data.agenda.update.id');
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    },
                    refetchQueries: ["agendaQueryById"]
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.agenda.create.id'));
                }
                id = _.get(result, 'data.agenda.create.id');
                clearDraft();
            }
            if (values.action === "continue") {
                history.push("/agenda/agenda/edit/" + id);
            }
            else if (values.action === "print") {
                setPrinted(false);
                history.push("/agenda/agenda/edit/" + id + "?print=true");
            }
            else {
                urlOrigem ? history.push(urlOrigem) : redirectBackForm(history, location);
            }
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
        return null
    };
    const updateCurrentTipo = values => {
        if (values.agendaTipoId !== currentTipo) {
            setCurrentTipo(values.agendaTipoId);
            const stepsWithMeta = getStepsWithMeta(values.agendaTipoId);
            //if current step is not visible
            const currentStep = _.first(stepsWithMeta.filter(x => x.id === activeStep));
            if (activeStep < 0 || (currentStep && !currentStep.visible)) {
                const firstVisible = _.first(stepsWithMeta.filter(x => x.visible));
                setActiveStep(firstVisible ? firstVisible.id : -1);
            }
        }
    };
    const notEditting = !params || !params.id;
    const isEditting = !notEditting;
    const [urlOrigem, setUrlOrigem] = React.useState("");
    const [starter, setStarter] = React.useState({
        status: 'CONFIRMADO',
        fim: new Date(initialDate).addHours(1).toISOString(),
        inicio: initialDate,
        empId: _.get(empresa, 'id'),
        areId: _.get(area, 'id'),
        equId: _.get(equipe, 'id'),
        gerarOrdemServico: false,
        lembrete: true,
        lembreteTempo: "00:15:00"
    });
    let queryStringParams = queryString.parse(props.location.search);
    const tarefaIds = queryStringParams.tarefaIds;
    const projetoId = queryStringParams.projetoId;
    let projeto = {};
    if (projetoId) {
        projeto = useProjetoQueryById({ variables: { id: projetoId } });
        projeto = _.get(projeto.data, 'projeto.list[0]');
    }
    const tarefaListaIds = useTarefaListaIds({
        skip: !tarefaIds,
        variables: { ids: tarefaIds ? tarefaIds.split(",") : null }
    });
    React.useEffect(() => {
        const urlOrigemQS = queryStringParams.urlOrigem;
        if (urlOrigemQS && urlOrigem !== urlOrigemQS) {
            setUrlOrigem(urlOrigemQS);
        }
    });
    //Quando abre a tela passando a tarefa Id set o starter value
    React.useEffect(() => {
        if (!_.get(tarefaListaIds.data, 'tarefa.list')) {
            return;
        }
        const data = tarefaListaIds.data;
        let newStarter = { ...starter };
        let tarefas = _.get(data, 'tarefa.list').map(x => ({ ...x, state: "join_projeto" }));
        let empresa = _.get(tarefas, '[0].etapa.pro.empId');
        newStarter.contaCliente = _.get(tarefas, '[0].etapa.pro.cli');
        newStarter.clienteContatoUsuId = _.get(tarefas, '[0].etapa.pro.responsavelUsuId');
        newStarter.agendaTarefa = [];
        newStarter.empId = empresa;
        if (projetoId && projeto.parId)
            newStarter.parId = projeto.parId;
        // newStarter.fim = dataFinal;
        newStarter.equId = _.get(tarefas, '[0].etapa.pro.equId');
        newStarter.areId = _.get(tarefas, '[0].etapa.pro.areId');
        newStarter.titulo = _.get(tarefas, '[0].descricao', "") + " ".substring(0, 1000);
        newStarter.agendaTipoId = 'f55ba268-e847-492e-aa23-5fdb96ccb4e3';
        // eslint-disable-next-line array-callback-return
        tarefas.map(x => {
            newStarter.agendaTarefa.push({ tar: x });
        });
        if (newStarter.agendaTarefa !== starter.agendaTarefa) {
            setStarter(newStarter);
        }
    }, [_.get(tarefaListaIds.data, 'tarefa.list[0].etapa.pro')]);
    const handleClose = () => urlOrigem ? history.push(urlOrigem) : redirectBackForm(history, location);
    const viewHistory = () => history.push(`${match.url}/historico`);
    React.useEffect(() => {
        setTimeout(() => {
            if (notEditting) {
                if (empresaRef.current)
                    empresaRef.current.focus();
            }
            else {
                console.log(tituloRef);
                if (tituloRef.current)
                    tituloRef.current.focus();
            }
        }, 100);
    }, []);
    console.log('hahha11111111111')
    return (<React.Fragment>
        <AgendaQueryByIdComponent variables={params} errorPolicy={'none'} skip={notEditting} onError={(err) => {
            showError({ message: defaultErrorMessage('query_one', err) });
            redirectBackForm(history, location);
        }} onCompleted={value => {
            if (value && !printed && props.location.search && props.location.search.indexOf("print") !== -1) {
                const orderId = _.get(value, "agenda.list[0].ordId");
                if (orderId) {
                    setPrinted(true);
                    imprimirOrdemServico([orderId]);
                }
            }
        }}>
            {({ data, loading }) => {
                if (data && _.get(data, 'agenda.list[0]'))
                    _.set(data, 'agenda.list[0].cliId', _.get(data, 'agenda.list[0].conId'));
                return (<Form onSubmit={handleSubmit} subscription={{
                    submitting: true
                }} mutators={{
                    setInicio: (args, state, utils) => {
                        utils.changeValue(state, 'inicio', () => args[0]);
                    },
                    setFim: (args, state, utils) => {
                        utils.changeValue(state, 'fim', () => args[0]);
                    }
                }} validate={validate(AgendaSchema)} initialValues={_.get(data, 'agenda.list[0]') || starter} decorators={defaultDecorators} render={({ handleSubmit: handleSubmitCallback, form, submitting }) => {
                    const handleSubmit = () => {
                        setTouchedSteps(touchedSteps.map(() => true));
                        handleSubmitCallback();
                    };
                    return (<Dialog fullScreen open={true} onClose={handleClose} TransitionComponent={Transition}>
                        <FormSpy subscription={{ initialValues: true }}>
                            {({ mutators }) => {
                                let queryStringParams = queryString.parse(props.location.search);
                                if (queryStringParams.start) {
                                    mutators.setInicio(queryStringParams.start);
                                }
                                if (queryStringParams.end) {
                                    mutators.setFim(queryStringParams.end);
                                }
                                return null;
                            }}
                        </FormSpy>
                        <FormSpy subscription={{ initialValues: true, values: true }}>
                            {({ values }) => {
                                updateStepCompleted(values);
                                updateCurrentTipo(values);
                                if (empId !== values.empId) {
                                    setEmpId(values.empId);
                                }
                                return null;
                            }}
                        </FormSpy>
                        {isMobile ? (<AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                                    <i className="material-icons" title="Fechar">
                                        close
														</i>
                                </IconButton>
                                <Typography variant="h6" color="inherit" className={classes.flex}>
                                    Agenda
													</Typography>
                                <Button type="button" variant="contained" className="btnSuccess ml5" key="submit" onClick={() => {
                                    form.change("action", "none");
                                    handleSubmit();
                                }}>
                                    Salvar
												<SaveIcon>send</SaveIcon>
                                </Button>
                            </Toolbar>
                        </AppBar>) : (<AppBar className={classes.appBar}>
                            <Toolbar className={classes.toolbar}>
                                <Typography variant="h6" color="inherit" className={classes.flex}>
                                    Manutenção Agenda
								</Typography>
                            </Toolbar>
                        </AppBar>)}
                        <AbaseLoading open={loading || submitting} />
                        <DialogContent className="mt15">
                            {!isMobile || showInfo ? (<Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <EmpresaEquipeArea empresaRef={empresaRef} disableEmpresa={isEditting} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={3}>
                                    <Field name="agendaTipoId">
                                        {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm
                                            label={"Tipo de Agenda"}
                                            meta={meta}
                                            valueField={'id'}
                                            textField={'descricao'}
                                            loading={agendaTipoLoading}
                                            data={_.get(agendaTipoData, "agendaTipo.list", [])}
                                            {...input}
                                            onChange={x => {
                                                let tiposAgendas = _.get(agendaTipoData, "agendaTipo.list", [])
                                                let abas = JSON.parse(tiposAgendas.filter(a => { return a.id === x })[0].tabs);
                                                abas = abas.filter(aba => { return aba.visible === true })
                                                setActiveStep(_.get(abas, '[0].id', 0))
                                                input.onChange(x)
                                            }} />)}
                                    </Field>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                    <Field name="status">
                                        {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm label={AgendaLabel.status} meta={meta} valueField={'id'} textField={'text'} data={agendaStatusList} {...input} />)}
                                    </Field>
                                </Grid>
                                <Field name="agendaRecurso">{({ input: { value: valueAgendaRecurso } }) => {
                                    const possuiRecursos = valueAgendaRecurso && valueAgendaRecurso.filter(x => x.state !== "delete").length > 0;
                                    return (<React.Fragment>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <div className="filForms">
                                                <Field name="fim">{({ input: { value: valueFim } }) => (
                                                    <Field name="inicio">
                                                        {({ input, meta }) => (
                                                            <KeyboardDateTimePicker
                                                                disabled={possuiRecursos}
                                                                ampm={false}
                                                                meta={meta}
                                                                {...input}
                                                                invalidDateMessage="Data Inválida"
                                                                id="startDateFilter"
                                                                variant="inline"
                                                                format="dd/MM/yyyy HH:mm:ss"
                                                                label="Inicio"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={x => {
                                                                    let data = moment(x, 'dd/MM/yyyy HH:mm:ss')
                                                                    let dataFim = moment(x, 'dd/MM/yyyy HH:mm:ss');
                                                                    dataFim = dataFim.add(4, 'h')// um turno
                                                                    form.change("fim", dataFim.toISOString());
                                                                    input.onChange(data.toISOString())
                                                                }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        )}
                                                    </Field>)}
                                                </Field>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <div className="filForms">
                                                <Field name="fim" validate={() => {
                                                    if (fimRef.current && fimRef.current.validity && fimRef.current.validity.badInput) {
                                                        return "Valor inválido";
                                                    }
                                                    return "";
                                                }} formatOnBlur>
                                                    {({ input, meta }) => (
                                                        <KeyboardDateTimePicker
                                                            disabled={possuiRecursos}
                                                            ampm={false}
                                                            autoOk={true}
                                                            meta={meta}
                                                            {...input}
                                                            invalidDateMessage="Data Inválida"
                                                            id="endDate"
                                                            variant="inline"
                                                            format="dd/MM/yyyy HH:mm:ss"
                                                            label="Fim"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={x => {
                                                                input.onChange(x)
                                                            }}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </Grid>
                                    </React.Fragment>);
                                }}</Field>
                                {projetoId ? null :
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <Field name="parId">
                                            {({ input, meta }) => {
                                                return (<ParceiroAllComponent>{({ data, loading }) => {
                                                    return !loading ? <AbaseAutoCompleteFieldFinalForm label={"Parceiro"} meta={meta} valueField={'id'} textField={'nome'} data={_.get(data, 'parceiro.list', [])} {...input} /> : null;
                                                }}</ParceiroAllComponent>);
                                            }}
                                        </Field>
                                    </Grid>}
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={7} lg={7}>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Field name="particular">{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={AgendaLabel.particular} />)}</Field>
                                                </Grid>
                                                <Grid item>
                                                    <Field name="lembrete">{({ input, meta }) => (<AbaseCheckbox meta={meta} {...input} label={"Lembrar envolvidos"} />)}</Field>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5} lg={5}>
                                            <Field name="lembrete">{({ input: inpuptLembrete }) => (!inpuptLembrete.value ? null :
                                                <Field name="lembreteTempo">
                                                    {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm label={"Tempo de lembrete"} meta={meta} valueField={'id'} textField={'descricao'} loading={agendaTipoLoading} data={[
                                                        {
                                                            id: "00:15:00",
                                                            descricao: "15 minutos antes"
                                                        },
                                                        {
                                                            id: "00:30:00",
                                                            descricao: "30 minutos antes"
                                                        },
                                                        {
                                                            id: "01:00:00",
                                                            descricao: "1 hora antes"
                                                        },
                                                        {
                                                            id: "06:00:00",
                                                            descricao: "6 horas antes"
                                                        },
                                                        {
                                                            id: "12:00:00",
                                                            descricao: "12 horas antes"
                                                        },
                                                        {
                                                            id: "1.00:00:00",
                                                            descricao: "1 dia antes"
                                                        },
                                                        {
                                                            id: "2.00:00:00",
                                                            descricao: "2 dias antes"
                                                        },
                                                    ]} {...input} />)}
                                                </Field>)}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field name="titulo" parse={defaultFieldParser}>
                                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} multiline rows={3} {...input} inputRef={tituloRef} label={AgendaLabel.titulo} />)}
                                    </Field>
                                </Grid>
                            </Grid>)
                                : null}
                            {!isMobile ? (<Stepper nonLinear alternativeLabel activeStep={currentIndex} className={classes.stepper}>
                                {stepsWithMeta.map(({ label, required, id: index, visible, completed }) => {
                                    if (!visible) {
                                        return null;
                                    }
                                    const props = {};
                                    const buttonProps = {};
                                    if (isStepSkipped(index)) {
                                        props.completed = false;
                                    }
                                    return (<Step key={label} {...props}>
                                        <StepButton tabIndex={-1} onClick={() => goToStep(index)} completed={completed} {...buttonProps} style={{ padding: '5px', margin: '0px -8px 0px -5px' }}>
                                            <StepLabel error={required && !completedSteps[index] && touchedSteps[index]}>{label}</StepLabel>
                                        </StepButton>
                                        )}
                                    </Step>);
                                })}
                            </Stepper>) : (<AppBar position="fixed" color={"default"} className={classes.appBarBottom}>
                                <Tabs value={showInfo ? 0 : activeStep + 1} onChange={(e, index) => {
                                    if (index === 0) {
                                        setShowInfo(true);
                                        setActiveStep(-1);
                                    }
                                    else {
                                        goToStep(index - 1);
                                    }
                                }} variant="scrollable" scrollButtons="on" indicatorColor="primary" textColor="primary">
                                    <TabStep label="Agenda" completed tabIndex={-1} />
                                    {stepsWithMeta.map(({ label, required, id: index, visible }) => {
                                        if (!visible) {
                                            return null;
                                        }
                                        return (<TabStep tabIndex={-1} key={index} label={label} completed={completedSteps[index]} error={required && !completedSteps[index] && touchedSteps[index]} />);
                                    })}
                                </Tabs>
                            </AppBar>)}
                            {isMobile ? (getContentForStep()) : (stepsWithMeta.length > 0 ?
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div className={classes.grids}>{getContentForStep()}</div>
                                    </Grid>
                                </Grid>
                                : null)}
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className="mt15">
                                        {typeof prevId === "number" || (isMobile && !showInfo) ? (<Button tabIndex={-1} variant="contained" className={classes.floatLeft} onClick={previousStep}>
                                            <i className={'material-icons'}>keyboard_arrow_left</i> Voltar
                                        </Button>) : null}
                                        {typeof nextId === "number" ? (<Button variant="contained" color="primary" className={classes.floatRight} onClick={nextStep} tabIndex={-1}>
                                            Avançar <i className={'material-icons'}>keyboard_arrow_right</i>
                                        </Button>) : null}
                                    </div>
                                </Grid>
                                {isMobile ? null : (<Grid item xs={12} className="center mt15">
                                    {/* {params.id ? (<Button variant="contained" className="btnInf" tabIndex={-1} onClick={viewHistory}>
                                        <i className="material-icons">history</i> Histórico
                                    </Button>) : null} */}
                                    <Button variant="contained" className={"ml5"} tabIndex={-1} onClick={handleClose}>
                                        <i className="material-icons">close</i> Cancelar
														</Button>
                                    <Button id="saveAndContinueBtn" color="secondary" variant="contained" className="ml5" onClick={() => {
                                        form.change("action", "continue");
                                        handleSubmit();
                                    }}>
                                        <SaveIcon>send</SaveIcon> Salvar e Continuar
									</Button>
                                    <Button variant="contained" className="btnSuccess ml5" onClick={() => {
                                        form.change("action", "none");
                                        handleSubmit();
                                    }}><SaveIcon>send</SaveIcon>Salvar
                                    </Button>
                                </Grid>)}
                            </Grid>
                        </DialogContent>
                    </Dialog>);
                }} />);
            }}
        </AgendaQueryByIdComponent>

        <Route path={`${match.url}/historico`} render={(props) => <AgendaTimelineView {...props} agendaId={params.id} />} />

    </React.Fragment>);
}
const styles = {
    appBar: {
        position: 'relative'
    },
    appBarBottom: {
        bottom: 0,
        top: 'auto',
    },
    flex: {
        flex: 1
    },
    abaseDateTimePickerField: {
        width: '100%'
    },
    toolbar: {
        color: '#404040',
        backgroundColor: '#e1e8ed'
    },
    abaseCheckbox: {
        marginTop: '15px'
    },
    grids: {
        width: '100%',
        padding: '15px',
        marginTop: '15px',
        display: 'inline-block',
        backgroundColor: '#f8f8f8',
        border: '1px solid #e0e0e0',
        '& label': {
            backgroundColor: '#f8f8f8',
        },
        '& input': {
            backgroundColor: '#f8f8f8',
        },
        '& textarea': {
            backgroundColor: '#f8f8f8',
        },
    },
    stepper: {
        padding: 0,
        marginTop: '20px',
        '& span': {
            fontWeight: 'bold',
        },
    },
    floatLeft: {
        float: 'left',
        padding: '5px 16px 5px 5px'
    },
    floatRight: {
        float: 'right',
        padding: '5px 5px 5px 16px'
    },
    appBarIndicator: {
        backgroundColor: "#fff"
    },
    appBarSelected: {
        color: "#fff"
    },
    titulo: {
        width: '100%',
        marginTop: 15,
        padding: "6px 7px",
        '& input': {
            width: '100%',
            height: '28px',
            fontSize: '13px',
            padding: '5px 10px',
            boxSizing: 'border-box',
            backgroundColor: 'white',
        },
        '& textarea': {
            fontSize: '13px',
            boxSizing: 'border-box',
            backgroundColor: 'white',
        }
    }
};
export default withIsMobile(withStyles(styles)(AgendaForm));
