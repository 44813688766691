import React from 'react';
import { Grid, Tooltip, IconButton } from '@material-ui/core';
import { AbasePaperTable } from 'abase-components';
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter } from 'react-router-dom';
import DynamicFormEditorFields from './DynamicFormEditorFields';
import AddIcon from '@material-ui/icons/Add';
class DynamicFormEditor extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            openFormAddFields: false
        };
        this.handleChange = (evt) => {
            this.setState({ [evt.target.name]: evt.target.value });
        };
        this.handleChangeTipo = (valor) => {
            this.setState({ typeAtual: valor.name });
        };
        this.columns = [
            {
                name: 'Id',
                field: 'id',
                defaultVisible: true
            },
            {
                name: 'Label',
                field: 'label'
            },
            {
                name: 'Tipo',
                field: 'type'
            }
        ];
        this.selectedActions = (selected) => {
            return [
                <Tooltip title="Excluir" disableFocusListener onClick={() => {
                    let values = this.props.value ? JSON.parse(this.props.value) : [];
                    values = values.filter((x) => x.id !== selected[0].id);
                    this.props.onChange(JSON.stringify(values));
                }}>
				<IconButton aria-label="Excluir">
					<DeleteIcon />
				</IconButton>
			</Tooltip>
            ];
        };
    }
    onSubmit(newValue, update) {
        if (!update) {
            let values = this.props.value ? JSON.parse(this.props.value) : [];
            values.push(newValue);
            this.props.onChange(JSON.stringify(values));
        }
    }
    render() {
        let values = this.props.value ? JSON.parse(this.props.value) : [];
        return (<React.Fragment>
				{this.state.openFormAddFields ? (<DynamicFormEditorFields onChange={this.onSubmit.bind(this)} onClose={() => {
            this.setState({ openFormAddFields: false });
        }}/>) : (<div />)}
				<form>
					<Grid container spacing={16}>
						<Grid item xs={12}>
							<AbasePaperTable sticky={true} selectMode={'single'} loading={false} data={values && values.filter((x) => x.state !== 'delete')} total={0} columns={this.columns} title={''} requestData={() => Promise.resolve([])} customSelectedActions={this.selectedActions} customActions={[
            <Tooltip title="Adicionar" disableFocusListener onClick={() => this.setState({ openFormAddFields: true })}>
										<IconButton aria-label="Adicionar">
											<AddIcon />
										</IconButton>
									</Tooltip>
        ]} disableFilter={true}/>
						</Grid>
					</Grid>
				</form>
			</React.Fragment>);
    }
}
export default withRouter(DynamicFormEditor);
