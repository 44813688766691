import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseDatePickerField, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { withRouter } from 'react-router';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { ContasQueryByIdComponent, useContasCreate, useContasUpdate, EmpresaAllComponent } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast, validateCnpj, validateCpf } from '../../helpers/validations';
import ContasLabel from './ContasNameLabel';
import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { MaskCep, MaskTelefone, MaskCpfCnpj } from '../../helpers/mask';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { cidadeDeCep } from '../../helpers/geography';
const ContasSchema = Yup.object()
    .shape({
        id: Yup.string().notRequired(),
        empId: Yup.string().required().label("Empresa"),
        nomerazaosocial: Yup.string().required('Nome ou Razão Social é obrigatório'),
        nomefantasia: Yup.string().notRequired(),
        cpfcnpj: Yup.string().when("tipopessoa", (tipopessoa) => {
            if (!tipopessoa) {
                return Yup.string().required();
            }
            if (tipopessoa === "FISICA") {
                return Yup.string().test('cpf', 'CPF  inválido', value => !value || validateCpf(value)).required();
            }
            return Yup.string().test('CNPJ', 'CNPJ inválido', value => !value || validateCnpj(value)).required();
        }),
        tipopessoa: Yup.string().notRequired(),
        telefone: Yup.string().notRequired(),
        email: Yup.string().email().notRequired(),
        inscricaoestadual: Yup.string().notRequired(),
        datanascimentofundacao: Yup.date().notRequired(),
        observacao: Yup.string().notRequired(),
        cep: Yup.string().trim().notRequired(),
        logradouro: Yup.string().notRequired(),
        bairro: Yup.string().notRequired(),
        cidade: Yup.string().notRequired(),
        uf: Yup.string().notRequired(),
        complemento: Yup.string().notRequired(),
        numero: Yup.string().notRequired(),
        ativo: Yup.boolean().notRequired(),
        cliente: Yup.boolean().notRequired()
    })
    .noUnknown();
function ContasForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useContasUpdate();
    const insert = useContasCreate();
    const handleSubmit = async (values) => {
        values.cpfcnpj = values.cpfcnpj ? values.cpfcnpj.replace(/[^\d]/g, '') : null;
        values.telefone = values.telefone ? values.telefone.replace(/[^\d]/g, '') : null;
        values.cep = values.cep ? values.cep.replace(/[^\d]/g, '') : null;
        const mutationData = await cast(ContasSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.contas.update'));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.contas.create'));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<ContasQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading, refetch, error }) => {
            return (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(ContasSchema)} initialValues={_.get(data, 'contas.list[0]') || {
                uf: 'RS',
                tipopessoa: "JURIDICA"
            }} subscription={{
                submitting: true
            }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Contas" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                <Grid container spacing={1}>
                    <AbaseLoading open={loading || submitting} />
                    <Grid item xs={12}>
                        <EmpresaAllComponent>
                            {({ data, loading }) => (<Field name={'empId'}>
                                {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={'Empresa'} valueField={'id'} textField={'razaosocial'} data={_.get(data, 'empresa.list')} {...input} />)}
                            </Field>)}
                        </EmpresaAllComponent>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="tipopessoa">
                            {({ input, meta }) => {
                                return (<FormControl className="radioButton">
                                    <FormLabel component="label" className="ft-form">Tipo de Pessoa</FormLabel>
                                    <RadioGroup aria-label="Tipo de Pessoa" name="Tipo de Pessoa" {...input}>
                                        <FormControlLabel value="FISICA" control={<Radio />} label="Física" />
                                        <FormControlLabel value="JURIDICA" control={<Radio />} label="Jurídica" />
                                    </RadioGroup>
                                </FormControl>);
                            }}
                        </Field>
                    </Grid>
                    <FormSpy subscription={{ values: true }}>{({ values }) => (
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Field name="cpfcnpj">
                                    {({ input, meta }) => {
                                        return (<AbaseTextField meta={meta} fullWidth={true} {...input} label={values.tipopessoa === "FISICA" ? "CPF" : "CNPJ"} InputProps={{
                                            inputComponent: MaskCpfCnpj
                                        }} />);
                                    }}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <Field name="nomerazaosocial" parse={defaultFieldParser}>
                                    {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={values.tipopessoa === "FISICA" ? "Nome" : "Razão Social"} />)}
                                </Field>
                            </Grid>
                            <Grid item xs={values.tipopessoa === "FISICA" ? 12 : 12} sm={values.tipopessoa === "FISICA" ? 12 : 12} md={values.tipopessoa === "FISICA" ? 12 : 6} lg={values.tipopessoa === "FISICA" ? 12 : 6}>
                                <Field name="nomefantasia" parse={defaultFieldParser}>
                                    {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={values.tipopessoa === "FISICA" ? "Apelido" : "Nome Fantasia"} />)}
                                </Field>
                            </Grid>
                            {values.tipopessoa === "FISICA" ? null : (<Grid item xs={12} sm={12} md={6} lg={6}>
                                <Field name="inscricaoestadual">
                                    {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.inscricaoestadual} />)}
                                </Field>
                            </Grid>)}
                        </React.Fragment>)}
                    </FormSpy>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="email" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.email} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Field name="telefone">
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.telefone} InputProps={{
                                inputComponent: MaskTelefone
                            }} />)}
                        </Field>
                    </Grid>
                    <FormSpy subscription={{ values: true }}>{({ values }) => (
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Field name="datanascimentofundacao">
                                    {({ input, meta }) => (<AbaseDatePickerField meta={meta} {...input} label={values.tipopessoa === "FISICA" ? "Data de Nascimento" : "Data de Fundação"} />)}
                                </Field>
                            </Grid>
                        </React.Fragment>)}
                    </FormSpy>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <Field name="cep">
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.cep} InputProps={{
                                inputComponent: MaskCep
                            }} onBlur={async (e) => {
                                input.onChange(e);
                                const cidade = await cidadeDeCep(input.value);
                                form.change("cidade", cidade.toUpperCase());
                            }} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="cidade" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.cidade} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="uf">
                            {({ input, meta }) => {
                                // <AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.uf} />
                                const estados = [
                                    { estado: 'Acre', sigla: 'AC' },
                                    { estado: 'Alagoas', sigla: 'AL' },
                                    { estado: 'Amapá', sigla: 'AP' },
                                    { estado: 'Amazonas', sigla: 'AM' },
                                    { estado: 'Bahia', sigla: 'BA' },
                                    { estado: 'Ceará', sigla: 'CE' },
                                    { estado: 'Distrito Federal', sigla: 'DF' },
                                    { estado: 'Espírito Santo', sigla: 'ES' },
                                    { estado: 'Goiás', sigla: 'GO' },
                                    { estado: 'Maranhão', sigla: 'MA' },
                                    { estado: 'Mato Grosso', sigla: 'MT' },
                                    { estado: 'Mato Grosso do Sul', sigla: 'MS' },
                                    { estado: 'Minas Gerais', sigla: 'MG' },
                                    { estado: 'Pará', sigla: 'PA' },
                                    { estado: 'Paraíba', sigla: 'PB' },
                                    { estado: 'Paraná', sigla: 'PR' },
                                    { estado: 'Pernambuco', sigla: 'PE' },
                                    { estado: 'Piauí', sigla: 'PI' },
                                    { estado: 'Rio de Janeiro', sigla: 'RJ' },
                                    { estado: 'Rio Grande do Norte', sigla: 'RN' },
                                    { estado: 'Rio Grande do Sul', sigla: 'RS' },
                                    { estado: 'Rondônia', sigla: 'RO' },
                                    { estado: 'Roraima', sigla: 'RR' },
                                    { estado: 'Santa Catarina', sigla: 'SC' },
                                    { estado: 'São Paulo', sigla: 'SP' },
                                    { estado: 'Sergipe', sigla: 'SE' },
                                    { estado: 'Tocantins', sigla: 'TO' }
                                ];
                                return (<AbaseAutoCompleteFieldFinalForm data={estados} label={ContasLabel.uf} valueField="sigla" textField="estado" {...input} />);
                            }}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="logradouro" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.logradouro} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Field name="numero">
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.numero} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Field name="bairro" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.bairro} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6}>
                        <Field name="complemento" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.complemento} />)}
                        </Field>
                    </Grid>
                    <Grid item xs={12}>
                        <Field name="observacao" parse={defaultFieldParser}>
                            {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ContasLabel.observacao} />)}
                        </Field>
                    </Grid>
                </Grid>
            </DialogForm>)} />);
        }}
    </ContasQueryByIdComponent>);
}
export default withRouter(ContasForm);