/* eslint-disable no-unused-vars */
import React from 'react';
export default class DynamicFormView extends React.Component {
    constructor() {
        super(...arguments);
        this.renderField = (values, field) => {
            const value = values[field.id] || "";
            const meta = { touched: true, error: !value && field.required ? "Campo obrigatório" : "" };
            switch (field.type) {
                case "text":
                    return <React.Fragment key={field.id}>{field.label}: <b>{value}</b><br /></React.Fragment>;
                default:
                    return <React.Fragment key={field.id}>{field.label}: <b>{value}</b><br /></React.Fragment>;
            }
        };
    }
    render() {
        const form = this.props.formData ? JSON.parse(this.props.formData) : [];
        const values = this.props.value ? JSON.parse(this.props.value) : {};
        return (<React.Fragment>
            {form.map(this.renderField.bind(this, values))}
        </React.Fragment>);
    }
}
