const names = {
    "form": "Empresa",
    "id": "Id",
    "razaosocial": "Razão Social",
    "nomefantasia": "Nome Fantasia",
    "agenda": "Agenda",
    "cliente": "Cliente",
    "recurso": "Recurso",
    "usuarioEmpresa": "Usuário Empresa",
};
export default names;
