import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { EmpresaAllComponent, RecursoTipoAllComponent, RecursoQueryByIdComponent, useRecursoCreate, useRecursoUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import RecursoLabel from './RecursoNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import DynamicFormRenderer from '../../components/DynamicFormRenderer';
import { Grid } from '@material-ui/core';
const RecursoSchema = Yup.object()
    .shape({
        id: Yup.string().notRequired(),
        empId: Yup.string().required(),
        recId: Yup.string().required(),
        nome: Yup.string().required(),
        dadosFormulario: Yup.string().notRequired()
    })
    .noUnknown();
function RecursoForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useRecursoUpdate();
    const insert = useRecursoCreate();
    const handleSubmit = async (values) => {
        const mutationData = await cast(RecursoSchema, values);
        try {
            if (params && params.id) {
                const result = await update({
                    variables: {
                        id: params.id,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.recurso.update.id'));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, 'data.recurso.create.id'));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack('save'));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage('query_one', err) });
        }
    };
    const notEditting = !params || !params.id;
    const isUsingDraft = notEditting;
    return (<RecursoQueryByIdComponent variables={params} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage('query_one', err) });
    }}>
        {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(RecursoSchema)} initialValues={_.get(data, 'recurso.list[0]', {})} decorators={defaultDecorators} render={({ handleSubmit, form, submitting, values }) => (<DialogForm title="Recurso" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
            <AbaseLoading open={loading || submitting} />
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Field name="nome" parse={defaultFieldParser}>
                        {({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={RecursoLabel.nome} />)}
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <Field name="dadosFormulario">
                        {({ input, meta }) => {
                            console.log(values);
                            return (<DynamicFormRenderer value={input.value} onChange={input.onChange} formData={values.req ? values.req.formulario : undefined} />);
                        }}
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <EmpresaAllComponent>
                        {({ data, loading }) => (<Field name="empId">
                            {({ input, meta }) => (<AbaseAutoCompleteFieldFinalForm isLoading={loading} label={RecursoLabel.emp} valueField={'id'} textField={'razaosocial'} meta={meta} data={_.get(data, 'empresa.list', [])} {...input} />)}
                        </Field>)}
                    </EmpresaAllComponent>
                </Grid>
                <Grid item xs={12}>
                    <RecursoTipoAllComponent>
                        {({ data, loading }) => (<Field name="recId">
                            {({ input, meta }) => {
                                const selectedRecurso = _.first(_.get(data, 'recursoTipo.list', []).filter(x => x.id === input.value));
                                return (<React.Fragment>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <AbaseAutoCompleteFieldFinalForm isLoading={loading} label={RecursoLabel.rec} valueField={'id'} textField={'descricao'} data={_.get(data, 'recursoTipo.list', [])} {...input} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field name="dadosFormulario">{({ input, meta }) => (<DynamicFormRenderer {...input} formData={selectedRecurso ? selectedRecurso.formulario : null} />)}</Field>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>);
                            }}
                        </Field>)}
                    </RecursoTipoAllComponent>
                </Grid>
            </Grid>
        </DialogForm>)} />)}
    </RecursoQueryByIdComponent>);
}
export default RecursoForm;
