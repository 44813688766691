/* eslint-disable no-unused-vars */
import styled from "@emotion/styled";
import { Typography } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { Progress } from "semantic-ui-react";
import { formatDateTime, diferencaEmHorasParaHoje } from "../../helpers/format";
import { colors, grid } from "./constants";
import classNames from "classnames";
const getBackgroundColor = (isDragging, isGroupedOver) => {
  if (isDragging) {
    return "rgb(222, 235, 255)";
  }
  if (isGroupedOver) {
    return colors.N30;
  }
  return colors.N0;
};
const getBorderColor = (isDragging, authorColors) => isDragging ? authorColors.hard : "transparent";
const Container = styled.a`
  background-color: ${(props) => getBackgroundColor(props.isDragging, props.isGroupedOver)};
  box-shadow: ${
  //@ts-ignore
  ({ isDragging }) => isDragging ? `2px 2px 1px ${colors.N70}` : "none"};
  min-height: 40px;
  user-select: none;
  padding: ${grid}px;
  /* anchor overrides */
  color: ${colors.N900};
  margin-bottom: ${grid}px;
  border: 1px solid #e0e0e0;

  &:hover,
  &:active {
    color: ${colors.N900};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: #fff;
    box-shadow: none;
    border-color: ${(props) => props.colors ? props.colors.hard : "#fff"};
  }

  /* flexbox */
  display: flex;
`;
const Avatar = styled.img`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  margin-right: ${grid}px;
`;
const Content = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
  Needed to wrap text in ie11
  https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;
  /* flex parent */
  display: flex;
  flex-direction: column;
`;
const BlockQuote = Typography;
const Footer = styled.div`
  display: block;
  align-items: center;
`;
const Warn = styled.small`
  padding: 3px 5px;
  font-weight: normal;
  background-color: "#e7f436";
`;
const QuoteId = styled.small`
  margin: 0;
  float: left;
  display: block;
  font-weight: normal;
  text-overflow: ellipsis;
`;
// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
export default class QuoteItem extends React.PureComponent {
  render() {
    const { quote, isDragging, isGroupedOver, provided } = this.props;
    return (<Container isDragging={isDragging} isGroupedOver={isGroupedOver} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

      <Content onClick={() => this.props.onClickItem(quote)}>
        <div style={{ position: "relative" }}>
          <Typography>
            <BlockQuote>{_.get(quote, "tar.descricao")}</BlockQuote>
            {this.props.onDelete ? (<div style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer"
            }} onClick={(e) => {
              e.stopPropagation();
              if (this.props.onDelete)
                this.props.onDelete(quote);
            }}>
              <i className={"material-icons"} style={{ fontSize: 18 }}>
                delete_outline
                  </i>
            </div>) : null}
            <Footer>
              {_.get(quote, "agendaTarefa[0].age.titulo") ?
                <div className={"colRow"}>
                  <small>
                    Agenda: <b>{_.get(quote, "agendaTarefa[0].age.titulo")}</b>
                  </small>
                </div>
                : null}
              {_.get(quote, "descricao") ?
                <div className={"colRow"}>
                  <small>
                    Obs. da Agenda: <b>{_.get(quote, "descricao")}</b>
                  </small>
                </div>
                : null}
              {_.get(quote, "usu.nome") ? (<div className={"colRow"}>
                <small>
                  Responsável: <b>{_.get(quote, "usu.nome")}</b>
                </small>
              </div>) : null}
              {quote.datalimite ? (<div className={"colRow"}>
                <QuoteId>
                  Data Limite:{" "}
                  <b className={classNames({
                    alertYellow: quote.porcentagem !== 100 &&
                      diferencaEmHorasParaHoje(quote.datalimite) < 48,
                    alertRed: quote.porcentagem !== 100 &&
                      diferencaEmHorasParaHoje(quote.datalimite) < 0
                  })}>
                    {formatDateTime(quote.datalimite)}
                  </b>
                </QuoteId>
              </div>) : null}
              <div className={"colRow"}>
                <Progress style={{ margin: "5px 0 0 0" }} success={quote.porcentagem === 100} value={quote.porcentagem} total={100} progress="percent" />
              </div>


            </Footer>
          </Typography>
        </div>
      </Content>
    </Container>);
  }
}
const ContainerPlaceholder = styled.a`
  padding: 8px;
  color: #404040;
  cursor: pointer;
  user-select: none;
  text-align: center;
  box-shadow: "none";
  /* anchor overrides */
  background-color: #dadada;

  &:hover,
  &:active {
    color: ${colors.N900};
    text-decoration: none;
  }

  p {
    font-weight: bold;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;
export class QuoteItemPlaceholder extends React.PureComponent {
  render() {
    const { isDragging, isGroupedOver, provided, onAdd } = this.props;
    return (<ContainerPlaceholder onClick={onAdd}>
      <Content>
        <Typography>
          <BlockQuote> NOVO</BlockQuote>
        </Typography>
      </Content>
    </ContainerPlaceholder>);
  }
}
