/* eslint-disable no-useless-escape */
import React from 'react';
import { AbaseAutocompleteField } from 'abase-components';
import { ClienteContasComponent } from '../generated/graphql';
import _ from 'lodash';
const fixTypescript = AbaseAutocompleteField;
const CardCliente = ({ data, ...otherProps }) => {
    let style = { borderBottom: "1px dashed #cccccc", width: "100%", fontSize: '12px', lineHeight: '18px', display: 'block' };
    const cssInativo = { color: "#898989" };
    if (!data.ativo) {
        style = { ...style, ...cssInativo };
    }
    return (<fixTypescript.Components.Option {...otherProps}>
            <div style={style}>
                <strong>{data.tipopessoa === "FISICA" ? "Nome" : "Razão Social"}</strong>: {data.razaosocial}<br />
                <strong>{data.tipopessoa === "FISICA" ? "Apelido" : "Nome Fantasia"}</strong>: {data.nome}<br />
                <strong>{data.cpfcnpj && data.cpfcnpj.length < 14 ? "CPF" : "CNPJ"}:</strong>{data.cpfcnpj}&nbsp;{!data.ativo ? <strong>(inativo)&nbsp;</strong> : null}{data.__typename === "ClienteType" ? <strong>(cliente)</strong> : <strong>(lead)</strong>}
            </div>
        </fixTypescript.Components.Option>);
};
export default function AutoCompleteCliente(props) {
    const [searchCliente, setSearchCliente] = React.useState("");
    console.log({ empId: props.empId });
    return (<ClienteContasComponent skip={!searchCliente} variables={{
        filter: searchCliente,
        empId: props.empId ? props.empId : undefined
    }}>
            {({ data, loading, refetch, fetchMore }) => (<AbaseAutocompleteField noOptionsMessage={() => {
        return (searchCliente ?
            (loading ? "Carregando" : `Nenhuma opção encontrada para \"${searchCliente}\"`) : "Digite para buscar");
    }} onInputChange={searchString => {
        setSearchCliente(searchString);
        // refetch({
        // 	filter: searchString
        // })
    }} getOptionValue={(x) => x} isLoading={loading} label={"Cliente"} getOptionLabel={x => x.razaosocial + " " + (!x.ativo ? "(inativo)" : "")} textField={'nome'} data={_.get(data, 'cliente.contas', [])} itemHeight={73} components={{ Option: CardCliente }} filterOption={(candidate, value) => {
        return true;
    }} {...props}/>)}</ClienteContasComponent>);
}
