import { ListItemText, ListItem, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
function MenuWithSubmenu(props) {
    const [anchorElement, setAnchorElement] = useState(null);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const onClickItem = (item) => () => {
        if (item.route.startsWith("http")) {
            window.open(item.route)
        } else {
            props.history.push(item.route + "");

        }
        handleClose();
    };
    return (<React.Fragment>

        <Menu anchorEl={anchorElement} open={open} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} transformOrigin={{ vertical: "top", horizontal: "center" }} getContentAnchorEl={null} className="menuIten">
            {props.items.filter(x => x !== null).map(item => (<MenuItem onClick={onClickItem(item)} key={item.label}>{item.label}</MenuItem>))}
        </Menu>
        <ListItem button onClick={event => {
            setAnchorElement(event.target);
            setOpen(true);
        }}>
            <ListItemText primary={props.label} />
            {open ? <i className="material-icons">arrow_drop_up</i> : <i className="material-icons">arrow_drop_down</i>}
        </ListItem>
    </React.Fragment>);
}
export default withRouter(MenuWithSubmenu);
