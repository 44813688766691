const names = {
    "form": "Tarefa",
    "id": "ID",
    "usuId": "Usuário ID",
    "datatarefa": "Data da Tarefa",
    "descricao": "Descrição",
    "status": "Status",
    "datainicio": "Data Início",
    "dataconclusao": "Data Conclusão",
    "datalimite": "Data Limite",
    "usu": "Responsável",
    "agendaTarefa": "Agenda Tarefa",
    "agendaTimeline": "Agenda Timeline",
};
export default names;
export const tarefaStatus = {
    'FAZER': {
        text: "A fazer",
        icon: "check_box"
    },
    'EXECUTANDO': {
        text: "Executando",
        icon: "intermediate_checkbox"
    },
    'BLOQUEADO': {
        text: "Bloqueado",
        icon: "intermediate_checkbox"
    },
    'FINALIZADO': {
        text: "Finalizado",
        icon: "close"
    },
    'CANCELADO': {
        text: "Cancelado",
        icon: "close"
    },
};
export const tarefaStatusList = Object.keys(tarefaStatus).map(x => ({ id: x, ...tarefaStatus[x] }));
