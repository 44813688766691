import { AbaseLoading, AbaseTextField, FormDraft, clearDraft, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from "react-final-form";
import DialogForm from '../../DialogForm';
import * as Yup from 'yup';
import { ModeloEtapaQueryByIdComponent, useModeloEtapaCreate, useModeloEtapaUpdate } from '../../generated/graphql';
import { defaultDecorators, defaultErrorMessage, defaultSnack, redirectBackForm } from '../../helpers';
import { validate, cast } from '../../helpers/validations';
import ModeloEtapaLabel from './ModeloEtapaNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { Grid } from '@material-ui/core';
const ModeloEtapaSchema = Yup.object().shape({
    id: Yup.string().notRequired(),
    modId: Yup.string().notRequired().required("Não foi possivel verificar o modelo que esta sendo utilizado!"),
    descricao: Yup.string().required(),
    observacao: Yup.string().notRequired(),
    ordem: Yup.number().required(),
}).noUnknown();
function ModeloEtapaForm(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError, showSnack } = useAbaseDialog();
    const update = useModeloEtapaUpdate();
    const insert = useModeloEtapaCreate();
    console.log(params);
    const handleSubmit = async (values) => {
        const mutationData = await cast(ModeloEtapaSchema, values);
        try {
            if (params && params.idEtapa) {
                const result = await update({
                    variables: {
                        id: params.idEtapa,
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.modeloEtapa.update.id"));
                }
            }
            else {
                const result = await insert({
                    variables: {
                        data: mutationData
                    }
                });
                if (props.onSave) {
                    props.onSave(_.get(result, "data.modeloEtapa.create.id"));
                }
                clearDraft();
            }
            redirectBackForm(history, location);
            showSnack(defaultSnack("save"));
        }
        catch (err) {
            console.log({ err });
            showError({ message: defaultErrorMessage("query_one", err) });
        }
    };
    const notEditting = !params || !params.idEtapa;
    const isUsingDraft = notEditting;
    return (<ModeloEtapaQueryByIdComponent variables={{ id: params.idEtapa }} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
            {({ data, loading, refetch, error }) => (<FormDraft useDraft={notEditting} onSubmit={handleSubmit} validate={validate(ModeloEtapaSchema)} initialValues={_.get(data, "modeloEtapa.list[0]") || {
        modId: params.modeloProjetoId
    }} decorators={defaultDecorators} render={({ handleSubmit, form, submitting }) => (<DialogForm title="Etapa do Modelo" onSubmit={handleSubmit} reset={isUsingDraft ? form.reset : undefined}>
                            <AbaseLoading open={loading || submitting}/>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Field name="descricao" parse={defaultFieldParser}>{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ModeloEtapaLabel.descricao}/>)}</Field>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field name="ordem">{({ input, meta }) => (<AbaseTextField meta={meta} fullWidth={true} {...input} label={ModeloEtapaLabel.ordem}/>)}</Field>
                                </Grid>
                            </Grid>
                        </DialogForm>)}/>)}
        </ModeloEtapaQueryByIdComponent>);
}
export default ModeloEtapaForm;
