import { AbaseLoading, useAbaseDialog, AbaseTextField, ResponsivePaperTable } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { defaultErrorMessage, redirectBackForm, errorMessagePaperTable } from '../../helpers';
import { Grid, Button, Typography, withStyles } from '@material-ui/core';
import { formataCpfCnpj } from '../../helpers/format';
import { ClienteQueryByIdComponent, UsuarioContatoClienteAllComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { Field } from 'react-final-form';
import DialogForm from '../../DialogForm';
function ClienteView(props) {
    const { match, history, location } = props;
    const { params } = match;
    const { showError } = useAbaseDialog();
    const notEditting = !params || !params.id;
    const onClose = () => {
        redirectBackForm(history, location);
    };
    return (<ClienteQueryByIdComponent variables={{ id: params.id }} skip={notEditting} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
        redirectBackForm(history, location);
    }}>
        {({ data, loading }) => {
            const values = _.get(data, "cliente.list[0]") || {};
            return (<DialogForm title={"Cliente"} onSubmit={() => onClose()} actions={<Grid container spacing={0}>
                <Grid item xs={12} className="center mb15">
                    <Button onClick={onClose} key="cancelar" variant="contained">
                        Fechar<i className="material-icons">close</i>
                    </Button>
                </Grid>
            </Grid>}>
                <AbaseLoading open={loading} />
                <div className="colRow f-12">
                    <Typography color="textSecondary" gutterBottom>
                        <b>As informações dos clientes não podem ser alteradas a partir do SAC-G</b>
                    </Typography>
                    <ul className="listaHome mt15 f-13 lnHeig">
                        <li>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div style={{ backgroundColor: _.get(values, "contratoativo", false) ? 'Green' : "Grey" }} className="workflow">
                                        <span>{_.get(values, "contratoativo", false) ? "Contrato Ativo" : "Contrato Inativo"}</span>
                                        <div className="seta-direita"></div>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <p>
                                        Empresa: <strong>{_.get(values, "emp.nomefantasia") || "N/A"}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p>
                                        Gestor Código Cliente: <strong>{_.get(values, "gestorCodigocliente", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p>
                                        CPF/CNPJ: <strong>{formataCpfCnpj(_.get(values, "cpfcnpj")) || "N/A"}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p>
                                        Nome: <strong>{_.get(values, "nome", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p>
                                        Razão Social: <strong>{_.get(values, "razaosocial", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p>
                                        Atividade: <strong>{_.get(values, "atividade.descricao", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p>
                                        Categoria: <strong>{_.get(values, "categoria.descricao", 'N/A')}</strong>
                                    </p>
                                </Grid>
                            </Grid>
                        </li>
                    </ul>


                    <ul className="listaHome">
                        <li>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <p>
                                        Inscrição Estadual: <strong>{_.get(values, "inscricaoestadual", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12}>
                                    <p>
                                        RG: <strong>{_.get(values, "rg", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        Telefone: <strong>{_.get(values, "telefone", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        Tipo de Pessoa: <strong>{_.get(values, "tipopessoa", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        E-mail: <strong>{_.get(values, "email", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        CEP: <strong>{_.get(values, "cep", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        Cidade: <strong>{_.get(values, "cidade", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        UF: <strong>{_.get(values, "uf", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        Logradouro: <strong>{_.get(values, "logradouro", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        Bairro: <strong>{_.get(values, "bairro", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        Número: <strong>{_.get(values, "numero", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        Complemento: <strong>{_.get(values, "complemento", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        Gestor Código Empresa: <strong>{_.get(values, "gestorCodigoempresa", 'N/A')}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <p>
                                        Possui Pendência Financeira: <strong>{_.get(values, "possuiPendenciaFinanceira", false) ? "Sim" : 'Não'}</strong>
                                    </p>
                                </Grid>
                            </Grid>
                        </li>
                    </ul>
                    {
                        _.get(values, "clienteObservacao", []).map((obs, index) => {
                            return <ul className="listaHome mt15 f-13 lnHeig">
                                <li>
                                    <Grid container spacing={1}>
                                        <div>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <p>
                                                    <strong>Observação {index + 1}</strong>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <p>
                                                    Tipo: <strong>{_.get(obs, "observacao.descricao") || "N/A"}</strong>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <p>
                                                    Complemento: <strong>{_.get(obs, "complemento") || "N/A"}</strong>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <p>
                                                    Data de Lançamento: <strong>{_.get(obs, "datalancamento") ? new Date(_.get(obs, "datalancamento")).toLocaleDateString() : 'N/A'}</strong>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <p>
                                                    Data de Baixa: <strong>{_.get(obs, "databaixa") ? new Date(_.get(obs, "databaixa")).toLocaleDateString() : 'N/A'}</strong>
                                                </p>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </li>
                            </ul>
                        })
                    }
                    <GraphqlPaperTableListener extraVariables={{ cliId: params.id }}>{({ variables, setVariables }) => (<UsuarioContatoClienteAllComponent variables={{ ...variables, cliId: params.id }}>
                        {({ data, loading, refetch, error }) => {
                            return <ResponsivePaperTable sticky={true} selectMode={"single"} loading={loading} data={_.get(data, "usuario.list") || []} total={_.get(data, "usuario.list", []).length} columns={[
                                {
                                    name: "Id",
                                    field: "id",
                                    defaultVisible: false
                                }, {
                                    name: "Nome",
                                    field: "nome",
                                    defaultVisible: true
                                }, {
                                    name: "Email",
                                    field: "email",
                                    defaultVisible: true
                                }, {
                                    name: "Telefone",
                                    field: "telefone",
                                    defaultVisible: true
                                }, {
                                    name: "Ramal",
                                    field: "ramal",
                                    defaultVisible: true
                                }, {
                                    name: "Setor",
                                    field: "setor",
                                    defaultVisible: true
                                },
                            ]} customFilters={() => [
                                <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
                                <Field name={"nome"}>{({ input, meta }) => <AbaseTextField label={"Nome"} {...input} meta={meta}></AbaseTextField>}</Field>,
                                <Field name={"email"}>{({ input, meta }) => <AbaseTextField label={"Email"} {...input} meta={meta}></AbaseTextField>}</Field>,
                            ]} title={'Contatos'} requestData={setVariables} error={errorMessagePaperTable(error)} customSelectedActions={(id) => []} customActions={[]} tableProps={{
                                defaultOrderBy: "nome"
                            }}></ResponsivePaperTable>;
                        }}
                    </UsuarioContatoClienteAllComponent>)}
                    </GraphqlPaperTableListener>
                </div>
            </DialogForm>);
        }}
    </ClienteQueryByIdComponent>);
}
const styles = {
    bullet: {
        margin: '0 2px',
        transform: 'scale(0.8)',
        display: 'inline-block',
    },
    title: {
        fontSize: 18,
        color: 'black'
    }
};
export default withStyles(styles)(ClienteView);
