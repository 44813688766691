import { AbaseLoading, FormDraft, useAbaseDialog } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import DialogForm from '../../DialogForm';
import { AgendaTimelinePorAgendaComponent } from '../../generated/graphql';
import { defaultErrorMessage } from '../../helpers';
import UserAvatarSac from '../../components/UserAvatarSac';
import { Grid } from '@material-ui/core';
import { formatDateTime } from '../../helpers/format';
function AgendaTimelineView(props) {
    const { agendaId } = props;
    const { showError } = useAbaseDialog();
    return (<AgendaTimelinePorAgendaComponent variables={{ agendaId }} onError={(err) => {
        showError({ message: defaultErrorMessage("query_one", err) });
    }}>
        {({ data, loading }) => (<FormDraft useDraft={false} onSubmit={() => { }} render={({ handleSubmit, submitting }) => (<DialogForm title="Histórico de alterações" onSubmit={handleSubmit} actions={[]}>
            <AbaseLoading open={loading || submitting} />
            {data && data.agendaTimeline && data.agendaTimeline.list ? (<div className="colRow f-12">
                <ul className="listaHome">
                    {data.agendaTimeline.list.map((item, i) => {
                        return (<li key={i}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={1} lg={1}>
                                    <UserAvatarSac name={_.get(item, "usu.nome")} url={_.get(item, "usu.avatarUrl")} color={_.get(item, "usu.corAvatar")} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9}>
                                    <b>{_.get(item, "usu.nome")}</b><br />
                                    {_.get(item, "acao", "").split("\n").map((x, i) => <p key={i}>{x}</p>)}
                                    <b>{formatDateTime(_.get(item, "data"))}</b>
                                </Grid>
                            </Grid>
                        </li>);
                    })}
                </ul>
                {data.agendaTimeline.list.length === 0 ? (<div className="attention">
                    <i className="material-icons">warning</i> Não há nenhuma alteração lançada
                                                    </div>) : null}
            </div>) : null}



        </DialogForm>)} />)}
    </AgendaTimelinePorAgendaComponent>);
}
export default AgendaTimelineView;
