/* eslint-disable no-unused-vars */
import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction, AbaseAutoCompleteFieldFinalForm } from 'abase-components';
import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import { RestauranteListComponent } from '../../generated/graphql';
import GraphqlPaperTableListener from '../../GraphqlPaperTableListener';
import { errorMessagePaperTable } from '../../helpers';
import ReenterRouteListener from '../../ReenterRouteListener';
import RenderWithModal from '../../RenderWithModal';
import RestauranteDelete from './RestauranteDelete';
import RestauranteForm from './RestauranteForm';
import RestauranteLabel from './RestauranteNameLabel';
import { defaultFieldParser } from '../../helpers/finalFormDecorators';
import { IconButton } from '@material-ui/core';
export default function RestauranteList(props) {
    const { match } = props;
    const columns = [
        {
            name: RestauranteLabel.id,
            field: 'id',
            defaultVisible: false
        },
        {
            name: RestauranteLabel.nome,
            field: 'nome',
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: RestauranteLabel.telefone,
            field: 'telefone',
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: RestauranteLabel.celular,
            field: 'celular',
            defaultVisible: false,
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: RestauranteLabel.email,
            field: 'email',
            defaultVisible: false,
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: RestauranteLabel.site,
            field: 'site',
            customBodyRender: (value) => (value ? value : 'N/A'),
            defaultVisible: false
        },
        {
            name: RestauranteLabel.cidade,
            field: 'cidade',
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: RestauranteLabel.endereco,
            field: 'endereco',
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: RestauranteLabel.preferencia,
            field: 'preferencia',
            customBodyRender: (value) => {
                return value ? value : 0;
            }
        },
        {
            name: RestauranteLabel.precoBuffetLivre,
            field: 'precoBuffetLivre',
            customBodyRender: (value) => value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : 'N/A'
        },
        {
            name: RestauranteLabel.precoKg,
            field: 'precoKg',
            customBodyRender: (value) => value ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value) : 'N/A'
        },
        {
            name: RestauranteLabel.tipo,
            field: 'tipo',
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: RestauranteLabel.referencia,
            field: 'referencia',
            defaultVisible: false,
            customBodyRender: (value) => (value ? value : 'N/A')
        },
        {
            name: RestauranteLabel.cafeManha,
            field: 'cafeManha',
            customBodyRender: (value) => {
                return value ? 'Sim' : 'Não';
            }
        },
        {
            name: RestauranteLabel.almoco,
            field: 'almoco',
            customBodyRender: (value) => {
                return value ? 'Sim' : 'Não';
            }
        },
        {
            name: RestauranteLabel.janta,
            field: 'janta',
            customBodyRender: (value) => {
                return value ? 'Sim' : 'Não';
            }
        }
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.path}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" component={Link} to={`${match.path}/delete/${_.map(selected, 'id').join(',')}`} />
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.path}/add`} />,
        <IconButton aria-label={`Mapa`} onClick={() => setShowMap(!showMap)}>
            <i className={'material-icons'}>place</i>
        </IconButton>
    ];
    const filters = () => [
        <Field name={'id'}>{({ input, meta }) => <AbaseTextField label={'ID'} {...input} meta={meta} />}</Field>,
        <Field parse={defaultFieldParser} name={'nome'}>
            {({ input, meta }) => <AbaseTextField label={'Nome'} {...input} meta={meta} />}
        </Field>,
        <Field name={'telefone'}>
            {({ input, meta }) => <AbaseTextField label={'Telefone'} {...input} meta={meta} />}
        </Field>,
        <Field parse={defaultFieldParser} name={'email'}>
            {({ input, meta }) => <AbaseTextField label={'E-mail'} {...input} meta={meta} />}
        </Field>,
        <Field name={'site'}>{({ input, meta }) => <AbaseTextField label={'Site'} {...input} meta={meta} />}</Field>,
        <Field parse={defaultFieldParser} name={'cidade'}>
            {({ input, meta }) => <AbaseTextField label={'Cidade'} {...input} meta={meta} />}
        </Field>,
        <Field parse={defaultFieldParser} name={'endereco'}>
            {({ input, meta }) => <AbaseTextField label={'Endereço'} {...input} meta={meta} />}
        </Field>,
        <Field name={'celular'}>
            {({ input, meta }) => <AbaseTextField label={'Celular'} {...input} meta={meta} />}
        </Field>,
        <Field name={'tipo'}>
            {({ input, meta }) => {
                var tipos = [
                    { nome: 'SORVETERIA' },
                    { nome: 'TEMÁTICO' },
                    { nome: 'BAR' },
                    { nome: 'BISTRO' },
                    { nome: 'CANTINA' },
                    { nome: 'CHURRASCARIA' },
                    { nome: 'BUFFET' },
                    { nome: 'DOÇARIA' },
                    { nome: 'DRIVE-IN' },
                    { nome: 'FOOD TRUCK' },
                    { nome: 'LANCHONETE' },
                    { nome: 'PIZZARIA' },
                    { nome: 'RANCHO' },
                    { nome: 'FAST FOOD' },
                    { nome: 'CAFÉ' }
                ];
                return (<AbaseAutoCompleteFieldFinalForm data={tipos} label={RestauranteLabel.tipo} valueField="nome" textField="nome" {...input} />);
            }}
        </Field>,
        <Field parse={defaultFieldParser} name={'referencia'}>
            {({ input, meta }) => <AbaseTextField label={'Referência'} {...input} meta={meta} />}
        </Field>
    ];
    const formName = RestauranteLabel.form;
    let [showMap, setShowMap] = React.useState(false);
    let initial = [];
    let [restaurantes, setRestaurantes] = React.useState(initial);
    return (<GraphqlPaperTableListener>
        {({ variables, setVariables }) => (<React.Fragment>
            <RestauranteListComponent variables={variables} skip={variables === null}>
                {({ data, loading, refetch, error }) => {
                    if (!loading)
                        setRestaurantes(_.get(data, 'restaurante.pagedList.data', []));
                    return (<div>
                        <AbasePaperTable
                            customPlacehorderSearch={"Pesquisar por Nome, celular, cidade, email, endereço, referencia, site, telefone ou tipo"}
                            sticky={true}
                            selectMode={showMap ? 'multi' : 'single'}
                            loading={loading}
                            data={_.get(data, 'restaurante.pagedList.data') || []}
                            total={_.get(data, 'restaurante.pagedList.total')}
                            columns={columns}
                            customFilters={filters}
                            title={formName}
                            requestData={setVariables}
                            error={errorMessagePaperTable(error)}
                            customSelectedActions={selectedActions}
                            customActions={customActions}
                            selectListener={props.selectListener}
                            onDoubleClick={row => {
                                props.history.push(`${match.path}/edit/${row.id}`);
                            }} />
                        <ReenterRouteListener callback={refetch} />
                        <Route path={`${match.path}/add`} render={RenderWithModal(RestauranteForm)} />
                        <Route path={`${match.path}/edit/:id`} render={RenderWithModal(RestauranteForm)} />
                        <Route path={`${match.path}/delete/:id`} render={RenderWithModal(RestauranteDelete)} />
                    </div>);
                }}
            </RestauranteListComponent>
        </React.Fragment>)}
    </GraphqlPaperTableListener>);
}
