import { AbasePaperTable, AbaseTextField, AddAction, DeleteAction, EditAction } from "abase-components";
import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { ParceiroListComponent } from "../../generated/graphql";
import GraphqlPaperTableListener from "../../GraphqlPaperTableListener";
import { errorMessagePaperTable } from "../../helpers";
import ReenterRouteListener from "../../ReenterRouteListener";
import RenderWithModal from "../../RenderWithModal";
import ParceiroDelete from "./ParceiroDelete";
import ParceiroForm from "./ParceiroForm";
import ParceiroLabel from './ParceiroNameLabel';
export default function ParceiroList(props) {
    const { match } = props;
    const columns = [
        {
            name: ParceiroLabel.id,
            field: "id",
            defaultVisible: false
        },
        {
            name: ParceiroLabel.nome,
            field: "nome"
        },
        {
            name: ParceiroLabel.usuarios,
            field: "usuario",
            customBodyRender: (val, row) => {
                return val.length;
            },
            width: 100
        },
        {
            name: ParceiroLabel.endereco,
            field: "endereco",
            defaultVisible: true
        },
        {
            name: ParceiroLabel.site,
            field: "site",
            defaultVisible: true
        },
        {
            name: "Email",
            field: "email",
            defaultVisible: true
        },
        {
            name: "Telefone",
            field: "telefone",
            defaultVisible: true
        },
    ];
    const selectedActions = (selected) => {
        if (props.foreing)
            props.foreing(selected);
        return [
            selected.length === 1 ? (<EditAction key="edit" to={`${match.url}/edit/${selected[0].id}`} component={Link} />) : null,
            <DeleteAction key="delete" component={Link} to={`${match.url}/delete/${_.map(selected, "id").join(",")}`} />
        ];
    };
    const customActions = [
        <AddAction key="add" component={Link} to={`${match.url}/add`} />,
    ];
    const filters = () => ([
        <Field name={"id"}>{({ input, meta }) => <AbaseTextField label={"ID"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"nome"}>{({ input, meta }) => <AbaseTextField label={"Nome"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"endereco"}>{({ input, meta }) => <AbaseTextField label={"Endereço"} {...input} meta={meta}></AbaseTextField>}</Field>,
        <Field name={"site"}>{({ input, meta }) => <AbaseTextField label={"Site"} {...input} meta={meta}></AbaseTextField>}</Field>,
    ]);
    const formName = ParceiroLabel.form;
    return (<GraphqlPaperTableListener>{({ variables, setVariables }) => (<ParceiroListComponent variables={variables} skip={variables === null}>
        {({ data, loading, refetch, error }) => (<div>

            <AbasePaperTable
                customPlacehorderSearch={'Pesquisar por nome ou email'}
                sticky={true}
                selectMode={"multi"}
                loading={loading}
                data={_.get(data, "parceiro.pagedList.data") || []}
                total={_.get(data, "parceiro.pagedList.total")}
                columns={columns}
                customFilters={filters}
                title={formName}
                requestData={setVariables}
                error={errorMessagePaperTable(error)}
                customSelectedActions={selectedActions}
                customActions={customActions}
                selectListener={props.selectListener}
                onDoubleClick={row => {
                    props.history.push(`${match.url}/edit/${row.id}`);
                }}></AbasePaperTable>
            <ReenterRouteListener callback={refetch} />
            <Route path={`${match.url}/add`} render={RenderWithModal(ParceiroForm)} />
            <Route path={`${match.url}/edit/:id`} render={RenderWithModal(ParceiroForm)} />
            <Route path={`${match.url}/delete/:id`} render={RenderWithModal(ParceiroDelete)} />

        </div>)}
    </ParceiroListComponent>)}
    </GraphqlPaperTableListener>);
}
