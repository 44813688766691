import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function withIsMobile(wrapperComponent) {
    return (props) => {
        const isMobile = useMediaQuery('@media (max-width:796px)');
        return React.createElement(wrapperComponent, { ...props, isMobile });
    };
}
export function isMobileMediaQuery() {
    const isMobile = useMediaQuery('@media (max-width:796px)');
    return isMobile;
}
export default withIsMobile;
